import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Grid, List, Divider, MenuItem } from "@mui/material";
import { TopMenu } from "./topMenu";
import { ClientMenu } from "./clientMenu";
import { ManagerMenu } from "./managerMenu";
import { MenuDrawer } from "./menuDrawer";
import { MenuButtons } from "./menuButtons";

const getStyling = (background) => {
    let styling = {
        background: "transparent",
        boxShadow: "none",
        color: "#444444",
        buttonClass: "signin-button",
        logo: "WapiDocLogo",
    };
    if (background === "transparent") {
        styling = {
            ...styling,
            background: "transparent",
            boxShadow: "none",
        };
    } else if (background === "transparent-white") {
        styling = {
            ...styling,
            background: "transparent",
            boxShadow: "none",
            buttonClass: "signin-button-white",
            logo: "WapiDocLogoWhite",
            color: "#ffffff",
        };
    } else {
        styling = {
            ...styling,
            background: "#ffffff",
            boxShadow: "0 0.1rem 1rem rgba(0, 0, 0, 0.1)",
        };
    }

    return styling;
};

export const Menu = ({ background }) => {
    const [top, setTop] = useState(true);
    const [styling, setStyling] = useState(getStyling(background));
    const [y, setY] = useState(window.scrollY);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setTop(false);
                setStyling({
                    ...styling,
                    background: "#ffffff",
                    boxShadow: "0 0.1rem 1rem rgba(0, 0, 0, 0.1)",
                    buttonClass: "signin-button",
                    logo: "WapiDocLogo",
                    color: "#333333",
                });
            } else {
                setTop(true);
                setStyling(getStyling(background));
            }
        };

        window.addEventListener("scroll", (e) => handleScroll(e));

        return () => {
            window.removeEventListener("scroll", (e) => handleScroll(e));
        };
    }, [y, background]);

    return (
        <Grid
            container
            className="main-menu"
            style={{
                backgroundColor: `${styling.background}`,
                boxShadow: `${styling.boxShadow}`,
                color: `${styling.color}`,
            }}
        >
            {top && (
                <Grid item xs={12}>
                    <TopMenu bgColor={background} />
                </Grid>
            )}

            <Divider
                style={{
                    width: "100%",
                    margin: "0% auto",
                    background: "#dfdfdf",
                }}
            />
            <Grid item xs={3}>
                <List style={{ display: "flex" }}>
                    <NavLink exact to="/">
                        <img
                            style={{
                                width: "140px",
                                height: "auto",
                                padding: "0% 10%",
                            }}
                            src={`../../${styling.logo}.png`}
                            alt="WapiDocLogo"
                        />
                    </NavLink>
                </List>
            </Grid>
            <Grid
                className={"small-screen"}
                item
                xs={9}
                align="right"
                style={{ paddingTop: "3.5%", paddingRight: "3%" }}
            >
                <MenuDrawer styling={styling} />
            </Grid>
            <Grid
                className={"wide-screen"}
                item
                xs={9}
                align="right"
                style={{ paddingTop: ".6%", paddingRight: "1%" }}
            >
                {localStorage.getItem("token") && (
                    <ClientMenu styling={styling} />
                )}
                {localStorage.getItem("manager") && (
                    <ManagerMenu styling={styling} />
                )}
                {localStorage.getItem("token") === null &&
                    localStorage.getItem("manager") === null && (
                        <MenuButtons btnClass={styling.buttonClass} />
                    )}
            </Grid>
        </Grid>
    );
};
