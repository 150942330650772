import React, { Component } from "react";
import Drawer from "../../components/drawer";
import Dialog from "../../components/dialog";
import ServiceForm from "../../components/forms/service";
import { postRequest } from "../../middleware/graphQl";
import cogs from "../../middleware/cogs";
import { withSnackbar } from "notistack";
import { Link } from "react-router-dom";

import {
    Typography,
    Button,
    CircularProgress,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    CardActions,
    Grid,
} from "@material-ui/core";

class ServicesPage extends Component {
    state = {
        services: [],
        data: {
            care: "",
            description: "",
            institution: JSON.parse(localStorage.getItem("institution"))._id,
        },
        institution: JSON.parse(localStorage.getItem("institution")),
        loading: true,
        errors: {
            care: false,
            description: false,
        },
        dialog: { open: false, error: false, error_message: "" },
    };

    componentDidMount = () => {
        this.getServices();
    };

    getServices = async () => {
        const response = await postRequest("getServices", "manager", {
            id: this.state.institution._id,
        });

        if (response.errors) {
            this.setState({ loading: false });
            this.props.enqueueSnackbar(response.errors[0].message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
        } else {
            this.setState({ loading: false, services: response.data.services });
        }
    };

    // open dialog
    openDialog = () => {
        let dialog = this.state.dialog;
        dialog["open"] = true;

        this.setState({
            dialog: dialog,
            data: {
                care: "",
                description: "",
                institution: JSON.parse(localStorage.getItem("institution"))
                    ._id,
            },
        });
    };

    // create service
    createService = async () => {
        let errors = this.state.errors;
        let error = false;
        let dialog = this.state.dialog;

        // name
        if (this.state.data.care === "") {
            errors["care"] = true;
            error = true;
        } else {
            errors["care"] = false;
        }

        dialog["error"] = error;
        this.setState({ errors: errors, dialog: dialog });

        if (!error) {
            const response = await postRequest(
                "createService",
                "manager",
                this.state.data
            );

            if (response.errors) {
                dialog["error"] = true;
                dialog["error_message"] = response.errors[0].message;

                this.setState({
                    loading: false,
                    dialog: dialog,
                });
            } else {
                this.getServices();

                this.props.enqueueSnackbar("Service created successfully", {
                    variant: "success",
                });
                return true;
            }
        }
    };

    render() {
        let services = this.state.services.map((service) => {
            return (
                <Grid item xs={3} style={{ padding: ".5%" }} key={service._id}>
                    <Card>
                        <Link to={`../../manager/service/${service._id}`}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt={cogs.capitalize(service.care[0].name)}
                                    height="140"
                                    image={`../../images/services/${service.primary_photo}.jpg`}
                                    title={cogs.capitalize(
                                        service.care[0].name
                                    )}
                                />
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant="body1"
                                        style={{
                                            wordWrap: "break-word",
                                            color: "#333",
                                        }}
                                    >
                                        {cogs.capitalize(service.care[0].name)}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        {service.description
                                            .substring(0, 80)
                                            .concat("...")}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                        <CardActions>
                            <Button
                                size="small"
                                href={`../../manager/service/${service._id}`}
                                style={{ color: "#13479A" }}
                            >
                                View More
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            );
        });

        return (
            <Drawer
                content={
                    <div>
                        {!this.state.loading ? (
                            <div>
                                <Dialog
                                    title={"Create Service"}
                                    dialog={this.state.dialog}
                                    content={
                                        <ServiceForm
                                            data={this.state.data}
                                            errors={this.state.errors}
                                        />
                                    }
                                    action={() => this.createService()}
                                />
                                {this.state.services.length > 0 ? (
                                    <Grid
                                        container
                                        spacing={4}
                                        style={{
                                            width: "100%",
                                            margin: "2% auto",
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                padding: "5px",
                                                textAlign: "right",
                                            }}
                                        >
                                            {(this.state.institution.type ===
                                                "hospital" ||
                                                this.state.institution.type ===
                                                    "medical-center") && (
                                                <Button
                                                    className="submit-button add-b"
                                                    variant="contained"
                                                    onClick={() =>
                                                        this.openDialog()
                                                    }
                                                >
                                                    Add New Service
                                                </Button>
                                            )}
                                        </Grid>
                                        {services}
                                    </Grid>
                                ) : (
                                    <div
                                        style={{
                                            textAlign: "center",
                                            marginTop: "5%",
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "35%",
                                                height: "auto",
                                            }}
                                            src={"../images/blank.svg"}
                                            alt="No Data"
                                        />
                                        <br />
                                        <Button
                                            className="submit-button mini-b"
                                            variant="contained"
                                            onClick={() => this.openDialog()}
                                        >
                                            Create your first service
                                        </Button>
                                        <br />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    height: "85vh",
                                }}
                            >
                                <CircularProgress
                                    size={45}
                                    style={{
                                        marginTop: "20%",
                                        borderRadius: "100%",
                                        padding: ".5%",
                                        boxShadow:
                                            "0rem 0rem 1rem rgba(0, 0, 0, 0.15)",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(ServicesPage);
