import React, { useState } from "react";
import {
    ListItemButton,
    ListItemText,
    Typography,
    ListItemIcon,
    Collapse,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { Location } from "../user/locations";

const LocationForm = ({ data, updateData }) => {
    const [show, setShow] = useState(false);

    return (
        <div>
            <ListItemButton
                onClick={() => setShow(!show)}
                style={{ padding: "1% 5%", width: "100%" }}
            >
                <ListItemText>
                    <Typography variant="subtitle1" align="left">
                        Location
                    </Typography>
                </ListItemText>
                <ListItemIcon>
                    {show ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
            </ListItemButton>
            <Collapse
                in={show}
                timeout="auto"
                unmountOnExit
                style={{ padding: "1% 5%" }}
            >
                <Location data={data} updateData={updateData} />
            </Collapse>
        </div>
    );
};

export default LocationForm;
