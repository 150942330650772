import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { gql, useMutation } from "@apollo/client";
import { expiredToken } from "../../../helpers/user";
import { gqlQueryManager } from "../../../helpers/gqlManager";

export default function ManagerActivate({ match: { params } }) {
    if (!params.token || params.token === "") {
        window.location.replace(`${process.env.REACT_APP_HOST}/manager/signup`);
    }

    let token = jwtDecode(params.token);

    if (expiredToken(token)) {
        window.location.replace(`${process.env.REACT_APP_HOST}/manager/signup`);
    }

    let [execute] = useMutation(
        gql`
            ${gqlQueryManager("updateManager", gql)}
        `,
        {
            variables: {
                id: token._id,
                data: { emailVerification: true },
            },
            context: {
                clientName: "manager",
            },
        }
    );

    useEffect(() => {
        execute().then(({ data: { updateManager } }) => {
            localStorage.setItem("managerToken", token);
            window.location.replace(
                `${process.env.REACT_APP_HOST}/manager/institution/onboarding/${updateManager.industry}/${token.institution}`
            );
        });
        // eslint-disable-next-line
    }, []);

    return "redirecting ...";
}
