import React, { Component } from "react";
import Drawer from "../../components/drawer";
import { postRequest } from "../../middleware/graphQl";
import { withSnackbar } from "notistack";
import cogs from "../../middleware/cogs";

import {
    Typography,
    Button,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Fab,
    IconButton,
    Divider,
    Tooltip,
    Link,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import Rating from "@material-ui/lab/Rating";

import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import ContactsOutlinedIcon from "@material-ui/icons/ContactsOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import AssistantOutlinedIcon from "@material-ui/icons/AssistantOutlined";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import VerifiedIcon from "@mui/icons-material/Verified";
import DirectionsIcon from "@material-ui/icons/Directions";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import PublicIcon from "@material-ui/icons/Public";
import StarIcon from "@material-ui/icons/Star";
import LinkIcon from "@material-ui/icons/Link";

class servicePage extends Component {
    state = {
        current: "sub-services",
        favourite_loading: false,
        add_rating: false,
        update_rating: false,
        hover_rating: 0,
        rating: 0,
        comment: "",
        rating_id: "",
        avgRating: 0,
        avgRatingComment: "NOT RATED",
        client: JSON.parse(localStorage.getItem("client")),
        institution: {},
        service: {},
        ratings: [],
        offers: [],
        insurance: [],
        loading: true,
        error: false,
        error_message: "",
        errors: {
            comment: false,
        },
    };

    componentDidMount = () => {
        this.getService(this.props.match.params.id);
    };

    getService = async (id) => {
        const response = await postRequest("singleService", "manager", {
            id: id,
        });

        if (response.errors) {
            this.setState({ loading: false });
            if (response.errors[0].message.split(" ")[0] === "Cast") {
                window.location.replace(
                    `${process.env.REACT_APP_HOST}/manager/services`
                );
            } else {
                this.props.enqueueSnackbar(response.errors[0].message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            }
        } else {
            this.getAverageRating(response.data.singleService.ratings);

            const institution = await postRequest(
                "singleInstitution",
                "manager",
                {
                    id: response.data.singleService.institution[0]._id,
                }
            );

            if (institution.errors) {
                this.setState({ loading: false });

                if (institution.errors[0].message.split(" ")[0] === "Cast") {
                    window.location.replace(`${process.env.REACT_APP_HOST}/`);
                } else {
                    this.props.enqueueSnackbar(institution.errors[0].message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                    });
                }
            } else {
                this.setState({
                    loading: false,
                    institution: institution.data.singleInstitution,
                    service: response.data.singleService,
                    insurance: response.data.singleService.insurance,
                    offers: response.data.singleService.offers,
                    ratings: response.data.singleService.ratings,
                    offer: "",
                    price: "",
                });
            }
        }
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    getRatingComment = (rating) => {
        let rating_comment = "RATING";

        if (rating > 0 && rating <= 1) {
            rating_comment = "VERY POOR";
        } else if (rating > 1 && rating <= 2) {
            rating_comment = "POOR";
        } else if (rating > 2 && rating <= 3) {
            rating_comment = "AVERAGE";
        } else if (rating > 3 && rating <= 4) {
            rating_comment = "GOOD";
        } else if (rating > 4) {
            rating_comment = "EXCELLENT";
        }

        return rating_comment;
    };

    getAverageRating(ratings) {
        let totalRatings = 0;
        let count = 0;
        let avgRating = 0;
        let avgRatingComment = "Not Rated";

        if (ratings.length > 0) {
            for (const i in ratings) {
                totalRatings += ratings[i].rating;
                count += 1;
            }

            avgRating = Math.round((totalRatings / count) * 10) / 10;
            avgRatingComment = this.getRatingComment(avgRating);
        }

        this.setState({
            avgRating: avgRating,
            avgRatingComment: avgRatingComment,
        });
    }

    // create offer
    createOffer = async () => {
        let errors = this.state.errors;
        let error = false;

        // name
        if (this.state.offer === "") {
            errors["offer"] = true;
            error = true;
        } else {
            errors["offer"] = false;
        }

        this.setState({ errors: errors, error: error });

        if (!error) {
            this.state.price = this.state.price === "" ? 0 : this.state.price;
            const response = await postRequest(
                "createOffer",
                "manager",
                this.state
            );

            if (response.errors) {
                this.setState({
                    error: true,
                    error_message: response.errors[0].message,
                    loading: false,
                });
            } else {
                this.getService(this.state.service._id);

                this.props.enqueueSnackbar("Offer created successfully", {
                    variant: "success",
                });

                return true;
            }
        }
    };

    deleteOffer = (id) => {
        this.props.enqueueSnackbar("Confirm Deletion", {
            variant: "error",
            action: (
                <div>
                    <Button
                        style={{
                            border: "1px solid #ffffff",
                            color: "#ffffff",
                            fontSize: "12px",
                        }}
                        variant="outlined"
                        onClick={() => this.executeDelete({ id: id })}
                    >
                        CONFIRM
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        style={{ color: "#ffffff", fontSize: "12px" }}
                        onClick={() => this.props.closeSnackbar()}
                    >
                        CANCEL
                    </Button>
                </div>
            ),
            onClose: () => this.executeDelete({ id: id }),
        });
    };

    executeDelete = async (data) => {
        this.props.closeSnackbar();
        const response = await postRequest("deleteOffer", "manager", data);

        if (response.errors) {
            this.setState({
                error: true,
                error_message: response.errors[0].message,
                loading: false,
            });
        } else {
            this.getService(this.state.service._id);

            this.props.enqueueSnackbar("Offer deleted successfully", {
                variant: "success",
            });

            return true;
        }
    };

    render() {
        let offers = this.state.offers.map((offer) => {
            return (
                <TableRow key={offer._id}>
                    <TableCell align="left">
                        <Typography variant="body1" align="left">
                            {cogs.capitalize(offer.name)}
                        </Typography>
                    </TableCell>
                    <TableCell align="right">
                        <Typography variant="body1">
                            {offer.price === 0 ? "-" : "KSH" + offer.price}
                            <IconButton
                                aria-label="delete"
                                onClick={() => this.deleteOffer(offer._id)}
                            >
                                <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                        </Typography>
                    </TableCell>
                </TableRow>
            );
        });

        let ratings = this.state.ratings.map((rating) => {
            return (
                <Grid item xs={12} key={rating._id}>
                    <div
                        style={{
                            backgroundColor: "#ffffff",
                            padding: "2%",
                            margin: "1% 0%",
                            borderRadius: "10px",
                            boxShadow: "0 0.1rem 1rem rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <Typography variant="subtitle1" align="left">
                            {cogs.capitalize(rating.client[0].first_name)}{" "}
                            {cogs.capitalize(rating.client[0].last_name)}
                        </Typography>
                        <div style={{ display: "flex" }}>
                            <Typography variant="body1" align="left">
                                {rating.rating}{" "}
                            </Typography>
                            <Rating
                                name="simple-controlled"
                                precision={0.1}
                                value={rating.rating}
                                readOnly
                                size="small"
                                style={{
                                    marginTop: ".3%",
                                    marginLeft: ".5%",
                                }}
                            />
                        </div>
                        <Typography variant="body1" align="left">
                            {rating.comment}
                        </Typography>
                    </div>
                </Grid>
            );
        });

        let insurances = this.state.insurance.map((insurance) => {
            return (
                <Typography
                    variant="body1"
                    key={insurance}
                    style={{ margin: "1% 0%" }}
                >
                    <SupervisedUserCircleIcon
                        size={"small"}
                        style={{ color: "#13479A", marginBottom: "-.6%" }}
                    />{" "}
                    {insurance}
                </Typography>
            );
        });

        return (
            <Drawer
                content={
                    <div>
                        {!this.state.loading ? (
                            <Grid container style={{ color: "ffffff" }}>
                                <Grid
                                    item
                                    className="single-view"
                                    xs={12}
                                ></Grid>
                                <Grid container style={{ marginTop: "-5%" }}>
                                    <Grid item xs={12}>
                                        <div className="card">
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <Link
                                                        href={`../institution/${this.state.institution._id}`}
                                                        underline="hover"
                                                    >
                                                        <Typography
                                                            variant="h4"
                                                            align="left"
                                                        >
                                                            {cogs.capitalize(
                                                                this.state
                                                                    .institution
                                                                    .name
                                                            )}
                                                            <LinkIcon />
                                                        </Typography>
                                                    </Link>
                                                    <Typography
                                                        variant="subtitle1"
                                                        style={{
                                                            color: "#666666",
                                                        }}
                                                        align="left"
                                                    >
                                                        {cogs.capitalize(
                                                            this.state
                                                                .institution
                                                                .ward
                                                        )}
                                                        {", "}
                                                        {cogs.capitalize(
                                                            this.state
                                                                .institution
                                                                .county
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} align="right">
                                                    {/* <ShareIcon /> */}

                                                    <VerifiedIcon
                                                        style={{
                                                            marginLeft: "15%",
                                                            color: "green",
                                                        }}
                                                    />
                                                </Grid>
                                                <Divider
                                                    style={{
                                                        margin: "1% 0%",
                                                        width: "100%",
                                                    }}
                                                />
                                                <Grid item xs={10}>
                                                    <Typography
                                                        variant="h5"
                                                        align="left"
                                                    >
                                                        {cogs.capitalize(
                                                            this.state.service
                                                                .care[0].name
                                                        )}{" "}
                                                        Services
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        paragraph
                                                        align="left"
                                                    >
                                                        {
                                                            this.state.service
                                                                .description
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                padding:
                                                                    "1% 0%",
                                                            }}
                                                        >
                                                            {this.state
                                                                .avgRating === 0
                                                                ? "-"
                                                                : this.state
                                                                      .avgRating}
                                                        </Typography>
                                                        <Typography variant="body1">
                                                            {
                                                                this.state
                                                                    .avgRatingComment
                                                            }
                                                        </Typography>
                                                        <Rating
                                                            name="simple-controlled"
                                                            precision={0.1}
                                                            value={
                                                                this.state
                                                                    .avgRating
                                                            }
                                                            readOnly
                                                            size="small"
                                                        />
                                                        <Typography variant="body1">
                                                            {
                                                                this.state
                                                                    .service
                                                                    .ratings
                                                                    .length
                                                            }{" "}
                                                            {this.state.ratings
                                                                .length === 1
                                                                ? "Review"
                                                                : "Reviews"}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Divider
                                                    style={{
                                                        margin: "1% 0%",
                                                        width: "100%",
                                                    }}
                                                />
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{ marginTop: "1%" }}
                                                >
                                                    <Button
                                                        aria-label="call-now"
                                                        href={`tel:${this.state.service.primary_phone}`}
                                                        variant="text"
                                                        style={{
                                                            color: "#13479a",
                                                            marginRight: "3%",
                                                        }}
                                                        startIcon={<CallIcon />}
                                                    >
                                                        Call Now
                                                    </Button>
                                                    <Button
                                                        aria-label="send email"
                                                        href={`mailto:${this.state.service.primary_email}`}
                                                        target="_blank"
                                                        variant="text"
                                                        style={{
                                                            color: "#13479a",
                                                            marginRight: "3%",
                                                        }}
                                                        startIcon={<MailIcon />}
                                                    >
                                                        Send Email
                                                    </Button>
                                                    {this.state.institution
                                                        .service !== "" && (
                                                        <Button
                                                            aria-label="visit website"
                                                            href={
                                                                this.state
                                                                    .service
                                                                    .website
                                                            }
                                                            target="_blank"
                                                            variant="text"
                                                            style={{
                                                                color: "#13479a",
                                                                marginRight:
                                                                    "3%",
                                                            }}
                                                            startIcon={
                                                                <PublicIcon />
                                                            }
                                                        >
                                                            Visit Website
                                                        </Button>
                                                    )}
                                                    <Button
                                                        aria-label="leave a review"
                                                        variant="text"
                                                        disabled
                                                        style={{
                                                            marginRight: "3%",
                                                        }}
                                                        startIcon={
                                                            <AssistantOutlinedIcon />
                                                        }
                                                    >
                                                        Leave a review
                                                    </Button>
                                                    <Button
                                                        aria-label="get-directions"
                                                        href={`https://maps.google.com/maps?q=-1.219744,+36.888998&hl=es&z=14&amp;output=embed`}
                                                        target="_blank"
                                                        variant="text"
                                                        style={{
                                                            color: "#13479a",
                                                            marginRight: "3%",
                                                        }}
                                                        startIcon={
                                                            <DirectionsIcon />
                                                        }
                                                    >
                                                        Get&nbsp;Directions
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div
                                            className="card"
                                            style={{ padding: "0px" }}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    paddingBottom: "0%",
                                                    color: "#13479A",
                                                    borderBottom:
                                                        "1px solid #13479A",
                                                }}
                                            >
                                                <List
                                                    style={{
                                                        display: "flex",
                                                        paddingTop: "0%",
                                                        paddingBottom: "0px",
                                                    }}
                                                >
                                                    <ListItem
                                                        className={
                                                            this.state
                                                                .current ===
                                                            "sub-services"
                                                                ? "list-item"
                                                                : "nan"
                                                        }
                                                        button
                                                        key="sub-services"
                                                        onClick={() =>
                                                            this.setState({
                                                                current:
                                                                    "sub-services",
                                                            })
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <LiveHelpOutlinedIcon
                                                                style={{
                                                                    color: "#13479A",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Sub&nbsp;Services"
                                                            style={{
                                                                color: "#13479A",
                                                            }}
                                                        />
                                                    </ListItem>
                                                    <ListItem
                                                        className={
                                                            this.state
                                                                .current ===
                                                            "insurance"
                                                                ? "list-item"
                                                                : "nan"
                                                        }
                                                        button
                                                        key="insurance"
                                                        onClick={() =>
                                                            this.setState({
                                                                current:
                                                                    "insurance",
                                                            })
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <SupervisedUserCircleOutlinedIcon
                                                                style={{
                                                                    color: "#13479A",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Insurance"
                                                            style={{
                                                                color: "#13479A",
                                                            }}
                                                        />
                                                    </ListItem>
                                                    <ListItem
                                                        className={
                                                            this.state
                                                                .current ===
                                                            "reviews"
                                                                ? "list-item"
                                                                : "nan"
                                                        }
                                                        button
                                                        key="reviews"
                                                        onClick={() =>
                                                            this.setState({
                                                                current:
                                                                    "reviews",
                                                            })
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <AssistantOutlinedIcon
                                                                style={{
                                                                    color: "#13479A",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Reviews"
                                                            style={{
                                                                color: "#13479A",
                                                            }}
                                                        />
                                                    </ListItem>
                                                    <ListItem
                                                        className={
                                                            this.state
                                                                .current ===
                                                            "gallery"
                                                                ? "list-item"
                                                                : "nan"
                                                        }
                                                        button
                                                        key="gallery"
                                                        onClick={() =>
                                                            this.setState({
                                                                current:
                                                                    "gallery",
                                                            })
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <PhotoLibraryIcon
                                                                style={{
                                                                    color: "#13479A",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Gallery"
                                                            style={{
                                                                color: "#13479A",
                                                            }}
                                                        />
                                                    </ListItem>
                                                    <ListItem
                                                        className={
                                                            this.state
                                                                .current ===
                                                            "contacts"
                                                                ? "list-item"
                                                                : "nan"
                                                        }
                                                        button
                                                        key="contacts"
                                                        onClick={() =>
                                                            this.setState({
                                                                current:
                                                                    "contacts",
                                                            })
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <ContactsOutlinedIcon
                                                                style={{
                                                                    color: "#13479A",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Contacts"
                                                            style={{
                                                                color: "#13479A",
                                                            }}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ padding: "2%" }}
                                            >
                                                {this.state.current ===
                                                    "sub-services" && (
                                                    <div>
                                                        {this.state.offers
                                                            .length > 0 ? (
                                                            <TableContainer>
                                                                <Table
                                                                    size="small"
                                                                    aria-label="customized table"
                                                                >
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell align="left">
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    align="left"
                                                                                >
                                                                                    <b>
                                                                                        Sub
                                                                                        Service
                                                                                    </b>
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    align="right"
                                                                                >
                                                                                    <b>
                                                                                        Amount
                                                                                    </b>
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {offers}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        ) : (
                                                            <div>
                                                                <br />
                                                                <Typography
                                                                    variant="h6"
                                                                    align="center"
                                                                >
                                                                    No sub
                                                                    services yet
                                                                </Typography>
                                                                <img
                                                                    style={{
                                                                        width: "25%",
                                                                        height: "auto",
                                                                        display:
                                                                            "flex",
                                                                        margin: "0 auto",
                                                                    }}
                                                                    src={
                                                                        "../../images/blankSubService.png"
                                                                    }
                                                                    alt="No Data"
                                                                />
                                                            </div>
                                                        )}
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid item xs={12}>
                                                                <br />
                                                                {this.state
                                                                    .error && (
                                                                    <Alert severity="error">
                                                                        {this
                                                                            .state
                                                                            .error_message ===
                                                                        ""
                                                                            ? "Kindly fill in the highlighted fields."
                                                                            : this
                                                                                  .state
                                                                                  .error_message}
                                                                    </Alert>
                                                                )}
                                                                <Typography
                                                                    variant="body2"
                                                                    align="left"
                                                                >
                                                                    <b>
                                                                        Add new
                                                                        sub
                                                                        service
                                                                    </b>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <TextField
                                                                    error={
                                                                        this
                                                                            .state
                                                                            .errors
                                                                            .offer
                                                                    }
                                                                    id="offer"
                                                                    name="offer"
                                                                    label="Sub Service"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    placeholder="Consulation"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .offer
                                                                    }
                                                                    fullWidth
                                                                    onChange={
                                                                        this
                                                                            .handleChange
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <TextField
                                                                    id="price"
                                                                    name="price"
                                                                    label="Price (Optional)"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    placeholder="3000"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .price
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .handleChange
                                                                    }
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Fab
                                                                    color="primary"
                                                                    aria-label="add"
                                                                    onClick={() =>
                                                                        this.createOffer()
                                                                    }
                                                                    style={{
                                                                        background:
                                                                            "#13479A",
                                                                        float: "right",
                                                                    }}
                                                                >
                                                                    <AddIcon />
                                                                </Fab>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )}

                                                {this.state.current ===
                                                    "insurance" && (
                                                    <div
                                                        style={{
                                                            padding: ".5%",
                                                        }}
                                                    >
                                                        {insurances}
                                                    </div>
                                                )}
                                                {this.state.current ===
                                                    "reviews" && (
                                                    <div
                                                        style={{
                                                            padding: ".5%",
                                                        }}
                                                    >
                                                        {this.state.ratings
                                                            .length > 0 ? (
                                                            <Grid container>
                                                                {ratings}
                                                            </Grid>
                                                        ) : (
                                                            <div>
                                                                <br />
                                                                <Typography
                                                                    variant="h6"
                                                                    align="center"
                                                                >
                                                                    No reviews
                                                                    yet
                                                                </Typography>
                                                                <img
                                                                    style={{
                                                                        width: "35%",
                                                                        height: "auto",
                                                                        display:
                                                                            "flex",
                                                                        margin: "0 auto",
                                                                    }}
                                                                    src={
                                                                        "../../images/blankSubService.png"
                                                                    }
                                                                    alt="No Data"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {this.state.current ===
                                                    "gallery" && (
                                                    <div>
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                style={{
                                                                    padding:
                                                                        ".5%",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: "150px",
                                                                        borderRadius:
                                                                            "10px",
                                                                        backgroundImage:
                                                                            "url('../../images/services/1.jpg')",
                                                                        backgroundPosition:
                                                                            "center center",
                                                                        backgroundRepeat:
                                                                            "no-repeat",
                                                                        backgroundSize:
                                                                            "cover",
                                                                    }}
                                                                ></div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                style={{
                                                                    padding:
                                                                        ".5%",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: "150px",
                                                                        borderRadius:
                                                                            "10px",
                                                                        backgroundImage:
                                                                            "url('../../images/services/2.jpg')",
                                                                        backgroundPosition:
                                                                            "center center",
                                                                        backgroundRepeat:
                                                                            "no-repeat",
                                                                        backgroundSize:
                                                                            "cover",
                                                                    }}
                                                                ></div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                style={{
                                                                    padding:
                                                                        ".5%",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: "150px",
                                                                        borderRadius:
                                                                            "10px",
                                                                        backgroundImage:
                                                                            "url('../../images/services/3.jpg')",
                                                                        backgroundPosition:
                                                                            "center center",
                                                                        backgroundRepeat:
                                                                            "no-repeat",
                                                                        backgroundSize:
                                                                            "cover",
                                                                    }}
                                                                ></div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                style={{
                                                                    padding:
                                                                        ".5%",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: "150px",
                                                                        borderRadius:
                                                                            "10px",
                                                                        backgroundImage:
                                                                            "url('../../images/services/4.jpg')",
                                                                        backgroundPosition:
                                                                            "center center",
                                                                        backgroundRepeat:
                                                                            "no-repeat",
                                                                        backgroundSize:
                                                                            "cover",
                                                                    }}
                                                                ></div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                style={{
                                                                    padding:
                                                                        ".5%",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: "150px",
                                                                        borderRadius:
                                                                            "10px",
                                                                        backgroundImage:
                                                                            "url('../../images/services/5.jpg')",
                                                                        backgroundPosition:
                                                                            "center center",
                                                                        backgroundRepeat:
                                                                            "no-repeat",
                                                                        backgroundSize:
                                                                            "cover",
                                                                    }}
                                                                ></div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )}
                                                {this.state.current ===
                                                    "contacts" && (
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            align="left"
                                                        >
                                                            <CallIcon
                                                                size={"small"}
                                                                style={{
                                                                    color: "#13479A",
                                                                    marginBottom:
                                                                        "-.5%",
                                                                    marginRight:
                                                                        "1%",
                                                                }}
                                                            />
                                                            {"   "}
                                                            {
                                                                this.state
                                                                    .service
                                                                    .primary_phone
                                                            }
                                                        </Typography>
                                                        <br />
                                                        <Typography
                                                            variant="body1"
                                                            align="left"
                                                        >
                                                            <MailIcon
                                                                size={"small"}
                                                                style={{
                                                                    color: "#13479A",
                                                                    marginBottom:
                                                                        "-.8%",
                                                                    marginRight:
                                                                        "1%",
                                                                }}
                                                            />
                                                            {"   "}
                                                            {
                                                                this.state
                                                                    .service
                                                                    .primary_email
                                                            }
                                                        </Typography>
                                                        <br />
                                                        <Typography
                                                            variant="body1"
                                                            align="left"
                                                        >
                                                            <ContactMailIcon
                                                                size={"small"}
                                                                style={{
                                                                    color: "#13479A",
                                                                    marginBottom:
                                                                        "-.8%",
                                                                    marginRight:
                                                                        "1%",
                                                                }}
                                                            />
                                                            P.O BOX,{"   "}
                                                            {
                                                                this.state
                                                                    .institution
                                                                    .address
                                                            }
                                                            , Nairobi, Kenya
                                                        </Typography>
                                                        <br />
                                                        {this.state.service
                                                            .website !== "" && (
                                                            <Typography
                                                                variant="body1"
                                                                align="left"
                                                            >
                                                                <PublicIcon
                                                                    size={
                                                                        "small"
                                                                    }
                                                                    style={{
                                                                        color: "#13479A",
                                                                        marginBottom:
                                                                            "-.8%",
                                                                        marginRight:
                                                                            "1%",
                                                                    }}
                                                                />
                                                                {"   "}
                                                                {
                                                                    this.state
                                                                        .service
                                                                        .website
                                                                }
                                                            </Typography>
                                                        )}
                                                        <br />
                                                        {this.state.institution
                                                            .linkedin !==
                                                            "" && (
                                                            <Link
                                                                href={
                                                                    this.state
                                                                        .institution
                                                                        .linkedin
                                                                }
                                                                target="_blank"
                                                                underline="hover"
                                                            >
                                                                <LinkedInIcon
                                                                    style={{
                                                                        color: "#13479A",
                                                                        marginRight:
                                                                            "3%",
                                                                    }}
                                                                />
                                                            </Link>
                                                        )}
                                                        {this.state.institution
                                                            .facebook !==
                                                            "" && (
                                                            <Link
                                                                href={
                                                                    this.state
                                                                        .institution
                                                                        .facebook
                                                                }
                                                                target="_blank"
                                                                underline="hover"
                                                            >
                                                                <FacebookIcon
                                                                    style={{
                                                                        color: "#13479A",
                                                                        marginRight:
                                                                            "3%",
                                                                    }}
                                                                />
                                                            </Link>
                                                        )}

                                                        {this.state.institution
                                                            .twitter !== "" && (
                                                            <Link
                                                                href={
                                                                    this.state
                                                                        .institution
                                                                        .twitter
                                                                }
                                                                target="_blank"
                                                                underline="hover"
                                                            >
                                                                <TwitterIcon
                                                                    style={{
                                                                        color: "#13479A",
                                                                        marginRight:
                                                                            "3%",
                                                                    }}
                                                                />
                                                            </Link>
                                                        )}
                                                        {this.state.institution
                                                            .instagram !==
                                                            "" && (
                                                            <Link
                                                                href={
                                                                    this.state
                                                                        .institution
                                                                        .instagram
                                                                }
                                                                target="_blank"
                                                                underline="hover"
                                                            >
                                                                <InstagramIcon
                                                                    style={{
                                                                        color: "#13479A",
                                                                        marginRight:
                                                                            "3%",
                                                                    }}
                                                                />
                                                            </Link>
                                                        )}
                                                    </div>
                                                )}
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    height: "85vh",
                                }}
                            >
                                <CircularProgress
                                    size={45}
                                    style={{
                                        marginTop: "15%",
                                        borderRadius: "100%",
                                        padding: ".1%",
                                        boxShadow:
                                            "0rem 0rem 1rem rgba(0, 0, 0, 0.15)",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(servicePage);
