import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";

export const PhoneNumber = ({ data, updateData }) => {
    return (
        <TextField
            id="phone"
            name="phone"
            label="Phone Number"
            margin="normal"
            variant="outlined"
            placeholder="Enter phone number"
            value={data.phoneNumber}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <PhoneOutlinedIcon
                            fontSize="small"
                            style={{
                                color: "#939393",
                                cursor: "pointer",
                            }}
                        />
                    </InputAdornment>
                ),
            }}
            onChange={(event) => {
                updateData({ ...data, phoneNumber: event.target.value });
            }}
        />
    );
};
