import React from "react";
import {
    Grid,
    TextField,
    InputAdornment,
    Typography,
    Button,
} from "@mui/material";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import { capitalize } from "../../../../helpers/string";

export const InstitutionInfo = ({ data, updateData, setReady }) => {
    let disabled = data.name === "" || data.description === "";

    return (
        <Grid container>
            <Grid item xs={12} md={8} style={{ margin: "0px auto" }}>
                <Typography
                    variant="body2"
                    textAlign={"center"}
                    style={{
                        color: "#888",
                        fontSize: "24px",
                        padding: "3% 0%",
                    }}
                >
                    Name and description
                </Typography>

                <TextField
                    id="name"
                    name="name"
                    label="Institution Name"
                    margin="normal"
                    variant="outlined"
                    placeholder="Institution XYZ"
                    defaultValue={capitalize(data.name)}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <StoreOutlinedIcon
                                    fontSize="small"
                                    style={{
                                        color: "#939393",
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event) => {
                        updateData({ ...data, name: event.target.value });
                    }}
                />

                <TextField
                    id="description"
                    name="description"
                    label="Description / Tagline / Slogan"
                    variant="outlined"
                    placeholder="Enter your description here"
                    value={data.description}
                    multiline
                    rows={2}
                    fullWidth
                    inputProps={{ maxLength: 240 }}
                    onChange={(event) => {
                        updateData({
                            ...data,
                            description: event.target.value,
                        });
                    }}
                />

                <Grid container spacing={3} my>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            style={{
                                backgroundColor: "#444444",
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    status: "category",
                                });
                            }}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            disabled={disabled}
                            style={{
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                                marginLeft: "0%",
                                backgroundColor: `${
                                    !disabled ? "#ce043d" : "#E0E0E0"
                                }`,
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    status: "location",
                                });
                                setReady(true);
                            }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
