import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
    List,
    Button,
    Drawer,
    Box,
    Grid,
    Link,
    ListItem,
    Typography,
    ListItemIcon,
} from "@mui/material";
import { ManagerMenu } from "./managerMenu";
import { MenuButtons } from "./menuButtons";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PeopleIcon from "@mui/icons-material/People";

const logOut = () => {
    localStorage.removeItem("token");
    window.location.replace(`${process.env.REACT_APP_HOST}/`);
};

export const MenuDrawer = ({ styling }) => {
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <div>
            {localStorage.getItem("token") ? (
                <Button onClick={toggleDrawer("left", true)}>
                    <MenuIcon fontSize={"large"} style={{ color: "#000000" }} />
                </Button>
            ) : (
                <div>
                    {localStorage.getItem("manager") && <ManagerMenu />}
                    {localStorage.getItem("token") === null &&
                        localStorage.getItem("manager") === null && (
                            <MenuButtons />
                        )}
                </div>
            )}

            <Drawer
                anchor={"left"}
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
            >
                <Box
                    sx={{
                        width: 300,
                    }}
                    role="presentation"
                >
                    <Grid container className="top-side-menu">
                        <Grid item xs={3}>
                            <List style={{ display: "flex" }}>
                                <NavLink exact to="/">
                                    <img
                                        style={{
                                            width: "140px",
                                            height: "auto",
                                            padding: "0% 10%",
                                        }}
                                        src={`../../${styling.logo}.png`}
                                        alt="WapiDocLogo"
                                    />
                                </NavLink>
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                <Link href="/favourites">
                                    <ListItem
                                        key="signup"
                                        align="left"
                                        style={{ padding: "5%" }}
                                    >
                                        <ListItemIcon
                                            style={{ color: "#cf053e" }}
                                        >
                                            <FavoriteIcon />
                                        </ListItemIcon>
                                        <Typography variant="body1">
                                            Favourites
                                        </Typography>
                                    </ListItem>
                                </Link>

                                <Link href="/profile">
                                    <ListItem
                                        key="profile"
                                        align="left"
                                        style={{ padding: "5%" }}
                                    >
                                        <ListItemIcon
                                            style={{ color: "#cf053e" }}
                                        >
                                            <AccountCircleIcon />
                                        </ListItemIcon>
                                        <Typography variant="body1">
                                            Profile
                                        </Typography>
                                    </ListItem>
                                </Link>
                                <Link href="/referral-program">
                                    <ListItem
                                        key="referral-program"
                                        align="left"
                                        style={{ padding: "5%" }}
                                    >
                                        <ListItemIcon
                                            style={{ color: "#cf053e" }}
                                        >
                                            <PeopleIcon />
                                        </ListItemIcon>
                                        <Typography variant="body1">
                                            Referrals
                                        </Typography>
                                    </ListItem>
                                </Link>
                                <Link href="#">
                                    <ListItem
                                        align="left"
                                        onClick={() => logOut()}
                                        style={{ padding: "5%" }}
                                    >
                                        <ListItemIcon
                                            style={{ color: "#cf053e" }}
                                        >
                                            <ExitToAppIcon />
                                        </ListItemIcon>
                                        <Typography variant="body1">
                                            Logout
                                        </Typography>
                                    </ListItem>
                                </Link>
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
        </div>
    );
};
