import React, { Component } from "react";
import Drawer from "../../components/drawer";

class SettingsPage extends Component {
    render() {
        return <Drawer content={"settings"} />;
    }
}

export default SettingsPage;
