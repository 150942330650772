import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import {
    Grid,
    TextField,
    InputAdornment,
    Typography,
    Button,
    CircularProgress,
    ListItemIcon,
    List,
    ListItem,
    ListItemText,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

class ContactUsPage extends Component {
    state = {
        first_name: "",
        last_name: "",
        email: "",
        message: "",
        phone: "",
        success: "",
        loading: false,
        error: false,
        error_message: "",
        errors: {
            first_name: false,
            last_name: false,
            email: false,
            message: false,
            phone: false,
        },
    };

    // send message
    sendMessage = async () => {
        let errors = this.state.errors;
        let error = false;

        // first_name
        if (this.state.first_name === "") {
            errors["first_name"] = true;
            error = true;
        } else {
            errors["first_name"] = false;
        }

        // last_name
        if (this.state.last_name === "") {
            errors["last_name"] = true;
            error = true;
        } else {
            errors["last_name"] = false;
        }

        // email
        if (this.state.email === "") {
            errors["email"] = true;
            error = true;
        } else {
            errors["email"] = false;
        }

        // message
        if (this.state.message === "") {
            errors["message"] = true;
            error = true;
        } else {
            errors["message"] = false;
        }

        // phone
        if (this.state.phone === "") {
            errors["phone"] = true;
            error = true;
        } else {
            if (
                !this.state.phone.match(
                    // eslint-disable-next-line
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                )
            ) {
                error = true;
                errors["phone"] = true;
                this.setState({ error_message: "Invalid phone number." });
            } else {
                errors["phone"] = false;
                this.setState({ error_message: "" });
            }
        }

        this.setState({ errors: errors, error: error });

        if (!error) {
            this.setState({ loading: true });
            // const response = await postRequest(
            //     "createManager",
            //     "manager",
            //     this.state
            // );
            // if (response.errors) {
            //     this.setState({
            //         loading: false,
            //         error: true,
            //         errors: errors,
            //         error_message: "Your message can't be sent.",
            //     });
            // } else {
            //     this.setState({
            //         success: true,
            //         loading: false,
            //     });
            // }
        }
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>Contact Us</title>
                    <meta
                        name="description"
                        content="We are located at Westlands, Woodvale Groove, Krishna Center, 5th Floor. Give us a call at +254113885426 or send us an email at info@wapidoc.com"
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid
                    container
                    style={{ paddingTop: "2%" }}
                    className="add-space"
                >
                    <Grid container className="vision-mission add-space">
                        <Grid item xs={12} align="center">
                            <Typography variant="h3">Contact Us</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            align="left"
                            style={{ padding: "3% " }}
                        >
                            <Typography variant="h4">Get in touch</Typography>
                            <br />
                            <ListItem component="a" href="tel:+254113885426">
                                <ListItemIcon>
                                    <CallIcon />
                                </ListItemIcon>
                                <ListItemText primary="0113885426" />
                            </ListItem>
                            <ListItem
                                component="a"
                                target="_blank"
                                href="mailto:info@wapidoc.com"
                            >
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                <ListItemText primary="info@wapidoc.com" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ContactMailIcon />
                                </ListItemIcon>
                                <ListItemText primary="P. O Box 28196 G.P.O" />
                            </ListItem>
                            <Typography variant="subtitle1"></Typography>
                            <ListItem>
                                <ListItemIcon>
                                    <LocationOnIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Krishna Center, Woodvale Grove, Westlands,
                                Nairobi"
                                />
                            </ListItem>

                            <Grid item sm={6} className="sub-footer">
                                <List
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <ListItem
                                        component="a"
                                        target="_blank"
                                        href="https://www.facebook.com/WapiDoc-102230008911132"
                                        key="facebook"
                                    >
                                        <ListItemText
                                            primary={<FacebookIcon />}
                                        />
                                    </ListItem>
                                    <ListItem
                                        component="a"
                                        target="_blank"
                                        href="https://twitter.com/wapidoc"
                                        key="twitter"
                                    >
                                        <ListItemText
                                            primary={<TwitterIcon />}
                                        />
                                    </ListItem>
                                    <ListItem
                                        component="a"
                                        target="_blank"
                                        href="https://www.linkedin.com/in/wapidoc/"
                                        key="linkedin"
                                    >
                                        <ListItemText
                                            primary={<LinkedInIcon />}
                                        />
                                    </ListItem>
                                    <ListItem
                                        component="a"
                                        target="_blank"
                                        href="https://instagram.com/wapidoc"
                                        key="instagram"
                                    >
                                        <ListItemText
                                            primary={<InstagramIcon />}
                                        />
                                    </ListItem>
                                    <ListItem
                                        component="a"
                                        target="_blank"
                                        href="https://wa.me/%2B254745308174?text=I'd%20like%20to%20enquire%20about"
                                        key="whatsapp"
                                    >
                                        <ListItemText
                                            primary={<WhatsAppIcon />}
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                            <br />
                            <iframe
                                title="ContactUs"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8468759574052!2d36.8014209141583!3d-1.2643835990777923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f171556f250f7%3A0x28319a5d3b7730a9!2sKrishna%20Centre!5e0!3m2!1sen!2ske!4v1640178178981!5m2!1sen!2ske"
                                width="100%"
                                height="250"
                                style={{
                                    border: "none",
                                    borderRadius: "5px",
                                }}
                                allowfullscreen=""
                                loading="lazy"
                            ></iframe>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4">
                                Send us a message
                            </Typography>
                            <br />
                            {this.state.success && (
                                <Alert
                                    severity="success"
                                    style={{ marginBottom: "1.5%" }}
                                >
                                    Your message has been sent successfully
                                </Alert>
                            )}
                            {this.state.error && (
                                <Alert
                                    severity="error"
                                    style={{ marginBottom: "1.5%" }}
                                >
                                    {this.state.error_message === ""
                                        ? "Kindly fill in the highlighted fields."
                                        : this.state.error_message}
                                </Alert>
                            )}
                            <Grid
                                container
                                style={{
                                    padding: "0px",
                                }}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        padding: "0px 10px 0px 0px",
                                    }}
                                >
                                    <TextField
                                        error={this.state.errors.first_name}
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="John"
                                        value={this.state.first_name}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonOutlineOutlinedIcon
                                                        fontSize="small"
                                                        style={{
                                                            color: "#939393",
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    style={{
                                        padding: "0px 0px 0px 10px",
                                    }}
                                >
                                    <TextField
                                        error={this.state.errors.last_name}
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Doe"
                                        value={this.state.last_name}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PersonOutlineOutlinedIcon
                                                        fontSize="small"
                                                        style={{
                                                            color: "#939393",
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                error={this.state.errors.email}
                                id="email"
                                name="email"
                                label="Email"
                                margin="normal"
                                variant="outlined"
                                placeholder="johndoe@example.com"
                                value={this.state.email}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailOutlinedIcon
                                                fontSize="small"
                                                style={{ color: "#939393" }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={this.handleChange}
                            />
                            <TextField
                                error={this.state.errors.phone}
                                id="phone"
                                name="phone"
                                label="Phone Number"
                                margin="normal"
                                variant="outlined"
                                placeholder="0700123456"
                                value={this.state.phone}
                                fullWidth
                                inputProps={{ maxLength: 10 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <PhoneOutlinedIcon
                                                fontSize="small"
                                                style={{ color: "#939393" }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={this.handleChange}
                            />
                            <TextField
                                error={this.state.errors.message}
                                name="message"
                                label="Message"
                                variant="outlined"
                                placeholder="Enter your description here"
                                defaultValue={this.props.message}
                                multiline
                                rows={4}
                                fullWidth
                                inputProps={{ maxLength: 240 }}
                                onChange={this.handleChange}
                            />

                            <Button
                                className="submit-button"
                                variant="contained"
                                fullWidth
                                onClick={() => this.sendMessage()}
                                style={{
                                    padding: "1.5% 0%",
                                }}
                            >
                                {!this.state.loading ? (
                                    "Send Message"
                                ) : (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: "#ffffff" }}
                                    />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default ContactUsPage;
