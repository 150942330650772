import React from "react";
import { Grid, Typography } from "@mui/material";
import { capitalize } from "../../../../helpers/string";

const categories = {
    care: [
        {
            category: "hospital",
            description: "Inpatient and outpatient services",
        },
        {
            category: "medical-center",
            description: "Multiple outpatient services",
        },
        {
            category: "pharmacy",
            description: "Provides pharmacy services",
        },
        {
            category: "consultant",
            description: "Provides consultancy services",
        },
        {
            category: "clinic",
            description:
                "Provides one medical service (e.g) Dental, Imaging, Optical",
        },
        {
            category: "mobile",
            description: "Provides a mobile medical service",
        },
        {
            category: "online",
            description: "Provides an online medical service",
        },
    ],
    training: [
        {
            category: "vocational",
            description: "That teaches one special skill",
        },
        {
            category: "polytechnic",
            description: "That teaches multiple special skills",
        },
        {
            category: "college",
            description: "Provides undergraduate courses",
        },
        {
            category: "university",
            description: "Provides undergraduate and postgraduate courses",
        },
    ],
    products: [
        {
            category: "distributor",
            description: "Ships products from manufacturers",
        },
        {
            category: "manufacturer",
            description: "Makes products and equipment",
        },
    ],
};

export const Categories = ({ industry, data, updateData, setReady }) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <Typography
                    variant="body2"
                    textAlign={"center"}
                    style={{
                        color: "#888",
                        fontSize: "24px",
                        padding: "1% 0%",
                    }}
                >
                    Choose a category that suits you.
                </Typography>
            </Grid>

            {categories[industry].map((item) => {
                return (
                    <Grid item xs={12} md={4} key={item.category}>
                        <div
                            className={`f-type ${
                                data.type === item.category && "f-selected"
                            }`}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    type: item.category,
                                    status: "institution-info",
                                });
                                setReady(true);
                            }}
                        >
                            <img
                                src={`../../../../images/${item.category}.png`}
                                alt={item.category}
                            />
                            <Typography
                                variant="h6"
                                style={{
                                    color: "#939393",
                                }}
                            >
                                {capitalize(item.category.replace("-", " "))}
                            </Typography>
                            <Typography variant="subtitle1">
                                {item.description}
                            </Typography>
                        </div>
                    </Grid>
                );
            })}
        </Grid>
    );
};
