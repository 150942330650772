import React from "react";
import {
    Grid,
    TextField,
    InputAdornment,
    Typography,
    Button,
} from "@mui/material";
import { capitalize } from "../../../../helpers/string";

const visitingHours = [
    { day: "morning" },
    { day: "afternoon" },
    { day: "evening" },
];

export const InstitutionVHours = ({ setReady, data, updateData }) => {
    return (
        <Grid container>
            <Grid item xs={12} md={8} style={{ margin: "0px auto" }}>
                <Typography
                    variant="body2"
                    textAlign={"center"}
                    style={{
                        color: "#888",
                        fontSize: "24px",
                        padding: "3% 0%",
                    }}
                >
                    Visiting Hours
                </Typography>

                {visitingHours.map((item) => {
                    return (
                        <div key={item.day}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                style={{
                                    paddingTop: "2%",
                                }}
                            >
                                <Typography variant="subtitle1">
                                    {capitalize(item.day)}
                                </Typography>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={6} md={3}>
                                    <TextField
                                        id={`${item.day}Opening`}
                                        name={`${item.day}Opening`}
                                        label="Opening"
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="08:00"
                                        value={
                                            data.hours.visitingHours[
                                                item.day
                                            ].split("-")[0]
                                        }
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    AM
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(event) => {
                                            updateData({
                                                ...data,
                                                hours: {
                                                    ...data.hours,
                                                    visitingHours: {
                                                        ...data.hours
                                                            .visitingHours,
                                                        [item.day]:
                                                            event.target.value +
                                                            "-" +
                                                            data.hours.visitingHours[
                                                                item.day
                                                            ].split("-")[1],
                                                    },
                                                },
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <TextField
                                        id={`${item.day}Closing`}
                                        name={`${item.day}Closing`}
                                        label="Closing"
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="12:00"
                                        value={
                                            data.hours.visitingHours[
                                                item.day
                                            ].split("-")[1]
                                        }
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    PM
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(event) => {
                                            updateData({
                                                ...data,
                                                hours: {
                                                    ...data.hours,
                                                    visitingHours: {
                                                        ...data.hours
                                                            .visitingHours,
                                                        [item.day]:
                                                            data.hours.visitingHours[
                                                                item.day
                                                            ].split("-")[0] +
                                                            "-" +
                                                            event.target.value,
                                                    },
                                                },
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        fullWidth
                                        style={{
                                            padding: "7.5%",
                                            borderRadius: "10px",
                                            marginTop: "2px",
                                        }}
                                        onClick={(event) => {
                                            updateData({
                                                ...data,
                                                hours: {
                                                    ...data.hours,
                                                    visitingHours: {
                                                        ...data.hours
                                                            .visitingHours,
                                                        [item.day]:
                                                            "00:00-00:00",
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        24&nbsp;HOURS
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        fullWidth
                                        style={{
                                            padding: "7.5%",
                                            borderRadius: "10px",
                                            marginTop: "2px",
                                        }}
                                        onClick={(event) => {
                                            updateData({
                                                ...data,
                                                hours: {
                                                    ...data.hours,
                                                    visitingHours: {
                                                        ...data.hours
                                                            .visitingHours,
                                                        [item.day]:
                                                            "__:__-__:__",
                                                    },
                                                },
                                            });
                                        }}
                                    >
                                        Closed
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    );
                })}

                <Grid container spacing={3} my>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            style={{
                                backgroundColor: "#444444",
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    status: "working-hours",
                                });
                            }}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            style={{
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                                marginLeft: "0%",
                                backgroundColor: "#ce043d",
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    status: "confirm-details",
                                });
                                setReady(true);
                            }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
