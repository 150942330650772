import React, { Component } from "react";
import Drawer from "../../components/drawer";
import { postRequest } from "../../middleware/graphQl";
import { Link } from "react-router-dom";
import { withSnackbar } from "notistack";

import {
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Chip,
    CircularProgress,
} from "@material-ui/core";

import PaymentIcon from "@material-ui/icons/Payment";

class BillingPage extends Component {
    state = {
        invoices: [],
        loading: true,
    };

    componentDidMount = () => {
        this.getInvoices();
    };

    getInvoices = async () => {
        this.setState({ loading: true });
        const response = await postRequest("myInvoices", "manager", {
            id: JSON.parse(localStorage.getItem("institution"))._id,
        });

        if (response.errors) {
            this.setState({ loading: false });
            this.props.enqueueSnackbar(response.errors[0].message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
        } else {
            this.setState({
                loading: false,
                invoices: response.data.myInvoices,
            });
        }
    };

    render() {
        let invoices = this.state.invoices.map((invoice) => {
            return (
                <TableRow
                    component={Link}
                    to={`/manager/invoice/${invoice._id}`}
                    key={invoice._id}
                >
                    <TableCell align="left">{invoice.number}</TableCell>
                    <TableCell align="left">{invoice.date}</TableCell>
                    <TableCell align="left">{invoice.expires}</TableCell>
                    <TableCell align="left">{invoice.amount}</TableCell>
                    <TableCell align="left">{invoice.description}</TableCell>
                    <TableCell align="left">
                        {invoice.status ? (
                            <Chip
                                icon={<PaymentIcon size={11} />}
                                label="Paid"
                                color="secondary"
                                style={{
                                    backgroundColor: "green",
                                    padding: "0% 1%",
                                }}
                            />
                        ) : (
                            <Chip
                                icon={<PaymentIcon fontSize={"small"} />}
                                label="Unpaid"
                                color="secondary"
                                style={{
                                    backgroundColor: "#F73F37",
                                    padding: "0% 1%",
                                }}
                            />
                        )}
                    </TableCell>
                </TableRow>
            );
        });
        return (
            <Drawer
                content={
                    <div>
                        {!this.state.loading ? (
                            <div>
                                {this.state.invoices.length > 0 ? (
                                    <div>
                                        <TableContainer
                                            style={{
                                                width: "95%",
                                                borderRadius: "5px",
                                                margin: "3% auto",
                                                backgroundColor: "#ffffff",
                                                boxShadow:
                                                    "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                                            }}
                                        >
                                            <Table
                                                size="small"
                                                aria-label="customized table"
                                            >
                                                <TableHead>
                                                    <TableRow
                                                        style={{
                                                            backgroundColor:
                                                                "#dfdfdf",
                                                        }}
                                                    >
                                                        <TableCell align="left">
                                                            <Typography
                                                                variant="body2"
                                                                align="left"
                                                            >
                                                                <b>INVOICES</b>
                                                                <br />
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell align="left">
                                                            <Typography
                                                                variant="body2"
                                                                align="left"
                                                            >
                                                                <b>
                                                                    Invoice ID
                                                                </b>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                variant="body2"
                                                                align="left"
                                                            >
                                                                <b>Date</b>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                variant="body2"
                                                                align="left"
                                                            >
                                                                <b>Expires</b>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                variant="body2"
                                                                align="left"
                                                            >
                                                                <b>Amount</b>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                variant="body2"
                                                                align="left"
                                                            >
                                                                <b>
                                                                    Description
                                                                </b>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography
                                                                variant="body2"
                                                                align="left"
                                                            >
                                                                <b>Status</b>
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {invoices}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                ) : (
                                    <div style={{ textAlign: "center" }}>
                                        <img
                                            style={{
                                                width: "35%",
                                                height: "auto",
                                                marginTop: "8%",
                                            }}
                                            src={"../images/blank.svg"}
                                            alt="No Data"
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    height: "85vh",
                                }}
                            >
                                <CircularProgress
                                    size={45}
                                    style={{
                                        marginTop: "20%",
                                        borderRadius: "100%",
                                        padding: ".5%",
                                        boxShadow:
                                            "0rem 0rem 1rem rgba(0, 0, 0, 0.15)",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(BillingPage);
