import React, { useState } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import {
    TextField,
    InputAdornment,
    Button,
    Alert,
    CircularProgress,
    Link,
    Typography,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { validateEmail } from "../../../helpers/validation";
import { gqlQuery } from "../../../helpers/gql";
import { authProcess } from "../../../helpers/user";

export const Email = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [err, setError] = useState("");
    const [view, setView] = useState(false);
    const [btnLoad, setBtnLoad] = useState(false);

    const validateInput = () => {
        try {
            setBtnLoad(true);
            validateEmail(email);
            setError("");
            execute().then(({ data }) => {
                authProcess(data.authLogin.token);
                setBtnLoad(false);
            });
        } catch (err) {
            setError(err.message);
            setBtnLoad(false);
        }
    };

    let disabled = email === "" || password === "";

    let [execute] = useLazyQuery(
        gql`
            ${gqlQuery("authLogin", gql)}
        `,
        {
            variables: {
                email: email,
                password: password,
            },
        }
    );

    document.addEventListener("keyup", (event) => {
        if (!disabled) {
            if (event.keyCode === 13) {
                validateInput();
            }
        }
    });

    return (
        <div>
            {err !== "" && (
                <Alert severity="error" style={{ marginBottom: "1.5%" }}>
                    {err}
                </Alert>
            )}
            <TextField
                error={!(err === "")}
                id="email"
                name="email"
                label="Email"
                margin="normal"
                variant="outlined"
                placeholder="Enter email address"
                value={email}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <AlternateEmailIcon
                                fontSize="small"
                                style={{
                                    color: "#939393",
                                    cursor: "pointer",
                                }}
                            />
                        </InputAdornment>
                    ),
                }}
                onChange={(event) => {
                    setEmail(event.target.value);
                }}
            />

            <TextField
                id="password"
                name="password"
                label="Password"
                margin="normal"
                variant="outlined"
                placeholder="*********"
                type={view ? "text" : "password"}
                value={password}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {view ? (
                                <VisibilityIcon
                                    fontSize="small"
                                    style={{
                                        color: "#939393",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setView(!view);
                                    }}
                                />
                            ) : (
                                <VisibilityOffIcon
                                    fontSize="small"
                                    style={{
                                        color: "#939393",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setView(!view);
                                    }}
                                />
                            )}
                        </InputAdornment>
                    ),
                }}
                onChange={(event) => {
                    setPassword(event.target.value);
                }}
            />
            <Button
                id="submit"
                className="send-data-button"
                variant="contained"
                disableElevation
                fullWidth
                disabled={disabled}
                style={{
                    backgroundColor: `${!disabled ? "#ce043d" : "#E0E0E0"}`,
                }}
                onClick={() => {
                    validateInput();
                }}
            >
                {!btnLoad ? (
                    "Continue"
                ) : (
                    <CircularProgress size={24} style={{ color: "#ffffff" }} />
                )}
            </Button>
            <Link href="/reset-password/client">
                <Typography
                    variant="body1"
                    align="right"
                    style={{
                        marginTop: "3.5%",
                        padding: "0% 1.5%",
                    }}
                >
                    Forgot password?
                </Typography>
            </Link>
        </div>
    );
};
