import React, { useState } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import {
    TextField,
    Grid,
    Button,
    CircularProgress,
    Alert,
    Typography,
} from "@mui/material";
import { gqlQuery } from "../../../helpers/gql";
import { authProcess } from "../../../helpers/user";

export const AuthCode = ({ codeData: { authCode, phoneNumber } }) => {
    const [code1, setCode1] = useState("");
    const [code2, setCode2] = useState("");
    const [code3, setCode3] = useState("");
    const [code4, setCode4] = useState("");
    const [err, setError] = useState("");
    const [btnLoad, setBtnLoad] = useState(false);

    const validateInput = () => {
        try {
            setBtnLoad(true);
            let inputCode = code1 + code2 + code3 + code4;

            if (inputCode === authCode) {
                execute().then(({ data }) => {
                    authProcess(data.authClient.token);
                });
            } else {
                setError("Incorrect activation code");
                setBtnLoad(false);
            }
        } catch (err) {
            setError(err.message);
            setBtnLoad(false);
        }
    };

    let disabled = code1 === "" || code2 === "" || code3 === "" || code4 === "";

    let [execute] = useLazyQuery(
        gql`
            ${gqlQuery("authClient", gql)}
        `,
        {
            variables: {
                phoneNumber: phoneNumber,
                signUpMethod: "web",
                signUpRefCode: "",
            },
        }
    );

    document.addEventListener("keyup", (event) => {
        if (!disabled) {
            if (event.keyCode === 13) {
                validateInput();
            }
        }
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                {err !== "" && (
                    <Alert severity="error" style={{ marginBottom: "1.5%" }}>
                        {err}
                    </Alert>
                )}
                <Typography variant="body1">
                    Please enter the 4 digit verification code sent to{" "}
                    {phoneNumber}
                </Typography>
                <br />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    id="1"
                    name="1"
                    margin="normal"
                    variant="outlined"
                    value={code1}
                    autoFocus={true}
                    inputProps={{
                        maxLength: 1,
                        style: { fontSize: 40, textAlign: "center" },
                    }}
                    style={{
                        margin: "2%",
                        width: "80px",
                        padding: "5%",
                    }}
                    onChange={(event) => {
                        setCode1(event.target.value);
                        event.target.value !== "" &&
                            document.getElementById("2").focus();
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    id="2"
                    name="2"
                    margin="normal"
                    variant="outlined"
                    value={code2}
                    inputProps={{
                        maxLength: 1,
                        style: { fontSize: 40, textAlign: "center" },
                    }}
                    style={{
                        margin: "2%",
                        width: "80px",
                        padding: "5%",
                    }}
                    onChange={(event) => {
                        setCode2(event.target.value);
                        event.target.value !== "" &&
                            document.getElementById("3").focus();
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    id="3"
                    name="3"
                    margin="normal"
                    variant="outlined"
                    value={code3}
                    inputProps={{
                        maxLength: 1,
                        style: { fontSize: 40, textAlign: "center" },
                    }}
                    style={{
                        margin: "1%",
                        width: "80px",
                        padding: "5%",
                    }}
                    onChange={(event) => {
                        setCode3(event.target.value);
                        event.target.value !== "" &&
                            document.getElementById("4").focus();
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    id="4"
                    name="4"
                    margin="normal"
                    variant="outlined"
                    value={code4}
                    inputProps={{
                        maxLength: 1,
                        style: { fontSize: 40, textAlign: "center" },
                    }}
                    style={{
                        margin: "1%",
                        width: "80px",
                        padding: "5%",
                    }}
                    onChange={(event) => {
                        setCode4(event.target.value);
                    }}
                />
            </Grid>
            <Button
                id="submit"
                className="send-data-button"
                variant="contained"
                disableElevation
                fullWidth
                disabled={disabled}
                style={{
                    padding: "1.5% 0%",
                    backgroundColor: `${!disabled ? "#ce043d" : "#E0E0E0"}`,
                }}
                onClick={() => validateInput()}
            >
                {!btnLoad ? (
                    "Continue"
                ) : (
                    <CircularProgress size={24} style={{ color: "#ffffff" }} />
                )}
            </Button>
        </Grid>
    );
};
