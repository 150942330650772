import React from "react";
import jwtDecode from "jwt-decode";
import { Helmet } from "react-helmet";
import { Grid } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { gqlQuery } from "../helpers/gql";
import { InfoCard } from "../containers/institution/infoCard";
import { DataCard } from "../containers/institution/dataCard";
import { Menu } from "../components/menu/menu";
import { capitalize } from "../helpers/string";
import { clientAuthorization } from "../helpers/user";

export default function InstitutionPage({
    match: {
        params: { id },
    },
}) {
    clientAuthorization(jwtDecode, window.location);

    let { data, loading, error } = useQuery(
        gql`
            ${gqlQuery("singleService", gql)}
        `,
        {
            variables: {
                id,
            },
        }
    );

    if (loading) return loading;

    if (error) return <pre>{error.message}</pre>;

    if (data) console.log(data);

    return (
        <div>
            <Helmet>
                <title>{capitalize(data.singleService.institution.name)}</title>
                <meta
                    name="description"
                    content={data.singleService.description}
                />
            </Helmet>
            <Menu background={"transparent-white"} />
            <Grid container>
                <Grid
                    item
                    className="single-view"
                    style={{
                        background:
                            "url('https://ik.imagekit.io/9vm1rbs0vzz/managers/institutions/tr:q-100/" +
                            data.singleService.gallery.primaryPhoto,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        height: "50vh",
                    }}
                    xs={12}
                >
                    <div
                        style={{
                            height: "100%",
                            backgroundImage:
                                "linear-gradient(180deg, rgba(0, 0, 0,.5), rgba(255,255,255,0))",
                        }}
                    ></div>
                </Grid>
                <Grid container style={{ marginTop: "-10%" }}>
                    <Grid item xs={12} md={9}>
                        <InfoCard data={data.singleService} page="service" />
                        <DataCard data={data.singleService} page="service" />
                    </Grid>
                    <Grid item xs={12} md={3} className="wide-screen">
                        <div className="card" style={{ padding: "0px" }}>
                            <iframe
                                title="Geo Tag"
                                src={`https://www.google.com/maps/embed/v1/place?q=${data.singleService.location.gps.coordinates[0]},${data.singleService.location.gps.coordinates[1]}&key=${process.env.REACT_APP_MAPS_API_KEY}`}
                                width="100%"
                                height="400vh"
                                allowFullScreen=""
                                style={{
                                    border: "0",
                                    borderRadius: "10px",
                                }}
                                loading="lazy"
                            ></iframe>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
