import React from "react";
import { NavLink } from "react-router-dom";
import jwt_decode from "jwt-decode";
import cogs from "../middleware/cogs";
import { postRequest } from "../middleware/graphQl";
import {
    Drawer,
    AppBar,
    CssBaseline,
    Toolbar,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Grid,
    Tooltip,
    Typography,
    Link,
} from "@material-ui/core";

import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import PaymentIcon from "@material-ui/icons/Payment";

const drawerWidth = "17%";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "#324C74",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#324C74!important",
    },
    drawerContainer: {
        overflow: "auto",
    },
    content: {
        flexGrow: 1,
        padding: "4% 1% 10% 1%",
        height: "100vh",
        overflow: "auto",
    },
}));

export default function ClippedDrawer(props) {
    const classes = useStyles();

    const logOut = () => {
        localStorage.removeItem("manager");
        localStorage.removeItem("managerToken");
        localStorage.removeItem("institution");

        window.location.replace(`${process.env.REACT_APP_HOST}/manager/signin`);
    };

    cogs.managerAuthorization(jwt_decode, postRequest);

    const manager = JSON.parse(localStorage.getItem("manager"));
    const institution = JSON.parse(localStorage.getItem("institution"));

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Grid container>
                        <Grid item xs={4} style={{ display: "flex" }}>
                            <Link href={process.env.REACT_APP_HOST}>
                                <img
                                    style={{
                                        width: "120px",
                                        height: "auto",
                                    }}
                                    src={`${process.env.REACT_APP_HOST}/WapiDocLogoWhite.png`}
                                    alt="WapiDocLogo"
                                />
                            </Link>
                        </Grid>
                        <Grid item xs={4}>
                            <Link
                                href={`${process.env.REACT_APP_HOST}/manager/institution/${institution._id}`}
                            >
                                <Typography
                                    variant="subtitle1"
                                    align="center"
                                    style={{ color: "#ffffff" }}
                                >
                                    {cogs.capitalize(institution.name)}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    align="center"
                                    style={{
                                        color: "#ffffff",
                                        fontSize: "12px",
                                    }}
                                >
                                    {cogs.capitalize(institution.ward)}
                                    {", "}
                                    {cogs.capitalize(institution.county)}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            style={{
                                paddingTop: "1%",
                                textAlign: "right",
                            }}
                        >
                            <NavLink
                                exact
                                to="#"
                                style={{
                                    margin: "3% 5%",
                                }}
                            >
                                <Tooltip title="Notifications">
                                    <NotificationsNoneIcon
                                        style={{ color: "#ffffff" }}
                                    />
                                </Tooltip>
                            </NavLink>
                            <NavLink
                                exact
                                to="/manager/profile"
                                style={{
                                    margin: "3% 5%",
                                }}
                            >
                                <Tooltip title="My Profile">
                                    <AccountCircleIcon
                                        style={{ color: "#ffffff" }}
                                    />
                                </Tooltip>
                            </NavLink>
                            <NavLink
                                exact
                                to="#"
                                alt="logout"
                                onClick={() => logOut()}
                                style={{
                                    margin: "3% 5%",
                                }}
                            >
                                <Tooltip title="Logout">
                                    <ExitToAppIcon
                                        style={{ color: "#ffffff" }}
                                    />
                                </Tooltip>
                            </NavLink>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Toolbar />
                <div className={classes.drawerContainer}>
                    <List>
                        <NavLink exact to="/manager/dashboard">
                            <ListItem button key="dashboard">
                                <ListItemIcon>
                                    <DashboardIcon
                                        style={{ color: "#ffffff" }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Dashboard"
                                    style={{ color: "#ffffff" }}
                                />
                            </ListItem>
                        </NavLink>
                        {manager.owns === "multiple" ? (
                            <NavLink exact to="/manager/institutions">
                                <ListItem button key="institutions">
                                    <ListItemIcon>
                                        <StorefrontOutlinedIcon
                                            style={{ color: "#ffffff" }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="My Institutions"
                                        style={{ color: "#ffffff" }}
                                    />
                                </ListItem>
                            </NavLink>
                        ) : (
                            <NavLink
                                exact
                                to={`/manager/institution/${institution._id}`}
                            >
                                <ListItem button key="institution">
                                    <ListItemIcon>
                                        <StorefrontOutlinedIcon
                                            style={{ color: "#ffffff" }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="My Institution"
                                        style={{ color: "#ffffff" }}
                                    />
                                </ListItem>
                            </NavLink>
                        )}

                        <NavLink exact to="/manager/services">
                            <ListItem button key="services">
                                <ListItemIcon>
                                    <LocalHospitalIcon
                                        style={{ color: "#ffffff" }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Services"
                                    style={{ color: "#ffffff" }}
                                />
                            </ListItem>
                        </NavLink>
                        <NavLink exact to="/manager/gallery">
                            <ListItem button key="gallery">
                                <ListItemIcon>
                                    <PhotoLibraryIcon
                                        style={{ color: "#ffffff" }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Gallery"
                                    style={{ color: "#ffffff" }}
                                />
                            </ListItem>
                        </NavLink>
                        <NavLink exact to="/manager/billing"></NavLink>
                        <Divider />
                        <List component="div" disablePadding>
                            <NavLink exact to="/manager/invoices">
                                <ListItem button>
                                    <ListItemIcon>
                                        <ReceiptIcon
                                            style={{ color: "#ffffff" }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Invoices"
                                        style={{ color: "#ffffff" }}
                                    />
                                </ListItem>
                            </NavLink>
                            <NavLink exact to="/manager/payments">
                                <ListItem button>
                                    <ListItemIcon>
                                        <PaymentIcon
                                            style={{ color: "#ffffff" }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Payments"
                                        style={{ color: "#ffffff" }}
                                    />
                                </ListItem>
                            </NavLink>
                        </List>

                        <NavLink exact to="/manager/logs">
                            <ListItem button key="logs">
                                <ListItemIcon>
                                    <DescriptionIcon
                                        style={{ color: "#ffffff" }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Logs"
                                    style={{ color: "#ffffff" }}
                                />
                            </ListItem>
                        </NavLink>
                        {/* <NavLink exact to="/manager/settings">
                            <ListItem button key="settings">
                                <ListItemIcon>
                                    <TuneIcon style={{ color: "#ffffff" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Settings"
                                    style={{ color: "#ffffff" }}
                                />
                            </ListItem>
                        </NavLink> */}
                        <ListItem button key="logout" onClick={() => logOut()}>
                            <ListItemIcon>
                                <ExitToAppIcon style={{ color: "#ffffff" }} />
                            </ListItemIcon>
                            <ListItemText
                                primary="Logout"
                                style={{ color: "#ffffff" }}
                            />
                        </ListItem>
                    </List>
                </div>
            </Drawer>
            <main className={classes.content}>{props.content}</main>
        </div>
    );
}
