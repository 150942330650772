import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import {
    Grid,
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
    Tabs,
    Tab,
    Button,
} from "@material-ui/core";

class AdvertPage extends Component {
    state = {
        value: 0,
        category: "Monthly",
        pricingA: "KES 18,000",
        pricingB: "KES 30,000",
        pricingC: "KES 60,000",
        pricingD: "KES 100,000",
        pricingE: "KES 200,000",
        statement:
            "The clients are given a choice to pick a month that they would like to advertise in a given location.",
    };

    updateTable = () => {
        let category;
        let pricingA;
        let pricingB;
        let pricingC;
        let pricingD;
        let pricingE;
        let statement;

        if (this.state.value === 0) {
            category = "Monthly";
            pricingA = "KES 18,000";
            pricingB = "KES 30,000";
            pricingC = "KES 60,000";
            pricingD = "KES 100,000";
            pricingE = "KES 200,000";
            statement =
                "The clients are given a choice to pick a month that they would like to advertise in a given location.";
        } else if (this.state.value === 1) {
            category = "Semi Annual";
            pricingA = "KES 90,000";
            pricingB = "KES 156,000";
            pricingC = "KES 330,000";
            pricingD = "KES 550,000";
            pricingE = "KES 1,100,000";
            statement =
                "The clients are listed in a given area for 6 consecutive months based on different monthly themes.";
        } else if (this.state.value === 2) {
            category = "Annual";
            pricingA = "KES 160,000";
            pricingB = "KES 300,000";
            pricingC = "KES 620,000";
            pricingD = "KES 1,050,000";
            pricingE = "KES 2,100,000";
            statement =
                "The clients are listed in a given area for 12 consecutive months based on different monthly themes.";
        }

        this.setState({
            category: category,
            pricingA: pricingA,
            pricingB: pricingB,
            pricingC: pricingC,
            pricingD: pricingD,
            pricingE: pricingE,
            statement: statement,
        });
    };

    addEarlyBirdDiscount = (amount) => {
        return Math.round(amount - amount * 0.25);
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>Julikana na WapiDoc</title>
                    <meta
                        name="description"
                        content="Ready to get started with WapiDoc?"
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid
                    container
                    className={"pricing-table"}
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ margin: "0% auto", padding: "0% 5%" }}
                        className={"add-space"}
                    >
                        <Box my={3}>
                            <Typography variant="h4" component="h1">
                                WAPIDOC ADVERTISING CAMPAIGN
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="h5" component="h1">
                                Who is WapiDoc?
                            </Typography>
                            <Typography variant="body1">
                                Wapidoc is an online platform that seeks to
                                connect healthcare service providers (HCSP) with
                                those in need of healthcare services while at
                                the same time providing the much required
                                feedback of the quality of service received.
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="h5" component="h1">
                                What is Julikana Na Wapidoc all about?
                            </Typography>
                            <Typography variant="body1">
                                JNW endeavours to provide visibility of HCSP to
                                those in need of their services countrywide
                                using the various marketing platforms such as
                                billboards, social media platforms as well as
                                mainstream media.
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="body1">
                                With JNW, HCSP are given an opportunity to be
                                listed on our website absolutely free for one
                                year after taking up one of the billboard
                                marketing campaigns. The billboard marketing
                                campaign seeks to advertise the facilities
                                listed on our website. The campaign plans to
                                reduce the cost incurred by medical facilities
                                in putting up billboards by consolidating the
                                various medical facilities in an area that
                                complement each other in one billboard. There
                                are three advertising plans that offer the
                                clients a choice to pick one that offers them
                                the best. The options are as listed below:
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Box my={2} mt={5}>
                            <Tabs
                                value={this.state.value}
                                onChange={(event, value) => {
                                    this.setState(
                                        {
                                            value: value,
                                        },
                                        () => {
                                            this.updateTable();
                                        }
                                    );
                                }}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable force tabs example"
                            >
                                <Tab label="Monthly" />
                                <Tab label="Semi Annual" />
                                <Tab label="Annual" />
                            </Tabs>
                        </Box>
                    </Grid>
                    <br />
                    <Grid item xs={12} md={12} align="center">
                        <Box my={2}>
                            <Typography variant="body1">
                                {this.state.statement}
                            </Typography>
                        </Box>
                    </Grid>
                    <br />
                    <Grid item xs={12} md={2}>
                        <Box className="price-card" m={1}>
                            <Box p={2} className="header" py={3} px={2}>
                                <Typography
                                    variant="h5"
                                    style={{ color: "#ffffff" }}
                                >
                                    PLAN A
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{ color: "#ffffff" }}
                                >
                                    {this.state.pricingA}
                                </Typography>
                                <br />
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: "#ffffff",
                                        fontWeight: "600",
                                    }}
                                >
                                    {this.state.category} Package
                                </Typography>
                            </Box>
                            <Box>
                                <List>
                                    <ListItem>
                                        <ListItemText>
                                            Listed clients <b>20 - 24</b>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            Min Dimension <b>2X2M</b>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box className="price-card" m={1}>
                            <Box p={2} className="header" py={3} px={2}>
                                <Typography
                                    variant="h5"
                                    style={{ color: "#ffffff" }}
                                >
                                    PLAN B
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{ color: "#ffffff" }}
                                >
                                    {this.state.pricingB}
                                </Typography>
                                <br />
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: "#ffffff",
                                        fontWeight: "600",
                                    }}
                                >
                                    {this.state.category} Package
                                </Typography>
                            </Box>
                            <Box>
                                <List>
                                    <ListItem>
                                        <ListItemText>
                                            Listed clients <b>10 - 16</b>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            Min Dimension <b>2X3M</b>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box className="price-card" m={1}>
                            <Box p={2} className="header" py={3} px={2}>
                                <Typography
                                    variant="h5"
                                    style={{ color: "#ffffff" }}
                                >
                                    PLAN C
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{ color: "#ffffff" }}
                                >
                                    {this.state.pricingC}
                                </Typography>
                                <br />
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: "#ffffff",
                                        fontWeight: "600",
                                    }}
                                >
                                    {this.state.category} Package
                                </Typography>
                            </Box>
                            <Box>
                                <List>
                                    <ListItem>
                                        <ListItemText>
                                            Listed clients <b>5 - 8</b>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            Min Dimension <b>4X3.5M</b>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box className="price-card" m={1}>
                            <Box p={2} className="header" py={3} px={2}>
                                <Typography
                                    variant="h5"
                                    style={{ color: "#ffffff" }}
                                >
                                    PLAN D
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{ color: "#ffffff" }}
                                >
                                    {this.state.pricingD}
                                </Typography>
                                <br />
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: "#ffffff",
                                        fontWeight: "600",
                                    }}
                                >
                                    {this.state.category} Package
                                </Typography>
                            </Box>
                            <Box>
                                <List>
                                    <ListItem>
                                        <ListItemText>
                                            Listed clients <b>2 - 4</b>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            Min Dimension <b>5X5M</b>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Box className="price-card" m={1}>
                            <Box p={2} className="header" py={3} px={2}>
                                <Typography
                                    variant="h5"
                                    style={{ color: "#ffffff" }}
                                >
                                    PLAN E
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{ color: "#ffffff" }}
                                >
                                    {this.state.pricingE}
                                </Typography>
                                <br />
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: "#ffffff",
                                        fontWeight: "600",
                                    }}
                                >
                                    {this.state.category} Package
                                </Typography>
                            </Box>
                            <Box>
                                <List>
                                    <ListItem>
                                        <ListItemText>
                                            Listed clients <b>1</b>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>
                                            Min Dimension <b>10X10M</b>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ margin: "0% auto", padding: "3% 5%" }}
                        className={"add-space"}
                    >
                        <Box my={3}>
                            <Typography variant="h5" gutterBottom>
                                Benefits Of Listing on the WapiDoc Advertisement
                                Campaign
                            </Typography>
                            <Typography variant="body1" paragraph gutterBottom>
                                <ul>
                                    <li>
                                        HCSP get free listing on the wapidoc
                                        website for one year from the last paid
                                        advertisement.
                                    </li>
                                    <li>
                                        Ability to be seen at different
                                        locations in the country and around the
                                        world.
                                    </li>
                                    <li>
                                        Access to various medical associations
                                        that collaborate with wapidoc.
                                    </li>
                                    <li>
                                        HCSP get support from wapidoc team when
                                        it comes to managing their page on the
                                        wapidoc platform e.g adding
                                        photos,services and any other requests
                                        made for the page.
                                    </li>
                                    <li>
                                        Free report for the first quarter your
                                        listed on the platform.
                                    </li>
                                    <li>
                                        Additional publicity, exposure and
                                        credibility.
                                    </li>
                                </ul>
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={8}
                        align="center"
                        className={"add-space"}
                    >
                        <Box my={3}>
                            <Typography variant="h5" gutterBottom>
                                Samples
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box my={3} align="right">
                            <img
                                src="https://ik.imagekit.io/9vm1rbs0vzz/public/sample1_Ybfe9AIsv.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660546654800"
                                alt="Sample 1"
                                style={{
                                    width: "400px",
                                    margin: "0% auto",
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box my={3}>
                            <img
                                src="https://ik.imagekit.io/9vm1rbs0vzz/public/sample2_5OzZazr7j.png?ik-sdk-version=javascript-1.4.3&updatedAt=1660546657361"
                                alt="Sample 2"
                                style={{
                                    width: "400px",
                                    margin: "0% auto",
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        align="center"
                        style={{
                            padding: "5%",
                            margin: "5%",
                            background: "#cf053e",
                        }}
                        className={"card"}
                    >
                        <Typography
                            variant="body1"
                            style={{
                                color: "#ffffff",
                                fontSize: "20px",
                            }}
                        >
                            Looking to get started
                        </Typography>
                        <Typography variant="h3" style={{ color: "#ffffff" }}>
                            Register now
                        </Typography>
                        <Button
                            className=""
                            variant="outlined"
                            href="/manager/signup"
                            style={{
                                padding: "1% 2%",
                                border: "1px solid #ffffff",
                                color: "#ffffff",
                                margin: "3% 1%",
                            }}
                        >
                            Get Started
                        </Button>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default AdvertPage;
