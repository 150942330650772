import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import { Grid, Typography, Box } from "@material-ui/core";

class ChristmasSmilesPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>10 Christmas Smiles initiative</title>
                    <meta
                        name="description"
                        content="No amount is too little to save a life so kindly support this initiative."
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ margin: "10% auto 0% auto", padding: "0% 5%" }}
                        className={"add-space"}
                    >
                        <Box my={3}>
                            <Typography variant="h4" component="h1">
                                10 CHRISTMAS SMILES INITIATIVE
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="body1">
                                WapiDoc has endeavored to change the outlook of
                                our medical world by providing access to medical
                                practitioners and facilities for the past year.
                                Through our website and App, WapiDoc seeks to
                                provide all users with information on where they
                                can find all medical services including
                                veterinary services
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="body1">
                                We have been supporting Dr. Joseph Murimi the
                                Founder/Executive Director of Save a Life
                                Community in various capacities ranging from
                                financial aid to advising on doctors to perform
                                various medical procedures.
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="body1">
                                As they celebrate 1000 Lives Saved on{" "}
                                <b>10th Dec 2022</b> at{" "}
                                <b>Roasters Luke Hotel</b>, WapiDoc will be
                                conducting a fundraising campaign to enable them
                                to change the lives of 10 people before
                                Christmas.
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="body1">
                                With an average procedure going for
                                approximately 3.5M Shillings, we are looking to
                                raise 35M for the organization. These funds will
                                be utilized to cover the medical procedure,
                                nursing requirements after the procedure and the
                                purchase of medicine to be used after the
                                procedure.
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="body1">
                                The patients will be picked from the Save a Life
                                Community priority list that is compiled from
                                all over the country based on the health urgency
                                of the patient or the level of threat to his/her
                                life.
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="body1">
                                The procedures will be conducted in different
                                medical facilities in and around the country
                                subject to the availability of the doctors and
                                facilities themselves.
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="body1">
                                As a token of appreciation, WapiDoc and Save a
                                Life Community will give a token of appreciation
                                to the top three organizations and individuals
                                to support this initiative on the day of marking
                                the celebrations.
                            </Typography>
                            <br />
                            <br />
                            <Typography variant="body1">
                                No amount is too little to save a life so kindly
                                support this initiative.
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                <a
                                    href={
                                        "https://mchanga.africa/fundraiser/60104"
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Mchanga
                                </a>
                            </Typography>
                            <Typography variant="body1">
                                <a
                                    href={"https://www.savealifecommunity.org/"}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Save a life community
                                </a>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={3} align="center">
                            <img
                                src="https://ik.imagekit.io/9vm1rbs0vzz/public/christmas_smiles.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1668616309068"
                                alt="christmas smiles"
                                style={{
                                    width: "400px",
                                    margin: "0% auto",
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default ChristmasSmilesPage;
