import React from "react";
import { Grid, List, ListItem, Typography, ListItemText } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const TopMenu = ({ bgColor }) => {
    return (
        <Grid
            container
            className={"top-menu"}
            id="topmenu"
            style={{ background: "transparent" }}
        >
            <Grid item xs={12} md={8}>
                <List
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                        color: `${
                            bgColor === "transparent-white"
                                ? "#ffffff"
                                : "#444444"
                        }`,
                    }}
                    className={"info"}
                >
                    <ListItem component="a" href="/" key="home" align="left">
                        <Typography
                            variant="subtitle2"
                            style={{
                                color: `${
                                    bgColor === "transparent-white"
                                        ? "#ffffff"
                                        : "#444444"
                                }`,
                            }}
                        >
                            Home
                        </Typography>
                    </ListItem>
                    <ListItem
                        component="a"
                        href="../about-us"
                        key="about"
                        align="left"
                    >
                        <Typography
                            variant="subtitle2"
                            style={{
                                color: `${
                                    bgColor === "transparent-white"
                                        ? "#ffffff"
                                        : "#444444"
                                }`,
                            }}
                        >
                            About Us
                        </Typography>
                    </ListItem>
                    <ListItem
                        component="a"
                        href="../contact-us"
                        key="contacts"
                        align="left"
                        className={"wide-screen"}
                    >
                        <Typography
                            variant="subtitle2"
                            style={{
                                color: `${
                                    bgColor === "transparent-white"
                                        ? "#ffffff"
                                        : "#444444"
                                }`,
                            }}
                        >
                            Contact Us
                        </Typography>
                    </ListItem>
                    <ListItem
                        component="a"
                        href="tel:+254113885426"
                        key="call"
                        align="left"
                    >
                        <ListItemText>
                            <Typography
                                variant="subtitle2"
                                style={{
                                    color: `${
                                        bgColor === "transparent-white"
                                            ? "#ffffff"
                                            : "#444444"
                                    }`,
                                }}
                            >
                                <CallIcon
                                    fontSize={"small"}
                                    style={{ marginBottom: "-3%" }}
                                />{" "}
                                &nbsp; 0113885426
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={4} className={"wide-screen"}>
                <List
                    style={{
                        display: "flex",
                        alignItems: "right",
                        justifyContent: "right",
                    }}
                    className={"info"}
                >
                    <ListItem
                        component="a"
                        target="_blank"
                        href="https://www.facebook.com/WapiDoc-102230008911132"
                        key="facebook"
                    >
                        <ListItemText
                            primary={
                                <FacebookIcon
                                    style={{
                                        color: `${
                                            bgColor === "transparent-white"
                                                ? "#ffffff"
                                                : "#444444"
                                        }`,
                                    }}
                                />
                            }
                        />
                    </ListItem>
                    <ListItem
                        component="a"
                        target="_blank"
                        href="https://twitter.com/wapidoc"
                        key="twitter"
                    >
                        <ListItemText
                            primary={
                                <TwitterIcon
                                    style={{
                                        color: `${
                                            bgColor === "transparent-white"
                                                ? "#ffffff"
                                                : "#444444"
                                        }`,
                                    }}
                                />
                            }
                        />
                    </ListItem>
                    <ListItem
                        component="a"
                        target="_blank"
                        href="https://www.linkedin.com/in/wapidoc/"
                        key="linkedin"
                    >
                        <ListItemText
                            primary={
                                <LinkedInIcon
                                    style={{
                                        color: `${
                                            bgColor === "transparent-white"
                                                ? "#ffffff"
                                                : "#444444"
                                        }`,
                                    }}
                                />
                            }
                        />
                    </ListItem>
                    <ListItem
                        component="a"
                        target="_blank"
                        href="https://instagram.com/wapidoc"
                        key="instagram"
                    >
                        <ListItemText
                            primary={
                                <InstagramIcon
                                    style={{
                                        color: `${
                                            bgColor === "transparent-white"
                                                ? "#ffffff"
                                                : "#444444"
                                        }`,
                                    }}
                                />
                            }
                        />
                    </ListItem>
                    <ListItem
                        component="a"
                        target="_blank"
                        href="https://wa.me/%2B254745308174?text=I'd%20like%20to%20enquire%20about"
                        key="whatsapp"
                    >
                        <ListItemText
                            primary={
                                <WhatsAppIcon
                                    style={{
                                        color: `${
                                            bgColor === "transparent-white"
                                                ? "#ffffff"
                                                : "#444444"
                                        }`,
                                    }}
                                />
                            }
                        />
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};
