import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import SignUp from "./pages/signup";
import SignIn from "./pages/signin";
import homePage from "./pages/home";
import managerSignUp from "./pages/manager/signup";
import managerSignIn from "./pages/manager/signin";
import institutionPage from "./pages/manager/institution";
import institutionEdit from "./pages/manager/institutionEdit";
import institutionsPage from "./pages/manager/institutions";
import invoicesPage from "./pages/manager/invoices";
import paymentsPage from "./pages/manager/payments";
import galleryPage from "./pages/manager/gallery";
import dashboardPage from "./pages/manager/dashboard";
import servicesPage from "./pages/manager/services";
import servicePage from "./pages/manager/service";
import profilePage from "./pages/manager/profile";
import invoicePage from "./pages/manager/invoice";
import institutionSetup from "./pages/manager/institutionSetup";
import logsPage from "./pages/manager/log";
import settingsPage from "./pages/manager/settings";

import searchPage from "./pages/search";
import managersPage from "./pages/managers";
import requirementsPage from "./pages/requirements";
import careersPage from "./pages/careers";
// import careersContentCreatorPage from "./pages/career-content-creator";
import careersFieldTeamsPage from "./pages/career-field-teams";
// import careersFullStackDevPage from "./pages/career-full-stack-dev";
import pricingsPage from "./pages/pricing";
import advertPage from "./pages/advert";
import christmasSmilesPage from "./pages/christmas-smiles";
import howItWorksPage from "./pages/how-it-works";

import resetPasswordPage from "./pages/auth/reset-password";
import changePasswordPage from "./pages/auth/change-password";

import clientInstitutionPage from "./pages/institution";
import clientServicePage from "./pages/service";
import clientProfilePage from "./pages/profile";
import favouritesPage from "./pages/favourites";
import referralsPage from "./pages/referral";
import TermsandConditionsPage from "./pages/terms-and-conditions";
import PrivacyPolicyPage from "./pages/privacy-policy";
import CookiePolicyPage from "./pages/cookie-policy";
import FAQsPage from "./pages/faqs";
import AboutUsPage from "./pages/about-us";
import ContactUsPage from "./pages/contact-us";
import ManagerActivate from "./pages/manager/auth/activate";
import Onboarding from "./pages/manager/onboarding";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <React.Fragment>
                    <Switch>
                        <Route
                            path="/manager/auth/activate/:token"
                            component={ManagerActivate}
                        />
                        <Route
                            path="/manager/institution/onboarding/:industry/:institution"
                            component={Onboarding}
                        />
                        <Route
                            path="/manager/signup/:refcode?"
                            component={managerSignUp}
                        />
                        <Route
                            exact
                            path="/manager/signin/"
                            component={managerSignIn}
                        />
                        <Route
                            path="/manager/institution/setup/"
                            component={institutionSetup}
                        />
                        <Route
                            exact
                            path="/manager/institutions/"
                            component={institutionsPage}
                        />
                        <Route
                            exact
                            path="/manager/institution/edit/:id"
                            component={institutionEdit}
                        />
                        <Route
                            exact
                            path="/manager/institution/:id"
                            component={institutionPage}
                        />
                        <Route
                            exact
                            path="/manager/dashboard/"
                            component={dashboardPage}
                        />
                        <Route
                            exact
                            path="/manager/services/"
                            component={servicesPage}
                        />
                        <Route
                            exact
                            path="/manager/service/:id"
                            component={servicePage}
                        />
                        <Route
                            exact
                            path="/manager/profile/"
                            component={profilePage}
                        />
                        <Route
                            exact
                            path="/manager/gallery/"
                            component={galleryPage}
                        />
                        <Route
                            exact
                            path="/manager/invoices/"
                            component={invoicesPage}
                        />
                        <Route
                            exact
                            path="/manager/invoice/:id"
                            component={invoicePage}
                        />
                        <Route
                            exact
                            path="/manager/payments/"
                            component={paymentsPage}
                        />
                        <Route
                            exact
                            path="/manager/settings/"
                            component={settingsPage}
                        />
                        <Route
                            exact
                            path="/manager/logs/"
                            component={logsPage}
                        />

                        {/**
                         * ----------------------
                         * Static Pages | Manager
                         * ----------------------
                         */}
                        <Route
                            exact
                            path="/managers/application-requirements"
                            component={requirementsPage}
                        />
                        <Route
                            exact
                            path="/managers/pricing/"
                            component={pricingsPage}
                        />
                        <Route
                            exact
                            path="/managers/julikana-na-wapidoc/"
                            component={advertPage}
                        />
                        <Route
                            exact
                            path="/managers/how-it-works"
                            component={howItWorksPage}
                        />
                        <Route
                            exact
                            path="/managers"
                            component={managersPage}
                        />
                        {/**
                         * ---------------------
                         * Static Pages | Client
                         * ---------------------
                         */}
                        <Route
                            exact
                            path="/legal/terms-and-conditions/"
                            component={TermsandConditionsPage}
                        />
                        <Route
                            exact
                            path="/christmas-smiles/"
                            component={christmasSmilesPage}
                        />
                        <Route
                            exact
                            path="/legal/privacy-policy/"
                            component={PrivacyPolicyPage}
                        />
                        <Route
                            exact
                            path="/legal/cookie-policy/"
                            component={CookiePolicyPage}
                        />
                        <Route exact path="/faqs" component={FAQsPage} />
                        <Route exact path="/about-us" component={AboutUsPage} />
                        <Route
                            exact
                            path="/contact-us"
                            component={ContactUsPage}
                        />
                        <Route exact path="/signin/" component={SignIn} />
                        <Route exact path="/signup/" component={SignUp} />
                        <Route
                            exact
                            path="/institution/:id"
                            component={clientInstitutionPage}
                        />
                        <Route
                            exact
                            path="/service/:id"
                            component={clientServicePage}
                        />
                        {/* <Route
                            path="/careers/digital-marketer-and-content-creator"
                            component={careersContentCreatorPage}
                        /> */}
                        <Route
                            path="/careers/field-sales-marketing-teams"
                            component={careersFieldTeamsPage}
                        />
                        {/* <Route
                            path="/careers/full-stack-dev-intern"
                            component={careersFullStackDevPage}
                        /> */}
                        <Route path="/careers/" component={careersPage} />
                        <Route
                            exact
                            path="/reset-password/:user"
                            component={resetPasswordPage}
                        />
                        <Route
                            exact
                            path="/change-password/:token"
                            component={changePasswordPage}
                        />
                        <Route path="/profile/" component={clientProfilePage} />
                        <Route
                            exact
                            path="/favourites/"
                            component={favouritesPage}
                        />
                        <Route
                            exact
                            path="/referral-program/"
                            component={referralsPage}
                        />
                        <Route path="/search" component={searchPage} />
                        <Route exact path="/" component={homePage} />
                    </Switch>
                </React.Fragment>
            </BrowserRouter>
        );
    }
}

export default App;
