import React from "react";
import {
    Grid,
    Typography,
    Button,
} from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";
import insurances from "../../../../pages/manager/insurance.json";

export const InstitutionInsurance = ({ data, updateData, setReady }) => {
    let disabled = data.insurance.length < 1;

    return (
        <Grid container>
            <Grid item xs={12} md={8} style={{ margin: "0px auto" }}>
                <Typography
                    variant="body2"
                    textAlign={"center"}
                    style={{
                        color: "#888",
                        fontSize: "24px",
                        padding: "3% 0%",
                    }}
                >
                    Insurance options
                </Typography>
                {insurances.map((insurance) => {
                    return (
                        <Button
                            key={insurance}
                            style={{
                                margin: ".8%",
                                fontSize: "12px",
                                borderColor: "#000000",
                                color: `${
                                    data.insurance.includes(insurance)
                                        ? "white"
                                        : "black"
                                }`,
                                backgroundColor: `${
                                    data.insurance.includes(insurance)
                                        ? "#ce043d"
                                        : "white"
                                }`,
                            }}
                            onClick={() =>
                                updateData({
                                    ...data,
                                    insurance: data.insurance.includes(
                                        insurance
                                    )
                                        ? data.insurance.filter(
                                              (x, i) =>
                                                  i !==
                                                  data.insurance.indexOf(
                                                      insurance
                                                  )
                                          )
                                        : data.insurance.concat([insurance]),
                                })
                            }
                            disableElevation
                            variant={
                                data.insurance.includes(insurance)
                                    ? "contained"
                                    : "outlined"
                            }
                            endIcon={
                                data.insurance.includes(insurance) ? (
                                    <Cancel style={{ fontSize: "17px" }} />
                                ) : (
                                    false
                                )
                            }
                        >
                            {insurance}
                        </Button>
                    );
                })}

                <Grid container spacing={3} my>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            style={{
                                backgroundColor: "#444444",
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    status: "contacts",
                                });
                            }}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            disabled={disabled}
                            style={{
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                                marginLeft: "0%",
                                backgroundColor: `${
                                    !disabled ? "#ce043d" : "#E0E0E0"
                                }`,
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    status: "social-media",
                                });
                                setReady(true);
                            }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
