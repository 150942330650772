import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { CookiesProvider } from "react-cookie";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import {
    ApolloProvider,
    InMemoryCache,
    ApolloClient,
    ApolloLink,
    HttpLink,
} from "@apollo/client";

const THEME = createTheme({
    typography: {
        fontFamily: "TTNormsRegular",
        fontStyle: "normal",
        fontWeightLight: 200,
        fontWeightRegular: 200,
        fontWeightMedium: 200,
    },
});

const client = new HttpLink({
    uri: "https://client.wapidoc.com",
});
const manager = new HttpLink({
    uri: "https://manager.wapidoc.com/",
});

const config = new ApolloClient({
    link: ApolloLink.split(
        (operation) => operation.getContext().clientName === "manager",
        manager,
        client
    ),
    cache: new InMemoryCache({ addTypename: false }),
});

ReactDOM.render(
    <SnackbarProvider maxSnack={3}>
        <CookiesProvider>
            <MuiThemeProvider theme={THEME}>
                <ApolloProvider client={config}>
                    <App />
                </ApolloProvider>
            </MuiThemeProvider>
        </CookiesProvider>
    </SnackbarProvider>,
    document.getElementById("root")
);
