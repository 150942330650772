import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import { Grid, Typography, Link } from "@material-ui/core";
class FAQsPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>FAQ's</title>
                    <meta
                        name="description"
                        content="WapiDoc is the leading online resource for users to
                            find and connect with the right health care
                            institutions."
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid
                    container
                    style={{ paddingTop: "5%", paddingBottom: "5%" }}
                    className={"add-space"}
                >
                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ margin: "0% auto", padding: "5%" }}
                        className={"add-space"}
                    >
                        <Typography variant="h4" component="h1" gutterBottom>
                            FAQs
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            What is WapiDoc?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            WapiDoc is the leading online resource for users to
                            find and connect with the right health care
                            institutions. We help about a million people a day
                            find and connect with healthcare providers. You can
                            search for top-rated health care institutions on our
                            site, read what other patients have to say about
                            them.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Is WapiDoc free?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            Yes. WapiDoc is advertiser supported and therefore
                            free for users to use. If you are a health care
                            provider, click{" "}
                            <Link href="../manager/signup">here</Link> to claim
                            your account.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Does WapiDoc have a privacy policy?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            Yes, you can read our privacy policy{" "}
                            <Link href="../legal/privacy-policy">here</Link>.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            How do I contact customer service?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            To contact our customer support team, please fill
                            out our online form,{" "}
                            <Link href="../contact-us">here</Link>.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Health Care Institutions
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Can you contact my health care provider for me?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            We are not able to leave a message for your health
                            care provider. We recommend that you contact your
                            health care provider by the phone number listed on
                            their profile.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Can you give me my health care provider's email
                            address?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            Unfortunately, we don't have access to individual
                            health care provider's email addresses.They have the
                            ability to authorize the emails they want available
                            in public.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            How do I find a health care provider on your site?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            You can search for a health care provider on any
                            page of our website with the search bar at the top
                            of the page. You’ll be able to search by service and
                            your current location. You can also filter by
                            distance, institution type and reviews.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Why isn't my health care provider listed on your
                            site?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            Your healthcare provider has not listed their
                            institution with us.We currently list every health
                            care provider in the country who has an active
                            medical lisence provided by the relevant authority.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Where does your health care provider data come from?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            Data on wapidoc.com is collected from direct health
                            care providers with the interest to be listed on our
                            site.
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            Are health care providers paying to be on this site?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            Yes. There are different listing options available
                            for different types of health care institutions to
                            promote their themselves on our site.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Can I correct information on a health care
                            provider’s profile?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            No. You cannot directly correct a health care
                            provider's profile but you can make suggestion by
                            contacting them directly.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Health care provider Reviews
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            How do I leave a review for a health care provider?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            To leave a review for a health care provider, you
                            first need to find their profile on our site at
                            www.wapidoc.com. On their profile, click the option
                            under their name that says "LEAVE A REVIEW", and you
                            should be directed to a pop-up form to fill out your
                            review.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            How do I remove or edit a review I submitted?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            If you created a WapiDoc account with password when
                            submitting your review, you can simply log back into
                            your account, find the health care institution your
                            left a review for. From this page, you will be able
                            to remove or edit your review.
                        </Typography>
                        {/* <Typography variant="h6" gutterBottom>
                            How do I flag a comment for review?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            To flag a review which you believe displays
                            inappropriate content or privacy information, click
                            on the ellipsis (…) in the top right corner of the
                            comment box and select “Flag Comment.” Complete the
                            questionnaire and click “Submit Flag.” Once
                            submitted, the customer service team will be
                            notified of the flagged comment and will review
                            based on our review guidelines.
                        </Typography> */}
                        {/* <Typography variant="h6" gutterBottom>
                            Does WapiDoc monitor which reviews get published?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            We do look at the reviews to make sure they comply
                            with our guidelines. We’re committed to maintaining
                            the quality and integrity of our reviews, so each
                            review does go through a confirmation and auditing
                            process. All content, both from patients and
                            providers, is audited for inappropriate content
                            prior to being published.
                        </Typography> */}
                        <Typography variant="h6" gutterBottom>
                            Will my review be posted anonymously?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            No. When you leave a review, the information you
                            used to register your account will be available to
                            the public. i.e Name, location and diplay image.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Why doesn't my health care provider have a star
                            rating?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            If a health care provider doesn't have a star rating
                            on their profile, it's more than likely because a
                            user has not left a review for them yet. If you’d
                            like to leave feedback for your health care
                            provider, you can be the first to leave a review for
                            them on wapidoc.com.
                        </Typography>
                        {/* <Typography variant="h6" gutterBottom>
                            Why doesn't my health care provider have written
                            reviews?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            Not everyone who provides feedback on a health care
                            provider includes a written review, so some
                            providers only have star ratings while others have
                            star ratings with written reviews. If you’d like to
                            share your feedback, you can be the first patient to
                            leave a written review for your health care
                            provider.
                        </Typography> */}
                        <Typography variant="h6" gutterBottom>
                            Do health care providers pay for good reviews?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            No, health care providers cannot pay for good
                            reviews.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Do health care providers pay to have bad reviews
                            removed?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            No, health care providers cannot pay to have
                            negative reviews removed from our site.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Can I leave more than one review?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            We don’t allow users to submit multiple reviews for
                            one provider. You can, however, submit more than one
                            review if you’re submitting for more than one
                            provider.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Why did WapiDoc post my first and last name on my
                            review?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            When you leave a review, the data you used to
                            register your account will be posted with your
                            review. i.e name, location and display photo.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Are there rules on what I can and cannot say in my
                            review?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            Yes, and those are listed within our community
                            guidelines. Any reviews that include name-calling,
                            personal attacks, racist comments, threats,
                            obscenities, phone numbers, email addresses or URLs
                            will not be posted on our site.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            WapiDoc user Accounts
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            What can I do with a WapiDoc account?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            A WapiDoc account allows you to save health care
                            providers for featured health care providers.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Can I sign up for a WapiDoc account?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            Patients and consumers can create a free WapiDoc
                            account by signing up for one{" "}
                            <Link href="../signup">here</Link>. If you are a
                            provider or administrator you can sign up for a
                            health care provider account{" "}
                            <Link href="../manager/signup">here</Link>.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            How do I reset my password?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            You can reset your password by selecting the "forgot
                            your password" link on the login page. Please be
                            sure you are using the correct email address. If you
                            do not receive an email within a day, the address
                            you entered is probably not the one you used to sign
                            up.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Emails from WapiDoc
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Why am I getting emails from WapiDoc?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            There are a number of reasons you could be getting
                            emails from us: If you reviewed a health care
                            provider on our site or signed up for a WapiDoc
                            account.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            How do I opt-out of emails from WapiDoc?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            You can opt-out of WapiDoc emails by clicking
                            unsubscribe from any of the emails you've received
                            from WapiDoc
                        </Typography>
                        {/* <Typography variant="h6" gutterBottom>
                            Does WapiDoc have an email newsletter?
                        </Typography>
                        <Typography variant="body1"  gutterBottom>
                            We have a biweekly newsletter that covers a variety
                            of health topics. You can sign up for this
                            newsletter here.
                        </Typography> */}
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default FAQsPage;
