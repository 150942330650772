import React from "react";
import { List, Link, ListItem, ListItemText } from "@mui/material";

const logOut = () => {
    localStorage.removeItem("manager");
    localStorage.removeItem("managerToken");
    localStorage.removeItem("institution");

    window.location.replace(`${process.env.REACT_APP_HOST}/`);
};

export const ManagerMenu = () => {
    return (
        <List
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                paddingTop: "1%",
            }}
        >
            <Link href="/manager/dashboard" style={{ margin: "0% 2%" }}>
                <ListItem key="dashboard">
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </Link>
            <Link href={"#"} style={{ margin: "0% 2%" }}>
                <ListItem key="logout" onClick={() => logOut()}>
                    <ListItemText primary="Logout" />
                </ListItem>
            </Link>
        </List>
    );
};
