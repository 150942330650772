/**
 * Function that returns the right gql query based on the input
 * @param {*} string
 * @returns GQL Query
 */
export const gqlQuery = (key, gql) => {
    let query = [];

    switch (key) {
        case "searchInstitution":
            query = `
                query SearchInstitution(
                    $keyword: String
                    $filterData: FilterData
                    $userData: UserData
                ) {
                    searchInstitution(
                        keyword: $keyword
                        filterData: $filterData
                        userData: $userData
                    ) {
                        _id
                        name
                        type
                        location {
                            county
                            constituency
                            ward
                            streetAddress {
                                street
                                building
                                floor
                                unit
                            }
                            gps {
                                coordinates
                                distance
                            }
                        }
                        reviewCount
                        averageRating
                        gallery {
                            primaryPhoto
                            photos
                        }
                    }
                }`;
            break;
        case "searchService":
            query = `
                query SearchService(
                    $keyword: String!
                    $filterData: FilterData
                    $userData: UserData
                ) {
                    searchService(
                        keyword: $keyword
                        filterData: $filterData
                        userData: $userData
                    ) {
                        _id
                        name
                        institution
                        type
                        location {
                            county
                            constituency
                            ward
                            streetAddress {
                                street
                                building
                                floor
                                unit
                            }
                            gps {
                                coordinates
                                distance
                            }
                        }
                        reviewCount
                        averageRating
                        gallery {
                            primaryPhoto
                            photos
                        }
                    }
                }`;
            break;
        case "singleInstitution":
            query = `
                query SingleInstitution($id: String) {
                    singleInstitution(_id: $id) {
                        _id
                        name
                        description
                        type
                        location {
                            county
                            constituency
                            ward
                            streetAddress {
                                street
                                building
                                floor
                                unit
                            }
                            gps{
                                coordinates
                            }
                        }
                        contact {
                            address
                            phoneNumber {
                                primary
                                secondary
                            }
                            email {
                                primary
                                secondary
                            }
                        }
                        links {
                            linkedin
                            facebook
                            instagram
                            twitter
                            youtube
                            website
                        }
                        hours {
                            workingHours {
                                weekday
                                saturday
                                sunday
                                holiday
                            }
                            visitingHours {
                                morning
                                afternoon
                                evening
                            }
                        }
                        gallery {
                            primaryPhoto
                            photos
                        }
                        insurance
                        reviews {
                            _id
                            rating
                            comment
                            client {
                                _id
                                profilePhoto
                                firstName
                                lastName
                            }
                            service {
                                _id
                                name
                            }
                            institution
                        }
                        averageRating
                        services {
                            _id
                            name
                        }
                    }
                }`;
            break;
        case "institutions":
            query = `
                query Institutions($ids: [String!]) {
                    institutions(ids: $ids) {
                        _id
                        name
                        description
                        type
                        location {
                            county
                            constituency
                            ward
                            streetAddress {
                                street
                                building
                                floor
                                unit
                            }
                            gps{
                                coordinates
                            }
                        }
                        contact {
                            address
                            phoneNumber {
                                primary
                                secondary
                            }
                            email {
                                primary
                                secondary
                            }
                        }
                        links {
                            linkedin
                            facebook
                            instagram
                            twitter
                            youtube
                            website
                        }
                        hours {
                            workingHours {
                                weekday
                                saturday
                                sunday
                                holiday
                            }
                            visitingHours {
                                morning
                                afternoon
                                evening
                            }
                        }
                        gallery {
                            primaryPhoto
                            photos
                        }
                        insurance
                        reviews {
                            _id
                            rating
                            comment
                            client {
                                _id
                                profilePhoto
                                firstName
                                lastName
                            }
                            service {
                                _id
                                name
                            }
                            institution
                        }
                        averageRating
                        services {
                            _id
                            name
                        }
                    }
                }`;
            break;
        case "singleService":
            query = `
                query SingleService($id: String) {
                    singleService(_id: $id) {
                        _id
                        name
                        description
                        type
                        location {
                            county
                            constituency
                            ward
                            streetAddress {
                                street
                                building
                                floor
                                unit
                            }
                            gps{
                                coordinates
                            }
                        }
                        contact {
                            address
                            phoneNumber {
                                primary
                                secondary
                            }
                            email {
                                primary
                                secondary
                            }
                        }
                        links {
                            linkedin
                            facebook
                            instagram
                            twitter
                            youtube
                            website
                        }
                        hours {
                            workingHours {
                                weekday
                                saturday
                                sunday
                                holiday
                            }
                            visitingHours {
                                morning
                                afternoon
                                evening
                            }
                        }
                        gallery {
                            primaryPhoto
                            photos
                        }
                        insurance
                        reviews {
                            _id
                            rating
                            comment
                            client {
                                _id
                                profilePhoto
                                firstName
                                lastName
                            }
                            service {
                                _id
                                name
                            }
                            institution
                        }
                        averageRating
                        offers {
                            _id
                            name
                            price
                        }
                        institution {
                            _id
                            name
                        }
                    }
                }`;
            break;
        case "authPhone":
            query = `
                query AuthPhone($phoneNumber: String!) {
                    authPhone(phoneNumber: $phoneNumber) {
                        authCode
                        phoneNumber
                    }
                }`;
            break;
        case "authClient":
            query = `
                query AuthClient(
                    $phoneNumber: String!, 
                    $signUpMethod: String, 
                    $signUpRefCode: String
                    ) {
                    authClient(
                        phoneNumber: $phoneNumber, 
                        signUpMethod: $signUpMethod, 
                        signUpRefCode: $signUpRefCode
                    ) {
                        token
                        tokenExpiration
                    }
                }`;
            break;
        case "authLogin":
            query = `
                query AuthLogin($email: String!, $password: String!) {
                    authLogin(email: $email, password: $password) {
                        token,
                        tokenExpiration
                    }
                }`;
            break;
        case "singleClient":
            query = `
                query SingleClient($id: String!) {
                    singleClient(_id: $id) {
                        _id
                        firstName
                        lastName
                        phoneNumber
                        dob
                        gender
                        county
                        constituency
                        ward
                        favourites
                        profilePhoto
                        referralCode
                        signUpRefCode
                        signUpMethod
                        phoneVerification
                        emailVerification
                    }
                }`;
            break;
        case "updateClient":
            query = `
                mutation Mutation($id: String!, $data: UpdateClient) {
                    updateClient(_id: $id, data: $data) {
                        _id
                    }
                }`;
            break;
        default:
            query = "facilities";
            break;
    }

    return query;
};
