import React from "react";
import { Grid, Typography, Slider } from "@mui/material";

const DistanceForm = ({ data, updateData }) => {
    return (
        <Grid item xs={12} style={{ padding: "1% 5%" }}>
            <Typography variant="subtitle1" align="left">
                Distance
            </Typography>
            <Typography variant="body2" align="left">
                Less than {data.distance}KM
            </Typography>
            <Slider
                key={data.distance}
                id="distance"
                name="distance"
                size="small"
                defaultValue={data.distance}
                aria-label="Distance"
                valueLabelDisplay="on"
                step={5}
                min={5}
                max={1000}
                onChange={(event) => {
                    updateData({ ...data, distance: event.target.value });
                }}
                style={{ color: "#ce043d" }}
            />
        </Grid>
    );
};

export default DistanceForm;
