import React from "react";
import { Helmet } from "react-helmet";
import { useQuery, gql } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { Grid } from "@mui/material";
import { gqlQuery } from "../helpers/gql";
import { clientAuthorization } from "../helpers/user";
import { SearchResultCard } from "../containers/searchResultCard";
import { Loading } from "../components/loading";
import { Menu } from "../components/menu/menu";

export default function FavouritesPage() {
    clientAuthorization(jwtDecode, window.location);

    let ids = ["62558344f5023835dae6420f", "620385e8c35d398e9b8a2430"];
    let { data, loading } = useQuery(
        gql`
            ${gqlQuery("institutions", gql)}
        `,
        {
            variables: {
                ids,
            },
        }
    );

    return (
        <div>
            <Helmet>
                <title>Favourites</title>
                <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
            </Helmet>
            <Menu />
            <Grid
                container
                className={"add-space"}
                style={{ paddingTop: "10%" }}
            >
                {data &&
                    (data.institutions.length > 0
                        ? data.institutions.map((institution) => {
                              return (
                                  <SearchResultCard
                                      key={institution._id}
                                      data={institution}
                                      page="favourites"
                                  />
                              );
                          })
                        : "No results")}
            </Grid>
            {loading && <Loading />}
        </div>
    );
}
