import React from "react";
import { Grid, Link, Typography, Divider } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { capitalize } from "../../helpers/string";

export const ServiceList = ({ services }) => {
    return services.length > 0 ? (
        <Grid container>
            {services.map(({ _id, name }) => {
                return (
                    <Link
                        href={`../service/${_id}`}
                        key={_id}
                        className="category"
                    >
                        <Grid container>
                            <Grid item xs={11}>
                                <Typography variant="h6">
                                    {capitalize(name)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <LaunchIcon
                                    fontSize="small"
                                    style={{
                                        color: "#000000",
                                        float: "right",
                                    }}
                                />
                            </Grid>
                            {services.length > 1 && (
                                <Divider
                                    style={{
                                        margin: "1% 0%",
                                        width: "100%",
                                        color: "#dfdfdf",
                                    }}
                                />
                            )}
                        </Grid>
                    </Link>
                );
            })}
        </Grid>
    ) : (
        <img
            style={{
                width: "35%",
                height: "auto",
                display: "flex",
                margin: "5% auto",
            }}
            src={
                "https://ik.imagekit.io/9vm1rbs0vzz/public/Empty_sK1ZlJORw.svg"
            }
            alt="No Data"
        />
    );
};
