import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import { postRequest } from "../middleware/graphQl";
import PasswordStrengthBar from "react-password-strength-bar";

import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    Button,
    CircularProgress,
    Divider,
} from "@material-ui/core/";
import Alert from "@material-ui/lab/Alert";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

class managerSignUp extends Component {
    state = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        success: "",
        loading: false,
        error: false,
        error_message: "",
        errors: {
            first_name: false,
            last_name: false,
            email: false,
            password: false,
        },
    };

    componentDidMount = () => {
        document.addEventListener("keyup", (event) => {
            if (this.state.email !== "" && this.state.password) {
                if (event.keyCode === 13) {
                    this.signUp();
                }
            }
        });
    };

    handleChange = (event) => {
        if (event.target.name === "email") {
            this.setState({
                [event.target.name]: event.target.value.replace(/\s/g, ""),
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    };

    // sign up
    signUp = async () => {
        let errors = this.state.errors;
        let error = false;

        // first_name
        if (this.state.first_name === "") {
            errors["first_name"] = true;
            error = true;
        } else {
            errors["first_name"] = false;
        }

        // last_name
        if (this.state.last_name === "") {
            errors["last_name"] = true;
            error = true;
        } else {
            errors["last_name"] = false;
        }

        // email
        if (this.state.email === "") {
            errors["email"] = true;
            error = true;
        } else {
            errors["email"] = false;
        }

        // phone
        if (this.state.phone === "") {
            errors["phone"] = true;
            error = true;
        } else {
            if (
                !this.state.phone.match(
                    // eslint-disable-next-line
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
                )
            ) {
                error = true;
                errors["phone"] = true;
                this.setState({ error_message: "Invalid phone number." });
            } else {
                errors["phone"] = false;
                this.setState({ error_message: "" });
            }
        }

        // password
        if (this.state.password === "") {
            errors["password"] = true;
            error = true;
        } else {
            if (this.state.password.length < 8) {
                error = true;
                errors["password"] = true;
                this.setState({ error_message: "Password is too short." });
            } else {
                errors["password"] = false;
            }
        }

        this.setState({ errors: errors, error: error });

        if (!error) {
            this.setState({ loading: true });
            const response = await postRequest(
                "createClient",
                "client",
                this.state
            );
            if (response.errors) {
                this.setState({ loading: false });
                if (response.errors[0].message === "email already exists") {
                    errors["email"] = true;
                    this.setState({
                        error: true,
                        errors: errors,
                        error_message: "Email already exists.",
                    });
                } else if (
                    response.errors[0].message === "phone already exists"
                ) {
                    errors["phone"] = true;
                    this.setState({
                        error: true,
                        errors: errors,
                        error_message: "Phone Number already exists.",
                    });
                } else {
                    this.props.enqueueSnackbar(response.errors[0].message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                    });
                }
            } else {
                this.setState({
                    success: true,
                    loading: false,
                });
            }
        }
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>SignUp</title>
                    <meta
                        name="description"
                        content="Create an account with WapiDoc and enjoy our services."
                    />
                </Helmet>
                <Grid container style={{ overflow: "hidden" }}>
                    <Grid
                        className={"wide-screen-section"}
                        item
                        xs={12}
                        md={6}
                        display={{ xs: "none", lg: "block" }}
                        style={{ backgroundColor: "#ffebf0" }}
                    >
                        <Link to="/">
                            <img
                                style={{
                                    width: "140px",
                                    height: "auto",
                                    padding: "1% 2%",
                                }}
                                src={"../../WapiDocLogo.png"}
                                alt="WapiDoc Sign Up"
                            />
                        </Link>
                        <img
                            style={{
                                width: "auto",
                                height: "80vh",
                                margin: "0 auto",
                                display: "block",
                            }}
                            src={"../../images/signupWapiDoc.svg"}
                            alt="Sign Up to WapiDOc"
                        />
                    </Grid>
                    <Grid
                        className={"signup-section"}
                        item
                        xs={12}
                        md={6}
                        style={{
                            padding: "3%",
                            backgroundColor: "#ffffff",
                            height: "100vh",
                            overflow: "auto",
                        }}
                    >
                        <Link to="/">
                            <img
                                className={"small-screen"}
                                style={{
                                    width: "120px",
                                    height: "auto",
                                    paddingBottom: "5%",
                                    margin: "0% auto",
                                }}
                                src={"../WapiDocLogo.png"}
                                alt="WapiDocLogo"
                            />
                        </Link>
                        {this.state.success ? (
                            <div>
                                <Typography variant="h5" gutterBottom>
                                    Account Created Successfully
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#5DC726",
                                    }}
                                >
                                    <CheckCircleIcon
                                        style={{ marginBottom: "-1.2%" }}
                                    />{" "}
                                    An email is on the way
                                </Typography>
                                <Divider style={{ margin: "2% 0%" }} />
                                <Typography variant="body2" paragraph>
                                    A verification link has just been sent to
                                    your email account. Kindly click on the link
                                    to confirm your email address.
                                </Typography>
                            </div>
                        ) : (
                            <div>
                                <Typography variant="h5" gutterBottom>
                                    Create an account
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    paragraph
                                    style={{ color: "#939393" }}
                                >
                                    Already have an account?{" "}
                                    <Link to="/signin">Signin</Link>
                                </Typography>
                                {this.state.error && (
                                    <Alert
                                        severity="error"
                                        style={{ marginBottom: "1.5%" }}
                                    >
                                        {this.state.error_message === ""
                                            ? "Kindly fill in the highlighted fields."
                                            : this.state.error_message}
                                    </Alert>
                                )}

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={this.state.errors.first_name}
                                            id="first_name"
                                            name="first_name"
                                            label="First Name"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="John"
                                            value={this.state.first_name}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <PersonOutlineOutlinedIcon
                                                            fontSize="small"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={this.state.errors.last_name}
                                            id="last_name"
                                            name="last_name"
                                            label="Last Name"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="Doe"
                                            value={this.state.last_name}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <PersonOutlineOutlinedIcon
                                                            fontSize="small"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <TextField
                                    error={this.state.errors.email}
                                    id="email"
                                    name="email"
                                    label="Email"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="johndoe@example.com"
                                    value={this.state.email}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AlternateEmailOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.phone}
                                    id="phone"
                                    name="phone"
                                    label="Phone Number"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="0700123456"
                                    value={this.state.phone}
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.password}
                                    id="password"
                                    name="password"
                                    label="Password"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="****"
                                    type="password"
                                    value={this.state.password}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LockOpenOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <PasswordStrengthBar
                                    minLength={8}
                                    password={this.state.password}
                                />
                                <Button
                                    className="submit-button"
                                    variant="contained"
                                    fullWidth
                                    onClick={() => this.signUp()}
                                    style={{
                                        padding: "2% 0%",
                                    }}
                                >
                                    {!this.state.loading ? (
                                        "Sign Up"
                                    ) : (
                                        <CircularProgress
                                            size={24}
                                            style={{ color: "#ffffff" }}
                                        />
                                    )}
                                </Button>
                                <br />
                                <br />
                                <Typography
                                    variant="subtitle2"
                                    style={{ color: "#939393" }}
                                >
                                    By creating an account, you agree to our{" "}
                                    <Link to="/legal/terms-and-conditions">
                                        Terms and Conditions
                                    </Link>{" "}
                                    ,{" "}
                                    <Link to="/legal/data-policy">
                                        Data Policy
                                    </Link>{" "}
                                    and{" "}
                                    <Link to="/legal/cookie-policy">
                                        Cookie Policy
                                    </Link>
                                    .
                                </Typography>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withSnackbar(managerSignUp);
