import React from "react";
import { Typography, Grid } from "@mui/material";

export const EmptyData = ({ search, keyword }) => {
    return (
        <Grid item md={12} align="center">
            <Typography variant="h4">
                No {search} results for "{keyword}"
            </Typography>
        </Grid>
    );
};
