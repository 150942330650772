import React from "react";
import { Typography, Link } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PublicIcon from "@mui/icons-material/Public";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";

export const ContactList = ({ contact, links }) => {
    return (
        <div>
            <Typography variant="body1" align="left">
                <CallIcon
                    size={"small"}
                    style={{
                        color: "#000000",
                        marginBottom: "-.5%",
                        marginRight: "1%",
                    }}
                />
                {"   "}
                {contact.phoneNumber.primary}
                {contact.phoneNumber.secondary !== "" &&
                    " / " + contact.phoneNumber.secondary}
            </Typography>
            <br />
            <Typography variant="body1" align="left">
                <MailIcon
                    size={"small"}
                    style={{
                        color: "#000000",
                        marginBottom: "-.8%",
                        marginRight: "1%",
                    }}
                />
                {"   "}
                {contact.email.primary}
                {contact.email.secondary !== "" &&
                    " / " + contact.email.secondary}
            </Typography>
            <br />
            <Typography variant="body1" align="left">
                <ContactMailIcon
                    size={"small"}
                    style={{
                        color: "#000000",
                        marginBottom: "-.8%",
                        marginRight: "1%",
                    }}
                />
                P.O BOX,{"   "}
                {contact.address}, Nairobi, Kenya
            </Typography>
            <br />
            {links.website !== "" && (
                <Typography variant="body1" align="left">
                    <PublicIcon
                        size={"small"}
                        style={{
                            color: "#000000",
                            marginBottom: "-.8%",
                            marginRight: "1%",
                        }}
                    />
                    {"   "}
                    {links.website}
                </Typography>
            )}
            <br />
            {links.linkedin !== "" && (
                <Link href={links.linkedin} target="_blank" underline="hover">
                    <LinkedInIcon
                        style={{
                            color: "#000000",
                            marginRight: "3%",
                        }}
                    />
                </Link>
            )}
            {links.facebook !== "" && (
                <Link href={links.facebook} target="_blank" underline="hover">
                    <FacebookIcon
                        style={{
                            color: "#000000",
                            marginRight: "3%",
                        }}
                    />
                </Link>
            )}

            {links.twitter !== "" && (
                <Link href={links.twitter} target="_blank" underline="hover">
                    <TwitterIcon
                        style={{
                            color: "#000000",
                            marginRight: "3%",
                        }}
                    />
                </Link>
            )}
            {links.instagram !== "" && (
                <Link href={links.instagram} target="_blank" underline="hover">
                    <InstagramIcon
                        style={{
                            color: "#000000",
                            marginRight: "3%",
                        }}
                    />
                </Link>
            )}
            {links.youtube !== "" && (
                <Link href={links.youtube} target="_blank" underline="hover">
                    <YoutubeIcon
                        style={{
                            color: "#000000",
                            marginRight: "3%",
                        }}
                    />
                </Link>
            )}
        </div>
    );
};
