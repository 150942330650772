import React, { useState } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import {
    TextField,
    InputAdornment,
    Button,
    Alert,
    Typography,
    CircularProgress,
} from "@mui/material";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { validatePhone } from "../../../helpers/validation";
import { gqlQuery } from "../../../helpers/gql";
import { AuthCode } from "./authCode";

export const PhoneNumber = ({ setOption }) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [err, setError] = useState("");
    const [btnLoad, setBtnLoad] = useState(false);

    const validateInput = () => {
        try {
            setBtnLoad(true);
            setPhoneNumber(
                phoneNumber.charAt(0) !== "0" ? "0" + phoneNumber : phoneNumber
            );
            validatePhone(phoneNumber);
            setError("");
            execute().then(() => {
                setBtnLoad(false);
            });
        } catch (e) {
            setError(e.message);
            setBtnLoad(false);
        }
    };

    let disabled = !(phoneNumber.length > 8 && phoneNumber.length < 11);

    let [execute, { data, error }] = useLazyQuery(
        gql`
            ${gqlQuery("authPhone", gql)}
        `,
        {
            variables: {
                phoneNumber: phoneNumber,
            },
        }
    );

    document.addEventListener("keyup", (event) => {
        if (!disabled) {
            if (event.keyCode === 13) {
                validateInput();
            }
        }
    });

    return (
        <div>
            {data ? (
                <div>
                    {setOption(false)}
                    <AuthCode codeData={data.authPhone} />
                </div>
            ) : (
                <div>
                    {err !== "" && (
                        <Alert
                            severity="error"
                            style={{ marginBottom: "1.5%" }}
                        >
                            {err}
                        </Alert>
                    )}

                    <TextField
                        error={error === ""}
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        margin="normal"
                        variant="outlined"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        InputProps={{
                            startAdornment: (
                                <Typography
                                    variant="body1"
                                    style={{
                                        padding: "0% 1% 0% 0%",
                                    }}
                                >
                                    +254
                                </Typography>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PhoneOutlinedIcon
                                        fontSize="small"
                                        style={{ color: "#939393" }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(event) => {
                            setPhoneNumber(event.target.value);
                        }}
                    />
                    <Button
                        id="submit"
                        className="send-data-button"
                        variant="contained"
                        disableElevation
                        fullWidth
                        disabled={disabled}
                        style={{
                            padding: "1.5% 0%",
                            backgroundColor: `${
                                !disabled ? "#ce043d" : "#E0E0E0"
                            }`,
                        }}
                        onClick={() => validateInput()}
                    >
                        {!btnLoad ? (
                            "Continue"
                        ) : (
                            <CircularProgress
                                size={24}
                                style={{ color: "#ffffff" }}
                            />
                        )}
                    </Button>
                </div>
            )}
        </div>
    );
};
