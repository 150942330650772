import React, { Component } from "react";
import { TextField } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { postRequest } from "../../middleware/graphQl";

class ServiceForm extends Component {
    state = {
        cares: [],
    };

    componentDidMount() {
        this.getCares();
    }

    handleChange = (event) => {
        this.props.data[event.target.name] = event.target.value;
    };

    /**
     * Get all health care specialties
     */
    getCares = async () => {
        const response = await postRequest("getCares", "manager");
        this.setState({
            cares:
                JSON.parse(localStorage.getItem("institution")).type ===
                "pharmacy"
                    ? [response.data.cares[25]]
                    : response.data.cares,
        });
    };

    render() {
        const options = this.state.cares.map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                ...option,
            };
        });

        return (
            <div>
                <Autocomplete
                    options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, care) =>
                        (this.props.data["care"] = care ? care._id : "")
                    }
                    fullWidth
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={this.props.errors.care}
                            name="service"
                            label="Choose Service"
                            variant="outlined"
                            placeholder="Start typing your service"
                            value={this.props.service}
                        />
                    )}
                />
                <TextField
                    error={this.props.errors.description}
                    name="description"
                    label="Description"
                    variant="outlined"
                    placeholder="Enter your description here"
                    defaultValue={this.props.description}
                    multiline
                    rows={4}
                    fullWidth
                    inputProps={{ maxLength: 240 }}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

export default ServiceForm;
