import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import { Grid, Typography, Link, Button } from "@material-ui/core";

class ManagersPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Managers</title>
                    <meta
                        name="description"
                        content="WapiDoc’s innovative healthcare-focused
                                solutions enhance your success by improving your
                                reach through increased visibility."
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid container>
                    <Grid item xs={12} className={"welcome-section add-space"}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ padding: "16% 5% 0% 5%" }}
                        >
                            <Typography
                                variant="h2"
                                align="left"
                                style={{ color: "#ffffff" }}
                            >
                                Join our community.
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                                align="left"
                                style={{ color: "#ffffff", fontSize: "20px" }}
                            >
                                WapiDoc’s innovative healthcare-focused
                                solutions enhance your success by improving your
                                reach through increased visibility.
                            </Typography>
                            <Button
                                className="submit-button"
                                disableElevation
                                variant="contained"
                                href="/manager/signup"
                                style={{
                                    padding: "2% 10%",
                                    margin: "3% 0%",
                                }}
                            >
                                Get Started
                            </Button>
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "2% 5%" }}>
                        <Typography variant="h3" align="center">
                            Why WapiDoc?
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <img
                                    src={"../images/WapiDocLocalReach.svg"}
                                    alt="How it Works Step 1"
                                    width="60%"
                                    align="center"
                                    style={{ padding: "0% 10%" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ padding: "5%" }}>
                                <Typography
                                    variant="h4"
                                    gutterBottom
                                    align="left"
                                >
                                    Multiple and sustained visibility.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    paragraph
                                    align="left"
                                >
                                    The institution's services and products will
                                    have a primary view under WapidDoc products
                                    and services. Visibility will also be
                                    available from secondary linkages within the
                                    institutions services and products covered
                                    under the institution. As opposed to static
                                    forms of advertising, the application
                                    provides a one-entry multiple view model
                                    where it can be accessed as many times, at
                                    multiple location and even shared.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={6} style={{ padding: "5%" }}>
                                <Typography
                                    variant="h4"
                                    gutterBottom
                                    align="left"
                                >
                                    Wide reach and scope.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    paragraph
                                    align="left"
                                >
                                    The application is available to persons in
                                    both formal and informal setups. The target
                                    is also double edged as the client and
                                    health care provider can both be reached
                                    within the same platform. This is different
                                    from the traditional vertical system which
                                    targeted the client and provider
                                    independently.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img
                                    src={"../images/WapiDocPositiveImpact.svg"}
                                    alt="How it Works Step 1"
                                    width="60%"
                                    align="center"
                                    style={{ padding: "10%" }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <img
                                    src={"../images/WapiDocGrowth.svg"}
                                    alt="How it Works Step 1"
                                    width="60%"
                                    align="center"
                                    style={{ padding: "0% 10%" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ padding: "5%" }}>
                                <Typography
                                    variant="h4"
                                    gutterBottom
                                    align="left"
                                >
                                    Digital presence and convenience
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    paragraph
                                    align="left"
                                >
                                    The application is internet based conforming
                                    with the digital era. It will reach the
                                    digital age group which constitute those
                                    joining the workforce as well as starting
                                    families and businesses. The on-hand
                                    information allows for access at the
                                    convenience of the application user. The
                                    single databank for the institution allows
                                    for faster, easier and cheaper way of
                                    getting accurate and complete information.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{ padding: "8%", background: "#EBEBEB" }}
                    >
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <img
                                    src={"../images/WapiDocRequirements.jpg"}
                                    alt="WapiDoc Application Requirements"
                                    width="80%"
                                    style={{ borderRadius: "10px" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography
                                    variant="h3"
                                    align="left"
                                    style={{ margin: "0% 0% 3% 0%" }}
                                >
                                    If you have a licence, you are a few steps
                                    away from being registered on WapiDoc.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    paragraph
                                    align="left"
                                >
                                    Have a look at WapiDoc's{" "}
                                    <Link href="/managers/application-requirements">
                                        application requirements
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{ padding: "5%", background: "#cf053e" }}
                    >
                        <Grid container>
                            <Grid item xs={12} align="center">
                                <Typography
                                    variant="h3"
                                    style={{ color: "#ffffff" }}
                                >
                                    Register now.
                                </Typography>
                                <Button
                                    className=""
                                    variant="outlined"
                                    href="/manager/signup"
                                    style={{
                                        padding: "1% 2%",
                                        border: "1px solid #ffffff",
                                        color: "#ffffff",
                                        margin: "3% 1%",
                                    }}
                                >
                                    Get Started
                                </Button>
                                <Button
                                    className=""
                                    variant="contained"
                                    href="/managers/pricing"
                                    style={{
                                        padding: "1.2% 2.2%",
                                        background: "#ffffff",
                                        margin: "3% 1%",
                                    }}
                                >
                                    Learn More
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default ManagersPage;
