import React from "react";
import { Grid, Skeleton } from "@mui/material";

export const SkeletonLoader = (index) => {
    return (
        <Grid item xs={12} md={4} key={index}>
            <div style={{ margin: "3%" }}>
                <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={150}
                    animation="wave"
                    style={{
                        borderRadiusLeftTop: "5px",
                        borderRadiusRightTop: "5px",
                        borderRadius: "5px",
                    }}
                />
                <br />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" width="60%" />
            </div>
        </Grid>
    );
};
