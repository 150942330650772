import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import { withSnackbar } from "notistack";
import { postRequest } from "../../middleware/graphQl";

import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    Button,
    CircularProgress,
    Box,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core/";

import Alert from "@material-ui/lab/Alert";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined";

class managerSignIn extends Component {
    state = {
        email: "",
        password: "",
        success: "",
        remember_me: false,
        loading: false,
        error: false,
        error_message: "",
        errors: {
            email: false,
            password: false,
        },
    };

    componentDidMount = () => {
        document.addEventListener("keyup", (event) => {
            if (this.state.email !== "" && this.state.password) {
                if (event.keyCode === 13) {
                    this.signIn();
                }
            }
        });
    };

    handleChange = (event) => {
        if (event.target.name === "email") {
            this.setState({
                [event.target.name]: event.target.value.replace(/\s/g, ""),
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }

        if (event.target.name === "remember_me") {
            this.setState({ remember_me: !this.state.remember_me });
        }
    };

    // signin
    signIn = async () => {
        let errors = this.state.errors;
        let error = false;

        // email
        if (this.state.email === "") {
            errors["email"] = true;
            error = true;
        } else {
            errors["email"] = false;
        }

        // password
        if (this.state.password === "") {
            errors["password"] = true;
            error = true;
        } else {
            errors["password"] = false;
        }

        this.setState({ errors: errors, error: error });

        if (!error) {
            this.setState({ loading: true });

            const response = await postRequest(
                "managerSignIn",
                "manager",
                this.state
            );

            if (response.errors) {
                this.setState({ loading: false });

                this.props.enqueueSnackbar(response.errors[0].message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            } else {
                localStorage.removeItem("client");
                localStorage.removeItem("clientToken");

                let token = jwt_decode(response.data.managerLogin.token);

                localStorage.setItem(
                    "managerToken",
                    response.data.managerLogin.token
                );

                const manager = await postRequest("singleManager", "manager", {
                    id: token.manager_id,
                });

                localStorage.setItem(
                    "manager",
                    JSON.stringify(manager.data.singleManager)
                );

                if (manager.data.singleManager.institutions.length !== 0) {
                    localStorage.setItem(
                        "institution",
                        JSON.stringify(
                            manager.data.singleManager.institutions[0]
                        )
                    );
                    window.location.replace(
                        `${process.env.REACT_APP_HOST}/manager/dashboard`
                    );
                } else {
                    window.location.replace(
                        `${process.env.REACT_APP_HOST}/manager/institution/setup`
                    );
                }
            }
        }
    };

    render() {
        return (
            <div>
                <Grid container style={{ overflow: "hidden" }}>
                    <Grid
                        className={"wide-screen-section"}
                        item
                        xs={12}
                        md={6}
                        style={{ backgroundColor: "#ffebf0" }}
                    >
                        <Link to="/">
                            <img
                                style={{
                                    width: "140px",
                                    height: "auto",
                                    padding: "1% 2%",
                                }}
                                src={"../WapiDocLogo.png"}
                                alt="WapiDocLogo"
                            />
                        </Link>

                        <img
                            style={{
                                width: "auto",
                                height: "80vh",
                                margin: "0 auto",
                                display: "block",
                            }}
                            src={"../images/signupWapiDoc.svg"}
                            alt="Create an Account with WapiDoc"
                        />
                    </Grid>
                    <Grid
                        className={"signup-section"}
                        item
                        xs={12}
                        md={6}
                        style={{
                            padding: "3%",
                            backgroundColor: "#ffffff",
                            height: "100vh",
                            overflow: "auto",
                        }}
                    >
                        <Link to="/">
                            <img
                                className={"small-screen"}
                                style={{
                                    width: "120px",
                                    height: "auto",
                                    paddingBottom: "5%",
                                    margin: "0% auto",
                                }}
                                src={"../WapiDocLogo.png"}
                                alt="WapiDocLogo"
                            />
                        </Link>
                        <Typography variant="h5" gutterBottom>
                            Welcome Back Manager
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            paragraph
                            style={{ color: "#939393" }}
                        >
                            Don't have an account?{" "}
                            <Link to="/manager/signup">Create an account</Link>
                        </Typography>
                        <br />
                        <Box my={5}>
                            {this.state.error && (
                                <Alert
                                    severity="error"
                                    style={{ marginBottom: "1.5%" }}
                                >
                                    {this.state.error_message === ""
                                        ? "Kindly fill in the highlighted fields."
                                        : this.state.error_message}
                                </Alert>
                            )}
                            <TextField
                                error={this.state.errors.email}
                                id="email"
                                name="email"
                                label="Email"
                                margin="normal"
                                variant="outlined"
                                placeholder="johndoe@example.com"
                                value={this.state.email}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailOutlinedIcon
                                                fontSize="small"
                                                style={{ color: "#939393" }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={this.handleChange}
                            />

                            <TextField
                                error={this.state.errors.password}
                                id="password"
                                name="password"
                                label="Password"
                                margin="normal"
                                variant="outlined"
                                placeholder="****"
                                type="password"
                                value={this.state.password}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LockOpenOutlinedIcon
                                                fontSize="small"
                                                style={{ color: "#939393" }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={this.handleChange}
                            />
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <FormControlLabel
                                        style={{
                                            width: "95%",
                                        }}
                                        control={
                                            <Checkbox
                                                checked={this.state.remember_me}
                                                onClick={this.handleChange}
                                                name="remember_me"
                                                style={{
                                                    color: "#13479A",
                                                }}
                                            />
                                        }
                                        fontSize="small"
                                        label="Remember me on this device"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Link to="/reset-password/manager">
                                        <Typography
                                            variant="body1"
                                            align="right"
                                            style={{
                                                marginTop: "3.5%",
                                                padding: "0% 1.5%",
                                            }}
                                        >
                                            Forgot password?
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                            <Button
                                id="submit"
                                className="submit-button"
                                variant="contained"
                                fullWidth
                                style={{ padding: "1.5% 0%" }}
                                onClick={() => this.signIn()}
                            >
                                {!this.state.loading ? (
                                    "sign in"
                                ) : (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: "#ffffff" }}
                                    />
                                )}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withSnackbar(managerSignIn);
