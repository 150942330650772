import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

export const Email = ({ data, updateData }) => {
    return (
        <TextField
            id="email"
            name="email"
            label="Email"
            margin="normal"
            variant="outlined"
            placeholder="Enter email address"
            value={data.email}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <AlternateEmailIcon
                            fontSize="small"
                            style={{
                                color: "#939393",
                                cursor: "pointer",
                            }}
                        />
                    </InputAdornment>
                ),
            }}
            onChange={(event) => {
                updateData({ ...data, email: event.target.value });
            }}
        />
    );
};
