import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Beforeunload } from "react-beforeunload";
import axios from "axios";
import MapWrapper from "../../components/maps";
import jwt_decode from "jwt-decode";
import locations from "./locations.json";
import insuranceList from "./insurance.json";
import cogs from "../../middleware/cogs";
import { postRequest } from "../../middleware/graphQl";
import { withSnackbar } from "notistack";

import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    MenuItem,
    Select,
    Button,
    FormControl,
    InputLabel,
    Divider,
    CircularProgress,
    ListItem,
    ListItemText,
    List,
    ListItemIcon,
} from "@material-ui/core/";

import Alert from "@material-ui/lab/Alert";

import YouTubeIcon from "@mui/icons-material/YouTube";
import CancelIcon from "@mui/icons-material/Cancel";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LanguageIcon from "@material-ui/icons/Language";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import LocationOnIcon from "@material-ui/icons/LocationOn";

class InstitutionSetup extends Component {
    state = {
        _id: "",
        step: "manager",
        loading: false,
        load: 0,
        constituencies: [],
        type: "",
        amount: "",
        name: "",
        description: "",
        county: "",
        constituency: "",
        ward: "",
        gprs: "",
        maps: false,
        primary_phone: "",
        secondary_phone: "",
        primary_email: "",
        secondary_email: "",
        address: "",
        postal_code: "",
        linkedin: "",
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: "",
        website: "",
        working_hours: "",
        visiting_hours: "",
        error: false,
        error_message: "",
        errors: {
            name: false,
            description: false,
            constituency: false,
            ward: false,
            primary_phone: false,
            secondary_phone: false,
            primary_email: false,
            secondary_email: false,
            address: false,
            postal_code: false,
        },
        invoice_id: "",
        manager: "",
        owns: "",
        hospital_no: 0,
        medical_center_no: 0,
        pharmacy_no: 0,
        clinic_no: 0,
        consultant_no: 0,
        mobile_no: 0,
        stk_button: "Initialize Payment",
        subscription: "",
        insurance: [],
        aspect: false,
        weekday_opening: "08:00",
        weekday_closing: "05:00",
        saturday_opening: "08:00",
        saturday_closing: "05:00",
        sunday_opening: "08:00",
        sunday_closing: "12:00",
        holiday_opening: "08:00",
        holiday_closing: "12:00",
        morning_opening: "10:00",
        morning_closing: "11:59",
        afternoon_opening: "12:00",
        afternoon_closing: "02:00",
        evening_opening: "04:00",
        evening_closing: "07:00",
    };

    componentDidMount() {
        // request coordinates
        if (localStorage.getItem("coordinates") === null) {
            cogs.getLocation(axios);
        }

        if (
            window.location.href !==
            `${process.env.REACT_APP_HOST}/manager/institution/setup`
        ) {
            let token = window.location.href.replace(
                `${process.env.REACT_APP_HOST}/manager/institution/setup/`,
                ""
            );
            if (token.length > 0) {
                localStorage.setItem("managerToken", token);
                token = jwt_decode(token);

                postRequest("singleManager", "manager", {
                    id: token.manager_id,
                })
                    .then((response) => {
                        if (response.errors) {
                            window.location.replace(
                                `${process.env.REACT_APP_HOST}/manager/signin`
                            );
                        } else {
                            localStorage.setItem(
                                "manager",
                                JSON.stringify(response.data.singleManager)
                            );

                            this.ownership();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                cogs.managerAuthorization(jwt_decode, postRequest);
                this.ownership();
            }
        } else {
            cogs.managerAuthorization(jwt_decode, postRequest);
            this.ownership();
        }
    }

    ownership = () => {
        if (localStorage.getItem("manager")) {
            if (
                JSON.parse(localStorage.getItem("manager")).owns === "multiple"
            ) {
                let institution = JSON.parse(localStorage.getItem("manager"))
                    .institutions[0];

                this.setState({
                    aspect: true,
                    step: "type",
                    owns: "multiple",
                    loading: false,
                    type: institution.type,
                    name: institution.name,
                    description: institution.description,
                    primary_phone:
                        institution.primary_phone.slice(0, 3) === "254"
                            ? "0" + institution.primary_phone.substr(3)
                            : institution.primary_phone,
                    secondary_phone:
                        institution.secondary_phone.slice(0, 3) === "254"
                            ? "0" + institution.primary_phone.substr(3)
                            : institution.secondary_phone,
                    primary_email: institution.primary_email,
                    secondary_email: institution.secondary_email,
                    address: institution.address.split("-")[0],
                    postal_code: institution.address.split("-")[1],
                    insurance: institution.insurance,
                    linkedin: institution.linkedin,
                    facebook: institution.facebook,
                    twitter: institution.twitter,
                    instagram: institution.instagram,
                    youtube: institution.youtube,
                    website: institution.website,
                    working_hours: institution.working_hours,
                    visiting_hours: institution.visitin_hours,
                });
            } else if (
                JSON.parse(localStorage.getItem("manager")).owns === "single"
            ) {
                window.location.replace(
                    `${process.env.REACT_APP_HOST}/manager/dashboard`
                );
            } else {
                this.setState({
                    step: "manager",
                    loading: false,
                });
            }
        } else {
            window.location.replace(
                `${process.env.REACT_APP_HOST}/manager/signin`
            );
        }
    };

    handleChange = (event) => {
        if (event.target.name === "county") {
            this.setState({ constituency: "", ward: "" });
        } else if (event.target.name === "constituency") {
            this.setState({ ward: "" });
        }

        if (
            event.target.name === "primary_email" ||
            event.target.name === "secondary_email"
        ) {
            this.setState({
                [event.target.name]: event.target.value.replace(/\s/g, ""),
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    };

    addInsurance = (insurance) => {
        let insurances = this.state.insurance;
        if (insurances.includes(insurance)) {
            const index = this.state.insurance.indexOf(insurance);
            if (index > -1) {
                insurances.splice(index, 1);
            }
        } else {
            insurances.push(insurance);
        }
        this.setState({ insurance: insurances });
    };

    updateApplicationAmount = () => {
        // update amount
        let amount = "0";

        if (this.state.type === "hospital") {
            amount = "10000";
        } else if (this.state.type === "medical-center") {
            amount = "5000";
        } else if (
            this.state.type === "consultant" ||
            this.state.type === "clinic" ||
            this.state.type === "pharmacy"
        ) {
            amount = "3000";
        } else if (
            this.state.type === "mobile" ||
            this.state.type === "online"
        ) {
            amount = "1000";
        }

        this.setState({
            amount: +amount,
        });
    };

    getRoundedAmount = (plan) => {
        let amount = this.state.amount * 12;

        if (plan === "yearly") {
            amount = amount - amount * 0.25;
        } else if (plan === "bi-annual") {
            amount = amount - amount * 0.15;
            amount = amount / 2;
        } else if (plan === "quarterly") {
            amount = amount / 4;
        } else if (plan === "monthly") {
            amount = amount + amount * 0.15;
            amount = amount / 12;
        }

        return amount;
    };

    addEarlyBirdDiscount = (amount) => {
        return Math.round(amount - amount * 0.25);
    };

    // number of institutions
    getNumber = () => {
        this.setState({
            hospital_no:
                this.state.hospital_no === "" || this.state.hospital_no < 0
                    ? 0
                    : this.state.hospital_no,
            medical_center_no:
                this.state.medical_center_no === "" ||
                this.state.medical_center_no < 0
                    ? 0
                    : this.state.medical_center_no,
            clinic_no:
                this.state.clinic_no === "" || this.state.clinic_no < 0
                    ? 0
                    : this.state.clinic_no,
            pharmacy_no:
                this.state.pharmacy_no === "" || this.state.pharmacy_no < 0
                    ? 0
                    : this.state.pharmacy_no,
        });

        let sum =
            +this.state.hospital_no +
            +this.state.medical_center_no +
            +this.state.clinic_no +
            +this.state.pharmacy_no;

        if (sum < 2) {
            this.setState({
                error_message: "Branches must be more than one",
                error: true,
            });
        } else {
            this.setState({ step: "type", error: false });
        }
    };

    // name, description and location
    getBasic = () => {
        let errors = this.state.errors;
        let error = false;

        // name
        if (this.state.name === "") {
            errors["name"] = true;
            error = true;
        } else {
            errors["name"] = false;
        }

        // description
        if (this.state.description === "") {
            errors["description"] = true;
            error = true;
        } else {
            errors["description"] = false;
        }

        // county
        if (this.state.county === "") {
            errors["county"] = true;
            error = true;
        } else {
            errors["county"] = false;
        }

        // constituency
        if (this.state.constituency === "") {
            errors["constituency"] = true;
            error = true;
        } else {
            errors["constituency"] = false;
        }

        // ward
        if (this.state.ward === "") {
            errors["ward"] = true;
            error = true;
        } else {
            errors["ward"] = false;
        }

        this.setState({ errors: errors, error: error });

        if (!error) {
            this.setState({
                step: "contacts",
                gprs: [
                    Number(localStorage.getItem("coordinates").split(",")[0]),
                    Number(localStorage.getItem("coordinates").split(",")[1]),
                ],
            });
        }
    };

    // contact info
    getContacts = () => {
        let errors = this.state.errors;
        let error = false;
        let error_message = "";

        // primary phone
        if (this.state.primary_phone === "") {
            errors["primary_phone"] = true;
            error = true;
        } else if (
            !this.state.primary_phone.match(
                // eslint-disable-next-line
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
            )
        ) {
            errors["primary_phone"] = true;
            error = true;
            error_message = "Not a valid phone number";
        } else {
            errors["primary_phone"] = false;
        }

        // phone number
        if (
            !this.state.secondary_phone.match(
                // eslint-disable-next-line
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
            ) &&
            this.state.secondary_phone !== ""
        ) {
            errors["secondary_phone"] = true;
            error = true;
            error_message = "Not a valid phone number";
        } else if (
            this.state.primary_phone === this.state.secondary_phone &&
            this.state.secondary_phone !== ""
        ) {
            errors["secondary_phone"] = true;
            error = true;
            error_message = "Phone numbers can't be similar";
        } else {
            errors["secondary_phone"] = false;
        }

        // primary email
        if (this.state.primary_email === "") {
            errors["primary_email"] = true;
            error = true;
        } else if (
            !this.state.primary_email.match(
                // eslint-disable-next-line
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            )
        ) {
            errors["primary_email"] = true;
            error = true;
            error_message = "Not a valid email address";
        } else {
            errors["primary_email"] = false;
        }

        // secondary email
        if (
            !this.state.secondary_email.match(
                // eslint-disable-next-line
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            ) &&
            this.state.secondary_email !== ""
        ) {
            errors["secondary_email"] = true;
            error = true;
            error_message = "Not a valid email address";
        } else if (
            this.state.primary_email === this.state.secondary_email &&
            this.state.secondary_email !== ""
        ) {
            errors["secondary_email"] = true;
            error = true;
            error_message = "Emails can't be similar";
        } else {
            errors["secondary_email"] = false;
        }

        // address
        if (this.state.address === "") {
            errors["address"] = true;
            error = true;
        } else {
            errors["address"] = false;
        }

        // postal code
        if (this.state.postal_code === "") {
            errors["postal_code"] = true;
            error = true;
        } else {
            errors["postal_code"] = false;
        }

        this.setState({
            errors: errors,
            error: error,
            error_message: error_message,
        });

        if (!error) {
            this.setState({ step: "insurance" });
        }
    };

    // add social media
    getSocials = () => {
        let errors = this.state.errors;
        let error = false;
        let error_message = "";

        // linkedin
        if (
            this.state.linkedin !== "" &&
            !this.state.linkedin.match(
                // eslint-disable-next-line
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
        ) {
            errors["linkedin"] = true;
            error_message = "Not a valid url";
            error = true;
        } else {
            errors["linkedin"] = false;
        }

        // facebook
        if (
            this.state.facebook !== "" &&
            !this.state.facebook.match(
                // eslint-disable-next-line
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
        ) {
            errors["facebook"] = true;
            error_message = "Not a valid url";
            error = true;
        } else {
            errors["facebook"] = false;
        }

        // instagram
        if (
            this.state.instagram !== "" &&
            !this.state.instagram.match(
                // eslint-disable-next-line
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
        ) {
            errors["instagram"] = true;
            error_message = "Not a valid url";
            error = true;
        } else {
            errors["instagram"] = false;
        }

        // twitter
        if (
            this.state.twitter !== "" &&
            !this.state.twitter.match(
                // eslint-disable-next-line
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
        ) {
            errors["twitter"] = true;
            error_message = "Not a valid url";
            error = true;
        } else {
            errors["twitter"] = false;
        }

        // youtube
        if (
            this.state.youtube !== "" &&
            !this.state.youtube.match(
                // eslint-disable-next-line
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
        ) {
            errors["youtube"] = true;
            error_message = "Not a valid url";
            error = true;
        } else {
            errors["youtube"] = false;
        }

        // website
        if (
            this.state.website !== "" &&
            !this.state.website.match(
                // eslint-disable-next-line
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
        ) {
            errors["website"] = true;
            error_message = "Not a valid url";
            error = true;
        } else {
            errors["website"] = false;
        }

        this.setState({
            errors: errors,
            error: error,
            error_message: error_message,
        });

        if (!error) {
            this.setState({ step: "working_hours" });
        }
    };

    // get working hours
    getWorkingHours = async () => {
        let errors = this.state.errors;
        let error = false;

        // weekday opening
        if (this.state.weekday_opening === "") {
            errors["weekday_opening"] = true;
            error = true;
        } else {
            errors["weekday_opening"] = false;
        }

        // weekday opening
        if (this.state.weekday_closing === "") {
            errors["weekday_closing"] = true;
            error = true;
        } else {
            errors["weekday_closing"] = false;
        }

        // saturday opening
        if (this.state.saturday_opening === "") {
            errors["saturday_opening"] = true;
            error = true;
        } else {
            errors["saturday_opening"] = false;
        }

        // saturday closing
        if (this.state.saturday_closing === "") {
            errors["saturday_closing"] = true;
            error = true;
        } else {
            errors["saturday_closing"] = false;
        }

        // sunday opening
        if (this.state.sunday_opening === "") {
            errors["sunday_opening"] = true;
            error = true;
        } else {
            errors["sunday_opening"] = false;
        }

        // sunday closing
        if (this.state.sunday_closing === "") {
            errors["sunday_closing"] = true;
            error = true;
        } else {
            errors["sunday_closing"] = false;
        }

        // holiday opening
        if (this.state.holiday_opening === "") {
            errors["holiday_opening"] = true;
            error = true;
        } else {
            errors["holiday_opening"] = false;
        }

        // holiday closing
        if (this.state.holiday_closing === "") {
            errors["holiday_closing"] = true;
            error = true;
        } else {
            errors["holiday_closing"] = false;
        }

        this.setState({
            errors: errors,
            error: error,
        });

        if (!error) {
            if (this.state.type === "hospital") {
                this.setState({
                    step: "visiting_hours",
                });
            } else {
                this.updateApplicationAmount();
                this.setState({
                    step: "subscription",
                });
            }
            this.setState({
                working_hours: JSON.stringify({
                    weekday:
                        this.state.weekday_opening +
                        "-" +
                        this.state.weekday_closing,
                    saturday:
                        this.state.saturday_opening +
                        "-" +
                        this.state.saturday_closing,
                    sunday:
                        this.state.sunday_opening +
                        "-" +
                        this.state.sunday_closing,
                    holiday:
                        this.state.holiday_opening +
                        "-" +
                        this.state.holiday_closing,
                }),
            });
        }
    };

    // get visiting hours
    getVisitingHours = async () => {
        let errors = this.state.errors;
        let error = false;

        // morning opening
        if (this.state.morning_opening === "") {
            errors["morning_opening"] = true;
            error = true;
        } else {
            errors["morning_opening"] = false;
        }

        // morning closing
        if (this.state.morning_closing === "") {
            errors["morning_closing"] = true;
            error = true;
        } else {
            errors["morning_closing"] = false;
        }

        // afternoon opening
        if (this.state.afternoon_opening === "") {
            errors["afternoon_opening"] = true;
            error = true;
        } else {
            errors["afternoon_opening"] = false;
        }

        // afternoon closing
        if (this.state.afternoon_closing === "") {
            errors["afternoon_closing"] = true;
            error = true;
        } else {
            errors["afternoon_closing"] = false;
        }

        // evening opening
        if (this.state.evening_opening === "") {
            errors["evening_opening"] = true;
            error = true;
        } else {
            errors["evening_opening"] = false;
        }

        // evening closing
        if (this.state.evening_closing === "") {
            errors["evening_closing"] = true;
            error = true;
        } else {
            errors["evening_closing"] = false;
        }

        this.setState({
            errors: errors,
            error: error,
        });

        if (!error) {
            this.updateApplicationAmount();
            this.setState({
                step: "subscription",
                visiting_hours: JSON.stringify({
                    morning:
                        this.state.morning_opening +
                        "-" +
                        this.state.morning_closing,
                    afternoon:
                        this.state.afternoon_opening +
                        "-" +
                        this.state.afternoon_closing,
                    evening:
                        this.state.evening_opening +
                        "-" +
                        this.state.evening_closing,
                }),
            });
        }
    };

    setHours = (day, value) => {
        this.setState({ [day + "_opening"]: value, [day + "_closing"]: value });
    };

    confirmData = () => {
        this.setState({
            step: "",
            loading: true,
        });
        this.submitData(this.state.working_hours, this.state.visiting_hours);
    };

    submitData = async (working_hours = "", visiting_hours = "") => {
        this.state.visiting_hours =
            visiting_hours === ""
                ? JSON.stringify({ data: "empty" })
                : visiting_hours;
        this.state.working_hours = working_hours;

        postRequest("createInstitution", "manager", this.state)
            .then((response) => {
                if (response.errors) {
                    // choose place to show errors
                    this.setState({ loading: false, step: "preview" });

                    this.props.enqueueSnackbar(response.errors[0].message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                    });
                } else {
                    this.setState({ load: 1 });
                    localStorage.setItem(
                        "institution",
                        JSON.stringify(response.data.createInstitution)
                    );

                    // update manager
                    let data = JSON.parse(localStorage.getItem("manager"));

                    data["owns"] = this.state.owns;
                    if (this.state.owns === "multiple") {
                        data["number"] = JSON.stringify({
                            hospital: this.state.hospital_no,
                            medical_center: this.state.medical_center_no,
                            consultant: this.state.consultant_no,
                            clinic: this.state.clinic_no,
                            pharmacy: this.state.pharmacy_no,
                            mobile: this.state.mobile_no,
                        });
                    } else {
                        data["number"] = JSON.stringify({
                            hospital: this.state.type === "hospital" ? 1 : 0,
                            medical_center:
                                this.state.type === "medical_center" ? 1 : 0,
                            consultant:
                                this.state.type === "consultant" ? 1 : 0,
                            clinic: this.state.type === "clinic" ? 1 : 0,
                            pharmacy: this.state.type === "pharmacy" ? 1 : 0,
                            mobile: this.state.type === "mobile" ? 1 : 0,
                        });
                    }

                    postRequest("updateManager", "manager", data)
                        .then((response) => {
                            if (response.errors) {
                                this.props.enqueueSnackbar(
                                    response.errors[0].message,
                                    {
                                        variant: "error",
                                        anchorOrigin: {
                                            vertical: "top",
                                            horizontal: "center",
                                        },
                                    }
                                );
                            } else {
                                this.setState({ load: 2 });
                                // after manager update > create invoice
                                postRequest("createInvoice", "manager", {
                                    description: "institution subscription",
                                    amount: Math.round(
                                        this.addEarlyBirdDiscount(
                                            this.getRoundedAmount(
                                                this.state.subscription
                                            )
                                        )
                                    ),
                                    tax: Math.round(
                                        this.addEarlyBirdDiscount(
                                            this.getRoundedAmount(
                                                this.state.subscription
                                            )
                                        ) * 0.16
                                    ),
                                    taxed_amount: Math.round(
                                        this.addEarlyBirdDiscount(
                                            this.getRoundedAmount(
                                                this.state.subscription
                                            )
                                        ) +
                                            this.addEarlyBirdDiscount(
                                                this.getRoundedAmount(
                                                    this.state.subscription
                                                )
                                            ) *
                                                0.16
                                    ),
                                    category: this.state.type,
                                    subscription: this.state.subscription,
                                    institution: JSON.parse(
                                        localStorage.getItem("institution")
                                    )._id,
                                })
                                    .then((response) => {
                                        if (response.errors) {
                                            this.props.enqueueSnackbar(
                                                response.errors[0].message,
                                                {
                                                    variant: "error",
                                                    anchorOrigin: {
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    },
                                                }
                                            );
                                        } else {
                                            this.setState({
                                                load: 3,
                                                _id: JSON.parse(
                                                    localStorage.getItem(
                                                        "institution"
                                                    )
                                                )._id,
                                                invoice_id:
                                                    response.data.createInvoice
                                                        ._id,
                                            });
                                            this.proceedToPayment();
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    proceedToPayment = () => {
        window.location.replace(
            `${process.env.REACT_APP_HOST}/manager/invoice/${this.state.invoice_id}`
        );
    };

    render() {
        let countiesList = [];

        for (let i = 0; i < locations.length; i++) {
            countiesList.push(Object.keys(locations[i])[0]);
        }

        let counties = countiesList.map((county) => {
            return (
                <MenuItem key={county} value={county}>
                    {cogs.capitalize(county)}
                </MenuItem>
            );
        });

        let county = this.state.county;
        let countyPos = "";
        for (let i = 0; i < locations.length; i++) {
            if (Object.keys(locations[i])[0] === this.state.county) {
                countyPos = i;
                break;
            }
        }

        let constituencies = [];
        let constituenciesList = [];
        if (this.state.county !== "") {
            constituencies = locations[countyPos][county];
            for (let i = 0; i < constituencies.length; i++) {
                constituenciesList.push(Object.keys(constituencies[i])[0]);
            }

            constituencies = constituenciesList.map((constituency) => {
                return (
                    <MenuItem key={constituency} value={constituency}>
                        {cogs.capitalize(constituency)}
                    </MenuItem>
                );
            });
        }

        let communities = [];
        if (this.state.constituency !== "") {
            const pos = constituenciesList.indexOf(this.state.constituency);
            communities =
                locations[countyPos][county][pos][this.state.constituency];

            communities = communities.map((ward) => {
                return (
                    <MenuItem key={ward} value={ward}>
                        {cogs.capitalize(ward)}
                    </MenuItem>
                );
            });
        }

        const insurances = insuranceList.map((insurance) => {
            return (
                <Button
                    key={insurance}
                    style={{ margin: ".8%" }}
                    onClick={() => this.addInsurance(insurance)}
                    color="primary"
                    disableElevation
                    variant={
                        this.state.insurance.includes(insurance)
                            ? "contained"
                            : "outlined"
                    }
                    endIcon={
                        this.state.insurance.includes(insurance) ? (
                            <CancelIcon style={{ fontSize: "17px" }} />
                        ) : (
                            false
                        )
                    }
                >
                    {insurance}
                </Button>
            );
        });

        return (
            <div>
                {this.state.step !== "" && (
                    <Beforeunload
                        onBeforeunload={(event) => event.preventDefault()}
                    />
                )}
                <Grid container style={{ height: "100vh" }}>
                    <Grid
                        className={"wide-screen"}
                        item
                        xs={12}
                        md={6}
                        style={{ backgroundColor: "#324C74" }}
                    >
                        <img
                            style={{
                                width: "140px",
                                height: "auto",
                                padding: "1% 2%",
                            }}
                            src={"../../../WapiDocLogoWhite.png"}
                            alt="WapiDoc Setup Institution"
                        />
                        <img
                            style={{
                                width: "80%",
                                height: "auto",
                                margin: "0 auto",
                                display: "block",
                            }}
                            src={"../../../images/SetupInstitution.svg"}
                            alt="SetUp Institution"
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                            height: "100vh",
                            padding: "2%",
                            overflowY: "auto",
                            backgroundColor: "#ffffff",
                        }}
                    >
                        <Link to="/">
                            <img
                                className={"small-screen"}
                                style={{
                                    width: "120px",
                                    height: "auto",
                                    paddingBottom: "5%",
                                }}
                                src={"../../../WapiDocLogo.png"}
                                alt="WapiDocLogo"
                            />
                        </Link>
                        {this.state.step === "manager" && (
                            <div id="type">
                                <Typography variant="h4">
                                    Setup Institution
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#939393",
                                        padding: "1% 0%",
                                    }}
                                >
                                    Management Type
                                </Typography>
                                <Grid container style={{ margin: "5% 0%" }}>
                                    <Grid
                                        item
                                        xs={12}
                                        onClick={() =>
                                            this.setState({
                                                step: "number",
                                                owns: "multiple",
                                            })
                                        }
                                        className={
                                            this.state.owns === "multiple"
                                                ? "f-type f-selected"
                                                : "f-type"
                                        }
                                    >
                                        <Grid container>
                                            <Grid item xs={12} md={3}>
                                                <img
                                                    className={"wide-screen"}
                                                    src={
                                                        "../../../images/multiple.png"
                                                    }
                                                    alt="institution"
                                                    style={{
                                                        paddingTop: "3%",
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={9}
                                                align="left"
                                                style={{ padding: "2%" }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    gutterBottom
                                                    style={{ color: "#939393" }}
                                                >
                                                    Multiple Institution Manager
                                                </Typography>
                                                <Divider
                                                    style={{
                                                        margin: "3% 0%",
                                                        width: "65%",
                                                    }}
                                                />
                                                <Typography variant="subtitle1">
                                                    Manages multiple branches
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    paragraph
                                                >
                                                    Price, per agreement.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        onClick={() =>
                                            this.setState({
                                                step: "type",
                                                owns: "single",
                                            })
                                        }
                                        className={
                                            this.state.owns === "single"
                                                ? "f-type f-selected"
                                                : "f-type"
                                        }
                                        style={{ margin: "5% 0%" }}
                                    >
                                        <Grid container>
                                            <Grid item xs={12} md={3}>
                                                <img
                                                    className={"wide-screen"}
                                                    src={
                                                        "../../../images/single.png"
                                                    }
                                                    alt="institution"
                                                    style={{
                                                        paddingTop: "3%",
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={9}
                                                align="left"
                                                style={{ padding: "2%" }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    gutterBottom
                                                    style={{ color: "#939393" }}
                                                >
                                                    Single Institution Manager
                                                </Typography>
                                                <Divider
                                                    style={{
                                                        margin: "2% 0%",
                                                        width: "60%",
                                                    }}
                                                />
                                                <Typography variant="subtitle1">
                                                    Manages a single institution
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    paragraph
                                                >
                                                    Price, depends on
                                                    institution category.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        {this.state.step === "number" && (
                            <div id="type">
                                <Typography variant="h4">
                                    Setup Institution
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#939393",
                                        padding: "1% 0%",
                                    }}
                                >
                                    Number of Institutions
                                </Typography>
                                {this.state.error && (
                                    <Alert severity="error">
                                        {this.state.error_message}
                                    </Alert>
                                )}
                                <br />
                                <TextField
                                    error={this.state.errors.hospital_no}
                                    id="hospital_no"
                                    name="hospital_no"
                                    label="Number of Hospitals"
                                    margin="normal"
                                    variant="outlined"
                                    type="number"
                                    value={this.state.hospital_no}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <StoreOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.medical_center_no}
                                    id="medical_center_no"
                                    name="medical_center_no"
                                    label="Number of Medical Centers"
                                    margin="normal"
                                    variant="outlined"
                                    type="number"
                                    value={this.state.medical_center_no}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <StoreOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.clinic_no}
                                    id="clinic_no"
                                    name="clinic_no"
                                    label="Number of Clinics"
                                    margin="normal"
                                    variant="outlined"
                                    type="number"
                                    value={this.state.clinic_no}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <StoreOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.pharmacy_no}
                                    id="pharmacy_no"
                                    name="pharmacy_no"
                                    label="Number of Pharmacys"
                                    margin="normal"
                                    variant="outlined"
                                    type="number"
                                    value={this.state.pharmacy_no}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <StoreOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b back"
                                            variant="contained"
                                            fullWidth
                                            onClick={() =>
                                                this.setState({
                                                    step: "manager",
                                                })
                                            }
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b submit-button"
                                            variant="contained"
                                            fullWidth
                                            style={{ float: "right" }}
                                            onClick={() => this.getNumber()}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        {this.state.step === "type" && (
                            <div id="type">
                                <Typography variant="h4">
                                    {this.state.owns === "multiple" &&
                                    !this.state.aspect
                                        ? "Setup HQ Institution"
                                        : "Setup Institution"}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#939393",
                                        padding: "1% 0%",
                                    }}
                                >
                                    Institution Type
                                </Typography>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        onClick={() =>
                                            this.setState({
                                                step: "basic",
                                                type: "hospital",
                                            })
                                        }
                                    >
                                        <div
                                            className={
                                                this.state.type === "hospital"
                                                    ? "f-type f-selected"
                                                    : "f-type"
                                            }
                                        >
                                            <img
                                                src={
                                                    "../../../images/hospital.png"
                                                }
                                                alt="hospital"
                                            />
                                            <h2 style={{ color: "#939393" }}>
                                                HOSPITAL
                                            </h2>
                                            <h4>
                                                Inpatient and outpatient
                                                services
                                            </h4>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        onClick={() =>
                                            this.setState({
                                                step: "basic",
                                                type: "medical-center",
                                            })
                                        }
                                    >
                                        <div
                                            className={
                                                this.state.type ===
                                                "medical-center"
                                                    ? "f-type f-selected"
                                                    : "f-type"
                                            }
                                        >
                                            <img
                                                src={
                                                    "../../../images/medical-center.png"
                                                }
                                                alt="medical center"
                                            />
                                            <h2 style={{ color: "#939393" }}>
                                                MEDICAL CENTER
                                            </h2>
                                            <h4>
                                                Multiple outpatient services
                                            </h4>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        onClick={() =>
                                            this.setState({
                                                step: "basic",
                                                type: "pharmacy",
                                            })
                                        }
                                    >
                                        <div
                                            className={
                                                this.state.type === "pharmacy"
                                                    ? "f-type f-selected"
                                                    : "f-type"
                                            }
                                        >
                                            <img
                                                src={
                                                    "../../../images/pharmacy.png"
                                                }
                                                alt="pharmacy"
                                            />
                                            <h2 style={{ color: "#939393" }}>
                                                Pharmacy
                                            </h2>
                                            <h4>Provides pharmacy services</h4>
                                        </div>
                                    </Grid>
                                    {this.state.owns === "single" && (
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            onClick={() =>
                                                this.setState({
                                                    step: "basic",
                                                    type: "consultant",
                                                })
                                            }
                                        >
                                            <div
                                                className={
                                                    this.state.type ===
                                                    "consultant"
                                                        ? "f-type f-selected"
                                                        : "f-type"
                                                }
                                            >
                                                <img
                                                    src={
                                                        "../../../images/consultant.png"
                                                    }
                                                    alt="consultant"
                                                />
                                                <h2
                                                    style={{ color: "#939393" }}
                                                >
                                                    CONSULTANT
                                                </h2>
                                                <h4>
                                                    Provides consultancy
                                                    services
                                                </h4>
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        onClick={() =>
                                            this.setState({
                                                step: "basic",
                                                type: "clinic",
                                            })
                                        }
                                    >
                                        <div
                                            className={
                                                this.state.type === "clinic"
                                                    ? "f-type f-selected"
                                                    : "f-type"
                                            }
                                        >
                                            <img
                                                src={
                                                    "../../../images/clinic.png"
                                                }
                                                alt="clinic"
                                            />
                                            <h2 style={{ color: "#939393" }}>
                                                CLINIC / SPECIALIED SERVICES
                                            </h2>
                                            <h4>
                                                Provides one medical service
                                            </h4>
                                            <Typography variant="body1">
                                                (e.g) Dental, Imaging, Optical
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        onClick={() =>
                                            this.setState({
                                                step: "basic",
                                                type: "mobile",
                                            })
                                        }
                                    >
                                        <div
                                            className={
                                                this.state.type === "mobile"
                                                    ? "f-type f-selected"
                                                    : "f-type"
                                            }
                                        >
                                            <img
                                                src={
                                                    "../../../images/mobile.png"
                                                }
                                                alt="mobile"
                                            />
                                            <h2 style={{ color: "#939393" }}>
                                                MOBILE
                                            </h2>
                                            <h4>
                                                Provides a mobile medical
                                                service
                                            </h4>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        onClick={() =>
                                            this.setState({
                                                step: "basic",
                                                type: "online",
                                            })
                                        }
                                    >
                                        <div
                                            className={
                                                this.state.type === "mobile"
                                                    ? "f-type f-selected"
                                                    : "f-type"
                                            }
                                        >
                                            <img
                                                src={
                                                    "../../../images/mobile.png"
                                                }
                                                alt="online"
                                            />
                                            <h2 style={{ color: "#939393" }}>
                                                ONLINE
                                            </h2>
                                            <h4>
                                                Provides an online medical
                                                service
                                            </h4>
                                        </div>
                                    </Grid>
                                </Grid>
                                {!this.state.aspect && (
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            style={{ margin: "0% 3%" }}
                                        >
                                            <Button
                                                className="back"
                                                variant="contained"
                                                fullWidth
                                                onClick={() =>
                                                    this.setState({
                                                        step: "manager",
                                                    })
                                                }
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        )}
                        {this.state.step === "basic" && (
                            <div id="basic">
                                <Typography variant="h4">
                                    {this.state.owns === "multiple" &&
                                    !this.state.aspect
                                        ? "Setup HQ Institution"
                                        : "Setup Institution"}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#939393",
                                        padding: "1% 0%",
                                    }}
                                >
                                    Name, Description and Location
                                </Typography>
                                {this.state.error && (
                                    <Alert severity="error">
                                        {this.state.error_message === ""
                                            ? "Kindly fill in the highlighted fields."
                                            : this.state.error_message}
                                    </Alert>
                                )}
                                <TextField
                                    error={this.state.errors.name}
                                    id="name"
                                    name="name"
                                    label="Institution Name"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="Example XYZ"
                                    value={this.state.name}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <StoreOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.description}
                                    id="description"
                                    name="description"
                                    label="Description / Tagline / Slogan"
                                    variant="outlined"
                                    placeholder="Enter your description here"
                                    value={this.state.description}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    onChange={this.handleChange}
                                    inputProps={{ maxLength: 240 }}
                                />
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    error={this.state.errors.county}
                                >
                                    <InputLabel>County</InputLabel>
                                    <Select
                                        error={this.state.errors.county}
                                        label="county"
                                        name="county"
                                        labelId="county"
                                        value={this.state.county}
                                        onChange={this.handleChange}
                                    >
                                        <MenuItem value="">
                                            {" "}
                                            <em>Chooose County</em>
                                        </MenuItem>
                                        {counties}
                                    </Select>
                                </FormControl>
                                {this.state.county !== "" && (
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        error={this.state.errors.constituency}
                                    >
                                        <InputLabel>
                                            Choose Constituency
                                        </InputLabel>
                                        <Select
                                            label="choose constituency"
                                            name="constituency"
                                            labelId="constituency"
                                            value={this.state.constituency}
                                            onChange={this.handleChange}
                                        >
                                            <MenuItem value="">
                                                <em>Choose Constituency</em>
                                            </MenuItem>
                                            {constituencies}
                                        </Select>
                                    </FormControl>
                                )}
                                {this.state.constituency !== "" && (
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        error={this.state.errors.ward}
                                    >
                                        <InputLabel>Choose ward</InputLabel>
                                        <Select
                                            label="choose ward"
                                            name="ward"
                                            labelId="ward"
                                            value={this.state.ward}
                                            onChange={this.handleChange}
                                        >
                                            <MenuItem value="">
                                                {" "}
                                                <em>Chooose ward</em>
                                            </MenuItem>
                                            {communities}
                                        </Select>
                                    </FormControl>
                                )}

                                <TextField
                                    disabled
                                    id="coordinates"
                                    name="coordinates"
                                    label="GPRS Coordinates"
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue={localStorage.getItem(
                                        "coordinates"
                                    )}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon
                                                    fontSize="small"
                                                    style={{
                                                        color: "#939393",
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Typography
                                    variant="body1"
                                    color="primary"
                                    align="right"
                                    style={{
                                        // color: "#939393",
                                        margin: "2% 0%",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        this.setState({ map: !this.state.map });
                                    }}
                                >
                                    <b>
                                        {this.state.map ? "Hide" : "Show"} map
                                    </b>
                                </Typography>
                                {this.state.map && (
                                    <div>
                                        <Alert severity="info">
                                            Drag and drop the red marker to your
                                            institution's location.
                                        </Alert>
                                        <br />
                                        <MapWrapper />
                                    </div>
                                )}

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b back"
                                            variant="contained"
                                            fullWidth
                                            onClick={() =>
                                                this.setState({
                                                    step: "type",
                                                })
                                            }
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b submit-button"
                                            variant="contained"
                                            fullWidth
                                            style={{ float: "right" }}
                                            onClick={() => this.getBasic()}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        {this.state.step === "contacts" && (
                            <div id="contacts">
                                <Typography variant="h4">
                                    {this.state.owns === "multiple" &&
                                    !this.state.aspect
                                        ? "Setup HQ Institution"
                                        : "Setup Institution"}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#939393",
                                        padding: "1% 0%",
                                    }}
                                >
                                    Contact Information
                                </Typography>
                                {this.state.error && (
                                    <Alert severity="error">
                                        {this.state.error_message === ""
                                            ? "Kindly fill in the highlighted fields."
                                            : this.state.error_message}
                                    </Alert>
                                )}
                                <TextField
                                    error={this.state.errors.primary_phone}
                                    id="primary_phone"
                                    name="primary_phone"
                                    label="Primary Phone"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="0700123456"
                                    value={this.state.primary_phone}
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.secondary_phone}
                                    id="secondary_phone"
                                    name="secondary_phone"
                                    label="Secondary Phone (Optional)"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="0700123456"
                                    value={this.state.secondary_phone}
                                    fullWidth
                                    inputProps={{ maxLength: 10 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <PhoneOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.primary_email}
                                    id="primary_email"
                                    name="primary_email"
                                    label="Primary Email"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="info@example.com"
                                    value={this.state.primary_email}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AlternateEmailOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.secondary_email}
                                    id="secondary_email"
                                    name="secondary_email"
                                    label="Secondary Email (Optional)"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="helpdesk@example.com"
                                    value={this.state.secondary_email}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AlternateEmailOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={this.state.errors.address}
                                            id="address"
                                            name="address"
                                            label="Address"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="747"
                                            type="number"
                                            value={this.state.address}
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <span
                                                            style={{
                                                                color: "#222222",
                                                            }}
                                                        >
                                                            P.O BOX
                                                        </span>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <MailOutlinedIcon
                                                            fontSize="small"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            error={
                                                this.state.errors.postal_code
                                            }
                                            id="postal_code"
                                            name="postal_code"
                                            label="Postal Code"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="00100"
                                            type="number"
                                            value={this.state.postal_code}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <MailOutlinedIcon
                                                            fontSize="small"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b back"
                                            variant="contained"
                                            fullWidth
                                            onClick={() =>
                                                this.setState({
                                                    step: "basic",
                                                })
                                            }
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b submit-button"
                                            variant="contained"
                                            fullWidth
                                            style={{ float: "right" }}
                                            onClick={() => this.getContacts()}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        {this.state.step === "insurance" && (
                            <div>
                                <Typography variant="h4">
                                    {this.state.owns === "multiple" &&
                                    !this.state.aspect
                                        ? "Setup HQ Institution"
                                        : "Setup Institution"}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#939393",
                                        padding: "1% 0%",
                                    }}
                                >
                                    Insurance Providers
                                </Typography>
                                <div style={{ padding: "2% 0%" }}>
                                    {insurances}
                                </div>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b back"
                                            variant="contained"
                                            fullWidth
                                            onClick={() =>
                                                this.setState({
                                                    step: "contacts",
                                                })
                                            }
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b submit-button"
                                            variant="contained"
                                            fullWidth
                                            style={{ float: "right" }}
                                            onClick={() =>
                                                this.setState({
                                                    step: "socials",
                                                })
                                            }
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                        {this.state.step === "socials" && (
                            <div id="socials">
                                <Typography variant="h4">
                                    {this.state.owns === "multiple" &&
                                    !this.state.aspect
                                        ? "Setup HQ Institution"
                                        : "Setup Institution"}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#939393",
                                        padding: "1% 0%",
                                    }}
                                >
                                    Social Media
                                </Typography>
                                {this.state.error && (
                                    <Alert severity="error">
                                        {this.state.error_message === ""
                                            ? "Kindly fill in the highlighted fields."
                                            : this.state.error_message}
                                    </Alert>
                                )}
                                <TextField
                                    error={this.state.errors.linkedin}
                                    id="linkedin"
                                    name="linkedin"
                                    label="Linkedin (Optional)"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="https://www.linkedin.com/example"
                                    value={this.state.linkedin}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LinkedInIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.facebook}
                                    id="facebook"
                                    name="facebook"
                                    label="Facebook (Optional)"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="https://www.facebook.com/example"
                                    value={this.state.facebook}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FacebookIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.twitter}
                                    id="twitter"
                                    name="twitter"
                                    label="Twitter (Optional)"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="https://www.twitter.com/example"
                                    value={this.state.twitter}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <TwitterIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.instagram}
                                    id="instagram"
                                    name="instagram"
                                    label="Instagram (Optional)"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="https://www.instagram.com/example"
                                    value={this.state.instagram}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <InstagramIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.youtube}
                                    id="youtube"
                                    name="youtube"
                                    label="Youtube (Optional)"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="https://www.youtube.com/example"
                                    value={this.state.youtube}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <YouTubeIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    error={this.state.errors.website}
                                    id="website"
                                    name="website"
                                    label="Website (Optional)"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="http://www.example.com"
                                    value={this.state.website}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LanguageIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b back"
                                            variant="contained"
                                            fullWidth
                                            onClick={() =>
                                                this.setState({
                                                    step: "insurance",
                                                })
                                            }
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b submit-button"
                                            variant="contained"
                                            fullWidth
                                            style={{ float: "right" }}
                                            onClick={() => this.getSocials()}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                        {this.state.step === "working_hours" && (
                            <div id="hours">
                                <Typography variant="h4">
                                    {this.state.owns === "multiple" &&
                                    !this.state.aspect
                                        ? "Setup HQ Institution"
                                        : "Setup Institution"}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#939393",
                                        padding: "1% 0%",
                                    }}
                                >
                                    Working Hours
                                </Typography>
                                {this.state.error && (
                                    <Alert severity="error">
                                        {this.state.error_message === ""
                                            ? "Kindly fill in the highlighted fields."
                                            : this.state.error_message}
                                    </Alert>
                                )}
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: "#939393",
                                        margin: "2% 0%",
                                    }}
                                >
                                    Weekdays
                                </Typography>
                                <Divider
                                    style={{
                                        margin: "2% 0%",
                                    }}
                                />
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        style={{
                                            textAlign: "center",
                                            paddingTop: "2%",
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            Mon - Fri
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors
                                                    .weekday_opening
                                            }
                                            className="third-i"
                                            id="weekday_opening"
                                            name="weekday_opening"
                                            label="Opening"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="08:00"
                                            value={this.state.weekday_opening}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        AM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors
                                                    .weekday_closing
                                            }
                                            className="third-i"
                                            id="weekday_closing"
                                            name="weekday_closing"
                                            label="Closing"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="05:00"
                                            value={this.state.weekday_closing}
                                            fullWidth
                                            // style={{ float: "right" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        PM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid
                                            container
                                            spacing={1}
                                            style={{ paddingTop: "3px" }}
                                        >
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "weekday",
                                                            "00:00"
                                                        )
                                                    }
                                                >
                                                    24&nbsp;HOURS
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "weekday",
                                                            "__:__"
                                                        )
                                                    }
                                                >
                                                    Closed
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: "#939393",
                                        margin: "2% 0%",
                                    }}
                                >
                                    Weekends
                                </Typography>
                                <Divider
                                    style={{
                                        margin: "2% 0%",
                                    }}
                                />
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        style={{
                                            textAlign: "center",
                                            paddingTop: "2%",
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            Saturday
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors
                                                    .saturday_opening
                                            }
                                            className="third-i"
                                            id="saturday_opening"
                                            name="saturday_opening"
                                            label="Opening"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="08:00"
                                            value={this.state.saturday_opening}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        AM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors
                                                    .saturday_closing
                                            }
                                            className="third-i"
                                            id="saturday_closing"
                                            name="saturday_closing"
                                            label="Closing"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="05:00"
                                            value={this.state.saturday_closing}
                                            fullWidth
                                            style={{ float: "right" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        PM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid
                                            container
                                            spacing={1}
                                            style={{ paddingTop: "3px" }}
                                        >
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "saturday",
                                                            "00:00"
                                                        )
                                                    }
                                                >
                                                    24&nbsp;HOURS
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "saturday",
                                                            "__:__"
                                                        )
                                                    }
                                                >
                                                    Closed
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        style={{
                                            textAlign: "center",
                                            paddingTop: "2%",
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            Sunday
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors.sunday_opening
                                            }
                                            className="third-i"
                                            id="sunday_opening"
                                            name="sunday_opening"
                                            label="Opening"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="08:00"
                                            value={this.state.sunday_opening}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        AM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors.sunday_closing
                                            }
                                            className="third-i"
                                            id="sunday_closing"
                                            name="sunday_closing"
                                            label="Closing"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="12:00"
                                            value={this.state.sunday_closing}
                                            fullWidth
                                            style={{ float: "right" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        PM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid
                                            container
                                            spacing={1}
                                            style={{ paddingTop: "3px" }}
                                        >
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "sunday",
                                                            "00:00"
                                                        )
                                                    }
                                                >
                                                    24&nbsp;HOURS
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "sunday",
                                                            "__:__"
                                                        )
                                                    }
                                                >
                                                    Closed
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: "#939393",
                                            margin: "2% 0%",
                                        }}
                                    >
                                        Public Holidays
                                    </Typography>
                                    <Divider
                                        style={{
                                            width: "100%",
                                            margin: "2% 0%",
                                        }}
                                    />
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ paddingBottom: "5%" }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            md={2}
                                            style={{
                                                textAlign: "center",
                                                paddingTop: "2%",
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Holidays
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <TextField
                                                error={
                                                    this.state.errors
                                                        .holiday_opening
                                                }
                                                className="third-i"
                                                id="holiday_opening"
                                                name="holiday_opening"
                                                label="Opening"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="08:00"
                                                value={
                                                    this.state.holiday_opening
                                                }
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            AM
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <TextField
                                                error={
                                                    this.state.errors
                                                        .holiday_closing
                                                }
                                                className="third-i"
                                                id="holiday_closing"
                                                name="holiday_closing"
                                                label="Closing"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="12:00"
                                                value={
                                                    this.state.holiday_closing
                                                }
                                                fullWidth
                                                style={{ float: "right" }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            PM
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Grid
                                                container
                                                spacing={1}
                                                style={{ paddingTop: "3px" }}
                                            >
                                                <Grid item xs={6}>
                                                    <Button
                                                        color="primary"
                                                        variant="outlined"
                                                        fullWidth
                                                        style={{
                                                            padding: "16% 5%",
                                                        }}
                                                        onClick={() =>
                                                            this.setHours(
                                                                "holiday",
                                                                "00:00"
                                                            )
                                                        }
                                                    >
                                                        24&nbsp;HOURS
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        color="primary"
                                                        variant="outlined"
                                                        fullWidth
                                                        style={{
                                                            padding: "16% 5%",
                                                        }}
                                                        onClick={() =>
                                                            this.setHours(
                                                                "holiday",
                                                                "__:__"
                                                            )
                                                        }
                                                    >
                                                        Closed
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Button
                                                className="half-b back"
                                                variant="contained"
                                                fullWidth
                                                onClick={() =>
                                                    this.setState({
                                                        step: "socials",
                                                    })
                                                }
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                className="half-b submit-button"
                                                variant="contained"
                                                fullWidth
                                                style={{ float: "right" }}
                                                onClick={() =>
                                                    this.getWorkingHours()
                                                }
                                            >
                                                Next
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        {this.state.step === "visiting_hours" && (
                            <div id="hours">
                                <Typography variant="h4">
                                    {this.state.owns === "multiple" &&
                                    !this.state.aspect
                                        ? "Setup HQ Institution"
                                        : "Setup Institution"}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#939393",
                                        padding: "1% 0%",
                                    }}
                                >
                                    Visiting Hours
                                </Typography>
                                {this.state.error && (
                                    <Alert severity="error">
                                        {this.state.error_message === ""
                                            ? "Kindly fill in the highlighted fields."
                                            : this.state.error_message}
                                    </Alert>
                                )}
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        style={{
                                            textAlign: "center",
                                            paddingTop: "2%",
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            Morning Hours
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors
                                                    .morning_opening
                                            }
                                            className="third-i"
                                            id="morning_opening"
                                            name="morning_opening"
                                            label="Opening"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="08:00"
                                            value={this.state.morning_opening}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        AM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors
                                                    .morning_closing
                                            }
                                            className="third-i"
                                            id="morning_closing"
                                            name="morning_closing"
                                            label="Closing"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="12:00"
                                            value={this.state.morning_closing}
                                            fullWidth
                                            style={{ float: "right" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        AM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid
                                            container
                                            spacing={1}
                                            style={{ paddingTop: "3px" }}
                                        >
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "morning",
                                                            "00:00"
                                                        )
                                                    }
                                                >
                                                    24&nbsp;HOURS
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "morning",
                                                            "__:__"
                                                        )
                                                    }
                                                >
                                                    Closed
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        style={{
                                            textAlign: "center",
                                            paddingTop: "2%",
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            Afternoon Hours
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors
                                                    .afternoon_opening
                                            }
                                            className="third-i"
                                            id="afternoon_opening"
                                            name="afternoon_opening"
                                            label="Opening"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="08:00"
                                            value={this.state.afternoon_opening}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        AM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors
                                                    .afternoon_closing
                                            }
                                            className="third-i"
                                            id="afternoon_closing"
                                            name="afternoon_closing"
                                            label="Closing"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="12:00"
                                            value={this.state.afternoon_closing}
                                            fullWidth
                                            style={{ float: "right" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        PM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid
                                            container
                                            spacing={1}
                                            style={{ paddingTop: "3px" }}
                                        >
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "afternoon",
                                                            "00:00"
                                                        )
                                                    }
                                                >
                                                    24&nbsp;HOURS
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "afternoon",
                                                            "__:__"
                                                        )
                                                    }
                                                >
                                                    Closed
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        style={{
                                            textAlign: "center",
                                            paddingTop: "2%",
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            Evening Hours
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors
                                                    .evening_opening
                                            }
                                            className="third-i"
                                            id="evening_opening"
                                            name="evening_opening"
                                            label="Opening"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="08:00"
                                            value={this.state.evening_opening}
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        PM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            error={
                                                this.state.errors
                                                    .evening_closing
                                            }
                                            className="third-i"
                                            id="evening_closing"
                                            name="evening_closing"
                                            label="Closing"
                                            margin="normal"
                                            variant="outlined"
                                            placeholder="12:00"
                                            value={this.state.evening_closing}
                                            fullWidth
                                            style={{ float: "right" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        PM
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid
                                            container
                                            spacing={1}
                                            style={{ paddingTop: "3px" }}
                                        >
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "evening",
                                                            "00:00"
                                                        )
                                                    }
                                                >
                                                    24&nbsp;HOURS
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    fullWidth
                                                    style={{
                                                        padding: "16% 5%",
                                                    }}
                                                    onClick={() =>
                                                        this.setHours(
                                                            "evening",
                                                            "__:__"
                                                        )
                                                    }
                                                >
                                                    Closed
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <br />
                                <br />
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b back"
                                            variant="contained"
                                            fullWidth
                                            onClick={() =>
                                                this.setState({
                                                    step: "working_hours",
                                                })
                                            }
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b submit-button"
                                            variant="contained"
                                            fullWidth
                                            style={{ float: "right" }}
                                            onClick={() =>
                                                this.getVisitingHours()
                                            }
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        {this.state.step === "subscription" && (
                            <div id="visit">
                                <Typography variant="h4">
                                    {this.state.owns === "multiple" &&
                                    !this.state.aspect
                                        ? "Setup HQ Institution"
                                        : "Setup Institution"}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{ color: "#939393" }}
                                >
                                    Choose a Payment Plan
                                </Typography>
                                <Alert severity="info">
                                    Not Sure?{" "}
                                    <Link
                                        to="/managers/pricing"
                                        target="_blank"
                                    >
                                        Learn more about pricing.
                                    </Link>
                                </Alert>
                                <div style={{ marginTop: "5%" }}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            align="center"
                                        >
                                            <div
                                                style={{
                                                    padding: "10% 5%",
                                                }}
                                                className={
                                                    this.state.subscription ===
                                                    "yearly"
                                                        ? "f-type f-selected"
                                                        : "f-type"
                                                }
                                                onClick={() =>
                                                    this.setState({
                                                        step: "preview",
                                                        subscription: "yearly",
                                                    })
                                                }
                                            >
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        margin: "0% auto 4% auto",
                                                        background: "#F5E633",
                                                        borderRadius: "60px",
                                                        width: "60%",
                                                    }}
                                                >
                                                    RECOMMENDED
                                                </Typography>
                                                <Typography
                                                    variant="h4"
                                                    gutterBottom
                                                >
                                                    Yearly Plan
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    {cogs.capitalize(
                                                        this.state.type
                                                    )}{" "}
                                                    Category
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    <strike>
                                                        KES{" "}
                                                        {this.getRoundedAmount(
                                                            "yearly"
                                                        )
                                                            .toString()
                                                            .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                            )}
                                                    </strike>
                                                </Typography>
                                                <Typography variant="h6">
                                                    KES{" "}
                                                    {this.addEarlyBirdDiscount(
                                                        this.getRoundedAmount(
                                                            "yearly"
                                                        )
                                                    )
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )}
                                                    <br />
                                                    Every Year
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            align="center"
                                        >
                                            <div
                                                style={{
                                                    padding: "10% 5%",
                                                }}
                                                className={
                                                    this.state.subscription ===
                                                    "bi-annual"
                                                        ? "f-type f-selected"
                                                        : "f-type"
                                                }
                                                onClick={() =>
                                                    this.setState({
                                                        step: "preview",
                                                        subscription:
                                                            "bi-annual",
                                                    })
                                                }
                                            >
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        marginBottom: "4%",
                                                    }}
                                                >
                                                    -
                                                </Typography>
                                                <Typography
                                                    variant="h4"
                                                    gutterBottom
                                                >
                                                    Bi Annual Plan
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    {cogs.capitalize(
                                                        this.state.type
                                                    )}{" "}
                                                    Category
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    <strike>
                                                        KES{" "}
                                                        {this.getRoundedAmount(
                                                            "bi-annual"
                                                        )
                                                            .toString()
                                                            .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                            )}
                                                    </strike>
                                                </Typography>
                                                <Typography variant="h6">
                                                    KES{" "}
                                                    {this.addEarlyBirdDiscount(
                                                        this.getRoundedAmount(
                                                            "bi-annual"
                                                        )
                                                    )
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )}
                                                    <br />
                                                    Every 6 Months
                                                </Typography>
                                            </div>
                                        </Grid>{" "}
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            align="center"
                                        >
                                            <div
                                                style={{
                                                    padding: "10% 5%",
                                                }}
                                                className={
                                                    this.state.subscription ===
                                                    "quarterly"
                                                        ? "f-type f-selected"
                                                        : "f-type"
                                                }
                                                onClick={() =>
                                                    this.setState({
                                                        step: "preview",
                                                        subscription:
                                                            "quarterly",
                                                    })
                                                }
                                            >
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        marginBottom: "4%",
                                                    }}
                                                >
                                                    -
                                                </Typography>
                                                <Typography
                                                    variant="h4"
                                                    gutterBottom
                                                >
                                                    Quarterly Plan
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    {cogs.capitalize(
                                                        this.state.type
                                                    )}{" "}
                                                    Category
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    <strike>
                                                        KES{" "}
                                                        {this.getRoundedAmount(
                                                            "quarterly"
                                                        )
                                                            .toString()
                                                            .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                            )}
                                                        <br />
                                                    </strike>
                                                </Typography>
                                                <Typography variant="h6">
                                                    KES{" "}
                                                    {this.addEarlyBirdDiscount(
                                                        this.getRoundedAmount(
                                                            "quarterly"
                                                        )
                                                    )
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )}
                                                    <br />
                                                    Every 3 Months
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            align="center"
                                        >
                                            <div
                                                style={{
                                                    padding: "10% 5%",
                                                }}
                                                className={
                                                    this.state.subscription ===
                                                    "monthly"
                                                        ? "f-type f-selected"
                                                        : "f-type"
                                                }
                                                onClick={() =>
                                                    this.setState({
                                                        step: "preview",
                                                        subscription: "monthly",
                                                    })
                                                }
                                            >
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        marginBottom: "4%",
                                                    }}
                                                >
                                                    -
                                                </Typography>
                                                <Typography
                                                    variant="h4"
                                                    gutterBottom
                                                >
                                                    Monthly Plan
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    {cogs.capitalize(
                                                        this.state.type
                                                    )}{" "}
                                                    Category
                                                </Typography>
                                                <Typography variant="subtitle1">
                                                    <strike>
                                                        KES{" "}
                                                        {this.getRoundedAmount(
                                                            "monthly"
                                                        )
                                                            .toString()
                                                            .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                            )}
                                                    </strike>
                                                </Typography>
                                                <Typography variant="h6">
                                                    KES{" "}
                                                    {this.addEarlyBirdDiscount(
                                                        this.getRoundedAmount(
                                                            "monthly"
                                                        )
                                                    )
                                                        .toString()
                                                        .replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        )}
                                                    <br />
                                                    Every Month
                                                </Typography>
                                            </div>
                                        </Grid>{" "}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b back"
                                            variant="contained"
                                            style={{ margin: "0% 5%" }}
                                            fullWidth
                                            onClick={() => {
                                                if (
                                                    this.state.type ===
                                                    "hospital"
                                                ) {
                                                    this.setState({
                                                        step: "visiting_hours",
                                                    });
                                                } else {
                                                    this.setState({
                                                        step: "working_hours",
                                                    });
                                                }
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </div>
                            </div>
                        )}
                        {this.state.step === "preview" && (
                            <div id="preview">
                                <Typography variant="h4">
                                    {this.state.owns === "multiple" &&
                                    !this.state.aspect
                                        ? "Setup HQ Institution"
                                        : "Setup Institution"}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#939393",
                                        padding: "1% 0%",
                                    }}
                                >
                                    Confirm Institution
                                </Typography>
                                <br />
                                <Typography variant="subtitle1">
                                    Name, Description and Location
                                </Typography>
                                <Divider style={{ margin: "1% 0%" }} />
                                <Typography variant="body1" gutterBottom>
                                    <b>Name: </b>
                                    {cogs.capitalize(this.state.name)}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Description: </b>
                                    {this.state.description}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>County: </b>
                                    {cogs.capitalize(this.state.county)}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Constituency: </b>
                                    {cogs.capitalize(this.state.constituency)}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Ward: </b>
                                    {cogs.capitalize(this.state.ward)}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>GPRS: </b>
                                    {this.state.gprs.join(",")}
                                </Typography>

                                <br />
                                <Typography variant="subtitle1">
                                    Contact Info
                                </Typography>
                                <Divider style={{ margin: "1% 0%" }} />
                                <Typography variant="body1" gutterBottom>
                                    <b>Primary Phone: </b>
                                    {this.state.primary_phone}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Secondary Phone: </b>
                                    {this.state.secondary_phone}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Primary Email: </b>
                                    {this.state.primary_email}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Secondary Email: </b>
                                    {this.state.secondary_email}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Postal Address: </b>
                                    {this.state.address}
                                    {"-"}
                                    {this.state.postal_code}
                                </Typography>

                                <br />
                                <Typography variant="subtitle1">
                                    Insurance Covers
                                </Typography>
                                <Divider style={{ margin: "1% 0%" }} />
                                <Typography variant="body1" gutterBottom>
                                    {this.state.insurance}
                                </Typography>

                                <br />
                                <Typography variant="subtitle1">
                                    Socials
                                </Typography>
                                <Divider style={{ margin: "1% 0%" }} />
                                <Typography variant="body1" gutterBottom>
                                    <b>LinkedIn: </b>
                                    {this.state.linkedin}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Facebook: </b>
                                    {this.state.facebook}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Twitter: </b>
                                    {this.state.twitter}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Instagram: </b>
                                    {this.state.instagram}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Youtube: </b>
                                    {this.state.youtube}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Website: </b>
                                    {this.state.website}
                                </Typography>

                                <br />
                                <Typography variant="subtitle1">
                                    Working Hours
                                </Typography>
                                <Divider style={{ margin: "1% 0%" }} />
                                <Typography variant="body1" gutterBottom>
                                    <b>Mon - Fri: </b>
                                    {this.state.weekday_opening}
                                    {" AM"}
                                    {" - "}
                                    {this.state.weekday_closing}
                                    {" PM"}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Saturday: </b>
                                    {this.state.saturday_opening}
                                    {" AM"}
                                    {" - "}
                                    {this.state.saturday_closing}
                                    {" PM"}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Sunday: </b>
                                    {this.state.sunday_opening}
                                    {" AM"}
                                    {" - "}
                                    {this.state.sunday_closing}
                                    {" PM"}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Holidays: </b>
                                    {this.state.holiday_opening}
                                    {" AM"}
                                    {" - "}
                                    {this.state.holiday_closing}
                                    {" PM"}
                                </Typography>

                                {this.state.type === "hospital" && (
                                    <div>
                                        {" "}
                                        <br />
                                        <Typography variant="subtitle1">
                                            Visiting Hours
                                        </Typography>
                                        <Divider style={{ margin: "1% 0%" }} />
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            <b>Morning Hours: </b>
                                            {this.state.morning_opening}
                                            {" AM"}
                                            {" - "}
                                            {this.state.morning_closing}
                                            {" AM"}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            <b>Afternoon Hours: </b>
                                            {this.state.afternoon_opening}
                                            {" AM"}
                                            {" - "}
                                            {this.state.afternoon_closing}
                                            {" PM"}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            gutterBottom
                                        >
                                            <b>Evening Hours: </b>
                                            {this.state.evening_opening}
                                            {" PM"}
                                            {" - "}
                                            {this.state.evening_closing}
                                            {" PM"}
                                        </Typography>
                                    </div>
                                )}
                                <br />
                                <Typography variant="subtitle1">
                                    Subscription Plan
                                </Typography>
                                <Divider style={{ margin: "1% 0%" }} />
                                <Typography variant="body1" gutterBottom>
                                    <b>Category: </b>
                                    {cogs.capitalize(this.state.type)}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Amount: </b>
                                    KES{" "}
                                    {this.addEarlyBirdDiscount(
                                        this.getRoundedAmount(
                                            this.state.subscription
                                        )
                                    )
                                        .toString()
                                        .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                        )}{" "}
                                </Typography>

                                <Grid container>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b back"
                                            variant="contained"
                                            fullWidth
                                            onClick={() => {
                                                this.setState({
                                                    step: "subscription",
                                                });
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            className="half-b submit-button"
                                            variant="contained"
                                            fullWidth
                                            style={{ float: "right" }}
                                            onClick={() => this.confirmData()}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )}

                        {this.state.loading && (
                            <div
                                style={{
                                    textAlign: "center",
                                    paddingTop: "20%",
                                }}
                            >
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            {this.state.load > 0 ? (
                                                <CheckCircleIcon
                                                    style={{
                                                        height: "22px",
                                                        width: "22px",
                                                        color: "#5DC726",
                                                    }}
                                                />
                                            ) : (
                                                <CircularProgress
                                                    style={{
                                                        height: "20px",
                                                        width: "20px",
                                                    }}
                                                />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                Setting up your facility
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    {this.state.load > 0 && (
                                        <ListItem>
                                            <ListItemIcon>
                                                {this.state.load > 1 ? (
                                                    <CheckCircleIcon
                                                        style={{
                                                            height: "22px",
                                                            width: "22px",
                                                            color: "#5DC726",
                                                        }}
                                                    />
                                                ) : (
                                                    <CircularProgress
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                    />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography
                                                    variant="body1"
                                                    gutterBottom
                                                >
                                                    Updating Manager Status
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    )}

                                    {this.state.load > 1 && (
                                        <ListItem>
                                            <ListItemIcon>
                                                {this.state.load > 2 ? (
                                                    <CheckCircleIcon
                                                        style={{
                                                            height: "22px",
                                                            width: "22px",
                                                            color: "#5DC726",
                                                        }}
                                                    />
                                                ) : (
                                                    <CircularProgress
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                    />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography
                                                    variant="body1"
                                                    gutterBottom
                                                >
                                                    Generating Invoice
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    )}

                                    {this.state.load > 2 && (
                                        <ListItem>
                                            <ListItemIcon>
                                                <CircularProgress
                                                    style={{
                                                        height: "20px",
                                                        width: "20px",
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography
                                                    variant="body1"
                                                    gutterBottom
                                                >
                                                    Redirecting
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    )}
                                </List>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withSnackbar(InstitutionSetup);
