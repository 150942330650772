import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";
import { Grid, Typography, Link } from "@material-ui/core";

class PrivacyPolicyPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Privacy Policy</title>
                    <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid
                    container
                    style={{ paddingTop: "5%", paddingBottom: "5%" }}
                    className={"add-space"}
                >
                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ margin: "0% auto", padding: "5%" }}
                        className={"add-space"}
                    >
                        <Typography variant="h4" component="h1" gutterBottom>
                            Privacy Policy
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Effective Date: October 12, 2021
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WapiDoc ("WapiDoc," "We," "Us" or "Our") has
                            developed this privacy policy ("Privacy Policy") for
                            the benefit of the users ("You," "Your" and
                            "Yourself") of its websites and mobile apps,
                            including www.wapidoc.com (individually and
                            collectively, the "Site"). This Privacy Policy
                            summarizes how WapiDoc handles the personally
                            identifying information that You voluntarily provide
                            when You use the Site and that We may automatically
                            collect when You visit the Site ("Personal
                            Information").
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If You are accessing, using or browsing this Site on
                            behalf of an individual other than Yourself, which
                            may be either a natural person or entity other than
                            Yourself:
                            <br />
                            <br />
                            You represent, warrant and covenant that You are at
                            least age 18:
                            <br />
                            <br />
                            You represent, warrant and covenant that You are
                            authorized to engage in the activities that You
                            conduct within the Site on behalf of that person or
                            entity other than Yourself, which by way of
                            illustration but not limitation includes the
                            submission and receipt of any and all Personal
                            Information on behalf of that person or entity other
                            than Yourself;
                            <br />
                            <br />
                            You agree that We can rely on Your representation
                            that You are authorized to engage in the activities
                            that You conduct within the Site on behalf of that
                            natural person or entity other than Yourself; and
                            <br />
                            <br />
                            You understand that "You," "Your," and "Yourself" in
                            this Privacy Policy mean both that natural person or
                            entity and You acting on behalf of that natural
                            person or entity other than Yourself.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            This Site is controlled and operated by WapiDoc from
                            its offices within the the Republic of Kenya.
                            Accordingly, this Privacy Policy, and how WapiDoc
                            handles the information that You voluntarily provide
                            when You use the Site and information that We may
                            automatically collect when You visit the Site, is
                            governed by the Republic of Kenya laws, and not by
                            the laws of any country, territory or jurisdiction
                            other than the Republic of Kenya. We make no
                            representation that the Site is appropriate or
                            available for use outside of the Republic of Kenya.
                            Access to the Site from territories where its
                            contents or purpose is illegal is prohibited. Those
                            who choose to access the Site are responsible for
                            compliance with applicable local laws and
                            regulations.
                            <br />
                            <br />
                            Your use of this Site will be deemed conclusively to
                            constitute Your acceptance of and agreement to this
                            Privacy Policy and all portions hereof.
                            <br />
                            <br />
                            WapiDoc reserves the right, at its sole discretion,
                            to change this Privacy Policy at any time. If We
                            decide to change the Privacy Policy, We will post
                            the changes in appropriate places on the Site so
                            that You are aware of the changed Privacy Policy.
                            Your continued use of the Site after the Effective
                            Date for any such changes to the Privacy Policy will
                            be deemed conclusively to constitute Your acceptance
                            of and agreement to the changed Privacy Policy.
                            Consequently, You should frequently review this
                            Privacy Policy.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            What Personal Information does WapiDoc collect?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WapiDoc collects several types of Personal
                            Information. For example, to use some of its
                            services and access certain areas of the Site, You
                            must provide Personal Information that can be traced
                            back to You, such as full name, location and
                            telephone number, e-mail, gender, date of birth. We
                            collect this Personal Information that You
                            voluntarily provide through the use of on-line data
                            entry, survey and registration forms. WapiDoc
                            gathers most of this Personal Information from You
                            during an initial voluntary User registration
                            process for the Site. WapiDoc also occasionally asks
                            You for Personal Information at other times,
                            including when You complete optional survey forms,
                            fill out optional contact forms for additional
                            information on particular products and services or
                            use a particular service.
                            <br />
                            <br />
                            We collect information about your location when you
                            use or access our site. The degree of precision of
                            the location data varies depending on the source of
                            such information. Those sources include: Your
                            Internet browser and your IP address. We collect and
                            use this location-related data in order to:
                            <br />
                            <br />· Deliver content that is relevant to you
                            based upon your location <br />
                            <br />· Restrict certain access to our site
                            <br />
                            <br />
                            You may disable the collection and use of your
                            browser location data through browser settings.
                            <br />
                            <br />
                            When You visit the Site, WapiDoc may also
                            automatically serve and collect data via or from
                            Cookies and similar data collection technologies. A
                            “Cookie” is a text file that a website sends to a
                            visitor’s browser and is stored on Your computer,
                            mobile phone, tablet, or other connected device
                            (collectively, “Computer”). Cookies can store
                            information about Your visit to and use of Our Site
                            or other websites, can make that information
                            available to Us or third parties, and can help Us
                            offer You functionalities on Our Sites.
                            <br />
                            <br />
                            We may use other similar technologies, including web
                            beacons (also called “pixels,” “tags,” or “clear
                            GIFs”) and JavaScript, to fulfill similar functions
                            as Cookies. A web beacon is often a one-pixel,
                            transparent image (although it can be a larger or
                            visible image as well), located on a website or in
                            an email or other type of message and retrieved from
                            a website on the Internet. Similarly, our mobile
                            application may integrate “software development
                            kits” (SDKs) provided by Ourselves or third parties.
                            Both web beacons and SDKs are used for similar
                            purposes as Cookies, and are treated as “Cookies”
                            for purposes of this Privacy Policy.
                            <br />
                            <br />
                            When We place and access Cookies on Your Computer,
                            these Cookies are known as “first-party” Cookies.
                            Cookies may also be placed and accessed by
                            third-party advertisers or Our third-party service
                            providers; these are known as “third-party” Cookies.
                            When third-party Cookies are used, third parties may
                            receive information about Your visit to Our Site and
                            use it to present content or advertisements to You
                            on Our Sites, to recognize You and customize Your
                            experience at third-party sites, or for any other
                            purpose described in this Privacy Policy.
                            Additionally, Cookies can either be “persistent”
                            (i.e., they remain on Your Computer until You delete
                            them) or temporary (i.e., they last only until You
                            close Your browser). For more information, please
                            review Our{" "}
                            <Link href="../cookie-policy">Cookie Policy</Link>.
                            <br />
                            <br />
                            WapiDoc may also collect Personal Information about
                            you from third parties, and we may combine
                            information that is de-identified with Personal
                            Information. We will treat the combined information
                            as Personal Information as provided in this Privacy
                            Policy.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            What about the collection of Personal Information
                            from children?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WapiDoc Sites are general audience sites intended
                            for users 18 years of age and older. We do not
                            knowingly collect personal information from persons
                            under the age of 18.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            How does WapiDoc use the Personal Information we
                            collect?
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                            WapiDoc may use the Personal Information we obtain
                            to:
                            <br />
                            <br />
                            Provide products and services you request.
                            <br />
                            <br />
                            Advertise our and our affiliates’ and customers’
                            products and services.
                            <br />
                            <br />
                            Communicate with you about new information or
                            features available through the Site or that WapiDoc
                            otherwise feels might be of interest to users of the
                            Site.
                            <br />
                            <br />
                            Allow WapiDoc and our third-party advertisers and
                            retailers and their affiliates to communicate with
                            you to deliver relevant information or offers.
                            <br />
                            <br />
                            Operate, maintain, and improve the Site.
                            <br />
                            <br />
                            Make improvements to the Site to provide our users
                            with a more personalized, relevant and intelligent
                            service.
                            <br />
                            <br />
                            Identify the interests and needs of the users of our
                            Site.
                            <br />
                            <br />
                            Operate, evaluate and improve our business
                            (including developing new products and services;
                            managing our communications; determining the
                            effectiveness of our sales, marketing and
                            advertising; analyzing and enhancing our products,
                            services, websites and apps; ensuring the security
                            of our networks and information systems; performing
                            accounting, auditing, invoicing, reconciliation and
                            collection activities; and improving and maintaining
                            the quality of our customer service operations).
                            <br />
                            <br />
                            Perform data analyses (including market and consumer
                            search and analytics, trend analysis and profiling,
                            financial analysis and de-identification of personal
                            information).
                            <br />
                            <br />
                            Provide customer support and respond to and
                            communicate with you about your requests, questions
                            and comments.
                            <br />
                            <br />
                            Protect against, identify and prevent fraud and
                            other prohibited or illegal activity, claims and
                            other liabilities.
                            <br />
                            <br />
                            Comply with applicable legal requirements and our
                            policies.
                            <br />
                            <br />
                            Establish, exercise and defend legal claims.
                            <br />
                            <br />
                            Monitor and report compliance issues.
                            <br />
                            <br />
                            For systems administration and troubleshooting
                            purposes for the Site.
                            <br />
                            <br />
                            To report aggregate information to Our advertisers
                            and others.
                            <br />
                            <br />
                            In addition, we use clickstream data (data regarding
                            usage of the Site, whether identifiable to users or
                            de-identified) to determine usage patterns and how
                            we may tailor our websites and apps to better meet
                            the needs of our users.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Does WapiDoc disclose Your Personal Information to
                            third parties?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WapiDoc may share Your Personal Information:
                            <br />
                            <br />
                            we may share with third parties who have agreements
                            with Us, including marketers, advertisers and other
                            health service providers;
                            <br />
                            <br />
                            if WapiDoc is required by law to do so, including
                            laws outside Your country of residence;
                            <br />
                            <br />
                            with service providers. Among other things, service
                            providers may help us to administer our website,
                            conduct surveys, provide technical support, assist
                            or support our business operations and other
                            administrative functions, process payments, and
                            assist in the fulfillment of orders.
                            <br />
                            <br />
                            with audience measurement that help us and other
                            websites measure and understand the audiences that
                            view our content.
                            <br />
                            <br />
                            some of our Services may provide the opportunity to
                            post comments, or reviews, in a public forum. If You
                            decide to submit information on our Services, that
                            information may be publicly available
                            <br />
                            <br />
                            with appropriate consent when required, We may share
                            your Personal Information with third party marketing
                            partners, and they may contact you by email,
                            telephone, postal mail or other common communication
                            methods with marketing and promotional messages
                            about their products or services. Sometimes we will
                            give you the option to receive further
                            communications from one of our advertising partners,
                            for example when we run a competition with that
                            advertiser. When you provide consent to receive
                            communications from that third party advertiser,
                            your personal information will be managed by that
                            third party's own privacy policy;
                            <br />
                            <br />
                            in the event of a transfer of ownership, merger or
                            other similar transaction (including one in
                            connection with any bankruptcy or similar
                            proceedings);
                            <br />
                            <br />
                            as otherwise set forth in this Privacy Policy,
                            including as set forth below. Note, however, that
                            WapiDoc is not and cannot be responsible for the
                            activities or privacy policies of any third party
                            with whom Your Personal Information is shared.
                            <br />
                            <br />
                            We may share certain data with Advertising
                            platforms, including Google and Social Networks such
                            as Twitter, Facebook, LinkedIn and Snapchat to allow
                            us to target existing users and customers with
                            highly relevant advertising campaigns. For the
                            purposes of such campaigns. We may share a unique
                            code (such as a hashed email address) with the
                            applicable Advertising platform in order for that
                            identifier to be matched against existing users of
                            the relevant Advertising Platform and to identify
                            potentially relevant audiences for targeted
                            advertising. We use services such as Google Ads’
                            Customer Match, LiveRamp, LinkedIn Matched
                            Audiences, and Facebook Custom Audiences for these
                            purposes. In addition to advertisements for our own
                            goods and services, we may also facilitate third
                            parties to advertise their own goods and services
                            including by targeting those advertisements to those
                            that might be most interested in them. These
                            advertisements may be shown within our Services. In
                            order to provide advertisements relevant to you,
                            your IP address may be used, as well as your
                            operating system and device type. Information about
                            your particular device will be used to show you
                            advertisements which work properly on that device.
                            You may be able to adjust your preferences by
                            visiting our Cookie Policy below in the 'Your
                            Choices' section of this policy.
                            <br />
                            <br />
                            With respect to obtaining certain products and/or
                            services from this Site, WapiDoc may disclose to a
                            third party Your Personal Information that We gather
                            from You during an initial voluntary User
                            registration process for the Site ("Registration
                            Information"); as a result, You do not have to enter
                            Your Registration Information more than once.
                            WapiDoc may also disclose Your Registration
                            Information to certain third parties in connection
                            with various programs and tools created and
                            administered by third parties that are included in
                            the Site as an additional service to users. The use
                            of Your Registration Information by these Third
                            Party Programs is described below in the "What about
                            information You enter into Third Party Programs
                            included in the Site?"
                            <br />
                            <br />
                            We may provide Your Registration Information to Our
                            marketing partners with whom You have explicitly
                            authorized Us to share this information, and whose
                            offers You have elected to receive via electronic
                            delivery, telemarketing and direct mail.
                            Additionally, We may employ other third parties to
                            perform services or functions on Our behalf in order
                            to improve Our Site, merchandising, marketing and
                            promotional efforts, communications or other
                            services, or to facilitate e-commerce transactions,
                            including processing orders placed by credit card.
                            Those third parties may include authorized
                            contractors, consultants and other companies working
                            with Us (collectively, "Agents"). These Agents only
                            have access to Your Personal Information as needed
                            to perform their functions, and they may not use any
                            of Your Personal Information for any other purpose
                            than providing or improving WapiDoc' services and
                            offerings.
                            <br />
                            <br />
                            WapiDoc also reserves the right to share or transfer
                            Your Registration Information or other Personal
                            Information as We determine in Our sole discretion
                            to be necessary or appropriate in the following
                            circumstances: to comply with a legal requirement;
                            to enforce Our terms and conditions; to protect Our
                            operations or those of any affiliate of WapiDoc; to
                            protect Our rights, privacy, safety or property, or
                            that of any affiliate of WapiDoc, You or others; to
                            disclose any activities or information about You to
                            law enforcement or other government officials,
                            including public or government authorities outside
                            Your country of residence; in connection with an
                            investigation of fraud, for the administration of
                            justice, intellectual property infringements or
                            other activity that is illegal or may expose Us or
                            You to legal liability; in cooperation with various
                            law enforcement inquiries; to allow Us to pursue
                            available remedies or limit Our damages; and/or in
                            emergency situations.
                            <br />
                            <br />
                            WapiDoc discloses Personal Information to third
                            parties or advertisers in aggregate form, that is,
                            in a manner that cannot be traced back to You. For
                            example, WapiDoc might tell an advertiser how many
                            males between 20 and 30 years in age have visited
                            the Site over a period of time
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            How does WapiDoc use and disclose Your Protected
                            Health Information?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            In certain contexts, WapiDoc' use and disclosure of
                            Your Protected Health Information is governed by
                            HIPAA.
                            <br />
                            <br />
                            When You use the Appointment Services to request an
                            appointment with a selected Healthcare Provider, all
                            Protected Health Information that You submit with
                            the Appointment Materials or created from Your use
                            of the Appointment Services is used and disclosed by
                            WapiDoc as a Business Associate (as defined by
                            HIPAA) according to the terms of a Business
                            Associate Agreement between Us and that Healthcare
                            Provider. This means that WapiDoc may only use and
                            disclose Your Protected Health Information on behalf
                            of, or to provide services to, the Healthcare
                            Provider for the appointment scheduling services
                            available through the Appointment Services according
                            to the terms of the Business Associate Agreement.
                            There are exceptions to this use and disclosure
                            rule, including that WapiDoc may use and disclose
                            Your Protected Health Information (i) for its
                            internal management and administration; (ii) to
                            carry out its legal responsibilities; and (iii) to
                            perform certain data aggregation services for the
                            Healthcare Provider and other Healthcare Providers;
                            provided that, any disclosures for Our internal
                            management and administration or to carry out Our
                            legal responsibilities are either required by law or
                            made after WapiDoc obtains reasonable assurances
                            from the person to whom the Protected Health
                            Information is disclosed that it will be held
                            confidentially and used or further disclosed only as
                            required by law or for the purpose for which it was
                            disclosed to that person.
                            <br />
                            <br />
                            Some of the appointment scheduling services
                            available through the Appointment Services for a
                            particular Healthcare Provider may be provided by
                            subcontractors of WapiDoc. The Subcontractor must
                            comply with the same terms and conditions for the
                            Protected Health Information that apply to WapiDoc
                            as a Business Associate of the Healthcare Provider.
                            <br />
                            <br />
                            The descriptions in this Privacy Policy of the HIPAA
                            responsibilities and obligations of WapiDoc and any
                            Subcontractor do not provide any right to You under
                            this Privacy Policy, HIPAA or any other law.
                            <br />
                            <br />
                            As a Business Associate of a particular Healthcare
                            Provider, WapiDoc may be permitted to de-identify
                            Your Protected Health Information created or
                            received by Us for that Healthcare Provider. This
                            De-Identified Information must be created according
                            to criteria prescribed by HIPAA so that there is no
                            reasonable basis to believe that this information
                            can be traced back to You. WapiDoc may use and
                            disclose any such De-Identified Information in the
                            same manner as Personal Information under this
                            Privacy Policy.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            What does WapiDoc do to keep Your Personal
                            Information and Protected Health Information Secure?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            On this Site and within WapiDoc, We seek to use
                            appropriate administrative, technical and physical
                            security measures that are designed to reasonably
                            safeguard Your Personal Information and Protected
                            Health Information under Our control.
                            <br />
                            <br />
                            Please be advised, however, that although WapiDoc
                            has endeavored to create a secure and reliable Site
                            for its users, the confidentiality and security of
                            any communication or material transmitted to or from
                            WapiDoc via this Site or e-mail cannot be guaranteed
                            to be 100% secure at any time. When disclosing any
                            Personal Information or Protected Health
                            Information, You should remain mindful of the fact
                            that it is potentially accessible to the public, and
                            consequently, can be collected and used by others
                            without Your consent. Accordingly, You should
                            consider carefully if You want to submit sensitive
                            information that You would not want disclosed to the
                            public and should recognize that Your use of the
                            Internet and this Site is solely at Your risk. You,
                            alone, are ultimately responsible for maintaining
                            the secrecy for all Your Personal Information
                            including Your Protected Health Information. WapiDoc
                            has no responsibility or liability to anyone for the
                            security of Your Protected Health Information or any
                            of Your Personal Information transmitted via the
                            Internet. WapiDoc urges all of its users to be
                            careful and responsible whenever they are on-line.
                            <br />
                            <br />
                            If You have any reason to believe that Your
                            interaction with WapiDoc through this Site is no
                            longer secure, please immediately notify Us of this
                            problem as described in the "Further questions or
                            comments?" section below.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            What Choices Do I Have Concerning the Use of
                            Personal Information?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You may opt-out:
                            <br />
                            <br />
                            From all future use and disclosure of Your Personal
                            Information for direct marketing purposes by
                            requesting the removal of Your Personal Information
                            from WapiDoc' database as described in the "Do You
                            have the ability to access, change or remove Your
                            Personal Information?" section below; or
                            <br />
                            <br />
                            From receiving future marketing-related e-mails from
                            WapiDoc by clicking on the "opt-out" link in any
                            e-mail sent to You by WapiDoc.
                            <br />
                            <br />
                            Please note that if You opt-out as described above,
                            WapiDoc will not be able to remove Your Personal
                            Information from the databases of Our affiliates or
                            unaffiliated third parties with whom We have already
                            shared Your Personal Information as of the date of
                            Your opt-out request. Please also note that if You
                            do opt-out of receiving marketing-related messages
                            from Us, WapiDoc may still send administrative
                            messages to You.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Do You have the ability to access, change or remove
                            Your Personal Information?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            At any time, You may contact WapiDoc via e-mail to
                            request:
                            <br />
                            <br />
                            A summary of any of Your Personal Information
                            retained by WapiDoc;
                            <br />
                            <br />A change to the Your Personal Information
                            maintained by WapiDoc; or
                            <br />
                            <br />
                            Removal of Your Personal Information from WapiDoc'
                            database.
                            <br />
                            <br />
                            You should send Your request to
                            privacypolicy@wapdoc.com. In the event WapiDoc
                            receives such a request, WapiDoc may require You to
                            confirm or verify any change to Your Personal
                            Information.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Can You opt-out of WapiDoc' use and disclosure of
                            Your Protected Health Information or access, change
                            or remove Your Protected Health Information?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            When You use the Appointment Services, You may
                            receive various administrative e-mails automatically
                            sent by WapiDoc. You cannot opt-out from receiving
                            any administrative e-mail sent by WapiDoc.
                            <br />
                            <br />
                            WapiDoc treats as Protected Health Information all
                            personally identifying information that You submit
                            with the Appointment Materials to request an
                            appointment with a selected Healthcare Provider and
                            that WapiDoc or a Subcontractor creates from Your
                            use of the Appointment Services. As described in the
                            "How does WapiDoc use and disclose Your Protected
                            Health Information?” section above, Protected Health
                            Information is used and disclosed by WapiDoc
                            according to the terms of its Business Associate
                            Agreement with Your selected Healthcare Provider. To
                            comply with HIPAA, Your Healthcare Provider must
                            provide You with rights in certain circumstances
                            with respect to Your Protected Health Information,
                            regardless of whether Your requested appointment is
                            scheduled or Your scheduled appointment is
                            fulfilled. Very generally described, these rights
                            include a right to restrict the uses and disclosures
                            of, a right of access to, a right to amend and a
                            right to receive an accounting of the disclosures of
                            Your Protected Health Information. These limited
                            rights will be described in detail in the Healthcare
                            Provider's notice of privacy practices. If You wish
                            to restrict the uses and disclosures of Your
                            Protected Health Information, amend, or receive an
                            accounting of the disclosures of Your Protected
                            Health Information, then You must do so through Your
                            Healthcare Provider. Upon termination of Our
                            Business Associate Agreement with a particular
                            Healthcare Provider, WapiDoc generally must return
                            or destroy all Protected Health Information received
                            on behalf of or created for that particular
                            Healthcare Provider and then maintained in any form
                            by WapiDoc or a Subcontractor. This means that until
                            the Business Associate Agreement is terminated with
                            that Healthcare Provider, WapiDoc or a Subcontractor
                            can use and disclose Your Protected Health
                            Information as described in the "How does WapiDoc
                            use and disclose Your Protected Health Information?"
                            section above following the termination of:
                            <br />
                            <br />
                            any Appointment ID Number, which You received upon
                            completion of the OAS scheduling process for the
                            Healthcare Provider;
                            <br />
                            <br />
                            Your account with the Healthcare Provider; the
                            WapiDoc' Limited License and User Agreement; and/or{" "}
                            <br />
                            <br />
                            this Privacy Policy.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            How to distinguish between Advertisements and
                            Editorial Content?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WapiDoc believes that it is important for You to
                            easily differentiate between advertising and
                            independent, editorial content on the Site; thus,
                            WapiDoc has developed an Advertising Policy that
                            explains WapiDoc' approach to ensure that the
                            advertising You see on the Site has no influence on
                            the quality, accuracy or selection of editorial
                            content that WapiDoc provides WapiDoc clearly
                            identifies all advertising on the Site. When WapiDoc
                            speaks of "advertising" or "advertisements," it
                            refers to all forms of advertising, sponsorships and
                            promotions for which WapiDoc receives compensation.
                            WapiDoc has established guidelines on how it labels
                            advertising so that You will know what is an
                            advertisement and what is editorial content. WapiDoc
                            uses clear and consistent language and visual cues
                            or descriptors to distinguish advertising from
                            editorial content.
                            <br />
                            <br />
                            The word "Advertisement" or the phrase "Advertise
                            With Us" is used to label the most common form of
                            advertising on the Site. The content of the banner
                            or similar advertisement itself is controlled by the
                            advertiser. An advertisement is labeled with the
                            word "Advertisement" or the phrase "Advertise With
                            Us" along the bottom or bottom right corner of the
                            advertisement. If You click on an advertisement, it
                            will usually link to a website that is operated by
                            the advertiser. As noted in the "What You should
                            know about external web sites?" section above,
                            WapiDoc has no control over the advertiser's website
                            or the policies on that site. You should familiarize
                            Yourself with the terms of use, privacy policies and
                            other policies applicable to those sites before
                            using them. The fact that an advertisement appears
                            on a page has no bearing or influence on the
                            editorial content on the page outside of the labeled
                            advertisement itself. Other content on the Site page
                            is under WapiDoc' editorial control.
                            <br />
                            <br />
                            The phrases "Provided By" and "Sponsored By" are
                            used to identify content that is created or
                            influenced by an advertiser. WapiDoc identifies
                            advertiser-influenced content as "Provided By" or
                            "Sponsored By" advertising so that You can
                            distinguish such content from editorially
                            independent content for which WapiDoc has sole
                            editorial control. You can recognize
                            advertiser-influenced content on the Site by use of
                            the "Provided By" or "Sponsored By" label and the
                            name of the advertiser that appears in the sponsored
                            content.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            What about information You enter into Third Party
                            Programs included in WapiDoc' Site?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            As an additional service to its users, WapiDoc has
                            included in its Site various programs and tools
                            created and administered by third parties ("Third
                            Party Programs") whereby, as examples, You can
                            receive useful items such as information concerning
                            potential diseases/conditions, information about
                            drug alternatives, and tools whereby You can
                            maintain records relating to Your condition. These
                            Third Party Programs are separate from, and do not
                            include, the Appointment Services. Your use of these
                            Third Party Programs is entirely optional and at
                            Your sole discretion. If You do choose to use them,
                            then some or all of these Third Party Programs may
                            require that You provide certain personally
                            identifying information that can be traced back to
                            You. This personally identifying information may
                            include, for example, age, current health condition,
                            insurance provider, medical history, prescription
                            history or other health and lifestyle data. If You
                            elect to use one or more of these Third Party
                            Programs, then any personally identifying
                            information that You provide as part of the Third
                            Party Program, as well as Your Registration
                            Information, will be available to the third party
                            administering that Third Party Program. By using any
                            Third Party Program, You acknowledge that any
                            personally identifying information and Registration
                            Information that You provide to the third party does
                            not constitute a violation of WapiDoc' Privacy
                            Policy. WapiDoc will maintain the privacy of Your
                            personally identifying information in WapiDoc'
                            possession in accordance with this Privacy Policy
                            and applicable state and federal privacy laws.
                            WapiDoc cannot guarantee that any third party will
                            maintain the privacy of Your personally identifying
                            information under any circumstance.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Further questions or comments?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WapiDoc welcomes your questions and comments about
                            this Privacy Policy or the use of Your Personal
                            Information or Protected Health Information. Please
                            send any questions or comments to WapiDoc to the
                            e-mail address privacypolicy@wapidoc.com
                        </Typography>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default PrivacyPolicyPage;
