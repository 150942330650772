import React from "react";
import { TextField } from "@mui/material";

export const DOB = ({ data, updateData }) => {
    return (
        <TextField
            variant="outlined"
            id="dob"
            name="dob"
            label="Date of Birth"
            type="date"
            defaultValue={data.dob}
            fullWidth
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(event) => {
                updateData({ ...data, dob: event.target.value });
            }}
        />
    );
};
