import React, { useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { Tooltip } from "@mui/material";
import jwtDecode from "jwt-decode";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { GetUserData } from "../../helpers/user";
import { gqlQuery } from "../../helpers/gql";

export const FavouritesButton = ({ id }) => {
    let USER = GetUserData(jwtDecode, useQuery, gql);
    const [favourites, setFavourites] = useState([]);

    useEffect(() => {
        if (!USER.loading) {
            setFavourites(USER.data.singleClient.favourites);
        }
    }, [USER.loading, !USER.loading ? USER.data.singleClient.favourites : []]);

    const updateFavourites = (id) => {
        let favs = favourites.includes(id)
            ? favourites.filter((x, i) => i !== favourites.indexOf(id))
            : favourites.concat([id]);

        setFavourites(favs);
        return favs;
    };

    const [execute] = useMutation(
        gql`
            ${gqlQuery("updateClient", gql)}
        `
    );

    return (
        <Tooltip
            title={
                favourites.includes(id)
                    ? "Remove from favourites"
                    : "Add to favourites"
            }
        >
            {favourites.includes(id) ? (
                <FavoriteIcon
                    style={{
                        margin: "0% 3% -2% 3%",
                        cursor: "pointer",
                        color: "red",
                    }}
                    onClick={() => {
                        execute({
                            variables: {
                                id: USER.data.singleClient._id,
                                data: { favourites: updateFavourites(id) },
                            },
                        });
                    }}
                />
            ) : (
                <FavoriteBorderIcon
                    style={{
                        margin: "0% 3% -2% 3%",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        execute({
                            variables: {
                                id: USER.data.singleClient._id,
                                data: { favourites: updateFavourites(id) },
                            },
                        });
                    }}
                />
            )}
        </Tooltip>
    );
};
