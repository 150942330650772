import { gqlQuery } from "./gql";
/**
 * Function that acquires client coordinates
 * @param {*} axios
 * @returns Client location coordinates
 */
export const getClientCoordinates = (axios) => {
    navigator.geolocation.getCurrentPosition(
        (position) => {
            localStorage.removeItem("coordinates");
            localStorage.setItem("coordinates", [
                position.coords.latitude,
                position.coords.longitude,
            ]);
        },
        (error) => {
            axios
                .get(
                    `"https://api.ipdata.co/?api-key=${process.env.REACT_APP_GOOGLE_API_KEY}"`
                )
                .then((position) => {
                    localStorage.removeItem("coordinates");
                    localStorage.setItem("coordinates", [
                        position.data.latitude,
                        position.data.longitude,
                    ]);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    );

    navigator.geolocation.watchPosition(
        (position) => {
            localStorage.removeItem("coordinates");
            localStorage.setItem("coordinates", [
                position.coords.latitude,
                position.coords.longitude,
            ]);
        },
        (error) => {
            axios
                .get(
                    `"https://api.ipdata.co/?api-key=${process.env.REACT_APP_GOOGLE_API_KEY}"`
                )
                .then((position) => {
                    localStorage.removeItem("coordinates");
                    localStorage.setItem("coordinates", [
                        position.data.latitude,
                        position.data.longitude,
                    ]);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    );

    return [
        parseFloat(localStorage.getItem("coordinates").split(",")[0]),
        parseFloat(localStorage.getItem("coordinates").split(",")[1]),
    ];
};

/**
 * Function that acquires a user IP
 * @param {*} axios
 * @returns User's Ip Address
 */
export const getClientIP = async (axios) => {
    const response = await axios.get(
        "https://api.ipdata.co/?api-key=" + process.env.REACT_APP_GOOGLE_API_KEY
    );

    return response.data.ip;
};

export const authProcess = (token) => {
    localStorage.setItem("token", token);

    const url = new URL(window.location);
    if (url.searchParams.get("redirect")) {
        window.location.replace(
            window.location.search.replace("?redirect=", "")
        );
    } else {
        window.location.replace("/");
    }
};

export const clientAuthorization = (jwtDecode, url = "") => {
    if (!localStorage.getItem("token")) {
        window.location.replace(
            `${process.env.REACT_APP_HOST}/signin/?redirect=${url}`
        );
    }

    const token = jwtDecode(localStorage.getItem("token"));
    if (expiredToken(token)) {
        window.location.replace(
            `${process.env.REACT_APP_HOST}/signin/?redirect=${url}`
        );
    }
};

export const expiredToken = (token) => {
    var current = new Date().getTime();
    current = current.toString();
    current = current.slice(0, -3);
    current = parseInt(current);

    if (current > token.exp) {
        return true;
    } else {
        return false;
    }
};

export const GetUserData = (jwtDecode, useQuery, gql) => {
    const tokenData = jwtDecode(localStorage.getItem("token"));

    let id = tokenData.data._id;

    let { data, loading, error } = useQuery(
        gql`
            ${gqlQuery("singleClient", gql)}
        `,
        {
            variables: {
                id,
            },
        }
    );

    return { data, loading, error };
};
