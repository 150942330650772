import React, { Component } from "react";
import axios from "axios";
import { withSnackbar } from "notistack";
import { postRequest } from "../../middleware/graphQl";
import Drawer from "../../components/drawer";
import {
    Grid,
    Typography,
    LinearProgress,
    Box,
    Button,
    IconButton,
    Tooltip,
} from "@material-ui/core";

import DeleteIcon from "@mui/icons-material/Delete";

class GalleryPage extends Component {
    state = {
        imageLoading: false,
        progress: 10,
        buffer: 10,
        institution: JSON.parse(localStorage.getItem("institution")),
    };

    uploadImages = (event) => {
        this.setState({ imageLoading: true });

        let slots = 12 - this.state.institution.photos.length;

        if (event.target.files.length > slots) {
            this.props.enqueueSnackbar(
                "Only " + slots + " images will be uploaded",
                {
                    variant: "info",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                }
            );
        } else {
            slots = event.target.files.length;
        }

        let count = 0;
        let images = this.state.institution.photos;
        for (let i = 0; i < slots; i++) {
            // check if image is larger that 2MB
            if (event.target.files[i] > 2000000) {
                this.props.enqueueSnackbar("Image larger than 2MB", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            } else if (event.target.files[i].type.split("/")[0] !== "image") {
                this.props.enqueueSnackbar("Not a valid image", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            } else {
                // file compression
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[i]);
                reader.onload = (result) => {
                    // send data to backend
                    axios
                        .post(
                            `${process.env.REACT_APP_HOST_API}/imagekit/upload`,
                            {
                                file: result.target.result,
                                fileName: this.state.institution.name + ".jpg",
                                folder: "/managers/institutions/",
                            },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization:
                                        "Bearer " +
                                        localStorage.getItem("managerToken"),
                                },
                            }
                        )
                        .then((response) => {
                            images.push(response.data.name);

                            this.setState((prevState) => ({
                                institution: {
                                    ...prevState.institution,
                                    photos: images,
                                    primary_photo: images[0],
                                },
                                //prettier-ignore
                                progress: this.state.progress + (100 / slots),
                            }));

                            if (i === slots - 1) {
                                this.setState({ imageLoading: false });
                                this.updateInstitution();
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                };
                reader.onerror = (error) => {
                    console.log("Error: ", error);
                };
                count = count + 1;
            }
        }
    };

    deleteImage = (photo) => {
        var index = this.state.institution.photos.indexOf(photo);
        if (index !== -1) {
            this.state.institution.photos.splice(index, 1);
        }

        this.updateInstitution();
        axios.get(`${process.env.REACT_APP_HOST_API}/imagekit/delete/${photo}`);
    };

    updateInstitution = () => {
        postRequest("updateInstitution", "manager", this.state.institution)
            .then((response) => {
                if (response.errors) {
                    this.props.enqueueSnackbar(response.errors[0].message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                    });
                } else {
                    this.setState({
                        institution: response.data.updateInstitution,
                    });

                    localStorage.setItem(
                        "institution",
                        JSON.stringify(response.data.updateInstitution)
                    );

                    this.props.enqueueSnackbar("Gallery update successfull", {
                        variant: "success",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    render() {
        let photos = this.state.institution.photos.map((photo) => {
            return (
                <Grid
                    item
                    xs={12}
                    md={3}
                    className={"gallery-image"}
                    key={photo}
                >
                    <div
                        style={{
                            background:
                                "url('https://ik.imagekit.io/9vm1rbs0vzz/managers/institutions/" +
                                photo +
                                "?ik-sdk-version=javascript-1.4.3&updatedAt=1642468057296')",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            height: "30vh",
                            borderRadius: "5px",
                            margin: "2%",
                        }}
                    >
                        {this.state.institution.primary_photo !== photo && (
                            <Tooltip title="Delete">
                                <IconButton
                                    aria-label="delete"
                                    className={"delete-button"}
                                    onClick={() => {
                                        this.deleteImage(photo);
                                    }}
                                >
                                    <DeleteIcon style={{ fontSize: "21px" }} />
                                </IconButton>
                            </Tooltip>
                        )}
                        {this.state.institution.primary_photo === photo ? (
                            <Button
                                className={"button"}
                                variant="contained"
                                disabled
                                style={{
                                    background: "#2E7D32",
                                    color: "#ffffff",
                                }}
                                disableElevation
                            >
                                Primary photo
                            </Button>
                        ) : (
                            <Button
                                className={"button image-button"}
                                variant="contained"
                                color="primary"
                                disableElevation
                                onClick={() => {
                                    this.setState(
                                        (prevState) => ({
                                            institution: {
                                                ...prevState.institution,
                                                primary_photo: photo,
                                            },
                                        }),
                                        () => {
                                            this.updateInstitution();
                                        }
                                    );
                                }}
                            >
                                Set as primary photo
                            </Button>
                        )}
                    </div>
                </Grid>
            );
        });

        return (
            <Drawer
                content={
                    <div>
                        {" "}
                        <Grid container style={{ padding: "2%" }}>
                            {this.state.imageLoading && (
                                <Box sx={{ width: "99%", margin: "1% auto" }}>
                                    <LinearProgress
                                        variant="buffer"
                                        value={this.state.progress}
                                        valueBuffer={this.state.buffer}
                                    />
                                </Box>
                            )}

                            {this.state.institution.photos.length < 1 &&
                                !this.state.imageLoading && (
                                    <label
                                        id="imageuploadsection"
                                        htmlFor="imageupload"
                                    >
                                        <input
                                            id="imageupload"
                                            type="file"
                                            multiple
                                            accept="image/*"
                                            onChange={(event) => {
                                                this.uploadImages(event);
                                            }}
                                        />
                                        <div style={{ padding: "8% 0%" }}>
                                            <Typography
                                                variant="h4"
                                                align="center"
                                            >
                                                Click or Drag and drop images to
                                                upload
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                align="center"
                                            >
                                                Max upload 12 images
                                            </Typography>
                                        </div>
                                    </label>
                                )}

                            {photos}

                            {this.state.institution.photos.length < 12 &&
                                this.state.institution.photos.length > 0 &&
                                !this.state.imageLoading && (
                                    <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        className={"gallery-image"}
                                    >
                                        <div
                                            style={{
                                                background:
                                                    "url('https://ik.imagekit.io/9vm1rbs0vzz/public/BulkImageUpload_FWym-0Qju3I.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642468057296')",
                                                backgroundPosition:
                                                    "center center",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "cover",
                                                height: "30vh",
                                                borderRadius: "5px",
                                                margin: "2%",
                                                border: "4px dotted #324C74",
                                            }}
                                        >
                                            <Typography
                                                variant="h5"
                                                align="center"
                                                style={{
                                                    padding: "10% 2%",
                                                }}
                                            >
                                                Click or Drag and drop images to
                                                upload
                                            </Typography>
                                            <input
                                                type="file"
                                                multiple
                                                accept="image/*"
                                                onChange={(event) => {
                                                    this.uploadImages(event);
                                                }}
                                                style={{
                                                    height: "30vh",
                                                    width: "100%",
                                                    opacity: "0",
                                                    zIndex: "1",
                                                    position: "absolute",
                                                    top: "0",
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                )}
                        </Grid>
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(GalleryPage);
