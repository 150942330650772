import React from "react";
import { Grid, Typography, Link, Chip } from "@material-ui/core";
import cogs from "../middleware/cogs";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import StarIcon from "@mui/icons-material/Star";

export const SearchResultCard = ({
    data: { _id, name, institution, location, gallery, averageRating, type },
    page,
}) => {
    return (
        <Grid item xs={12} md={page === "favourites" ? 3 : 4} key={_id}>
            <Link href={`../../institution/${_id}`}>
                <div className="institution-view">
                    <div
                        style={{
                            background:
                                "url('https://ik.imagekit.io/9vm1rbs0vzz/managers/institutions/" +
                                gallery.primaryPhoto +
                                "?ik-sdk-version=javascript-1.4.3&updatedAt=1642468057296')",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            height: "130px",
                            borderTopRightRadius: "5px",
                            borderTopLeftRadius: "5px",
                        }}
                    ></div>
                    <div className="institution-info">
                        <Grid container>
                            <Grid item xs={averageRating > 0 ? 10 : 12}>
                                <Typography variant="subtitle1" align="left">
                                    {institution !== undefined
                                        ? cogs.capitalize(institution)
                                        : cogs.capitalize(name)}
                                </Typography>
                            </Grid>
                            {averageRating > 0 && (
                                <Grid item xs={2} align="right">
                                    <div
                                        style={{
                                            display: "flex",
                                            marginTop: "5%",
                                        }}
                                    >
                                        <StarIcon
                                            style={{
                                                fontSize: "16px",
                                                marginTop: "4%",
                                                marginRight: "-2%",
                                                color: "#000000",
                                            }}
                                        />
                                        &nbsp;
                                        <Typography variant="subtitle2">
                                            {averageRating}
                                        </Typography>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                        <Typography variant="body2" align="left" gutterBottom>
                            {cogs.capitalize(location.ward)}
                            {", "}
                            {cogs.capitalize(location.constituency)}
                            {", "}
                            {cogs.capitalize(location.county)}
                        </Typography>
                        {institution !== undefined && (
                            <Typography
                                variant="subtitle2"
                                align="left"
                                gutterBottom
                            >
                                {cogs.capitalize(name)} Services
                            </Typography>
                        )}
                        <Grid container>
                            <Grid item xs={6}>
                                {page === "search" && (
                                    <Typography variant="body2">
                                        {location.gps.distance} KM
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={6} align="right">
                                <Chip
                                    icon={
                                        <StoreOutlinedIcon fontSize="small" />
                                    }
                                    size="small"
                                    label={cogs
                                        .capitalize(type)
                                        .replace("-", " ")}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Link>
        </Grid>
    );
};
