import React, { useState } from "react";
import {
    ListItemButton,
    ListItemText,
    Typography,
    ListItemIcon,
    Collapse,
    Grid,
    Button,
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Cancel from "@mui/icons-material/Cancel";
import insurances from "../../../pages/manager/insurance.json";

const InsuranceForm = ({ data, updateData }) => {
    const [show, setShow] = useState(false);

    return (
        <div>
            <ListItemButton
                onClick={() => setShow(!show)}
                style={{ padding: "1% 5%", width: "100%" }}
            >
                <ListItemText>
                    <Typography variant="subtitle1" align="left">
                        Insurance
                    </Typography>
                </ListItemText>
                <ListItemIcon>
                    {show ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
            </ListItemButton>
            <Collapse in={show} timeout="auto" unmountOnExit>
                <Grid item xs={12} style={{ padding: "1% 5%" }}>
                    {insurances.map((insurance) => {
                        return (
                            <Button
                                key={insurance}
                                style={{
                                    margin: ".8%",
                                    fontSize: "12px",
                                    borderColor: "#000000",
                                    color: `${
                                        data.insurance.includes(insurance)
                                            ? "white"
                                            : "black"
                                    }`,
                                    backgroundColor: `${
                                        data.insurance.includes(insurance)
                                            ? "#ce043d"
                                            : "white"
                                    }`,
                                }}
                                onClick={() =>
                                    updateData({
                                        ...data,
                                        insurance: data.insurance.includes(
                                            insurance
                                        )
                                            ? data.insurance.filter(
                                                  (x, i) =>
                                                      i !==
                                                      data.insurance.indexOf(
                                                          insurance
                                                      )
                                              )
                                            : data.insurance.concat([
                                                  insurance,
                                              ]),
                                    })
                                }
                                disableElevation
                                variant={
                                    data.insurance.includes(insurance)
                                        ? "contained"
                                        : "outlined"
                                }
                                endIcon={
                                    data.insurance.includes(insurance) ? (
                                        <Cancel style={{ fontSize: "17px" }} />
                                    ) : (
                                        false
                                    )
                                }
                            >
                                {insurance}
                            </Button>
                        );
                    })}
                </Grid>
            </Collapse>
        </div>
    );
};

export default InsuranceForm;
