import React, { useState, useEffect } from "react";
import { withSnackbar } from "notistack";
import { useQuery, useMutation, gql } from "@apollo/client";
import { Grid, Typography, Button } from "@mui/material";
import { Loading } from "../../components/loading";
import { gqlQueryManager } from "../../helpers/gqlManager";
import { Categories } from "../../components/forms/institution/onboarding/categories";
import { StepList } from "../../components/forms/institution/onboarding/stepList";
import { InstitutionInfo } from "../../components/forms/institution/onboarding/info";
import { InstitutionLocation } from "../../components/forms/institution/onboarding/location";
import { InstitutionContact } from "../../components/forms/institution/onboarding/contact";
import { InstitutionSocials } from "../../components/forms/institution/onboarding/socials";
import { InstitutionWHours } from "../../components/forms/institution/onboarding/workingHours";
import { InstitutionVHours } from "../../components/forms/institution/onboarding/visitingHours";
import { InstitutionConfirm } from "../../components/forms/institution/onboarding/confirm";
import { InstitutionInsurance } from "../../components/forms/institution/onboarding/insurance";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Onboarding = ({ match: { params }, enqueueSnackbar }) => {
    const [instData, updateData] = useState({});
    const [ready, setReady] = useState(false);

    let { loading } = useQuery(
        gql`
            ${gqlQueryManager("singleInstitution", gql)}
        `,
        {
            variables: {
                id: params.institution,
            },
            context: {
                clientName: "manager",
            },
            onCompleted: (data) => {
                updateData(data.singleInstitution);
            },
            onError: (error) => {
                enqueueSnackbar(error.message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            },
        }
    );

    const [execute] = useMutation(
        gql`
            ${gqlQueryManager("updateInstitution", gql)}
        `,
        {
            variables: {
                id: params.institution,
                data: instData,
            },
            context: {
                clientName: "manager",
            },
        }
    );

    useEffect(() => {
        ready &&
            execute()
                .then(({ data: { updateInstitution } }) => {
                    setReady(false);
                })
                .catch((error) => {
                    enqueueSnackbar(error.message, {
                        variant: "error",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                    });
                    setReady(false);
                });
    }, [execute, ready, instData, enqueueSnackbar]);

    return loading ? (
        <Loading />
    ) : (
        <div>
            {instData.status === "active" ? (
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        style={{
                            boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.1)",
                            borderRadius: "10px",
                            margin: "10% auto",
                            padding: "5%",
                            textAlign: "center",
                        }}
                    >
                        <CheckCircleIcon
                            style={{
                                marginBottom: "-1.2%",
                                color: "#008000",
                                fontSize: "6em",
                            }}
                        />

                        <Typography variant="h5" my={3} gutterBottom>
                            Your institution has been created successfully
                        </Typography>

                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            href={`${process.env.REACT_APP_HOST}/manager/dashboard`}
                            style={{
                                padding: "1.5% 3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                                marginLeft: "3%",
                                backgroundColor: "#008000",
                            }}
                        >
                            PROCEED TO DASHBOARD
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Grid container style={{ padding: "2%" }}>
                    <Grid item xs={12} md={3}>
                        <StepList data={instData} updateData={updateData} />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Typography variant="h4" textAlign={"center"}>
                            Welcome to WapiDoc onboarding
                        </Typography>
                        {instData.status === "category" && (
                            <Categories
                                industry={params.industry}
                                data={instData}
                                updateData={updateData}
                                setReady={setReady}
                            />
                        )}

                        {instData.status === "institution-info" && (
                            <InstitutionInfo
                                id={params.institution}
                                data={instData}
                                updateData={updateData}
                                setReady={setReady}
                            />
                        )}

                        {instData.status === "location" && (
                            <InstitutionLocation
                                id={params.institution}
                                data={instData}
                                updateData={updateData}
                                setReady={setReady}
                            />
                        )}

                        {instData.status === "contacts" && (
                            <InstitutionContact
                                id={params.institution}
                                data={instData}
                                updateData={updateData}
                                setReady={setReady}
                            />
                        )}

                        {instData.status === "insurance" && (
                            <InstitutionInsurance
                                data={instData}
                                updateData={updateData}
                                setReady={setReady}
                            />
                        )}
                        {instData.status === "social-media" && (
                            <InstitutionSocials
                                data={instData}
                                updateData={updateData}
                                setReady={setReady}
                            />
                        )}
                        {instData.status === "working-hours" && (
                            <InstitutionWHours
                                data={instData}
                                updateData={updateData}
                                setReady={setReady}
                            />
                        )}
                        {instData.status === "visiting-hours" &&
                            instData.type === "hospital" && (
                                <InstitutionVHours
                                    data={instData}
                                    updateData={updateData}
                                    setReady={setReady}
                                />
                            )}
                        {instData.status === "confirm-details" && (
                            <InstitutionConfirm
                                data={instData}
                                updateData={updateData}
                                setReady={setReady}
                            />
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default withSnackbar(Onboarding);
