import React from "react";
import { List, Divider } from "@mui/material";
import LocationForm from "../forms/filter/locations";
import InsuranceForm from "../forms/filter/insurance";
import DistanceForm from "../forms/filter/distance";
import TypesForm from "../forms/filter/types";
import RatingForm from "../forms/filter/rating";

export const Filter = ({ filterData, search, updateData, generateType }) => {
    return (
        <div>
            <List>
                <LocationForm data={filterData} updateData={updateData} />
                <InsuranceForm data={filterData} updateData={updateData} />
            </List>
            <Divider style={{ width: "90%", margin: "3% auto" }} />
            <DistanceForm data={filterData} updateData={updateData} />
            <Divider style={{ width: "90%", margin: "3% auto" }} />
            <TypesForm
                data={filterData}
                search={search}
                updateData={updateData}
                generateType={generateType}
            />
            <Divider style={{ width: "90%", margin: "3% auto" }} />
            <RatingForm data={filterData} updateData={updateData} />
        </div>
    );
};
