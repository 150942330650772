import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";

export const Institution = ({ data, updateData }) => {
    return (
        <TextField
            id="institution"
            name="institution"
            label="Institution Name"
            margin="normal"
            variant="outlined"
            placeholder="Institution XYZ"
            defaultValue={data.institution}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <StoreOutlinedIcon
                            fontSize="small"
                            style={{
                                color: "#939393",
                            }}
                        />
                    </InputAdornment>
                ),
            }}
            onChange={(event) => {
                updateData({ ...data, institution: event.target.value });
            }}
        />
    );
};
