import React from "react";
import {
    Grid,
    TextField,
    InputAdornment,
    Typography,
    Button,
} from "@mui/material";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";

export const InstitutionContact = ({ setReady, data, updateData }) => {
    let disabled =
        data.contact.phoneNumber.primary === "" ||
        data.contact.email.primary === "";

    return (
        <Grid container>
            <Grid item xs={12} md={8} style={{ margin: "0px auto" }}>
                <Typography
                    variant="body2"
                    textAlign={"center"}
                    style={{
                        color: "#888",
                        fontSize: "24px",
                        padding: "3% 0%",
                    }}
                >
                    Contact Information
                </Typography>

                <TextField
                    id="primaryPhone"
                    name="primaryPhone"
                    label="Primary Phone"
                    margin="normal"
                    variant="outlined"
                    placeholder="Enter phone number"
                    value={data.contact.phoneNumber.primary}
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <PhoneOutlinedIcon
                                    fontSize="small"
                                    style={{ color: "#939393" }}
                                />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event) => {
                        updateData({
                            ...data,
                            contact: {
                                ...data.contact,
                                phoneNumber: {
                                    ...data.contact.phoneNumber,
                                    primary: event.target.value,
                                },
                            },
                        });
                    }}
                />
                <TextField
                    id="secondaryPhone"
                    name="secondaryPhone"
                    label="Secondary Phone (Optional)"
                    margin="normal"
                    variant="outlined"
                    placeholder="Enter phone number"
                    value={data.contact.phoneNumber.secondary}
                    fullWidth
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <PhoneOutlinedIcon
                                    fontSize="small"
                                    style={{ color: "#939393" }}
                                />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event) => {
                        updateData({
                            ...data,
                            contact: {
                                ...data.contact,
                                phoneNumber: {
                                    ...data.contact.phoneNumber,
                                    secondary: event.target.value,
                                },
                            },
                        });
                    }}
                />
                <TextField
                    id="primaryEmail"
                    name="primaryEmail"
                    label="Primary Email"
                    margin="normal"
                    variant="outlined"
                    placeholder="Enter email address"
                    value={data.contact.email.primary}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <AlternateEmailOutlinedIcon
                                    fontSize="small"
                                    style={{ color: "#939393" }}
                                />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event) => {
                        updateData({
                            ...data,
                            contact: {
                                ...data.contact,
                                email: {
                                    ...data.contact.email,
                                    primary: event.target.value,
                                },
                            },
                        });
                    }}
                />
                <TextField
                    id="secondary Email"
                    name="secondary Email (Optional)"
                    label="Secondary  Email"
                    margin="normal"
                    variant="outlined"
                    placeholder="Enter email address"
                    value={data.contact.email.seconadry}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <AlternateEmailOutlinedIcon
                                    fontSize="small"
                                    style={{ color: "#939393" }}
                                />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(event) => {
                        updateData({
                            ...data,
                            contact: {
                                ...data.contact,
                                email: {
                                    ...data.contact.email,
                                    secondary: event.target.value,
                                },
                            },
                        });
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="address"
                            name="address"
                            label="Address (Optional)"
                            margin="normal"
                            variant="outlined"
                            placeholder="1234"
                            type="number"
                            value={data.contact.address.split("-")[0]}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <span
                                            style={{
                                                color: "#222222",
                                            }}
                                        >
                                            P.O BOX
                                        </span>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <MailOutlinedIcon
                                            fontSize="small"
                                            style={{
                                                color: "#939393",
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                updateData({
                                    ...data,
                                    contact: {
                                        ...data.contact,
                                        address:
                                            event.target.value +
                                            "-" +
                                            data.contact.address.split("-")[1],
                                    },
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="postalCode"
                            name="postalCode"
                            label="Postal Code (Optional)"
                            margin="normal"
                            variant="outlined"
                            placeholder="00100"
                            type="number"
                            value={data.contact.address.split("-")[1]}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <MailOutlinedIcon
                                            fontSize="small"
                                            style={{
                                                color: "#939393",
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                updateData({
                                    ...data,
                                    contact: {
                                        ...data.contact,
                                        address:
                                            data.contact.address.split("-")[0] +
                                            "-" +
                                            event.target.value,
                                    },
                                });
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} my>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            style={{
                                backgroundColor: "#444444",
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    status: "location",
                                });
                            }}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            disabled={disabled}
                            style={{
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                                marginLeft: "0%",
                                backgroundColor: `${
                                    !disabled ? "#ce043d" : "#E0E0E0"
                                }`,
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    status: "insurance",
                                });
                                setReady(true);
                            }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
