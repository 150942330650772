import React, { Component } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { withSnackbar } from "notistack";
import PasswordStrengthBar from "react-password-strength-bar";
import { Menu } from "../../components/menu/menu";
import cogs from "../../middleware/cogs";

import {
    Grid,
    CircularProgress,
    Button,
    InputAdornment,
    TextField,
    Box,
    Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";

class RequirementsPage extends Component {
    state = {
        buttonLoading: false,
        password: "",
        confirmPassword: "",
        user_id: "",
        user: "",
        success: false,
        errors: {
            password: false,
            confirmPassword: false,
        },
        error_message: "",
        error: false,
    };

    componentDidMount = () => {
        let token = jwt_decode(this.props.match.params.token);

        if (!cogs.expiredToken(token) && typeof token.user_id == "string") {
            this.setState({ user_id: token.user_id, user: token.user });
        } else {
            window.location.replace(`${process.env.REACT_APP_HOST}/signin`);
        }

        document.addEventListener("keyup", (event) => {
            if (this.state.password !== "" && this.state.confirmPassword) {
                if (event.keyCode === 13) {
                    this.changePassword();
                }
            }
        });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    changePassword = async () => {
        let errors = this.state.errors;
        let error = false;

        // password
        if (this.state.password === "") {
            errors["password"] = true;
            error = true;
        } else {
            if (this.state.password.length < 8) {
                error = true;
                errors["password"] = true;
                this.setState({ error_message: "Password is too short." });
            } else {
                errors["password"] = false;
            }
        }

        // confirm password
        if (this.state.confirmPassword === "") {
            errors["confirmPassword"] = true;
            error = true;
        } else {
            if (this.state.confirmPassword !== this.state.password) {
                error = true;
                errors["confirmPassword"] = true;
                this.setState({ error_message: "Passwords are not similar" });
            } else {
                errors["confirmPassword"] = false;
            }
        }

        this.setState({
            errors: errors,
            error: error,
        });

        if (!error) {
            const response = await axios.post(
                `${process.env.REACT_APP_HOST_API}/query/user/change-password/`,
                {
                    user_id: this.state.user_id,
                    user: this.state.user,
                    password: this.state.password,
                }
            );

            if (response.data.error) {
                this.props.enqueueSnackbar(response.data.error, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            } else {
                this.props.enqueueSnackbar(response.data.success, {
                    variant: "success",
                });

                window.location.replace(
                    `${process.env.REACT_APP_HOST}${
                        this.state.user === "client" ? "/" : "/manager/"
                    }signin`
                );
            }
        }
    };

    render() {
        return (
            <div>
                <Menu background={"transparent"} />
                <Grid
                    container
                    style={{ margin: "1% auto" }}
                    className={"add-space"}
                >
                    <Grid
                        item
                        xs={12}
                        md={4}
                        style={{ padding: "5%", margin: "1% auto" }}
                        className={"add-space"}
                    >
                        <Box my={3}>
                            <Typography variant="h5">
                                Change your password
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                In order to protect your account, remember to
                                use a strong, secure password.
                            </Typography>
                        </Box>
                        {this.state.error && (
                            <Alert
                                severity="error"
                                style={{ margin: "1.5% 0%" }}
                            >
                                {this.state.error_message === ""
                                    ? "Kindly fill in the highlighted fields."
                                    : this.state.error_message}
                            </Alert>
                        )}
                        <TextField
                            error={this.state.errors.password}
                            id="password"
                            name="password"
                            label="Password"
                            margin="normal"
                            variant="outlined"
                            placeholder="****"
                            type="password"
                            value={this.state.password}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LockOpenOutlinedIcon
                                            fontSize="small"
                                            style={{ color: "#939393" }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={this.handleChange}
                        />
                        <Typography variant="body1">
                            <PasswordStrengthBar
                                minLength={8}
                                password={this.state.password}
                            />
                        </Typography>

                        <TextField
                            error={this.state.errors.confirmPassword}
                            id="confirmPassword"
                            name="confirmPassword"
                            label="confirmPassword"
                            margin="normal"
                            variant="outlined"
                            placeholder="****"
                            type="password"
                            value={this.state.confirmPassword}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LockOpenOutlinedIcon
                                            fontSize="small"
                                            style={{ color: "#939393" }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={this.handleChange}
                        />
                        <Button
                            className="submit-button"
                            variant="contained"
                            fullWidth
                            onClick={() => this.changePassword()}
                            style={{ padding: "3%" }}
                        >
                            {!this.state.buttonLoading ? (
                                "Change Password"
                            ) : (
                                <CircularProgress
                                    size={24}
                                    style={{ color: "#ffffff" }}
                                />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withSnackbar(RequirementsPage);
