import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import {
    Grid,
    Typography,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tabs,
    Tab,
    Button,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

class RequirementsPage extends Component {
    state = {
        value: 0,
        category: "Hospital",
        pricing: 10000,
        //prettier-ignore
        monthly: (10000 * .15) + 10000,
        //prettier-ignore
        quarterly: (10000 * 12) / 4,
        //prettier-ignore
        biAnnual: ((10000 * 6) - ((10000 * 6) * .15)),
        //prettier-ignore
        yearly: (10000 * 12) - ((10000 * 12) * 0.25),
    };

    updateTable = () => {
        let category;
        let pricing;

        if (this.state.value === 0) {
            category = "Hospital";
            pricing = 10000;
        } else if (this.state.value === 1) {
            category = "Medical Center";
            pricing = 5000;
        } else if (this.state.value === 2) {
            category = "Pharmacy";
            pricing = 3000;
        } else if (this.state.value === 3) {
            category = "Clinic";
            pricing = 3000;
        } else if (this.state.value === 4) {
            category = "Consultant";
            pricing = 3000;
        } else if (this.state.value === 5) {
            category = "Online Doctor";
            pricing = 1000;
        } else if (this.state.value === 6) {
            category = "Mobile Doctor";
            pricing = 1000;
        }

        this.setState({
            category: category,
            pricing: pricing,
            //prettier-ignore
            monthly: (pricing * .15) + pricing,
            //prettier-ignore
            quarterly: (pricing * 12) / 4,
            //prettier-ignore
            biAnnual: ((pricing * 6) - ((pricing * 6) * .15)),
            //prettier-ignore
            yearly: (pricing * 12) - ((pricing * 12) * .25),
        });
    };

    addEarlyBirdDiscount = (amount) => {
        return Math.round(amount - amount * 0.25);
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>Pricing</title>
                    <meta
                        name="description"
                        content="Ready to get started with WapiDoc?"
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid container className={"pricing-table"}>
                    <Grid item xs={12} align="center" className={"add-space"}>
                        <Box my={3}>
                            <Typography variant="h3" component="h1">
                                Ready to get started with WapiDoc?
                            </Typography>
                            <br />
                            <br />
                            <Typography
                                variant="body2"
                                style={{
                                    color: "#888",
                                    fontSize: "24px",
                                }}
                            >
                                Choose the package that suits you.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={2} mt={5}>
                            <Tabs
                                value={this.state.value}
                                onChange={(event, value) => {
                                    this.setState(
                                        {
                                            value: value,
                                        },
                                        () => {
                                            this.updateTable();
                                        }
                                    );
                                }}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable force tabs example"
                            >
                                <Tab label="Hospital" />
                                <Tab label="Medical Center" />
                                <Tab label="Pharmacy" />
                                <Tab label="Clinic" />
                                <Tab label="Consultant" />
                                <Tab label="Online Doctor" />
                                <Tab label="Mobile Doctor" />
                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid container style={{ padding: "5%" }}>
                        <Grid item xs={12} md={4}>
                            <Box className="price-card" m={2}>
                                <Box p={2} className="header" py={5} px={4}>
                                    <Typography
                                        variant="h4"
                                        style={{ color: "#ffffff" }}
                                    >
                                        Quarterly
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        style={{ color: "#ffffff" }}
                                    >
                                        {" "}
                                        KES{" "}
                                        {this.state.quarterly
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </Typography>
                                    <br />
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: "#ffffff",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {this.state.category} Category
                                    </Typography>
                                </Box>
                                <Box p={2}>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Dashboard with realtime metrics.
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {this.state.category ===
                                                    "Hospital" ||
                                                this.state.category ===
                                                    "Medical Center"
                                                    ? "Unlimited services."
                                                    : "One service"}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Unlimited sub services.
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Customizable gallery.
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Lifetime log storage.
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box className="price-card" m={2}>
                                <Box p={2} className="header" py={5} px={4}>
                                    <Typography
                                        variant="h4"
                                        style={{ color: "#ffffff" }}
                                    >
                                        Bi-Annual
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        style={{ color: "#ffffff" }}
                                    >
                                        {" "}
                                        KES{" "}
                                        {this.state.biAnnual
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </Typography>
                                    <br />
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: "#ffffff",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {this.state.category} Category
                                    </Typography>
                                </Box>
                                <Box p={2}>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Dashboard with realtime metrics.
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {this.state.category ===
                                                    "Hospital" ||
                                                this.state.category ===
                                                    "Medical Center"
                                                    ? "Unlimited services."
                                                    : "One service"}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Unlimited sub services.
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Customizable gallery.
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Lifetime log storage.
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box className="price-card">
                                <Box p={2} className="header" py={5} px={4}>
                                    <Typography
                                        variant="body2"
                                        align="center"
                                        style={{
                                            margin: "-5% 0% 6% 0%",
                                            background: "#F5E633",
                                            borderRadius: "60px",
                                            width: "60%",
                                        }}
                                    >
                                        RECOMMENDED
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        style={{ color: "#ffffff" }}
                                    >
                                        Yearly
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        style={{ color: "#ffffff" }}
                                    >
                                        {" "}
                                        KES{" "}
                                        {this.state.yearly
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </Typography>
                                    <br />
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: "#ffffff",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {this.state.category} Category
                                    </Typography>
                                </Box>
                                <Box p={2}>
                                    <List>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Dashboard with realtime metrics.
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {this.state.category ===
                                                    "Hospital" ||
                                                this.state.category ===
                                                    "Medical Center"
                                                    ? "Unlimited services."
                                                    : "One service"}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Unlimited sub services.
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Customizable gallery.
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <CheckIcon />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Lifetime log storage.
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        align="center"
                        style={{
                            padding: "5%",
                            margin: "5%",
                            background: "#cf053e",
                        }}
                        className={"card"}
                    >
                        <Typography
                            variant="body1"
                            style={{
                                color: "#ffffff",
                                fontSize: "20px",
                            }}
                        >
                            Looking to get started
                        </Typography>
                        <Typography variant="h3" style={{ color: "#ffffff" }}>
                            Register now
                        </Typography>
                        <Button
                            className=""
                            variant="outlined"
                            href="/manager/signup"
                            style={{
                                padding: "1% 2%",
                                border: "1px solid #ffffff",
                                color: "#ffffff",
                                margin: "3% 1%",
                            }}
                        >
                            Get Started
                        </Button>
                    </Grid>
                </Grid>

                <Footer />
            </div>
        );
    }
}

export default RequirementsPage;
