import React, { Component } from "react";
import axios from "axios";
import { withSnackbar } from "notistack";
import { Menu } from "../../components/menu/menu";

import {
    Grid,
    CircularProgress,
    Button,
    InputAdornment,
    TextField,
    Box,
    Typography,
    Divider,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

class RequirementsPage extends Component {
    state = {
        buttonLoading: false,
        email: "",
        success: false,
        errors: {
            email: false,
        },
        error_message: "",
        error: false,
    };

    componentDidMount = () => {
        document.addEventListener("keyup", (event) => {
            if (this.state.email !== "") {
                if (event.keyCode === 13) {
                    this.sendEmail();
                }
            }
        });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    sendEmail = async () => {
        let errors = this.state.errors;
        let error = false;
        let error_message = "";

        // email
        if (this.state.email === "") {
            errors["email"] = true;
            error = true;
        } else if (
            !this.state.email.match(
                // eslint-disable-next-line
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            )
        ) {
            errors["email"] = true;
            error = true;
            error_message = "Not a valid email address";
        } else {
            errors["email"] = false;
        }

        this.setState({
            errors: errors,
            error: error,
            error_message: error_message,
        });

        if (!error) {
            const response = await axios.post(
                `${process.env.REACT_APP_HOST_API}/query/user/validate-email/`,
                {
                    email: this.state.email,
                    user: this.props.match.params.user,
                }
            );

            if (response.data.error) {
                this.props.enqueueSnackbar(response.data.error, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            } else {
                this.setState({
                    success: true,
                });
            }
        }
    };

    render() {
        return (
            <div>
                <Menu background={"transparent"} />
                <Grid
                    container
                    style={{ paddingTop: "5%", margin: "1% auto" }}
                    className={"add-space"}
                >
                    <Grid
                        item
                        xs={12}
                        md={4}
                        style={{ padding: "5%", margin: "1% auto" }}
                        className={"add-space"}
                    >
                        {this.state.success ? (
                            <div>
                                <Typography
                                    variant="h6"
                                    style={{
                                        color: "#5DC726",
                                    }}
                                >
                                    <CheckCircleIcon
                                        style={{ marginBottom: "-1.2%" }}
                                    />{" "}
                                    An email is on the way
                                </Typography>
                                <Divider style={{ margin: "2% 0%" }} />
                                <Typography variant="body1">
                                    An email reset link has just been sent to
                                    your email account{" "}
                                    <b style={{ fontWeight: "600" }}>
                                        {this.state.email}.
                                    </b>{" "}
                                    Kindly click on the link to finish the reset
                                    process.
                                </Typography>
                            </div>
                        ) : (
                            <div>
                                <Box my={3}>
                                    <Typography variant="h5">
                                        Reset your password
                                    </Typography>
                                    <br />
                                    <Typography variant="body1">
                                        To reset your password, enter your email
                                        below and submit. An email will be sent
                                        to you with instructions about how to
                                        complete the process.
                                    </Typography>
                                </Box>
                                {this.state.error && (
                                    <Alert
                                        severity="error"
                                        style={{ margin: "1.5% 0%" }}
                                    >
                                        {this.state.error_message === ""
                                            ? "Kindly fill in the highlighted fields."
                                            : this.state.error_message}
                                    </Alert>
                                )}
                                <TextField
                                    error={this.state.errors.email}
                                    id="email"
                                    name="email"
                                    label="Email"
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="johndoe@example.com"
                                    value={this.state.email}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <AlternateEmailOutlinedIcon
                                                    fontSize="small"
                                                    style={{ color: "#939393" }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={this.handleChange}
                                />
                                <Button
                                    className="submit-button"
                                    variant="contained"
                                    fullWidth
                                    onClick={() => this.sendEmail()}
                                    style={{ padding: "3%" }}
                                >
                                    {!this.state.buttonLoading ? (
                                        "Reset Password"
                                    ) : (
                                        <CircularProgress
                                            size={24}
                                            style={{ color: "#ffffff" }}
                                        />
                                    )}
                                </Button>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withSnackbar(RequirementsPage);
