import React, { Component } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";
import { postRequest } from "../middleware/graphQl";
import cogs from "../middleware/cogs";
import { withSnackbar } from "notistack";

import {
    Grid,
    TextField,
    Typography,
    Link,
    Button,
    // ListItem,
    ListItemText,
    MenuList,
    MenuItem,
    ListItemIcon,
    // Chip,
} from "@material-ui/core";

// import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import SearchIcon from "@material-ui/icons/Search";

class HomePage extends Component {
    state = {
        institutions: [],
        keyword: "",
        insurance: "insurance-cover",
        error: false,
    };

    componentDidMount = () => {
        cogs.getLocation(axios);
        // this.getInstitutions();

        document.addEventListener("keyup", (event) => {
            if (event.keyCode === 13) {
                this.search();
            }
        });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    getInstitutions = async () => {
        const response = await postRequest("institutions", "client");

        if (response.errors) {
            this.setState({ loading: false });
            this.props.enqueueSnackbar(response.errors[0].message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
        } else {
            this.setState({
                institutions: response.data.institutions.slice(0, 4),
            });
        }
    };

    getAverageRating(ratings, avg = true) {
        let totalRatings = 0;
        let count = 0;
        let avgRating = 0;

        if (ratings.length > 0) {
            for (const i in ratings) {
                totalRatings += ratings[i].rating;
                count += 1;
            }

            avgRating = Math.round((totalRatings / count) * 10) / 10;
        }

        return avgRating;
    }

    search = async () => {
        if (this.state.keyword === "") {
            this.setState({ error: true });
        } else {
            window.location.replace(
                `${process.env.REACT_APP_HOST}/search?search=service&keyword=${this.state.keyword}`
            );
        }
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>WapiDoc: Your Health Care Directory</title>
                    <meta
                        name="description"
                        content="WapiDoc is the leading online resource for users to
                            find and connect with the right health care
                            institutions."
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid container>
                    <Grid item xs={12} className="welcome-section">
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <MenuList className="side-menu">
                                    <MenuItem
                                        component="a"
                                        href={`${process.env.REACT_APP_HOST}/search?search=institution&keyword=`}
                                        key="managers"
                                    >
                                        <ListItemIcon>
                                            <img
                                                src={
                                                    "https://ik.imagekit.io/9vm1rbs0vzz/public/hospital_uzHHZTvYb.png"
                                                }
                                                alt="Hospitals"
                                                width="40px"
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    paddingLeft: "10%",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                Hospitals
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        component="a"
                                        href={`${process.env.REACT_APP_HOST}/search?search=pharmacy&keyword=`}
                                        key="pharmacy"
                                    >
                                        <ListItemIcon>
                                            <img
                                                src={
                                                    "https://ik.imagekit.io/9vm1rbs0vzz/public/medicines_mzDO4q_Nf.png"
                                                }
                                                alt="Pharmacy"
                                                width="40px"
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    paddingLeft: "10%",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                Pharmacies
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        component="a"
                                        href={`${process.env.REACT_APP_HOST}/search?search=specialist&keyword=`}
                                        key="specialist"
                                    >
                                        <ListItemIcon>
                                            <img
                                                src={
                                                    "https://ik.imagekit.io/9vm1rbs0vzz/public/specialist_td58H3WVg.png"
                                                }
                                                alt="Specialist"
                                                width="40px"
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    paddingLeft: "10%",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                Specialists
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem>
                                    <MenuItem
                                        component="a"
                                        href={`${process.env.REACT_APP_HOST}/search?search=onlineMobile&keyword=`}
                                        key="onlineMobile"
                                    >
                                        <ListItemIcon>
                                            <img
                                                src={
                                                    "https://ik.imagekit.io/9vm1rbs0vzz/public/online_specialist_mu-ZmDF0R.png"
                                                }
                                                alt="onlineMobile"
                                                width="40px"
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    paddingLeft: "10%",
                                                    fontSize: "18px",
                                                }}
                                            >
                                                Online & Mobile
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem>
                                    {/* <MenuItem
                                        component="a"
                                        href="#"
                                        key="insurance"
                                        className="inactive"
                                        disableTouchRipple
                                    >
                                        <ListItemIcon>
                                            <img
                                                src={"../images/insurance.svg"}
                                                alt="insurance"
                                                width="40px"
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography
                                                variant="subtitle1"
                                                style={{
                                                    paddingLeft: "10%",
                                                }}
                                            >
                                                Health Insurance
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem> */}
                                    {/* <MenuItem
                                        component="a"
                                        href="#"
                                        key="medicine"
                                        className="inactive"
                                        disableTouchRipple
                                    >
                                        <ListItemIcon>
                                            <img
                                                src={"../images/medicine.svg"}
                                                alt="dental"
                                                width="40px"
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography
                                                variant="subtitle1"
                                                style={{
                                                    paddingLeft: "10%",
                                                }}
                                            >
                                                Medicine
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem> */}
                                    {/* <MenuItem
                                        component="a"
                                        href="#"
                                        key="products"
                                        className="inactive"
                                        disableTouchRipple
                                    >
                                        <ListItemIcon>
                                            <img
                                                src={"../images/products.svg"}
                                                alt="products"
                                                width="40px"
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography
                                                variant="subtitle1"
                                                style={{
                                                    paddingLeft: "10%",
                                                }}
                                            >
                                                Products
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem> */}
                                    {/* <MenuItem
                                        component="a"
                                        href="#"
                                        key="equipments"
                                        className="inactive"
                                        disableTouchRipple
                                    >
                                        <ListItemIcon>
                                            <img
                                                src={"../images/equipments.svg"}
                                                alt="equipments"
                                                width="40px"
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography
                                                variant="subtitle1"
                                                style={{
                                                    paddingLeft: "10%",
                                                }}
                                            >
                                                Equipments
                                            </Typography>
                                        </ListItemText>
                                    </MenuItem> */}
                                </MenuList>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container className="search-form">
                                    <Grid
                                        item
                                        xs={9}
                                        md={8}
                                        style={{ padding: "0% 5px" }}
                                    >
                                        <TextField
                                            error={this.state.error}
                                            id="keyword"
                                            name="keyword"
                                            margin="normal"
                                            placeholder="health service"
                                            variant="outlined"
                                            fullWidth
                                            onChange={this.handleChange}
                                            style={{
                                                backgroundColor: "#ffffff",
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        md={4}
                                        style={{ padding: "0px 5px" }}
                                    >
                                        <Button
                                            className="submit-button"
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => this.search()}
                                        >
                                            <span className={"wide-screen"}>
                                                Search &nbsp;
                                            </span>
                                            <SearchIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* <Grid
                        container
                        style={{
                            padding: "3%",
                        }}
                    >
                        <Grid container style={{ margin: "2% 1%" }}>
                            <Grid item xs={10}>
                                <Typography variant="h5">
                                    Heath Care Institutions Near You
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" align="right">
                                    <Link href="categories">View All</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                        {institutions}
                    </Grid> */}
                    <Grid
                        container
                        style={{ padding: "3%", background: "#f7f7f7" }}
                    >
                        <Grid container style={{ margin: "2% 1%" }}>
                            <Grid item xs={10}>
                                <Typography variant="h5">
                                    Top Searched Categories
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                {/* <Typography variant="body2" align="right">
                                    <Link href="search">View All</Link>
                                </Typography> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={4} md={2} align="center">
                            <Link href="/search/service/dental">
                                <div className="category">
                                    <img
                                        src={"../images/dental.png"}
                                        alt="dental"
                                        width="100%"
                                    />
                                    <br />
                                    <Typography variant="body1" align="center">
                                        Dental
                                    </Typography>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item xs={4} md={2} align="center">
                            <Link href="/search/service/gynacology">
                                <div className="category">
                                    <img
                                        src={"../images/obgyn.png"}
                                        alt="Gaenecology"
                                        width="100%"
                                    />
                                    <br />
                                    <Typography variant="body1" align="center">
                                        Gynaecology
                                    </Typography>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item xs={4} md={2} align="center">
                            <Link href="/search/service/psychiatry">
                                <div className="category">
                                    <img
                                        src={"../images/psychiatry.png"}
                                        alt="Psychiatry"
                                        width="100%"
                                    />
                                    <br />
                                    <Typography variant="body1" align="center">
                                        Psychiatry
                                    </Typography>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item xs={4} md={2} align="center">
                            <Link href="/search/service/diabetes">
                                <div className="category">
                                    <img
                                        src={"../images/diabetes.png"}
                                        alt="diabetes"
                                        width="100%"
                                    />
                                    <br />
                                    <Typography variant="body1" align="center">
                                        Diabetes
                                    </Typography>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item xs={4} md={2} align="center">
                            <Link href="/search/service/diabetes">
                                <div className="category">
                                    <img
                                        src={"../images/blood-pressure.png"}
                                        alt="Cardiology"
                                        width="100%"
                                    />
                                    <br />
                                    <Typography variant="body1" align="center">
                                        Cardiology
                                    </Typography>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item xs={4} md={2} align="center">
                            <Link href="/search/service/optical">
                                <div className="category">
                                    <img
                                        src={"../images/optical.png"}
                                        alt="Optical"
                                        width="100%"
                                    />
                                    <br />
                                    <Typography variant="body1" align="center">
                                        Optical
                                    </Typography>
                                </div>
                            </Link>
                        </Grid>
                    </Grid>
                    {/* <Grid container style={{ padding: "5%" }}>
                        <Grid item xs={12} style={{ paddingBottom: "5%" }}>
                            <Typography variant="h5">
                                Find health care providers by location
                            </Typography>
                        </Grid>

                        {countyList}
                    </Grid> */}
                    {/* <Grid container style={{ padding: "6% 0%" }}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h3"
                                align="center"
                                style={{ color: "#cf053e" }}
                            >
                                1,000+{" "}
                                <span style={{ color: "#999" }}>
                                    User Registrations / day
                                </span>
                            </Typography>
                        </Grid>
                    </Grid> */}

                    <Grid container style={{ padding: "5%" }}>
                        <Grid item xs={12} md={6}>
                            <img
                                src={
                                    "../images/WapiDocHealthCareInstitutionOwner.svg"
                                }
                                alt="WapiDoc Health Care Institution Owner"
                                width="90%"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: "0% 2%" }}>
                            <Typography variant="h4" align="left">
                                Are you a health care institution owner?
                            </Typography>
                            <br />
                            <Typography
                                variant="body1"
                                gutterBottom
                                paragraph
                                align="left"
                            >
                                The WapiDoc search allows for localized searches
                                by service, provider or product. It allows for
                                use of different search prompts that accommodate
                                “non-medical” locally adopted medical dialect
                                which is ultimately directted to the appropriate
                                search.
                            </Typography>

                            <Typography
                                variant="body1"
                                gutterBottom
                                paragraph
                                align="left"
                            >
                                Multilevel search is also accommodated in the
                                interface to allow for as customized a search as
                                possible. The search will always give the
                                provider nearest to the client. This ensures
                                that the provider will be found by the client
                                for whom they were set for in that locality.
                            </Typography>

                            <Button
                                className="submit-button"
                                disableElevation
                                variant="contained"
                                href={`managers`}
                                style={{ padding: "2% 5%" }}
                            >
                                Join our community
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default withSnackbar(HomePage);
