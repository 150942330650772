import React, { useState } from "react";
import axios from "axios";
import { useQuery, gql } from "@apollo/client";
import { Grid } from "@mui/material";
import { SearchResultCard } from "../containers/searchResultCard";
import { SkeletonLoader } from "../components/search/skeletonLoader";
import { Filter } from "../components/search/filter";
import { EmptyData } from "../components/search/empty";
import { Menu } from "../components/menu/menu";
import { getClientCoordinates } from "../helpers/user";
import { gqlQuery } from "../helpers/gql";
import { SearchBar } from "../components/search/bar";

/**
 * Function that generates the right title
 * @param {institution} search
 * @returns Hospitals
 */
const generateTitle = (search) => {
    let title = [];
    switch (search) {
        case "institution":
            title = "hospitals";
            break;
        case "pharmacy":
            title = "pharmacies";
            break;
        case "specialist":
            title = "specialists";
            break;
        case "onlineMobile":
            title = "online & mobile services";
            break;
        default:
            title = "facilities";
            break;
    }

    return title;
};

/**
 * Function that uses the search query and returns types array
 * @param {institution} search
 * @returns ["medical-center", "hospital", "clinic"]
 */
const generateType = (search) => {
    let types = [];
    switch (search) {
        case "institution":
            types = ["medical-center", "hospital", "clinic"];
            break;
        case "pharmacy":
            types = ["pharmacy"];
            break;
        case "specialist":
            types = ["consultant"];
            break;
        case "onlineMobile":
            types = ["online", "mobile"];
            break;
        default:
            types = [
                "medical-center",
                "hospital",
                "clinic",
                "pharmacy",
                "consultant",
                "online",
                "mobile",
            ];
            break;
    }

    return types;
};

export default function SearchPage() {
    const url = new URL(window.location);
    const params = url.searchParams;
    const [keyword, setKeyword] = useState(params.get("keyword"));

    const title = generateTitle(params.get("search"));

    const [filterData, updateData] = useState({
        type: generateType(params.get("search")),
        county: "",
        constituency: "",
        ward: "",
        insurance: [],
        distance: 1000,
        rating: 0,
    });

    let userData = {
        gps: getClientCoordinates(axios),
        userIP: "127.0.0.1",
        userID: "123",
    };

    let apolloQuery =
        params.get("search") === "service"
            ? "searchService"
            : "searchInstitution";

    let { data, loading, error } = useQuery(
        gql`
            ${gqlQuery(apolloQuery, gql)}
        `,
        {
            variables: {
                keyword: keyword,
                filterData: {
                    ...filterData,
                    distance: filterData.distance * 1000,
                },
                userData: userData,
            },
        }
    );

    const parseData = (results, loading) => {
        return (
            <div>
                <Menu />
                <Grid
                    container
                    style={{
                        padding: "10% 1% 5% 1%",
                        height: "100vh",
                        overflowY: "scroll",
                    }}
                >
                    <Grid item md={3} className="filter">
                        <Filter
                            filterData={filterData}
                            search={params.get("search")}
                            updateData={updateData}
                            generateType={generateType}
                            filterBox={true}
                        />
                    </Grid>
                    <Grid item md={9} className="search-results">
                        <Grid container>
                            <Grid item md={12} align="center">
                                <SearchBar
                                    url={url}
                                    keyword={keyword}
                                    setKeyword={setKeyword}
                                    params={params}
                                    title={title}
                                />
                            </Grid>

                            {results.length > 0 ? (
                                results.map((result) => {
                                    return loading ? (
                                        <SkeletonLoader key={result} />
                                    ) : (
                                        <SearchResultCard
                                            key={result._id}
                                            data={result}
                                            page="search"
                                        />
                                    );
                                })
                            ) : (
                                <EmptyData params={params} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    };

    if (loading) return parseData([...Array(12).keys()], loading);

    if (error) return <pre>{error.message}</pre>;

    return parseData(data[apolloQuery], false);
}
