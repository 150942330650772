import React, { Component } from "react";
import axios from "axios";
import Drawer from "../../components/drawer";

import {
    Typography,
    CircularProgress,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";

class LogPage extends Component {
    state = {
        logs: [],
        loading: true,
    };

    componentDidMount = () => {
        axios
            .get(
                `${process.env.REACT_APP_HOST_API}/query/logs/manager/${
                    JSON.parse(localStorage.getItem("manager"))._id
                }`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + localStorage.getItem("managerToken"),
                    },
                }
            )
            .then((response) => {
                this.setState({ logs: response.data, loading: false });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    render() {
        let logs = this.state.logs.map((log) => {
            return (
                <TableRow key={log._id}>
                    <TableCell align="left">
                        <Typography variant="body2" align="left">
                            MANAGER
                        </Typography>
                    </TableCell>
                    <TableCell align="left">
                        <Typography variant="body2" align="left">
                            {JSON.parse(localStorage.getItem("manager"))._id}
                        </Typography>
                    </TableCell>
                    <TableCell align="left">
                        <Typography variant="body2" align="left">
                            {log.action.toUpperCase()}
                            {log.action !== "logged in" && "D"}
                        </Typography>
                    </TableCell>
                    <TableCell align="left">
                        <Typography variant="body2">
                            {log.item.toUpperCase()}
                        </Typography>
                    </TableCell>
                    <TableCell align="left">
                        <Typography variant="body2">{log.item_id}</Typography>
                    </TableCell>
                    <TableCell align="left">
                        <Typography variant="body2">{log.createdAt.replace("T", " ").slice(0, -5)}</Typography>
                    </TableCell>
                </TableRow>
            );
        });

        return (
            <Drawer
                content={
                    <div>
                        {!this.state.loading ? (
                            <TableContainer
                                style={{
                                    borderRadius: "5px",
                                    margin: "3% auto",
                                    backgroundColor: "#ffffff",
                                    boxShadow:
                                        "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                                }}
                            >
                                <Table
                                    size="small"
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                <Typography
                                                    variant="subtitle1"
                                                    align="left"
                                                >
                                                    <b>MANAGER</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography
                                                    variant="subtitle1"
                                                    align="left"
                                                >
                                                    <b>MANAGER ID</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography
                                                    variant="subtitle1"
                                                    align="left"
                                                >
                                                    <b>ACTION</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography
                                                    variant="subtitle1"
                                                    align="left"
                                                >
                                                    <b>ITEM</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography
                                                    variant="subtitle1"
                                                    align="left"
                                                >
                                                    <b>ITEM ID</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography
                                                    variant="subtitle1"
                                                    align="left"
                                                >
                                                    <b>TIMESTAMP</b>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>{logs}</TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    height: "85vh",
                                }}
                            >
                                <CircularProgress
                                    size={45}
                                    style={{
                                        marginTop: "20%",
                                        borderRadius: "100%",
                                        padding: ".5%",
                                        boxShadow:
                                            "0rem 0rem 1rem rgba(0, 0, 0, 0.15)",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                }
            />
        );
    }
}

export default LogPage;
