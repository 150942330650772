import React from "react";
import { Grid, Typography, Chip, Divider } from "@mui/material";
import cogs from "../../middleware/cogs";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import StarIcon from "@mui/icons-material/Star";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ButtonList } from "../../components/institution/buttonList";
import { capitalizeFL } from "../../helpers/string";
import { FavouritesButton } from "../../components/institution/favouritesButton";

const isOpen = (workingHours) => {
    const d = new Date();
    let day = d.getDay();
    let hour = d.getHours();

    let hours = "";
    switch (day) {
        case 6:
            hours = workingHours.saturday.split("-");
            break;
        case 7:
            hours = workingHours.sunday.split("-");
            break;
        default:
            hours = workingHours.weekday.split("-");
            break;
    }

    let opens = parseInt(hours[0].split(":")[0]);
    let closes = parseInt(hours[1].split(":")[0]) + 12;

    switch (hours.toString()) {
        case "00:00,00:00":
            return "Open";
        case "__:__,__:__":
            return "Closed";
        default:
            return hour > opens && hour < closes ? "Open" : "Closed";
    }
};

export const InfoCard = ({
    data: {
        _id,
        name,
        type,
        description,
        location,
        averageRating,
        contact,
        links,
        hours: { workingHours },
        institution,
        reviews,
    },
    page,
}) => {
    return (
        <div className="card">
            <Grid container>
                <Grid item xs={12} md={7}>
                    <Typography variant="h4" align="left" gutterBottom>
                        {cogs.capitalize(
                            page === "institution" ? name : institution.name
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5} align="right">
                    <Chip
                        style={{ margin: "0% 2%" }}
                        icon={<StoreOutlinedIcon fontSize="small" />}
                        size="small"
                        elevation={3}
                        label={cogs.capitalize(type.replace("-", " "))}
                    />
                    <Chip
                        style={{ margin: "0% 2%" }}
                        icon={<AccessTimeIcon fontSize="small" />}
                        size="small"
                        label={isOpen(workingHours)}
                    />
                    {averageRating > 0 && (
                        <Chip
                            style={{ margin: "0% 2%" }}
                            icon={<StarIcon fontSize="small" />}
                            size="small"
                            label={`${averageRating} (${reviews.length})`}
                        />
                    )}
                    {page === "institution" && <FavouritesButton id={_id} />}
                </Grid>
                <Grid item xs={12}>
                    {location.streetAddress.street !== "" && (
                        <Typography variant="h6" align="left" gutterBottom>
                            {capitalizeFL(location.streetAddress.street)}
                            {", "}
                            {location.streetAddress.building}
                            {", "}
                            {location.streetAddress.floor}
                            {", "}
                            {location.streetAddress.unit}
                        </Typography>
                    )}

                    <Typography variant="paragraph" align="left" gutterBottom>
                        {cogs.capitalize(location.ward)}
                        {", "}
                        {cogs.capitalize(location.constituency)}
                        {", "}
                        {cogs.capitalize(location.county)}
                    </Typography>
                </Grid>
            </Grid>
            {page === "service" && (
                <Typography variant="h6" align="left" gutterBottom>
                    {cogs.capitalize(name)} Services
                </Typography>
            )}
            <Divider
                light={true}
                style={{
                    margin: "1% 0%",
                    width: "100%",
                }}
            />
            <Grid container>
                <Typography variant="body1" paragraph align="left">
                    {description}
                </Typography>
                <Grid item xs={8} md={10}></Grid>
            </Grid>
            <Divider
                style={{
                    margin: "1% 0%",
                    width: "100%",
                }}
            />
            <ButtonList
                phone={contact.phoneNumber.primary}
                email={contact.email.primary}
                website={links.website}
                coordinates={location.gps.coordinates}
            />
        </div>
    );
};
