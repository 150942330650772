import React from "react";
import { Grid, Button } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import PublicIcon from "@mui/icons-material/Public";
import AssistantIcon from "@mui/icons-material/Assistant";
import DirectionsIcon from "@mui/icons-material/Directions";

export const ButtonList = ({ phone, email, website, coordinates }) => {
    return (
        <Grid container>
            <Button
                aria-label="call-now"
                href={`tel:${phone}`}
                variant="text"
                startIcon={<CallIcon />}
                style={{ marginRight: "2%", color: "#000000" }}
            >
                Call Now
            </Button>
            <Button
                aria-label="send email"
                href={`mailto:${email}`}
                target="_blank"
                variant="text"
                startIcon={<MailIcon />}
                style={{ marginRight: "2%", color: "#000000" }}
            >
                Send Email
            </Button>
            {website !== "" && (
                <Button
                    aria-label="visit website"
                    href={website}
                    target="_blank"
                    variant="text"
                    startIcon={<PublicIcon />}
                    style={{ marginRight: "2%", color: "#000000" }}
                >
                    Visit Website
                </Button>
            )}
            <Button
                aria-label="leave a review"
                variant="text"
                startIcon={<AssistantIcon />}
                style={{ marginRight: "2%", color: "#000000" }}
            >
                Leave a review
            </Button>
            <Button
                aria-label="get-directions"
                href={`https://www.google.com/maps/dir/?saddr=My+Location&daddr=${coordinates[0]},${coordinates[1]}`}
                target="_blank"
                variant="text"
                startIcon={<DirectionsIcon />}
                style={{ marginRight: "2%", color: "#000000" }}
            >
                Get&nbsp;Directions
            </Button>
        </Grid>
    );
};
