import React from "react";
// import { compose, withProps, lifecycle } from "recompose";
// import {
//     withGoogleMap,
//     GoogleMap,
//     Marker,
// } from "react-google-maps";
// import axios from "axios";
// import cogs from "../middleware/cogs";

// const MapComponent = compose(
//     withProps({
//         googleMapURL:
//             "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHw-lEDaCiG2Xu56wVkhaOyPdPtf-Lxys&libraries=geometry,drawing,places",
//         loadingElement: <div style={{ height: `100%` }} />,
//         containerElement: <div style={{ height: `300px` }} />,
//         mapElement: <div style={{ height: `100%` }} />,
//     }),
//     lifecycle({
//         componentWillMount() {
//             const refs = {};

//             this.setState({
//                 position: null,
//                 onMarkerMounted: (ref) => {
//                     refs.marker = ref;
//                 },

//                 onPositionChanged: () => {
//                     const position = refs.marker.getPosition();
//                     localStorage.removeItem("coordinates");
//                     localStorage.setItem(
//                         "coordinates",
//                         position.toString().replace("(", "").replace(")", "")
//                     );
//                     document.getElementById("coordinates").value = position
//                         .toString()
//                         .replace("(", "")
//                         .replace(")", "");
//                 },
//                 coordinates:
//                     localStorage.getItem("coordinates") === null
//                         ? cogs.getLocation(axios)
//                         : localStorage.getItem("coordinates").split(","),
//             });
//         },
//     }),
//     withScriptjs,
//     withGoogleMap
// )((props) => (
//     <div>
//         <GoogleMap
//             defaultZoom={8}
//             defaultCenter={{
//                 lat: Number(props.coordinates[0]),
//                 lng: Number(props.coordinates[1]),
//             }}
//         >
//             {props.isMarkerShown && (
//                 <Marker
//                     position={{
//                         lat: Number(props.coordinates[0]),
//                         lng: Number(props.coordinates[1]),
//                     }}
//                     draggable={true}
//                     ref={props.onMarkerMounted}
//                     onPositionChanged={props.onPositionChanged}
//                 />
//             )}
//         </GoogleMap>
//     </div>
// ));

export default function MapWrapper(props, coordinates) {
    return (
        <div>
            red
            {/* <MapComponent isMarkerShown={true} /> */}
        </div>
    );
}
