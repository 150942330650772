import React from "react";
import { Typography } from "@mui/material";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

export const InsuranceList = ({ insurances }) => {
    return insurances.length > 0 ? (
        insurances.map((insurance) => {
            return (
                <Typography
                    variant="body1"
                    key={insurance}
                    style={{ margin: "1% 0%" }}
                >
                    <SupervisedUserCircleIcon
                        size={"small"}
                        style={{ color: "#000000", marginBottom: "-.6%" }}
                    />{" "}
                    {insurance}
                </Typography>
            );
        })
    ) : (
        <img
            style={{
                width: "35%",
                height: "auto",
                display: "flex",
                margin: "5% auto",
            }}
            src={
                "https://ik.imagekit.io/9vm1rbs0vzz/public/Empty_sK1ZlJORw.svg"
            }
            alt="No Data"
        />
    );
};
