import React, { useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { Helmet } from "react-helmet";
import jwtDecode from "jwt-decode";
import {
    Grid,
    Typography,
    Alert,
    Button,
    CircularProgress,
} from "@mui/material";
import { gqlQuery } from "../helpers/gql";
import { GetUserData, clientAuthorization } from "../helpers/user";
import { Menu } from "../components/menu/menu";
import { Loading } from "../components/loading";
import { FirstName } from "../components/forms/user/firstName";
import { LastName } from "../components/forms/user/lastName";
import { DOB } from "../components/forms/user/dob";
import { Gender } from "../components/forms/user/gender";
import { Location } from "../components/forms/user/locations";

export default function ProfilePage() {
    clientAuthorization(jwtDecode, window.location);

    const [response, setResponse] = useState({ message: "", type: "" });
    const [btnLoad, setButtonLoad] = useState(false);
    const [user, setUser] = useState({});

    let { loading, error, data } = GetUserData(jwtDecode, useQuery, gql);

    useEffect(() => {
        if (data) {
            setUser({
                firstName: data.singleClient.firstName,
                lastName: data.singleClient.lastName,
                dob: data.singleClient.dob,
                gender: data.singleClient.gender,
                county: data.singleClient.county,
                constituency: data.singleClient.constituency,
                ward: data.singleClient.ward,
            });
        }
    }, [data]);

    if (error) setResponse({ message: error.message, type: "error" });

    const [execute] = useMutation(
        gql`
            ${gqlQuery("updateClient", gql)}
        `
    );

    return (
        <div>
            <Helmet>
                <title>Profile</title>
                <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
            </Helmet>
            <Menu />
            {user.firstName !== undefined && (
                <Grid
                    container
                    className={"add-space"}
                    style={{
                        paddingTop: "8%",
                    }}
                >
                    <Grid
                        className={"add-space"}
                        item
                        xs={12}
                        md={6}
                        style={{
                            margin: "2% auto",
                            backgroundColor: "#ffffff",
                            padding: "2%",
                            borderRadius: "10px",
                            boxShadow: "0 0.1rem 1rem rgba(0, 0, 0, 0.15)",
                        }}
                    >
                        <Typography variant="h5" align="center">
                            Update Profile
                        </Typography>
                        {response.message !== "" && (
                            <Alert
                                severity={
                                    response.type === "error"
                                        ? "error"
                                        : "success"
                                }
                            >
                                {response.message}
                            </Alert>
                        )}
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FirstName data={user} updateData={setUser} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LastName data={user} updateData={setUser} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <DOB data={user} updateData={setUser} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Gender data={user} updateData={setUser} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Location
                                    data={user}
                                    updateData={setUser}
                                    page="profile"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            fullWidth
                            id="submit"
                            className="send-data-button"
                            variant="contained"
                            disableElevation
                            style={{
                                backgroundColor: "#ce043d",
                            }}
                            onClick={() => {
                                setButtonLoad(true);
                                setResponse({ message: "", type: "" });
                                execute({
                                    variables: {
                                        id: data.singleClient._id,
                                        data: user,
                                    },
                                }).then(() => {
                                    setButtonLoad(false);
                                    setResponse({
                                        message: "Update successful",
                                        type: "success",
                                    });
                                });
                            }}
                        >
                            {!btnLoad ? (
                                "Update Info"
                            ) : (
                                <CircularProgress
                                    size={24}
                                    style={{ color: "#ffffff" }}
                                />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            )}
            {loading && <Loading />}
        </div>
    );
}
