import React, { useCallback } from "react";
import { Marker, GoogleMap, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: "10px",
    margin: "2% 0%",
};

export const InstitutionMap = ({ data, updateData }) => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: `${process.env.REACT_APP_MAPS_API_KEY}`,
    });

    const [, setMap] = React.useState(null);

    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds({
            lat: data.location.gps.coordinates[0],
            lng: data.location.gps.coordinates[1],
        });

        map.fitBounds(bounds);
        setMap(map);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={8}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker
                position={{
                    lat: data.location.gps.coordinates[0],
                    lng: data.location.gps.coordinates[1],
                }}
                draggable={true}
                onDrag={(e) => {
                    updateData({
                        ...data,
                        location: {
                            ...data.location,
                            gps: {
                                ...data.location.gps,
                                coordinates: [e.latLng.lat(), e.latLng.lng()],
                            },
                        },
                    });
                }}
            />
        </GoogleMap>
    ) : (
        <p>Loading...</p>
    );
};
