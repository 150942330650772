import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import { Grid, Typography, Divider } from "@material-ui/core";

class AboutUsPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>About us</title>
                    <meta
                        name="description"
                        content="We build strong relationships by creating a
                                network for healthcare. Creating a culture of
                                innovation in which our highly talented team
                                strives to deliver."
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid container>
                    <Grid container className="about-us">
                        <Grid item xs={12} md={6} style={{ padding: "20% 5%" }}>
                            <Typography
                                variant="body1"
                                gutterBottom
                                align="left"
                                style={{
                                    fontSize: "20px",
                                    color: "#ffffff",
                                }}
                            >
                                ABOUT US
                            </Typography>
                            <Typography
                                variant="h2"
                                align="left"
                                style={{
                                    color: "#ffffff",
                                }}
                            >
                                WapiDoc - Your Health Care Directory.
                            </Typography>
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                    <Grid container className="vision-mission">
                        <Grid item xs={12} md={6} align="center">
                            <img
                                src={"../images/Vaccinated.jpg"}
                                alt="Equity"
                                width="70%"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ paddingTop: "10%" }}>
                            <Typography variant="h6" gutterBottom>
                                We build strong relationships by creating a
                                network for healthcare. Creating a culture of
                                innovation in which our highly talented team
                                strives to deliver.
                            </Typography>
                            <Divider style={{ margin: "5% 0%" }} />
                            <Typography
                                variant="body1"
                                align="left"
                                paragraph
                                gutterBottom
                            >
                                <b>Vision: </b>To be the most preferred source
                                of health care information in Kenya.
                            </Typography>
                            <br />
                            <Typography
                                variant="body1"
                                align="left"
                                paragraph
                                gutterButtom
                            >
                                <b>Mission: </b>To provide the widest accessible
                                comprehensive digital listing platform of health
                                services, providers and products in Kenya.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className="core-values">
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                            >
                                Core Values
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} align="center">
                            <img
                                src={"../images/equity.png"}
                                alt="Equity"
                                width="20%"
                            />
                            <Typography variant="h6">Equity</Typography>
                            <Typography variant="body1" paragraph gutterBottom>
                                At WapiDoc, we believe no one shoe fits all and
                                purpose to actively engage with different
                                players in the health industry to eliminate
                                barriers to opportunities
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} align="center">
                            <img
                                src={"../images/diversity.png"}
                                alt="Diversity"
                                width="20%"
                            />
                            <Typography variant="h6">Diversity</Typography>
                            <Typography variant="body1" paragraph gutterBottom>
                                WapiDoc recognizes that all of the ways in which
                                people differ create crucial diversity of
                                thought, ideas, perspectives and values, and as
                                such we are able to consider how our own
                                perspectives and biases impact our work, and
                                learn how to actively seek out and learn from
                                the voices of others
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} align="center">
                            <img
                                src={"../images/inclusivity.png"}
                                alt="Equity"
                                width="20%"
                            />
                            <Typography variant="h6">Inclusivity</Typography>
                            <Typography variant="body1" paragraph gutterBottom>
                                We create an environment in which any individual
                                or group can be and feel welcomed, respected,
                                supported, and valued to fully participate in
                                the health industry. We allow everyone to bring
                                their full, authentic selves to their work.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            xs={0}
                            md={6}
                            className="about-directory"
                        ></Grid>
                        <Grid item xs={12} md={6} style={{ padding: "5%" }}>
                            <Typography variant="h4">Why WapiDoc</Typography>
                            <br />
                            <br />
                            <Typography variant="body1" paragraph gutterBottom>
                                WapiDoc is a directory of all health services,
                                providers and products in Kenya. It provides a
                                listing for all preventive, promotive, curative,
                                diagnostic (laboratory, radiology), forensic,
                                psychosocial, palliative, aesthetic,
                                physiotherapy, occupational therapy, geriatric
                                care, counselling, international health,
                                insurance and rehabilitative services.
                                <br />
                                <br /> The listing includes all cadres in the
                                healthcare industry. wapiDoc lists all health
                                care products including machines and equipment,
                                pharmaceuticals and medical non-pharmaceutical
                                commodities. The interface with the client will
                                be user-friendly. It will allow searches using
                                different prompts, redirect searches accordingly
                                to allow maximum mining of available information
                                while at the same time narrowing to information
                                relevant to the original search.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default AboutUsPage;
