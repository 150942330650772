import React from "react";
import {
    Grid,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { capitalize } from "../../../helpers/string";

const TypesForm = ({ data, search, updateData, generateType }) => {
    return (
        <Grid item xs={12} style={{ padding: "1% 5%" }}>
            <Typography variant="subtitle1" align="left">
                Type
            </Typography>
            <FormGroup>
                {generateType(search).map((type) => {
                    return (
                        <FormControlLabel
                            key={type}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={
                                        data.type.includes(type) ? true : false
                                    }
                                    style={{ color: "#ce043d" }}
                                />
                            }
                            label={
                                <Typography variant="body2" align="left">
                                    {capitalize(type.replace("-", " "))}
                                </Typography>
                            }
                            onChange={() => {
                                updateData({
                                    ...data,
                                    type: data.type.includes(type)
                                        ? data.type.filter(
                                              (x, i) =>
                                                  i !== data.type.indexOf(type)
                                          )
                                        : data.type.concat([type]),
                                });
                            }}
                        />
                    );
                })}
            </FormGroup>
        </Grid>
    );
};

export default TypesForm;
