import React from "react";
import { Grid, Typography } from "@mui/material";
import { capitalize } from "../../helpers/string";

const getDay = (day) => {
    let result =
        day === "monday" ||
        day === "tuesday" ||
        day === "wednesday" ||
        day === "thursday" ||
        day === "friday"
            ? "weekday"
            : day;

    return result;
};

export const HoursList = ({ hours, type }) => {
    const days =
        type === "working"
            ? [
                  "monday",
                  "tuesday",
                  "wednesday",
                  "thursday",
                  "friday",
                  "saturday",
                  "sunday",
                  "holiday",
              ]
            : ["morning", "afternoon", "evening"];

    return type === "visiting" && hours["morning"] === "" ? (
        <img
            style={{
                width: "35%",
                height: "auto",
                display: "flex",
                margin: "5% auto",
            }}
            src={
                "https://ik.imagekit.io/9vm1rbs0vzz/public/Empty_sK1ZlJORw.svg"
            }
            alt="No Data"
        />
    ) : (
        days.map((day) => {
            return (
                <Typography variant="body1" key={day} style={{ margin: "1%" }}>
                    <Grid container>
                        <Grid item xs={2}>
                            {capitalize(day)}
                        </Grid>
                        <Grid item xs={3}>
                            {hours[getDay(day)].split("-")[0] === "00:00"
                                ? "24/7"
                                : hours[getDay(day)].split("-")[0] === "__:__"
                                ? "Closed"
                                : `${hours[getDay(day)].split("-")[0]}
                            AM - 
                            ${hours[getDay(day)].split("-")[1]} PM`}
                        </Grid>
                    </Grid>
                </Typography>
            );
        })
    );
};
