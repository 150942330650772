export const postRequest = async (type, user, data = {}) => {
    let body = queryType(type, data);
    let response;

    await fetch(`${process.env.REACT_APP_HOST_API}/api`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem(user + "Token"),
        },
    })
        .then((response) => response.json())
        .then((data) => {
            response = data;
        });
    return response;
};

export const queryType = (type, data = {}) => {
    if (type === "institutions") {
        return {
            query: `
                    query {
                        institutions {
                            _id
                            name
                            description
                            type
                            county
                            constituency
                            ward
                            primary_phone
                            secondary_phone
                            primary_email
                            secondary_email
                            primary_photo
                            address
                            linkedin
                            facebook
                            instagram
                            twitter
                            youtube
                            website
                            visiting_hours
                            working_hours
                            insurance
                            subscription
                            registration_status
                            registration_comment
                            last_payment_date
                            photos
                            primary_photo
                            status
                        }
                    }
                `,
        };
    } else if (type === "myInstitutions") {
        return {
            query: `
                    query {
                        myInstitutions {
                            _id
                            name
                            description
                            type
                            county
                            constituency
                            ward
                            primary_phone
                            secondary_phone
                            primary_email
                            secondary_email
                            primary_photo
                            address
                            linkedin
                            facebook
                            instagram
                            twitter
                            youtube
                            website
                            visiting_hours
                            working_hours
                            insurance
                            gprs
                            subscription
                            registration_status
                            registration_comment
                            last_payment_date
                            photos
                            primary_photo
                            status
                        }
                    }
                `,
        };
    } else if (type === "singleInstitution") {
        return {
            query: `
                    query{
                        singleInstitution(_id:"${data.id}"){
                            _id
                            name
                            description
                            type
                            county
                            constituency
                            ward
                            primary_phone
                            secondary_phone
                            primary_email
                            secondary_email
                            address
                            linkedin
                            facebook
                            instagram
                            twitter
                            youtube
                            website
                            working_hours
                            visiting_hours
                            insurance
                            gprs
                            subscription
                            registration_status
                            registration_comment
                            last_payment_date
                            photos
                            primary_photo
                            rating
                            ratings{
                                _id
                                rating
                                comment
                                service{
                                    _id
                                }
                                client{
                                    _id
                                    first_name
                                    last_name
                                    photo
                                }
                            }
                            status
                        }
                    }
                `,
        };
    } else if (type === "updateInstitution") {
        console.log(data);
        return {
            query: `
                    mutation{
                        updateInstitution(_id:"${data._id}",institutionUpdate:{
                            name:"${data.name}"
                            description:"${data.description.replace(
                                /(\r\n|\n|\r)/gm,
                                ""
                            )}"
                            type:"${data.type}"
                            county:"${data.county}"
                            constituency:"${data.constituency}"
                            ward:"${data.ward}"
                            primary_phone:"${data.primary_phone}",
                            secondary_phone:"${data.secondary_phone}",
                            primary_email:"${data.primary_email}"
                            secondary_email:"${data.secondary_email}"
                            address:"${data.address}-${data.postal_code}"
                            linkedin:"${data.linkedin}"
                            facebook:"${data.facebook}"
                            instagram:"${data.instagram}"
                            youtube:"${data.youtube}"
                            twitter:"${data.twitter}"
                            website:"${data.website}"
                            working_hours:${JSON.stringify(data.working_hours)}
                            visiting_hours:${JSON.stringify(
                                data.visiting_hours
                            )}
                            insurance:${JSON.stringify(data.insurance)}
                            gprs:[${data.gprs}]
                            subscription:"${data.subscription}"
                            photos:${JSON.stringify(data.photos)}
                            primary_photo:"${data.primary_photo}"
                        }){
                            _id
                            name
                            description
                            type
                            county
                            constituency
                            ward
                            primary_phone
                            secondary_phone
                            primary_email
                            secondary_email
                            address
                            linkedin
                            facebook
                            instagram
                            youtube
                            twitter
                            website
                            visiting_hours
                            working_hours
                            insurance
                            gprs
                            subscription
                            registration_status
                            registration_comment
                            last_payment_date
                            photos
                            primary_photo
                            status
                        }
                    }
                `,
        };
    } else if (type === "createInstitution") {
        return {
            query: `
                    mutation{
                        createInstitution(institutionInput:{
                            name:"${data.name}"
                            description:"${data.description.replace(
                                /(\r\n|\n|\r)/gm,
                                ""
                            )}"
                            type:"${data.type}"
                            county:"${data.county}"
                            constituency:"${data.constituency}"
                            ward:"${data.ward}"
                            primary_phone:"${data.primary_phone}",
                            secondary_phone:"${data.secondary_phone}",
                            primary_email:"${data.primary_email}"
                            secondary_email:"${data.secondary_email}"
                            address:"${data.address}-${data.postal_code}"
                            linkedin:"${data.linkedin}"
                            facebook:"${data.facebook}"
                            instagram:"${data.instagram}"
                            twitter:"${data.twitter}"
                            youtube:"${data.youtube}"
                            website:"${data.website}"
                            working_hours:${JSON.stringify(data.working_hours)}
                            visiting_hours:${JSON.stringify(
                                data.visiting_hours
                            )}
                            insurance:${JSON.stringify(data.insurance)}
                            gprs:[${data.gprs}]
                            subscription:"${data.subscription}"
                        }){
                            _id
                            name
                            description
                            type
                            county
                            constituency
                            ward
                            primary_phone
                            secondary_phone
                            primary_email
                            secondary_email
                            address
                            linkedin
                            facebook
                            instagram
                            twitter
                            youtube
                            website
                            visiting_hours
                            working_hours
                            insurance
                            gprs
                            subscription
                            registration_status
                            registration_comment
                            last_payment_date
                            photos
                            primary_photo
                            rating
                            status
                        }
                    }
                `,
        };
    } else if (type === "getManagers") {
        return {
            query: `
                    query {
                        admins {
                            _id
                            first_name
                            last_name
                            email
                            phone
                        }
                    }
                `,
        };
    } else if (type === "createManager") {
        return {
            query: `
                    mutation{
                        createManager(managerInput:{
                            first_name:"${data.first_name}"
                            last_name:"${data.last_name}"
                            email:"${data.email}"
                            phone:"${data.phone}"
                            institution:"${data.institution}"
                            designation:"${data.designation}"
                            password:"${data.password}"
                            referral_code:"${data.referral_code}"
                        }){
                            _id
                        }
                    }
                `,
        };
    } else if (type === "updateManager") {
        return {
            query: `
                    mutation{
                        updateManager(_id:"${data._id}",managerUpdate:{
                            first_name:"${data.first_name}"
                            last_name:"${data.last_name}"
                            email:"${data.email}"
                            phone:"${data.phone}"
                            institution:"${data.institution}"
                            designation:"${data.designation}"
                            owns:"${data.owns}"
                            dob:"${data.dob}"
                            gender:"${data.gender}"
                            county:"${data.county}"
                            constituency:"${data.constituency}"
                            ward:"${data.ward}"
                            photo:"${data.photo}"
                        }){
                            _id
                        }
                    }
                `,
        };
    } else if (type === "singleManager") {
        return {
            query: `
                    query{
                        singleManager(_id:"${data.id}"){
                            _id
                            first_name
                            last_name
                            email
                            phone
                            institution
                            designation
                            password
                            owns
                            dob
                            gender
                            county
                            constituency
                            ward
                            photo
                            institutions{
                                _id
                                name
                                description
                                type
                                county
                                constituency
                                ward
                                primary_phone
                                secondary_phone
                                primary_email
                                secondary_email
                                address
                                linkedin
                                facebook
                                instagram
                                twitter
                                youtube
                                website
                                visiting_hours
                                working_hours
                                insurance
                                gprs
                                subscription
                                registration_status
                                registration_comment
                                last_payment_date
                                photos
                                primary_photo
                                rating
                                ratings{
                                    _id
                                    rating
                                }
                                status
                            }
                        }
                    }
                `,
        };
    } else if (type === "getClients") {
        return {
            query: `
                    query {
                        clients {
                            _id
                            first_name
                            last_name
                            email
                            phone
                            password
                            status
                        }
                    }
                `,
        };
    } else if (type === "createClient") {
        return {
            query: `
                    mutation{
                        createClient(clientInput:{
                            first_name:"${data.first_name}"
                            last_name:"${data.last_name}"
                            email:"${data.email}"
                            phone:"${data.phone}"
                            password:"${data.password}"
                        }){
                            _id
                        }
                    }
                `,
        };
    } else if (type === "updateClient") {
        return {
            query: `
                    mutation{
                        updateClient(_id:"${data._id}",clientUpdate:{
                            first_name:"${data.first_name}"
                            last_name:"${data.last_name}"
                            email:"${data.email}"
                            phone:"${data.phone}"
                            dob:"${data.dob}"
                            gender:"${data.gender}"
                            county:"${data.county}"
                            constituency:"${data.constituency}"
                            ward:"${data.ward}"
                            photo:"${data.photo}"
                            favourites:[${data.favourites}]
                        }){
                            _id
                            first_name
                            last_name
                            email
                            phone
                            dob
                            gender
                            county
                            constituency
                            ward
                            photo
                            favourites
                        }
                    }
                `,
        };
    } else if (type === "singleClient") {
        return {
            query: `
                    query{
                        singleClient(_id:"${data.id}"){
                            _id
                            first_name
                            last_name
                            email
                            phone
                            dob
                            gender
                            county
                            constituency
                            ward
                            photo
                            password
                            favourites
                            referral_code
                        }
                    }
                `,
        };
    } else if (type === "clientSignIn") {
        return {
            query: `
                    query{
                        clientLogin(username:"${data.username}", password:"${data.password}", remember_me:${data.remember_me}){
                            token
                            tokenExpiration
                        }
                    }
                `,
        };
    } else if (type === "managerSignIn") {
        return {
            query: `
                    query{
                        managerLogin(email:"${data.email}", password:"${data.password}", remember_me:${data.remember_me}){
                            token
                            tokenExpiration
                        }
                    }
                `,
        };
    } else if (type === "updateCare") {
        return {
            query: `
                    mutation{
                        updateSite(_id:"${data._id}",siteUpdate:{
                            name:"${data.name}"
                            deleted:${data.deleted}
                        }){
                            _id
                        }
                    }
                `,
        };
    } else if (type === "singleCare") {
        return {
            query: `
                    query{
                        singleSite(_id:"${data.id}"){
                            _id
                            name
                        }
                    }
                `,
        };
    } else if (type === "getCares") {
        return {
            query: `
                    query{
                        cares{
                            _id
                            name
                            description
                            tags
                        }
                    }
                `,
        };
    } else if (type === "getServices") {
        return {
            query: `
                    query{
                        services(_id:"${data.id}"){
                            _id
                            name
                            description
                            primary_phone
                            primary_email
                            primary_photo
                            website
                            rating
                            care{
                                name
                            }
                            ratings{
                                _id
                                rating
                            }
                        }
                    }
                `,
        };
    } else if (type === "createService") {
        return {
            query: `
                    mutation{
                        createService(serviceInput:{
                            care:"${data.care}"
                            description:"${data.description}"
                            institution:"${data.institution}"
                        }){
                            _id
                            primary_phone
                            primary_email
                            website
                            primary_photo
                            rating
                        }
                    }
                `,
        };
    } else if (type === "updateService") {
        return {
            query: `
                    mutation{
                        updateServer(_id:"${data._id}",serverUpdate:{
                            name:"${data.name}"
                            server_ip:"${data.server_ip}"
                            binding_range:[${data.binding_range}]
                            user_pass_range:[${data.user_pass_range}]
                            site:"${data.site}"
                            deleted:${data.deleted}
                        }){
                            _id
                        }
                    }
                `,
        };
    } else if (type === "singleService") {
        return {
            query: `
                    query{
                        singleService(_id:"${data.id}"){
                            _id
                            name
                            description
                            primary_phone
                            primary_email
                            website
                            working_hours
                            visiting_hours
                            insurance
                            primary_photo
                            rating
                            county
                            constituency
                            ward
                            gprs
                            rating
                            photos
                            care{
                                _id
                                name
                            }
                            institution{
                                _id
                                name
                                type
                                address
                                linkedin
                                facebook
                                twitter
                                instagram
                            }
                            offers{
                                _id
                                name
                                price
                            }
                            ratings{
                                _id
                                rating
                                comment
                                client{
                                    _id
                                    first_name
                                    last_name
                                    photo
                                }
                            }
                        }
                    }
                `,
        };
    } else if (type === "getInvoices") {
        return {
            query: `
                    query{
                        profiles{
                            _id
                            name
                            before_the_day
                            on_the_day
                            after_the_day
                        }
                    }
                `,
        };
    } else if (type === "createInvoice") {
        return {
            query: `
                    mutation{
                        createInvoice(invoiceInput: {
                            description:"${data.description}"
                            amount:${data.amount}
                            tax:${data.tax}
                            taxed_amount:${data.taxed_amount}
                            institution:"${data.institution}"
                            subscription:"${data.subscription}"
                            category:"${data.category}"
                        }){
                            _id
                        }
                    }
                `,
        };
    } else if (type === "updateInvoice") {
        return {
            query: `
                    mutation{
                        updateInvoice(_id: "6123ded7cd7854b78d93fdc8", invoiceUpdate: {
                            status: true
                        }){
                            _id
                        }
                    }
                `,
        };
    } else if (type === "singleInvoice") {
        return {
            query: `
                    query{
                        singleInvoice(_id:"${data.id}"){
                                _id
                                number
                                description
                                date
                                expires
                                amount
                                tax
                                taxed_amount
                                gateway
                                transaction_id
                                subscription
                                category
                                institution{
                                    _id
                                    name
                                    primary_phone
                                    primary_email
                                    address
                                    county
                                    manager{
                                        phone
                                    }
                                }
                            }
                        }
                `,
        };
    } else if (type === "myInvoices") {
        return {
            query: `
                    query{
                        myInvoices(_id:"${data.id}"){
                            _id
                            number
                            description
                            date
                            expires
                            amount
                            tax
                            taxed_amount
                            gateway
                            subscription
                            category
                            transaction_id
                        }
                    }
                `,
        };
    } else if (type === "createOffer") {
        return {
            query: `
                    mutation{
                        createOffer(offerInput: {
                            name:"${data.offer}"
                            price:${data.price}
                            service:"${data.service._id}"
                        }){
                            _id
                        }
                    }
                `,
        };
    } else if (type === "deleteOffer") {
        return {
            query: `
                    mutation{
                        deleteOffer(_id:"${data.id}"){
                            _id
                        }
                    }
                `,
        };
    } else if (type === "createRating") {
        return {
            query: `
                    mutation{
                        createRating(ratingInput: {
                            rating:${data.rating},
                            comment:"${data.comment}",
                            service:"${data.service._id}"
                        }){
                            _id
                        }
                    }
                `,
        };
    } else if (type === "updateRating") {
        return {
            query: `
                    mutation{
                        updateRating(_id:"${data.rating_id}", ratingUpdate: {
                            rating:${data.rating},
                            comment:"${data.comment}",
                        }){
                            _id
                        }
                    }
                    `,
        };
    } else if (type === "deleteRating") {
        return {
            query: `
                    mutation{
                        deleteRating(_id:"${data.id}"){
                            _id
                        }
                    }
                `,
        };
    } else if (type === "createFeedback") {
        return {
            query: `
                    mutation{
                        createFeedback(feedbackInput:{
                            type:"${data.feedback}"
                            feedback:"${data.feedback}"
                        }){
                            _id
                        }
                    }
                `,
        };
    }
};
