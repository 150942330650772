import React from "react";
import {
    Button,
    Grid,
    ListItem,
    List,
    ListItemText,
    Typography,
    Divider,
} from "@material-ui/core/";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

export default function Footer() {
    return (
        <div>
            <Grid container className="footer">
                <Grid item xs={12} md={2}>
                    <Typography variant="h6" align="left">
                        Company
                    </Typography>
                    <List>
                        <ListItem
                            component="a"
                            href="../about-us"
                            key="about-us"
                        >
                            <ListItemText primary={"About Us"} />
                        </ListItem>
                        <ListItem component="a" href="../careers" key="careers">
                            <ListItemText primary={"Careers"} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography variant="h6" align="left">
                        Managers
                    </Typography>
                    <List>
                        <ListItem
                            component="a"
                            href="../managers"
                            key="managers"
                        >
                            <ListItemText primary={"Learn More"} />
                        </ListItem>
                        <ListItem
                            component="a"
                            href="../managers/how-it-works"
                            key="how-it-works"
                        >
                            <ListItemText primary={"How it works"} />
                        </ListItem>
                        <ListItem
                            component="a"
                            href="../managers/application-requirements"
                            key="requirements"
                        >
                            <ListItemText primary={"Requirements"} />
                        </ListItem>

                        <ListItem
                            component="a"
                            href="../managers/pricing"
                            key="pricing"
                        >
                            <ListItemText primary={"Pricing"} />
                        </ListItem>
                        <ListItem
                            component="a"
                            href="../managers/julikana-na-wapidoc"
                            key="pricing"
                        >
                            <ListItemText primary={"Julikana na WapiDoc"} />
                        </ListItem>
                        <ListItem
                            component="a"
                            href="../christmas-smiles"
                            key="christmas-smiles"
                        >
                            <ListItemText primary={"Christmas Smiles"} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography variant="h6" align="left">
                        Support
                    </Typography>
                    <List>
                        <ListItem
                            component="a"
                            href="../contact-us"
                            key="requirements"
                        >
                            <ListItemText primary={"Contact Us"} />
                        </ListItem>
                        {/* <NavLink exact to="#">
                            <ListItem key="about-us">
                                <ListItemText primary={"Help"} />
                            </ListItem>
                        </NavLink> */}
                        <ListItem component="a" href="../faqs" key="faqs">
                            <ListItemText primary={"FAQS"} />
                        </ListItem>
                        {/* <NavLink exact to="#">
                            <ListItem key="careers">
                                <ListItemText primary={"Sitemap"} />
                            </ListItem>
                        </NavLink> */}
                    </List>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography variant="h6" align="left">
                        Legal
                    </Typography>
                    <List>
                        <ListItem
                            component="a"
                            href="../legal/terms-and-conditions"
                            key="terms-and-conditions"
                        >
                            <ListItemText primary={"Terms and Conditions"} />
                        </ListItem>
                        <ListItem
                            component="a"
                            href="../legal/privacy-policy"
                            key="privacy-policy"
                        >
                            <ListItemText primary={"Privacy Policy"} />
                        </ListItem>
                        <ListItem
                            component="a"
                            href="../legal/cookie-policy"
                            key="press"
                        >
                            <ListItemText primary={"Cookie Policy"} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" align="left">
                        List your Institution
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        Do you provide a service or product in the healthcare
                        industry? List your institution on WapiDoc.
                        <br />
                        <br />
                        <Button
                            className="submit-button"
                            disableElevation
                            variant="contained"
                            href="/manager/signup"
                            style={{
                                padding: "2.5% 15%",
                                color: "#ffffff",
                            }}
                        >
                            Get Started
                        </Button>
                    </Typography>
                </Grid>
                <Divider
                    style={{
                        width: "100%",
                        margin: "5% 0% 1% 0%",
                    }}
                />
                <Grid item xs={12} md={9} className="sub-footer">
                    <Typography
                        variant="body2"
                        align="left"
                        style={{ paddingTop: "2%" }}
                    >
                        © 2022 WapiDoc A Trademark of Suanill Ltd.
                    </Typography>
                </Grid>
                <Grid item xs={4} md={3} className="sub-footer" align="right">
                    <List
                        style={{
                            display: "flex",
                        }}
                    >
                        <ListItem
                            component="a"
                            href="https://www.facebook.com/WapiDoc-102230008911132"
                            key="facebook"
                        >
                            <ListItemText primary={<FacebookIcon />} />
                        </ListItem>
                        <ListItem
                            component="a"
                            href="https://twitter.com/wapidoc"
                            key="twitter"
                        >
                            <ListItemText primary={<TwitterIcon />} />
                        </ListItem>
                        <ListItem
                            component="a"
                            href="https://www.linkedin.com/in/wapidoc/"
                            key="linkedin"
                        >
                            <ListItemText primary={<LinkedInIcon />} />
                        </ListItem>
                        <ListItem
                            component="a"
                            href="https://instagram.com/wapidoc"
                            key="instagram"
                        >
                            <ListItemText primary={<InstagramIcon />} />
                        </ListItem>
                        <ListItem
                            component="a"
                            href="https://wa.me/%2B254745308174?text=I'd%20like%20to%20enquire%20about"
                            key="whatsapp"
                        >
                            <ListItemText primary={<WhatsAppIcon />} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </div>
    );
}
