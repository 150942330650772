const cogs = {
    /**
     * Adds an s to a string
     * @param {5} number
     * @returns s
     */
    addS: (number) => {
        return number !== 1 ? "s" : "";
    },
    /**
     * --------------------
     * Capitalizes a string
     * --------------------
     * @param {string} string
     * @returns String
     */
    capitalize: (string) => {
        return string.replace(/\b\w/g, (l) => l.toUpperCase());
    },
    /**
     * ----------------------------------------
     * Format a number as a string with commas.
     * ----------------------------------------
     * @param num - The number to be formatted (e.g. 10000)
     * @return A string representing the formatted number (e.g. "10,000")
     */
    format_number: (number) => {
        var array = number.toString().split("");
        var index = -3;
        while (array.length + index > 0) {
            array.splice(index, 0, ",");
            // Decrement by 4 since we just added another unit to the array.
            index -= 4;
        }
        return array.join("");
    },

    /**
     * ----------------------------------
     * Replaces the 0 with a country code
     * ----------------------------------
     * @param { 0707837313 } phone
     * @returns +254707837313
     */
    filterPhone: (phone, country_code = false) => {
        country_code = country_code ? country_code : "254";

        if (phone.charAt(0) === "0") {
            phone = phone.replace("0", country_code);
        } else if (phone.charAt(0) === "+") {
            phone = phone.replace("+", "");
        }

        return phone;
    },

    managerAuthorization: (jwt_decode, postRequest) => {
        if (!localStorage.getItem("managerToken")) {
            window.location.replace(
                `${process.env.REACT_APP_HOST}/manager/signin`
            );
        } else {
            const token = jwt_decode(localStorage.getItem("managerToken"));
            if (cogs.expiredToken(token)) {
                window.location.replace(
                    `${process.env.REACT_APP_HOST}/manager/signin`
                );
            } else {
                postRequest("singleManager", "manager", {
                    id: token.manager_id,
                })
                    .then((response) => {
                        localStorage.setItem(
                            "manager",
                            JSON.stringify(response.data.singleManager)
                        );

                        if (
                            response.data.singleManager.institutions.length > 0
                        ) {
                            if (!localStorage.getItem("institution")) {
                                localStorage.setItem(
                                    "institution",
                                    JSON.stringify(
                                        response.data.singleManager
                                            .institutions[0]
                                    )
                                );
                            }
                        } else {
                            if (
                                window.location.href !==
                                `${process.env.REACT_APP_HOST}/manager/institution/setup`
                            ) {
                                window.location.replace(
                                    `${process.env.REACT_APP_HOST}/manager/institution/setup`
                                );
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    },

    clientAuthorization: (jwt_decode, postRequest, url = "") => {
        if (!localStorage.getItem("clientToken")) {
            window.location.replace(
                `${process.env.REACT_APP_HOST}/signin/?redirect=${url}`
            );
        } else {
            const token = jwt_decode(localStorage.getItem("clientToken"));
            if (cogs.expiredToken(token)) {
                window.location.replace(
                    `${process.env.REACT_APP_HOST}/signin/?redirect=${url}`
                );
            } else {
                postRequest("singleClient", "client", {
                    id: token.client_id,
                })
                    .then((response) => {
                        localStorage.setItem(
                            "client",
                            JSON.stringify(response.data.singleClient)
                        );
                        return true;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    },

    expiredToken: (token) => {
        var current = new Date().getTime();
        current = current.toString();
        current = current.slice(0, -3);
        current = parseInt(current);

        if (current > token.exp) {
            return true;
        } else {
            return false;
        }
    },

    getLocation: (axios) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                localStorage.removeItem("coordinates");
                localStorage.setItem("coordinates", [
                    position.coords.latitude,
                    position.coords.longitude,
                ]);
            },
            (error) => {
                axios
                    .get(
                        `"https://api.ipdata.co/?api-key=4ba433d298bd1d59fd6ca45f704ce4219557493b211d775db7fc4b96"`
                    )
                    .then((position) => {
                        localStorage.removeItem("coordinates");
                        localStorage.setItem("coordinates", [
                            position.data.latitude,
                            position.data.longitude,
                        ]);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        );

        navigator.geolocation.watchPosition(
            (position) => {
                localStorage.removeItem("coordinates");
                localStorage.setItem("coordinates", [
                    position.coords.latitude,
                    position.coords.longitude,
                ]);
            },
            (error) => {
                axios
                    .get(
                        `"https://api.ipdata.co/?api-key=4ba433d298bd1d59fd6ca45f704ce4219557493b211d775db7fc4b96"`
                    )
                    .then((position) => {
                        localStorage.removeItem("coordinates");
                        localStorage.setItem("coordinates", [
                            position.data.latitude,
                            position.data.longitude,
                        ]);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        );

        return localStorage.getItem("coordinates");
    },

    /**
     *
     * @param {*} axios
     * @returns User's Ip Address
     */
    getUserIP: async (axios) => {
        const response = await axios.get(
            "https://api.ipdata.co/?api-key=4ba433d298bd1d59fd6ca45f704ce4219557493b211d775db7fc4b96"
        );
        return response.data.ip;
    },
};

export default cogs;
