import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export const Designation = ({ data, updateData }) => {
    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel>Choose Designation</InputLabel>
            <Select
                label="choose designation"
                name="designation"
                labelId="designation"
                value={data.designation}
                onChange={(event) => {
                    updateData({ ...data, designation: event.target.value });
                }}
            >
                <MenuItem value="administrator">Administrator</MenuItem>
                <MenuItem value="it-personnel">IT Personnel</MenuItem>
                <MenuItem value="other">Other</MenuItem>
            </Select>
        </FormControl>
    );
};
