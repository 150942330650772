import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import { Grid, Typography, Button } from "@material-ui/core";

class RequirementsPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Manager - How it Works</title>
                    <meta
                        name="description"
                        content="Create an account by filling the required details and wait for a confirmation email / message in your email / phone."
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid container className={"how-it-works add-space"}>
                    <Grid
                        item
                        xs={12}
                        style={{ paddingTop: "10%" }}
                        className={"add-space"}
                    >
                        <Typography variant="h2" align="center">
                            How it Works
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={6} className={"step-content"}>
                            <img
                                src={"../images/HowItWorksStep1.svg"}
                                alt="How it Works Step 1"
                            />
                            <Typography variant="subtitle1" gutterBottom>
                                STEP 1
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                Create an account with WapiDoc
                            </Typography>
                            <Typography variant="body1" gutterBottom paragraph>
                                Create an account by filling the required
                                details and wait for a confirmation email /
                                message in your email / phone.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={"step-content"}>
                            <img
                                src={"../images/HowItWorksStep2.svg"}
                                alt="How it Works Step 1"
                                width="50%"
                            />
                            <Typography variant="subtitle1" gutterBottom>
                                STEP 2
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                Set up your first institution on WapiDoc
                            </Typography>
                            <Typography variant="body1" gutterBottom paragraph>
                                Select the institution category and follow the
                                steps as prompted.
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={12} md={6} className={"step-content"}>
                            <img
                                src={"../images/HowItWorksStep3.svg"}
                                alt="How it Works Step 1"
                            />
                            <Typography variant="subtitle1" gutterBottom>
                                STEP 3
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                Pay the application fee based on your
                                institution category.
                            </Typography>
                            <Typography variant="body1" gutterBottom paragraph>
                                Using your most convenient mode of payment pay
                                an application fee based on your institution
                                category.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={"step-content"}>
                            <img
                                src={"../images/HowItWorksStep4.svg"}
                                alt="How it Works Step 1"
                            />
                            <Typography variant="subtitle1" gutterBottom>
                                STEP 4
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                You'll receive a visit from WapiDoc's Agents
                            </Typography>
                            <Typography variant="body1" gutterBottom paragraph>
                                WapiDoc team will pay your institution a visit
                                at the scheduled date.
                            </Typography>
                        </Grid> */}
                        <Grid item xs={12} md={6} className={"step-content"}>
                            <img
                                src={"../images/HowItWorksStep3.svg"}
                                alt="How it Works Step 1"
                            />
                            <Typography variant="subtitle1" gutterBottom>
                                STEP 3
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                Pay the annual retention fee based on your
                                institution category.
                            </Typography>
                            <Typography variant="body1" gutterBottom paragraph>
                                To continue enjoying the service of the WapiDoc
                                platform. You will be required to pay an annual
                                retention fee as per agreement.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={"step-content"}>
                            <img
                                src={"../images/HowItWorksStep5.svg"}
                                alt="How it Works Step 1"
                                style={{ marginBottom: "5%" }}
                            />
                            <Typography variant="h4" gutterBottom>
                                You are good to go.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            align="center"
                            style={{ padding: "5%", background: "#cf053e" }}
                        >
                            <Typography
                                variant="body1"
                                style={{
                                    color: "#ffffff",
                                    fontSize: "20px",
                                }}
                            >
                                Looking to get started
                            </Typography>
                            <Typography
                                variant="h3"
                                style={{ color: "#ffffff" }}
                            >
                                Register now
                            </Typography>
                            <Button
                                className=""
                                variant="outlined"
                                href="/manager/signup"
                                style={{
                                    padding: "1% 2%",
                                    border: "1px solid #ffffff",
                                    color: "#ffffff",
                                    margin: "3% 1%",
                                }}
                            >
                                Get Started
                            </Button>
                            <Button
                                className=""
                                variant="contained"
                                href="/managers/pricing"
                                style={{
                                    padding: "1.2% 2.2%",
                                    background: "#ffffff",
                                    margin: "3% 1%",
                                }}
                            >
                                Learn More
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default RequirementsPage;
