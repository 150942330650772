import React, { useState } from "react";
import { List, Button } from "@mui/material";

export const MenuButtons = ({ btnClass }) => {
    const [action, setAction] = useState("");
    return (
        <List
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                textAlign: "right",
            }}
            align="right"
        >
            {action === "" && (
                <Button
                    className={btnClass}
                    variant="outlined"
                    onClick={() => setAction("signin")}
                >
                    Sign&nbsp;In
                </Button>
            )}

            {action === "" && (
                <Button
                    className={btnClass}
                    variant="outlined"
                    onClick={() => setAction("signup")}
                >
                    Create an account
                </Button>
            )}

            {action === "signin" && (
                <Button
                    className={btnClass}
                    variant="outlined"
                    href="/signin"
                >
                    User
                </Button>
            )}

            {action === "signin" && (
                <Button
                    className={btnClass}
                    variant="outlined"
                    href="/manager/signin"
                >
                    Institution
                </Button>
            )}

            {action === "signup" && (
                <Button
                    className={btnClass}
                    variant="outlined"
                    href="/signup"
                >
                    User
                </Button>
            )}

            {action === "signup" && (
                <Button
                    className={btnClass}
                    variant="outlined"
                    href="/manager/signup"
                >
                    Institution
                </Button>
            )}
        </List>
    );
};
