import React from "react";
import {
    Grid,
    TextField,
    InputAdornment,
    Typography,
    Button,
} from "@mui/material";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import LanguageIcon from "@material-ui/icons/Language";
import { capitalize } from "../../../../helpers/string";

const accounts = [
    {
        name: "linkedin",
        icon: <LinkedInIcon fontSize="small" style={{ color: "#939393" }} />,
        url: "https://www.linkedin.com/example",
    },
    {
        name: "facebook",
        icon: <FacebookIcon fontSize="small" style={{ color: "#939393" }} />,
        url: "https://www.facebook.com/example",
    },
    {
        name: "twitter",
        icon: <TwitterIcon fontSize="small" style={{ color: "#939393" }} />,
        url: "https://www.twitter.com/example",
    },
    {
        name: "instagram",
        icon: <InstagramIcon fontSize="small" style={{ color: "#939393" }} />,
        url: "https://www.instagram.com/example",
    },
    {
        name: "youtube",
        icon: <YouTubeIcon fontSize="small" style={{ color: "#939393" }} />,
        url: "https://www.youtube.com/example",
    },
    {
        name: "whatsapp",
        icon: <WhatsAppIcon fontSize="small" style={{ color: "#939393" }} />,
        url: "https://www.whatsapp.com/example",
    },
    {
        name: "website",
        icon: <LanguageIcon fontSize="small" style={{ color: "#939393" }} />,
        url: "https://www.example.com",
    },
];

export const InstitutionSocials = ({ setReady, data, updateData }) => {
    return (
        <Grid container>
            <Grid item xs={12} md={8} style={{ margin: "0px auto" }}>
                <Typography
                    variant="body2"
                    textAlign={"center"}
                    style={{
                        color: "#888",
                        fontSize: "24px",
                        padding: "3% 0%",
                    }}
                >
                    Social Media
                </Typography>

                {accounts.map((item) => {
                    return (
                        <TextField
                            key={item.name}
                            id={item.name}
                            name={item.name}
                            label={`${capitalize(item.name)} (Optional)`}
                            margin="normal"
                            variant="outlined"
                            placeholder={item.url}
                            value={data.links[item.name]}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {item.icon}
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                updateData({
                                    ...data,
                                    links: {
                                        ...data.links,
                                        [item.name]: event.target.value,
                                    },
                                });
                            }}
                        />
                    );
                })}

                <Grid container spacing={3} my>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            style={{
                                backgroundColor: "#444444",
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    status: "insurance",
                                });
                            }}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            style={{
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                                marginLeft: "0%",
                                backgroundColor: "#ce043d",
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    status: "working-hours",
                                });
                                setReady(true);
                            }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
