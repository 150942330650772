import React, { useState } from "react";
import jwtDecode from "jwt-decode";
import { useQuery, gql } from "@apollo/client";
import {
    List,
    Link,
    ListItem,
    Typography,
    Chip,
    Avatar,
    Menu,
    MenuItem,
    Divider,
    ListItemIcon,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { GetUserData } from "../../helpers/user";

const logOut = () => {
    localStorage.removeItem("token");
    window.location.replace(`${process.env.REACT_APP_HOST}/`);
};

export const ClientMenu = ({ styling }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    let USER = GetUserData(jwtDecode, useQuery, gql);

    if (USER.loading) return "";

    if (USER.data) {
        USER = USER.data.singleClient;
        return (
            <List
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    paddingTop: "1.5%",
                }}
            >
                <Link
                    href="/favourites"
                    style={{
                        marginRight: "3%",
                        color: `${styling.color}`,
                    }}
                >
                    <ListItem
                        key="favourites"
                        align="left"
                        style={{
                            marginTop: "5%",
                            marginRight: "3%",
                            color: `${styling.color}`,
                        }}
                    >
                        <Typography variant="body1">Favourites</Typography>
                    </ListItem>
                </Link>
                <Link
                    href="/referral-program"
                    style={{
                        marginRight: "3%",
                        color: `${styling.color}`,
                    }}
                >
                    <ListItem
                        key="referrals"
                        align="left"
                        style={{
                            marginTop: "5%",
                            marginRight: "3%",
                        }}
                    >
                        <Typography variant="body1">
                            Referrals
                        </Typography>
                    </ListItem>
                </Link>
                <div>
                    <Chip
                        avatar={
                            <Avatar
                                alt={`${USER.firstName} ${USER.lastName}`}
                                src={`https://ik.imagekit.io/9vm1rbs0vzz/tr:h-80,w-80/clients/profile_pictures/${USER.profilePhoto}?ik-sdk-version=javascript-1.4.3&updatedAt=1642490516065`}
                            />
                        }
                        label={
                            <Typography variant="body1">
                                {USER.firstName} {USER.lastName}
                            </Typography>
                        }
                        variant="outlined"
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                        style={{
                            color: `${styling.color}`,
                        }}
                    />
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={anchorEl}
                        onClose={() => {
                            setAnchorEl(false);
                        }}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                        style={{ width: "20%" }}
                    >
                        <Link href={"/profile"}>
                            <MenuItem>
                                <ListItemIcon>
                                    <AccountCircleIcon fontSize="small" />
                                </ListItemIcon>
                                Profile
                            </MenuItem>
                        </Link>
                        <Divider />
                        <Link href={"#"}>
                            <MenuItem onClick={() => logOut()}>
                                <ListItemIcon>
                                    <ExitToAppIcon fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Link>
                    </Menu>
                </div>
            </List>
        );
    }
};
