import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import { capitalize } from "../../helpers/string";

export const OfferList = ({ offers }) => {
    return offers.length > 0 ? (
        <Grid container>
            {offers.map(({ _id, name }) => {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {capitalize(name)}
                            </Typography>
                        </Grid>
                        {offers.length > 1 && (
                            <Divider
                                style={{
                                    margin: "1% 0%",
                                    width: "100%",
                                    color: "#dfdfdf",
                                }}
                            />
                        )}
                    </Grid>
                );
            })}
        </Grid>
    ) : (
        <img
            style={{
                width: "35%",
                height: "auto",
                display: "flex",
                margin: "5% auto",
            }}
            src={
                "https://ik.imagekit.io/9vm1rbs0vzz/public/Empty_sK1ZlJORw.svg"
            }
            alt="No Data"
        />
    );
};
