import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

export const FirstName = ({ data, updateData }) => {
    return (
        <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            margin="normal"
            variant="outlined"
            placeholder="John"
            defaultValue={data.firstName}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <PersonOutlineOutlinedIcon
                            fontSize="small"
                            style={{
                                color: "#939393",
                            }}
                        />
                    </InputAdornment>
                ),
            }}
            onChange={(event) => {
                updateData({ ...data, firstName: event.target.value });
            }}
        />
    );
};
