import React, {useState} from "react";
import { TextField, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";


export const Password = ({ data, updateData }) => {
    const [view, setView] = useState(false);

    return (
        <TextField
            id="password"
            name="password"
            label="Password"
            margin="normal"
            variant="outlined"
            placeholder="*********"
            type={view ? "text" : "password"}
            value={data.password}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {view ? (
                            <VisibilityIcon
                                fontSize="small"
                                style={{
                                    color: "#939393",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setView(!view);
                                }}
                            />
                        ) : (
                            <VisibilityOffIcon
                                fontSize="small"
                                style={{
                                    color: "#939393",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setView(!view);
                                }}
                            />
                        )}
                    </InputAdornment>
                ),
            }}
            onChange={(event) => {
                updateData({ ...data, password: event.target.value });
            }}
        />
    );
};
