import React from "react";
import { List, ListItem, ListItemText, ListItemAvatar } from "@mui/material/";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import FacebookIcon from "@mui/icons-material/Facebook";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { capitalize } from "../../../../helpers/string";

const categories = [
    {
        name: "category",
        avatar: <ThumbUpAltIcon />,
        description: "Choose a category",
    },
    {
        name: "institution-info",
        avatar: <InfoIcon />,
        description: "Name and description",
    },
    {
        name: "location",
        avatar: <LocationOnIcon />,
        description: "Location, street address ...",
    },
    {
        name: "contacts",
        avatar: <CallIcon />,
        description: "Phone, email ...",
    },
    {
        name: "insurance",
        avatar: <SupervisedUserCircleIcon />,
        description: "Insurance options",
    },
    {
        name: "social-media",
        avatar: <FacebookIcon />,
        description: "Facebook, twitter ...",
    },
    {
        name: "working-hours",
        avatar: <AccessTimeFilledIcon />,
        description: "Working hours",
    },
    {
        name: "visiting-hours",
        avatar: <AccessTimeFilledIcon />,
        description: "Visiting hours",
    },
];

export const StepList = ({ data, updateData }) => {
    return (
        <List className="card" style={{ margin: "5%" }}>
            {categories.map((item, index) => {
                return (
                    <div
                        key={item.name}
                        onClick={() =>
                            updateData({
                                ...data,
                                status: item.name,
                            })
                        }
                    >
                        <ListItem
                            className={`l-type ${
                                data.status === item.name && "l-type-current"
                            }`}
                            style={{
                                display: `${
                                    item.name === "visiting-hours" &&
                                    data.type !== "hospital"
                                        ? "none"
                                        : "flex"
                                }`,
                            }}
                        >
                            <ListItemAvatar>{item.avatar}</ListItemAvatar>
                            <ListItemText
                                primary={capitalize(
                                    item.name.replace("-", " ")
                                )}
                                secondary={item.description}
                            />
                            <NavigateNextIcon />
                        </ListItem>
                    </div>
                );
            })}
        </List>
    );
};
