import React from "react";
import { Grid } from "@mui/material";

export const GalleryList = ({ photos }) => {
    return photos.length > 0 ? (
        <Grid container>
            {photos.map((photo) => {
                return (
                    <Grid
                        item
                        xs={12}
                        md={3}
                        className={"gallery-image"}
                        key={photo}
                    >
                        <div
                            style={{
                                background:
                                    "url('https://ik.imagekit.io/9vm1rbs0vzz/managers/institutions/" +
                                    photo +
                                    "?ik-sdk-version=javascript-1.4.3&updatedAt=1642468057296')",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                height: "30vh",
                                borderRadius: "5px",
                                margin: "2%",
                            }}
                        ></div>
                    </Grid>
                );
            })}
        </Grid>
    ) : (
        <img
            style={{
                width: "35%",
                height: "auto",
                display: "flex",
                margin: "5% auto",
            }}
            src={
                "https://ik.imagekit.io/9vm1rbs0vzz/public/Empty_sK1ZlJORw.svg"
            }
            alt="No Data"
        />
    );
};
