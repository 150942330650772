import React, { Component } from "react";
import axios from "axios";
import Drawer from "../../components/drawer";
import cogs from "../../middleware/cogs";
import { postRequest } from "../../middleware/graphQl";

import {
    Grid,
    Typography,
    CircularProgress,
    Link,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Button,
} from "@material-ui/core/";

import Rating from "@material-ui/lab/Rating";
import ListItemButton from "@mui/material/ListItemButton";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import { withSnackbar } from "notistack";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

class Dashboard extends Component {
    state = {
        institution: JSON.parse(localStorage.getItem("institution")),
        viewsThisYear: 0,
        viewsThisMonth: 0,
        viewsThisWeek: 0,
        viewsToday: 0,
        searchesThisYear: 0,
        searchesThisMonth: 0,
        searchesThisWeek: 0,
        searchesToday: 0,
        avgRating: 0,
        avgRatingComment: "NOT RATED",
        loading: true,
        invoices: [],
        payments: [],
        services: [],
        service: { rating: 0 },
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        completionStatus: false,
    };

    componentDidMount = () => {
        this.getViewAnalytics("thisYear");
        this.getViewAnalytics("thisMonth");
        this.getViewAnalytics(1);
        this.getViewAnalytics(7);
        this.getSearchAnalytics("thisYear");
        this.getSearchAnalytics("thisMonth");
        this.getSearchAnalytics(1);
        this.getSearchAnalytics(7);
        this.getInstitution();
    };

    getInstitution = async () => {
        const response = await postRequest("singleInstitution", "manager", {
            id: this.state.institution._id,
        });

        if (response.errors) {
            this.props.enqueueSnackbar(response.errors[0].message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
        } else {
            this.setState(
                {
                    institution: response.data.singleInstitution,
                },
                () => {
                    this.getRatingComment();
                    this.getServices();
                }
            );
        }
    };

    /**
     * Get this institution's services
     */
    getServices = async () => {
        const response = await postRequest("getServices", "manager", {
            id: this.state.institution._id,
        });

        if (response.errors) {
            this.props.enqueueSnackbar(response.errors[0].message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
        } else {
            this.setState(
                {
                    services: response.data.services,
                },
                () => {
                    this.getPopularService();
                }
            );
        }
    };

    /**
     * Get Popular Service
     */
    getPopularService = () => {
        let service = this.state.service;
        for (let i = 0; i < this.state.services.length; i++) {
            if (service.rating !== 0) {
                if (this.state.services[i].rating > service.rating) {
                    service = this.state.services[i];
                }
            } else {
                service = this.state.services[i];
            }
        }

        this.setState(
            {
                service: service,
            },
            () => {
                this.getInvoices();
            }
        );
    };

    /**
     * Get Next Payment Date
     */
    getNextPaymentDate = () => {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        if (this.state.institution.last_payment_date !== "") {
            let date = Date.parse(this.state.institution.last_payment_date);
            let dateObject = new Date(date);

            let days = 0;
            switch (this.state.institution.subscription) {
                case "monthly":
                    days = 30;
                    break;
                case "quarterly":
                    days = 90;
                    break;
                case "bi-annual":
                    days = 180;
                    break;
                case "yearly":
                    days = 360;
                    break;
                default:
                    days = 0;
                    break;
            }

            dateObject.setDate(dateObject.getDate() + days);

            let dateString =
                this.getFormattedDay(dateObject.getDate()) +
                " " +
                months[dateObject.getMonth()].toUpperCase() +
                " " +
                dateObject.getFullYear();

            return dateString;
        } else {
            return "-";
        }
    };

    getFormattedDay = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    };

    getInvoices = async () => {
        const response = await postRequest("myInvoices", "manager", {
            id: this.state.institution._id,
        });

        if (response.errors) {
            this.props.enqueueSnackbar(response.errors[0].message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
        } else {
            this.setState(
                {
                    invoices: response.data.myInvoices,
                },
                () => {
                    this.updateCompletionStatus();
                }
            );
        }
    };

    getSubscriptionDuration = () => {
        let duration = "";
        switch (this.state.institution.subscription) {
            case "monthly":
                duration = "month";
                break;
            case "quarterly":
                duration = "3 months";
                break;
            case "bi-annual":
                duration = "6 months";
                break;
            case "yearly":
                duration = "year";
                break;
            default:
                duration = "";
                break;
        }

        return duration;
    };

    updateCompletionStatus = () => {
        if (
            this.state.institution.status === "subscribed" &&
            this.state.services.length > 0 &&
            this.state.institution.gprs.length > 0 &&
            this.state.institution.primary_photo !== "default_k29jR4Nfr.jpg"
        ) {
            this.setState({ completionStatus: true, loading: false });
        } else {
            this.setState({ loading: false });
        }
    };

    getViewAnalytics = async (query) => {
        const response = await axios.get(
            `${process.env.REACT_APP_HOST_API}/query/analytics/views/institution/${this.state.institution._id}/view/page/${query}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " + localStorage.getItem("managerToken"),
                },
            }
        );

        switch (query) {
            case 1:
                query = "viewsToday";
                break;
            case 7:
                query = "viewsThisWeek";
                break;
            case "thisMonth":
                query = "viewsThisMonth";
                break;
            case "thisYear":
                query = "viewsThisYear";
                break;
            default:
                break;
        }

        this.setState({ [query]: response.data });
    };

    getSearchAnalytics = async (query) => {
        const response = await axios.get(
            `${process.env.REACT_APP_HOST_API}/query/analytics/searches/institution/${this.state.institution._id}/${query}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " + localStorage.getItem("managerToken"),
                },
            }
        );

        switch (query) {
            case 1:
                query = "searchesToday";
                break;
            case 7:
                query = "searchesThisWeek";
                break;
            case "thisMonth":
                query = "searchesThisMonth";
                break;
            case "thisYear":
                query = "searchesThisYear";
                break;
            default:
                break;
        }

        this.setState({ [query]: response.data });
    };

    getGrowthRate = (obj) => {
        let diff = 100;

        if (obj.prevResult !== 0) {
            let diff = Math.abs(obj.prevResult - obj.result);
            console.log(diff);
            diff = (diff / obj.prevResult) * 100;
        }

        return diff;
    };

    /**
     * Function that gets comment from rating
     * @param {*} rating
     * @returns rating comment
     */
    getRatingComment = (rating) => {
        let ratingComment = "RATING";

        if (rating > 0 && rating <= 1) {
            ratingComment = "VERY POOR";
        } else if (rating > 1 && rating <= 2) {
            ratingComment = "POOR";
        } else if (rating > 2 && rating <= 3) {
            ratingComment = "AVERAGE";
        } else if (rating > 3 && rating <= 4) {
            ratingComment = "GOOD";
        } else if (rating > 4) {
            ratingComment = "EXCELLENT";
        }

        return ratingComment;
    };

    render() {
        let invoices = this.state.invoices.map((invoice) => {
            return (
                <Grid
                    item
                    xs={12}
                    style={{
                        padding: "2%",
                        borderRadius: "5px",
                        background: `${invoice.status ? "#4D9A51" : "#cc0000"}`,
                        color: "#ffffff",
                    }}
                    key={invoice._id}
                >
                    <Link href={`/manager/invoice/${invoice._id}`}>
                        <Typography
                            variant="subtitle2"
                            style={{
                                borderBottom: "1px solid #dfdfdf",
                                padding: "1% 0%",
                                margin: "1% 0%",
                                color: "#ffffff",
                            }}
                        >
                            #{invoice.number} -{" "}
                            {cogs.capitalize(invoice.description).toUpperCase()}
                            {invoice.status ? (
                                <Chip
                                    label="Paid"
                                    variant="outlined"
                                    size="small"
                                    style={{
                                        backgroundColor: "#ffffff",
                                        float: "right",
                                        border: "none",
                                        marginTop: "-1%",
                                    }}
                                />
                            ) : (
                                <Chip
                                    label="Unpaid"
                                    variant="outlined"
                                    size="small"
                                    style={{
                                        backgroundColor: "#ffffff",
                                        float: "right",
                                        border: "none",
                                        marginTop: "-1%",
                                    }}
                                />
                            )}
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{ color: "#ffffff" }}
                        >
                            SUBSCRIPTION:{" "}
                            <b>{invoice.subscription.toUpperCase()} </b>
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{ color: "#ffffff" }}
                        >
                            CATEGORY:
                            <b> {invoice.category.toUpperCase()}</b>
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{ color: "#ffffff" }}
                        >
                            AMOUNT:{" "}
                            <b>
                                KES {cogs.format_number(invoice.taxed_amount)}
                            </b>
                        </Typography>
                        <Typography
                            variant="body2"
                            style={{ color: "#ffffff" }}
                        >
                            GENERATED: <b>{invoice.date} - </b> EXPIRES:{" "}
                            <b>{invoice.expires}</b>
                        </Typography>
                    </Link>
                </Grid>
            );
        });

        return (
            <Drawer
                content={
                    <div>
                        {!this.state.loading ? (
                            <div>
                                {this.state.completionStatus ? (
                                    <Grid
                                        container
                                        spacing={1}
                                        style={{
                                            margin: "3% 0% 3% 3%",
                                            width: "96%",
                                        }}
                                    >
                                        <Grid item xs={3}>
                                            <div className="dash-item">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        >
                                                            INSTITUTION RATING
                                                        </Typography>
                                                        <Typography
                                                            variant="h1"
                                                            style={{
                                                                padding:
                                                                    "4% 0%",
                                                            }}
                                                        >
                                                            {this.state
                                                                .institution
                                                                .rating === 0
                                                                ? "-"
                                                                : this.state
                                                                      .institution
                                                                      .rating}
                                                        </Typography>

                                                        <Typography variant="body1">
                                                            {this.getRatingComment(
                                                                this.state
                                                                    .institution
                                                                    .rating
                                                            )}
                                                        </Typography>
                                                        <Rating
                                                            name="simple-controlled"
                                                            precision={0.1}
                                                            value={
                                                                this.state
                                                                    .institution
                                                                    .rating
                                                            }
                                                            readOnly
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="dash-item">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body1"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        >
                                                            HIGHEST-RATED
                                                            SERVICE
                                                        </Typography>
                                                        {this.state.service
                                                            .rating === 0 ? (
                                                            <Typography
                                                                variant="h6"
                                                                style={{
                                                                    padding:
                                                                        "1% 0%",
                                                                }}
                                                            >
                                                                NOT <br />
                                                                RATED
                                                            </Typography>
                                                        ) : (
                                                            <Typography
                                                                variant="h6"
                                                                style={{
                                                                    padding:
                                                                        "1% 0%",
                                                                }}
                                                            >
                                                                {this.state.service.name.toUpperCase()}
                                                                <br />
                                                                SERVICES
                                                            </Typography>
                                                        )}

                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                padding:
                                                                    "1% 0%",
                                                            }}
                                                        >
                                                            {this.state.service
                                                                .rating === 0
                                                                ? "-"
                                                                : this.state
                                                                      .service
                                                                      .rating}
                                                        </Typography>

                                                        <Typography variant="body1">
                                                            {this.getRatingComment(
                                                                this.state
                                                                    .service
                                                                    .rating
                                                            )}
                                                        </Typography>
                                                        <Rating
                                                            name="simple-controlled"
                                                            precision={0.1}
                                                            value={
                                                                this.state
                                                                    .institution
                                                                    .rating
                                                            }
                                                            readOnly
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div
                                                className="dash-item"
                                                style={{
                                                    background:
                                                        "url('https://ik.imagekit.io/9vm1rbs0vzz/public/StatusBackground_Zd-KWVp3X.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1644128092275')",
                                                    backgroundPosition:
                                                        "center center",
                                                    backgroundRepeat:
                                                        "no-repeat",
                                                    backgroundSize: "cover",
                                                    padding: "2%",
                                                }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        color: "#939393",
                                                    }}
                                                >
                                                    INSTITUTION STATUS
                                                </Typography>
                                                <Divider
                                                    style={{
                                                        margin: "2% 0%",
                                                        width: "0%",
                                                    }}
                                                />
                                                <Typography
                                                    variant="body1"
                                                    style={{ color: "#ffffff" }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: "800",
                                                        }}
                                                    >
                                                        {this.state.institution
                                                            .status ===
                                                        "subscribed"
                                                            ? "ACTIVE"
                                                            : "INACTIVE"}
                                                    </span>
                                                </Typography>
                                                <Divider
                                                    style={{
                                                        margin: "2% 0%",
                                                        width: "0%",
                                                    }}
                                                />
                                                <Typography
                                                    variant="body1"
                                                    style={{ color: "#ffffff" }}
                                                >
                                                    SUBSCRIPTION PLAN: <br />
                                                    <span
                                                        style={{
                                                            fontWeight: "800",
                                                        }}
                                                    >
                                                        {this.state.institution.subscription.toUpperCase()}
                                                    </span>
                                                </Typography>
                                                <Divider
                                                    style={{
                                                        margin: "2% 0%",
                                                        width: "0%",
                                                    }}
                                                />
                                                <Typography
                                                    variant="body1"
                                                    style={{ color: "#ffffff" }}
                                                >
                                                    NEXT PAYMENT DATE:
                                                    <br />
                                                    <span
                                                        style={{
                                                            fontWeight: 800,
                                                        }}
                                                    >
                                                        {this.getNextPaymentDate()}
                                                    </span>
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="dash-item">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        >
                                                            VIEWS TODAY
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant="h3"
                                                            align="left"
                                                            style={{
                                                                paddingTop:
                                                                    "10%",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .viewsToday
                                                                    .result
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        align="right"
                                                    >
                                                        <img
                                                            src={
                                                                "https://ik.imagekit.io/9vm1rbs0vzz/public/users_8QArb8hmZFf.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1644130568164"
                                                            }
                                                            alt="multiple"
                                                            style={{
                                                                height: "80px",
                                                                float: "right",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        itemxs={12}
                                                        style={{
                                                            marginTop: "3%",
                                                        }}
                                                    >
                                                        {this.state.viewsToday
                                                            .result >
                                                        this.state.viewsToday
                                                            .prevResult ? (
                                                            <span
                                                                style={{
                                                                    color: "#52D098",
                                                                }}
                                                            >
                                                                <TrendingUpIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .viewsToday
                                                                )}
                                                                %
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    color: "#F76363",
                                                                }}
                                                            >
                                                                <TrendingDownIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .viewsToday
                                                                )}
                                                                %
                                                            </span>
                                                        )}{" "}
                                                        SINCE YESTERDAY
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="dash-item">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        >
                                                            VIEWS THIS WEEK
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant="h3"
                                                            align="left"
                                                            style={{
                                                                paddingTop:
                                                                    "10%",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .viewsThisWeek
                                                                    .result
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <img
                                                            src={
                                                                "https://ik.imagekit.io/9vm1rbs0vzz/public/users_8QArb8hmZFf.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1644130568164"
                                                            }
                                                            alt="multiple"
                                                            style={{
                                                                height: "80px",
                                                                float: "right",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        itemxs={12}
                                                        style={{
                                                            marginTop: "3%",
                                                        }}
                                                    >
                                                        {this.state
                                                            .viewsThisWeek
                                                            .result >
                                                        this.state.viewsThisWeek
                                                            .prevResult ? (
                                                            <span
                                                                style={{
                                                                    color: "#52D098",
                                                                }}
                                                            >
                                                                <TrendingUpIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .viewsThisWeek
                                                                )}
                                                                %
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    color: "#F76363",
                                                                }}
                                                            >
                                                                <TrendingDownIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .viewsThisWeek
                                                                )}
                                                                %
                                                            </span>
                                                        )}{" "}
                                                        SINCE LAST WEEK
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="dash-item">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        >
                                                            VIEWS THIS MONTH
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                paddingTop:
                                                                    "10%",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .viewsThisMonth
                                                                    .result
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <img
                                                            src={
                                                                "https://ik.imagekit.io/9vm1rbs0vzz/public/users_8QArb8hmZFf.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1644130568164"
                                                            }
                                                            alt="multiple"
                                                            style={{
                                                                height: "80px",
                                                                float: "right",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        itemxs={12}
                                                        style={{
                                                            marginTop: "3%",
                                                        }}
                                                    >
                                                        {this.state
                                                            .viewsThisMonth
                                                            .result >
                                                        this.state
                                                            .viewsThisMonth
                                                            .prevResult ? (
                                                            <span
                                                                style={{
                                                                    color: "#52D098",
                                                                }}
                                                            >
                                                                <TrendingUpIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .viewsThisMonth
                                                                )}
                                                                %
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    color: "#F76363",
                                                                }}
                                                            >
                                                                <TrendingDownIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .viewsThisMonth
                                                                )}
                                                                %
                                                            </span>
                                                        )}{" "}
                                                        SINCE LAST MONTH
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="dash-item">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        >
                                                            VIEWS THIS YEAR
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                paddingTop:
                                                                    "10%",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .viewsThisYear
                                                                    .result
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <img
                                                            src={
                                                                "https://ik.imagekit.io/9vm1rbs0vzz/public/users_8QArb8hmZFf.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1644130568164"
                                                            }
                                                            alt="multiple"
                                                            style={{
                                                                height: "80px",
                                                                float: "right",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        itemxs={12}
                                                        style={{
                                                            marginTop: "3%",
                                                        }}
                                                    >
                                                        {this.state
                                                            .viewsThisYear
                                                            .result >
                                                        this.state.viewsThisYear
                                                            .prevResult ? (
                                                            <span
                                                                style={{
                                                                    color: "#52D098",
                                                                }}
                                                            >
                                                                <TrendingUpIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .viewsThisYear
                                                                )}
                                                                %
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    color: "#F76363",
                                                                }}
                                                            >
                                                                <TrendingDownIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .viewsThisYear
                                                                )}
                                                                %
                                                            </span>
                                                        )}{" "}
                                                        SINCE LAST YEAR
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="dash-item">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        >
                                                            SEARCHES TODAY
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant="h3"
                                                            align="left"
                                                            style={{
                                                                paddingTop:
                                                                    "10%",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .searchesToday
                                                                    .result
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        align="right"
                                                    >
                                                        <img
                                                            src={
                                                                "https://ik.imagekit.io/9vm1rbs0vzz/public/search_fUCKfA36C.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1644131028860"
                                                            }
                                                            alt="multiple"
                                                            style={{
                                                                height: "80px",
                                                                float: "right",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        itemxs={12}
                                                        style={{
                                                            marginTop: "3%",
                                                        }}
                                                    >
                                                        {this.state
                                                            .searchesToday
                                                            .result >
                                                        this.state.searchesToday
                                                            .prevResult ? (
                                                            <span
                                                                style={{
                                                                    color: "#52D098",
                                                                }}
                                                            >
                                                                <TrendingUpIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .searchesToday
                                                                )}
                                                                %
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    color: "#F76363",
                                                                }}
                                                            >
                                                                <TrendingDownIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .searchesToday
                                                                )}
                                                                %
                                                            </span>
                                                        )}{" "}
                                                        SINCE YESTERDAY
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="dash-item">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        >
                                                            SEARCHES THIS WEEK
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant="h3"
                                                            align="left"
                                                            style={{
                                                                paddingTop:
                                                                    "10%",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .searchesThisWeek
                                                                    .result
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <img
                                                            src={
                                                                "https://ik.imagekit.io/9vm1rbs0vzz/public/search_fUCKfA36C.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1644131028860"
                                                            }
                                                            alt="multiple"
                                                            style={{
                                                                height: "80px",
                                                                float: "right",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        itemxs={12}
                                                        style={{
                                                            marginTop: "3%",
                                                        }}
                                                    >
                                                        {this.state
                                                            .searchesThisWeek
                                                            .result >
                                                        this.state
                                                            .searchesThisWeek
                                                            .prevResult ? (
                                                            <span
                                                                style={{
                                                                    color: "#52D098",
                                                                }}
                                                            >
                                                                <TrendingUpIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .searchesThisWeek
                                                                )}
                                                                %
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    color: "#F76363",
                                                                }}
                                                            >
                                                                <TrendingDownIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .searchesThisWeek
                                                                )}
                                                                %
                                                            </span>
                                                        )}{" "}
                                                        SINCE LAST WEEK
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="dash-item">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        >
                                                            SEARCHES THIS MONTH
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                paddingTop:
                                                                    "10%",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .searchesThisMonth
                                                                    .result
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <img
                                                            src={
                                                                "https://ik.imagekit.io/9vm1rbs0vzz/public/search_fUCKfA36C.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1644131028860"
                                                            }
                                                            alt="multiple"
                                                            style={{
                                                                height: "80px",
                                                                float: "right",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        itemxs={12}
                                                        style={{
                                                            marginTop: "3%",
                                                        }}
                                                    >
                                                        {this.state
                                                            .searchesThisMonth
                                                            .result >
                                                        this.state
                                                            .searchesThisMonth
                                                            .prevResult ? (
                                                            <span
                                                                style={{
                                                                    color: "#52D098",
                                                                }}
                                                            >
                                                                <TrendingUpIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .searchesThisMonth
                                                                )}
                                                                %
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    color: "#F76363",
                                                                }}
                                                            >
                                                                <TrendingDownIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .searchesThisMonth
                                                                )}
                                                                %
                                                            </span>
                                                        )}{" "}
                                                        SINCE LAST MONTH
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="dash-item">
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                color: "#939393",
                                                            }}
                                                        >
                                                            SEARCHES THIS YEAR
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                paddingTop:
                                                                    "10%",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .searchesThisYear
                                                                    .result
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <img
                                                            src={
                                                                "https://ik.imagekit.io/9vm1rbs0vzz/public/search_fUCKfA36C.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1644131028860"
                                                            }
                                                            alt="multiple"
                                                            style={{
                                                                height: "80px",
                                                                float: "right",
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        itemxs={12}
                                                        style={{
                                                            marginTop: "3%",
                                                        }}
                                                    >
                                                        {this.state
                                                            .searchesThisYear
                                                            .result >
                                                        this.state
                                                            .searchesThisYear
                                                            .prevResult ? (
                                                            <span
                                                                style={{
                                                                    color: "#52D098",
                                                                }}
                                                            >
                                                                <TrendingUpIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .searchesThisYear
                                                                )}
                                                                %
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    color: "#F76363",
                                                                }}
                                                            >
                                                                <TrendingDownIcon
                                                                    style={{
                                                                        marginBottom:
                                                                            "-4%",
                                                                    }}
                                                                />{" "}
                                                                {this.getGrowthRate(
                                                                    this.state
                                                                        .searchesThisYear
                                                                )}
                                                                %
                                                            </span>
                                                        )}{" "}
                                                        SINCE LAST YEAR
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div
                                                style={{
                                                    height: "50vh",
                                                    overflow: "auto",
                                                    padding: "1%",
                                                    margin: "1%",
                                                    boxShadow:
                                                        "0 0.5rem 1rem rgba(0, 0, 0, 0.05)",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        backgroundColor:
                                                            "#efefef",
                                                        marginBottom: "2%",
                                                        borderRadius: "5px",
                                                        padding: "0% 1%",
                                                    }}
                                                >
                                                    Invoices{" "}
                                                    <Link
                                                        href="../manager/invoices"
                                                        style={{
                                                            fontSize: "12px",
                                                            float: "right",
                                                            padding: "1.2%",
                                                        }}
                                                    >
                                                        View All
                                                    </Link>
                                                </Typography>
                                                {this.state.invoices.length >
                                                0 ? (
                                                    <Grid container>
                                                        {invoices}
                                                    </Grid>
                                                ) : (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                            marginTop: "5%",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: "35%",
                                                                height: "auto",
                                                            }}
                                                            src={
                                                                "../images/blank.svg"
                                                            }
                                                            alt="No Data"
                                                        />
                                                        <br />
                                                        <Typography variant="h6">
                                                            No invoices have
                                                            been generated.
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div
                                                style={{
                                                    height: "50vh",
                                                    overflow: "auto",
                                                    padding: "1%",
                                                    margin: "1%",
                                                    boxShadow:
                                                        "0 0.5rem 1rem rgba(0, 0, 0, 0.05)",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        backgroundColor:
                                                            "#efefef",
                                                        marginBottom: "2%",
                                                        borderRadius: "5px",
                                                        padding: "0% 1%",
                                                    }}
                                                >
                                                    Payments
                                                    <Link
                                                        href="../manager/payments"
                                                        style={{
                                                            fontSize: "12px",
                                                            float: "right",
                                                            padding: "1.2%",
                                                        }}
                                                    >
                                                        View All
                                                    </Link>
                                                </Typography>
                                                {this.state.payments.length >
                                                0 ? (
                                                    <div> {/*payments*/}</div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                            marginTop: "5%",
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: "35%",
                                                                height: "auto",
                                                            }}
                                                            src={
                                                                "../images/empty.svg"
                                                            }
                                                            alt="No Data"
                                                        />
                                                        <br />
                                                        <Typography variant="h6">
                                                            No payments have
                                                            been made.
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid
                                        container
                                        spacing={1}
                                        style={{
                                            margin: "3% 0% 3% 3%",
                                            width: "96%",
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            style={{
                                                padding: "2%",
                                                margin: "0% auto",
                                                borderRadius: "5px",
                                                boxShadow:
                                                    "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    display: "block",
                                                    margin: "0% auto",
                                                    align: "center",
                                                    height: "100px",
                                                }}
                                                src="https://ik.imagekit.io/9vm1rbs0vzz/tr:q-100/public/Success_55Nr6qXHqjM.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644103032341"
                                                alt="congrats! You're almost done"
                                            />
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                gutterBottom
                                            >
                                                Congratulations! You're almost
                                                done.
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                align="center"
                                                gutterBottom
                                                style={{
                                                    color: "#888888",
                                                    padding: "0% 5%",
                                                }}
                                            >
                                                For your visibility on WapiDoc
                                                kindly finish setting up your
                                                institution by completing the
                                                steps below.
                                            </Typography>

                                            <List
                                                component="nav"
                                                aria-labelledby="nested-list-subheader"
                                            >
                                                <ListItem
                                                    onClick={() => {
                                                        this.setState({
                                                            step1: !this.state
                                                                .step1,
                                                        });
                                                    }}
                                                    style={{
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <CheckCircleIcon
                                                            fontSize="large"
                                                            sx={{
                                                                color: "#5DC726",
                                                            }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <ListItemText>
                                                            <Typography variant="h6">
                                                                Setup
                                                                Institution
                                                            </Typography>
                                                        </ListItemText>
                                                    </ListItemText>
                                                </ListItem>
                                                <ListItemButton
                                                    onClick={() => {
                                                        this.setState({
                                                            step2: !this.state
                                                                .step2,
                                                        });
                                                    }}
                                                    style={{
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        {this.state.institution
                                                            .status !==
                                                        "subscribed" ? (
                                                            <CheckCircleIcon
                                                                fontSize="large"
                                                                sx={{
                                                                    color: "#dfdfdf",
                                                                }}
                                                            />
                                                        ) : (
                                                            <CheckCircleIcon
                                                                fontSize="large"
                                                                sx={{
                                                                    color: "#5DC726",
                                                                }}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <ListItemText>
                                                            <Typography variant="h6">
                                                                Paid
                                                                subscription
                                                            </Typography>
                                                        </ListItemText>
                                                    </ListItemText>
                                                    {this.state.step2 ? (
                                                        <ExpandLessIcon />
                                                    ) : (
                                                        <ExpandMoreIcon />
                                                    )}
                                                </ListItemButton>
                                                <Collapse
                                                    in={this.state.step2}
                                                    timeout="auto"
                                                    unmountOnExit
                                                    style={{
                                                        background: "#f3f3f3",
                                                        padding: "3%",
                                                        borderRadius: "5px",
                                                        margin: "2% 0%",
                                                    }}
                                                >
                                                    <Typography variant="body1">
                                                        For your visibiliy on
                                                        WapiDoc kindly pay your
                                                        invoice{" "}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "800",
                                                            }}
                                                        >
                                                            #
                                                            {
                                                                this.state
                                                                    .invoices[0]
                                                                    .number
                                                            }
                                                        </span>{" "}
                                                        of KES{" "}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    "800",
                                                            }}
                                                        >
                                                            {
                                                                this.state
                                                                    .invoices[0]
                                                                    .taxed_amount
                                                            }
                                                        </span>{" "}
                                                        to be subscribed for the
                                                        next{" "}
                                                        {this.getSubscriptionDuration()}
                                                        . Pay early and enjoy
                                                        the the full wapidoc features.
                                                    </Typography>

                                                    <Button
                                                        variant="contained"
                                                        align="right"
                                                        disableElevation
                                                        className="submit-button"
                                                        href={`/manager/invoice/${this.state.invoices[0]._id}`}
                                                    >
                                                        Pay Invoice
                                                    </Button>
                                                </Collapse>
                                                <ListItemButton
                                                    onClick={() => {
                                                        this.setState({
                                                            step3: !this.state
                                                                .step3,
                                                        });
                                                    }}
                                                    style={{
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        {this.state.institution
                                                            .gprs.length < 1 ? (
                                                            <CheckCircleIcon
                                                                fontSize="large"
                                                                sx={{
                                                                    color: "#dfdfdf",
                                                                }}
                                                            />
                                                        ) : (
                                                            <CheckCircleIcon
                                                                fontSize="large"
                                                                sx={{
                                                                    color: "#5DC726",
                                                                }}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <ListItemText>
                                                            <Typography variant="h6">
                                                                Set GPS
                                                                coordinates
                                                            </Typography>
                                                        </ListItemText>
                                                    </ListItemText>
                                                    {this.state.step1 ? (
                                                        <ExpandLessIcon />
                                                    ) : (
                                                        <ExpandMoreIcon />
                                                    )}
                                                </ListItemButton>
                                                <Collapse
                                                    in={this.state.step3}
                                                    timeout="auto"
                                                    unmountOnExit
                                                    style={{
                                                        background: "#f3f3f3",
                                                        padding: "3%",
                                                        borderRadius: "5px",
                                                        margin: "2% 0%",
                                                    }}
                                                >
                                                    <Typography variant="body1">
                                                        Your GPS coordinates
                                                        helps users find your
                                                        institutions's location.
                                                        By clicking get
                                                        directions on your
                                                        institution's page users
                                                        will get a pinned
                                                        location of your
                                                        institution.
                                                    </Typography>

                                                    <Button
                                                        variant="contained"
                                                        // size="small"
                                                        align="right"
                                                        disableElevation
                                                        className="submit-button"
                                                        href={`/manager/institution/edit/${this.state.institution._id}`}
                                                    >
                                                        Update Coordinates
                                                    </Button>
                                                </Collapse>
                                                <ListItemButton
                                                    onClick={() => {
                                                        this.setState({
                                                            step4: !this.state
                                                                .step4,
                                                        });
                                                    }}
                                                    style={{
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        {this.state.institution
                                                            .primary_photo ===
                                                        "default_k29jR4Nfr.jpg" ? (
                                                            <CheckCircleIcon
                                                                fontSize="large"
                                                                sx={{
                                                                    color: "#dfdfdf",
                                                                }}
                                                            />
                                                        ) : (
                                                            <CheckCircleIcon
                                                                fontSize="large"
                                                                sx={{
                                                                    color: "#5DC726",
                                                                }}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <ListItemText>
                                                            <Typography variant="h6">
                                                                Set primary
                                                                photo
                                                            </Typography>
                                                        </ListItemText>
                                                    </ListItemText>
                                                    {this.state.step4 ? (
                                                        <ExpandLessIcon />
                                                    ) : (
                                                        <ExpandMoreIcon />
                                                    )}
                                                </ListItemButton>
                                                <Collapse
                                                    in={this.state.step4}
                                                    timeout="auto"
                                                    unmountOnExit
                                                    style={{
                                                        background: "#f3f3f3",
                                                        padding: "3%",
                                                        borderRadius: "5px",
                                                        margin: "2% 0%",
                                                    }}
                                                >
                                                    <Typography variant="body1">
                                                        To improve your
                                                        visibiity on WapiDoc,
                                                        upload some photos on
                                                        your gallery. One will
                                                        be automatically chosen
                                                        as your institution's
                                                        primary photo which you
                                                        can always change later.
                                                    </Typography>

                                                    <Button
                                                        variant="contained"
                                                        // size="small"
                                                        align="right"
                                                        disableElevation
                                                        className="submit-button"
                                                        href="/manager/gallery"
                                                    >
                                                        Visit Gallery
                                                    </Button>
                                                </Collapse>
                                                <ListItemButton
                                                    onClick={() => {
                                                        this.setState({
                                                            step5: !this.state
                                                                .step5,
                                                        });
                                                    }}
                                                    style={{
                                                        borderRadius: "5px",
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        {this.state.services
                                                            .length === 0 ? (
                                                            <CheckCircleIcon
                                                                fontSize="large"
                                                                sx={{
                                                                    color: "#dfdfdf",
                                                                }}
                                                            />
                                                        ) : (
                                                            <CheckCircleIcon
                                                                fontSize="large"
                                                                sx={{
                                                                    color: "#5DC726",
                                                                }}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <ListItemText>
                                                            <Typography variant="h6">
                                                                Create a service
                                                            </Typography>
                                                        </ListItemText>
                                                    </ListItemText>
                                                    {this.state.step5 ? (
                                                        <ExpandLessIcon />
                                                    ) : (
                                                        <ExpandMoreIcon />
                                                    )}
                                                </ListItemButton>
                                                <Collapse
                                                    in={this.state.step5}
                                                    timeout="auto"
                                                    unmountOnExit
                                                    style={{
                                                        background: "#f3f3f3",
                                                        padding: "3%",
                                                        borderRadius: "5px",
                                                        margin: "2% 0%",
                                                    }}
                                                >
                                                    <Typography variant="body1">
                                                        WapiDoc let's you create
                                                        services under your
                                                        institiution's page.
                                                        Services help improve
                                                        your visibility on
                                                        WapiDoc especially when
                                                        users search for a
                                                        healthcare service.
                                                    </Typography>

                                                    <Button
                                                        variant="contained"
                                                        // size="small"
                                                        align="right"
                                                        disableElevation
                                                        className="submit-button"
                                                        href="/manager/services"
                                                    >
                                                        Visit Services
                                                    </Button>
                                                </Collapse>
                                            </List>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    height: "85vh",
                                }}
                            >
                                <CircularProgress
                                    size={45}
                                    style={{
                                        marginTop: "20%",
                                        borderRadius: "100%",
                                        padding: ".4%",
                                        boxShadow:
                                            "0rem 0rem 1rem rgba(0, 0, 0, 0.15)",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(Dashboard);
