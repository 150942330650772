import React from "react";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function FormDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [submitted, setSubmit] = React.useState(false);

    React.useEffect(() => {
        if (props.dialog.open) {
            handleClickOpen();
        }
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.dialog.open = false;
        props.dialog.error = false;
        // props.clear();

        setOpen(false);
        setSubmit(false);
    };

    const handleSubmit = async () => {
        setSubmit(true);

        const response = await props.action();

        if (response) {
            handleClose();
        } else {
            setSubmit(false);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    {props.dialog.error && (
                        <Alert severity="error">
                            {props.error_message === ""
                                ? "Kindly fill in the highlighted fields."
                                : props.dialog.error_message}
                        </Alert>
                    )}
                    {props.content}
                </DialogContent>
                <DialogActions>
                    <Button
                        className="submit-button mini-b"
                        variant="contained"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="submit-button mini-b"
                        variant="contained"
                        onClick={handleSubmit}
                        style={{
                            width: "25%",
                        }}
                    >
                        {!submitted ? (
                            "Create Service"
                        ) : (
                            <CircularProgress
                                size={24}
                                style={{ color: "#ffffff" }}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
