/**
 * Validate whether string is a validate phone number
 * @param {*} phoneNumber
 * return error if invalid
 */
export const validatePhone = (phoneNumber) => {
    if (
        !phoneNumber.match(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
        )
    ) {
        throw new Error("Not a valid phone number");
    }
};

/**
 * Validate whether string is a validate email
 * @param {*} email
 * return error if invalid
 */
export const validateEmail = (email) => {
    if (
        !email
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
    ) {
        throw new Error("Not a valid email address");
    }
};

/**
 * Validate whether password string is too short
 * @param {*} password
 * return error if to short
 */
export const validatePassword = (password) => {
    if (password.length < 7) {
        throw new Error("Password too short");
    }
};
