import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import locations from "../../../pages/manager/locations.json";
import { capitalize } from "../../../helpers/string";

/**
 *  Function that parses county menu items
 * @returns County Menu Items
 */
const parseCounties = () => {
    let countyList = locations
        .map((location) => {
            return Object.keys(location)[0];
        })
        .sort();

    return countyList.map((county) => {
        return (
            <MenuItem key={county} value={county}>
                {capitalize(county)}
            </MenuItem>
        );
    });
};

/**
 * Function that acquires county position in array
 * @param {*} county
 * @returns county index position
 */
const getCountyPos = (county) => {
    let countyPos = "";
    for (let i = 0; i < locations.length; i++) {
        if (Object.keys(locations[i])[0] === county) {
            countyPos = i;
            break;
        }
    }

    return countyPos;
};

/**
 * Function that returns a list of constituencies based on
 * a county
 * @param {*} county
 * @returns constituency list
 */
const getConstArr = (county) => {
    let constituencies = locations[getCountyPos(county)][county];
    return constituencies.map((constituency) => {
        return Object.keys(constituency)[0];
    });
};

/**
 * Function that parses countituency menu items based on
 * a county
 * @returns Constituency Menu Items
 */
const parseConstituencies = (county) => {
    return getConstArr(county).map((constituency) => {
        return (
            <MenuItem key={constituency} value={constituency}>
                {capitalize(constituency)}
            </MenuItem>
        );
    });
};

/**
 * Function that parses wards menu items based on
 * county and constituency
 * @param {*} county
 * @param {*} constituency
 * @returns
 */
let parseWards = (county, constituency) => {
    const pos = getConstArr(county).indexOf(constituency);
    let wardArr = locations[getCountyPos(county)][county][pos][constituency];

    return wardArr.map((ward) => {
        return (
            <MenuItem key={ward} value={ward}>
                {capitalize(ward)}
            </MenuItem>
        );
    });
};

export const Location = ({ data, updateData }) => {
    return (
        <div>
            <FormControl variant="outlined" fullWidth>
                <InputLabel>County</InputLabel>
                <Select
                    label="county"
                    name="county"
                    labelId="county"
                    value={data.county}
                    onChange={(event) => {
                        updateData({
                            ...data,
                            county: event.target.value,
                            constituency: "",
                            ward: "",
                        });
                    }}
                >
                    <MenuItem value="">
                        <em>Choose County</em>
                    </MenuItem>
                    {parseCounties()}
                </Select>
            </FormControl>
            {data.county !== "" && (
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>Choose Constituency</InputLabel>
                    <Select
                        label="choose constituency"
                        name="constituency"
                        labelId="constituency"
                        value={data.constituency}
                        onChange={(event) => {
                            updateData({
                                ...data,
                                constituency: event.target.value,
                                ward: "",
                            });
                        }}
                    >
                        <MenuItem value="">
                            <em>Choose Constituency</em>
                        </MenuItem>
                        {parseConstituencies(data.county)}
                    </Select>
                </FormControl>
            )}
            {data.constituency !== "" && (
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>Choose ward</InputLabel>
                    <Select
                        label="choose ward"
                        name="ward"
                        labelId="ward"
                        value={data.ward}
                        onChange={(event) => {
                            updateData({
                                ...data,
                                ward: event.target.value,
                            });
                        }}
                    >
                        <MenuItem value="">
                            {" "}
                            <em>Chooose ward</em>
                        </MenuItem>
                        {parseWards(data.county, data.constituency)}
                    </Select>
                </FormControl>
            )}
        </div>
    );
};
