import React, { Component } from "react";
import Drawer from "../../components/drawer";
import { postRequest } from "../../middleware/graphQl";
import cogs from "../../middleware/cogs";
import { withSnackbar } from "notistack";

import {
    Typography,
    Button,
    CircularProgress,
    Card,
    CardActionArea,
    CardMedia,
    CardActions,
    Grid,
    Tooltip,
    CardHeader,
    IconButton,
    Switch,
    FormControlLabel,
} from "@material-ui/core";

import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";

class ServicesPage extends Component {
    state = {
        institutions: [],
        loading: true,
        institution: JSON.parse(localStorage.getItem("institution")),
        anchorEl: null,
    };

    componentDidMount = () => {
        this.getInstitutions();
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    getInstitutions = async () => {
        const response = await postRequest("myInstitutions", "manager");

        if (response.errors) {
            this.setState({ loading: false });
            this.props.enqueueSnackbar(response.errors[0].message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
        } else {
            this.setState({
                loading: false,
                institutions: response.data.myInstitutions,
            });
        }
    };

    switchInstitution = async (id) => {
        const response = await postRequest("singleInstitution", "manager", {
            id: id,
        });

        if (response.errors) {
            this.props.enqueueSnackbar(response.errors[0].message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
        } else {
            localStorage.removeItem("institution");

            localStorage.setItem(
                "institution",
                JSON.stringify(response.data.singleInstitution)
            );

            this.setState({
                institution: response.data.singleInstitution,
            });

            this.props.enqueueSnackbar("Switched Institution", {
                variant: "success",
            });
        }
    };

    render() {
        let institutions = this.state.institutions.map((institution) => {
            return (
                <Grid
                    item
                    xs={4}
                    style={{ padding: ".5%" }}
                    key={institution._id}
                >
                    <Card>
                        <CardHeader
                            action={
                                <div>
                                    <Tooltip title="View Institution">
                                        <IconButton
                                            aria-label="view"
                                            href={`../../manager/institution/${institution._id}`}
                                        >
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit Institution">
                                        <IconButton
                                            aria-label="edit"
                                            href={`../../manager/institution/edit/${institution._id}`}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }
                            title={
                                <Typography
                                    variant="subtitle1"
                                    style={{ color: "#333333" }}
                                >
                                    {cogs.capitalize(institution.name)}
                                </Typography>
                            }
                            subheader={
                                <Typography
                                    variant="subtitle2"
                                    style={{ color: "#999999" }}
                                >
                                    {cogs.capitalize(
                                        institution.constituency +
                                            ", " +
                                            institution.county
                                    )}
                                </Typography>
                            }
                        />

                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt={cogs.capitalize(institution.name)}
                                height="150"
                                image={`https://ik.imagekit.io/9vm1rbs0vzz/managers/institutions/${institution.primary_photo}`}
                                title={cogs.capitalize(institution.name)}
                            />
                        </CardActionArea>
                        <CardActions
                            style={{
                                background:
                                    this.state.institution._id ===
                                    institution._id
                                        ? "#89DEE2"
                                        : "#ffffff",
                                padding: "0px 10px",
                                color:
                                    this.state.institution._id ===
                                    institution._id
                                        ? "#ffffff"
                                        : "#333333",
                            }}
                        >
                            <Typography variant="body2">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color={
                                                this.state.institution._id ===
                                                institution._id
                                                    ? "primary"
                                                    : "default"
                                            }
                                            checked={
                                                this.state.institution._id ===
                                                institution._id
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => {
                                                if (
                                                    this.state.institution
                                                        ._id !== institution._id
                                                ) {
                                                    this.switchInstitution(
                                                        institution._id
                                                    );
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        this.state.institution._id ===
                                        institution._id
                                            ? "Current Institution"
                                            : "Switch Institution"
                                    }
                                />
                            </Typography>
                        </CardActions>
                    </Card>
                </Grid>
            );
        });

        return (
            <Drawer
                content={
                    <div>
                        {!this.state.loading ? (
                            <div>
                                {this.state.institutions.length > 0 ? (
                                    <Grid
                                        container
                                        spacing={4}
                                        style={{
                                            width: "100%",
                                            margin: "2% auto",
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                padding: "5px",
                                                textAlign: "right",
                                            }}
                                        >
                                            {JSON.parse(
                                                localStorage.getItem("manager")
                                            ).owns === "multiple" && (
                                                <Button
                                                    className="submit-button add-b"
                                                    variant="contained"
                                                    href={`../../manager/institution/setup/`}
                                                >
                                                    Add New Institution
                                                </Button>
                                            )}
                                        </Grid>
                                        {institutions}
                                    </Grid>
                                ) : (
                                    <div
                                        style={{
                                            textAlign: "center",
                                            marginTop: "5%",
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "35%",
                                                height: "auto",
                                            }}
                                            src={"../images/blank.svg"}
                                            alt="No Data"
                                        />
                                        <br />
                                        <Button
                                            className="submit-button mini-b"
                                            variant="contained"
                                            onClick={() =>
                                                window.location.replace(
                                                    `${process.env.REACT_APP_HOST}/institution/setup`
                                                )
                                            }
                                        >
                                            SetUp Your First Institution
                                        </Button>
                                        <br />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    height: "85vh",
                                }}
                            >
                                <CircularProgress
                                    size={45}
                                    style={{
                                        marginTop: "20%",
                                        borderRadius: "100%",
                                        padding: ".5%",
                                        boxShadow:
                                            "0rem 0rem 1rem rgba(0, 0, 0, 0.15)",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(ServicesPage);
