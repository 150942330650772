import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import { Grid, Typography, Link } from "@material-ui/core";

class TermsandConditionsPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Terms and Conditions</title>
                    <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid
                    container
                    style={{ paddingTop: "5%", paddingBottom: "5%" }}
                    className={"add-space"}
                >
                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ margin: "0% auto", padding: "5%" }}
                        className={"add-space"}
                    >
                        <Typography variant="h4" component="h1" gutterBottom>
                            Terms and Conditions
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Effective Date: October 12, 2021
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We hope you love using WapiDoc. We really do. This
                            document contains important information that you
                            need to consider before using WapiDoc.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            The following terms and conditions (the “Terms of
                            Use” or the “Agreement”) constitute an agreement
                            between you and WapiDoc, Ltd. (“WapiDoc,” “we,” or
                            “us”), the operator of WapiDoc.com and related
                            websites, applications, services and mobile
                            applications, and all associated services
                            (collectively, the “Services”) provided by WapiDoc
                            and on/in which these Terms of Use are posted or
                            referenced. For the purposes of these Terms of Use,
                            the “Services” include, without limitation,
                            scheduling pages maintained by WapiDoc on behalf of
                            third parties and appointment scheduling technology
                            integrated into third party websites. These Terms of
                            Use govern your use of the Services, whether or not
                            you have created a WapiDoc Account. This Agreement
                            constitutes a contract between you and us that
                            governs your access and use of the Services. What
                            does that mean? It means that by accessing and/or
                            using our Services, you agree to all the terms and
                            conditions of this Agreement. If you do not agree,
                            then you may not use the Services. As used in this
                            Agreement, “you” means any visitor, user, or other
                            person who accesses our Services; whether or not
                            such person registered for a WapiDoc Account (as
                            defined in Section 1).
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If your use of the Services is terminated for any
                            reason, then: (a) this Agreement will continue to
                            apply and be binding upon you with regard to your
                            prior use of the Services (as well as any subsequent
                            and unauthorized use of the Services), including
                            your indemnification obligations as described
                            herein; and (b) any rights or licenses granted to us
                            under this Agreement will survive such termination.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WapiDoc’s collection and use of Personal Information
                            (as defined in Section 5) in connection with the
                            Services is described in WapiDoc’s{" "}
                            <Link href="../legal/privacy-policy">
                                Privacy Policy
                            </Link>
                            .
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            IMPORTANT: PLEASE REVIEW THE ARBITRATION NOTICE AND
                            CLASS ACTION WAIVER IN SECTION 17.1 BELOW CAREFULLY,
                            AS IT WILL REQUIRE YOU TO RESOLVE DISPUTES BETWEEN
                            YOU AND WAPIDOC BY BINDING, INDIVIDUAL ARBITRATION.
                            YOU ACKNOWLEDGE AND AGREE THAT YOU AND WAPIDOC ARE
                            EACH WAIVING THE RIGHT TO A TRIAL BY JURY. YOU
                            FURTHER ACKNOWLEDGE AND AGREE THAT YOU WAIVE YOUR
                            RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER
                            IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE
                            PROCEEDING AGAINST WAPIDOC. BY ENTERING THIS
                            AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE
                            READ AND UNDERSTOOD, AND AGREE TO BE BOUND BY, ALL
                            OF THE TERMS AND CONDITIONS OF THIS AGREEMENT AND
                            HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS
                            IMPORTANT DECISION.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We may change this Agreement at any time and without
                            prior notice. If we make a change to this Agreement,
                            it will be effective as soon as we post it and the
                            most current version of this Agreement will always
                            be posted under the "Terms of Use" link available on
                            our website(s) and mobile application(s) ("Updated
                            Terms"). If we make a material change to the
                            Agreement, we will make reasonable efforts to notify
                            you. You agree that you will review this Agreement
                            periodically. By continuing to access and/or use the
                            Services after we post Updated Terms, you agree to
                            be bound by the Updated Terms, and if you do not
                            agree to the Updated Terms, you must stop using
                            and/or accessing the Services. Any disputes under
                            this Agreement will be governed by the version of
                            Agreement in effect at the time of the first event
                            which gave rise to the dispute. Except for changes
                            by us as described here, no other amendment or
                            modification of these Terms of Use will be effective
                            unless in writing and signed by both you and us.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We may from time to time add new features to the
                            Services, substitute a new service for one of the
                            existing Services, or discontinue or suspend one or
                            any part of the existing Services. Under no
                            circumstances will WapiDoc be liable for any
                            suspension or discontinuation of any of the Services
                            or portion thereof, and any use of new services will
                            be governed by this Agreement.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If you create a WapiDoc Account or use the Services
                            on behalf of an individual or entity other than
                            yourself, you represent that you are authorized by
                            such individual or entity to accept this Agreement
                            on such individual’s or entity’s behalf and bind
                            them to this Agreement (in which case, the
                            references to “you” and “your” in this Agreement,
                            except for in this sentence, refer to that
                            individual or entity).
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            1. ABOUT THE SERVICES
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Subject to these Terms of Use, WapiDoc grants you a
                            limited, non-exclusive, revocable,
                            non-sublicensable, non-transferable license to use
                            the Services in accordance with these Terms of Use.
                            Portions of the Services can be viewed without a
                            WapiDoc account. To benefit from all of the Services
                            we offer, including but limited to booking medical
                            appointments, receiving appointment-related
                            messaging, and utilizing the WapiDoc Video Service
                            (as defined in Section 8), you must create an
                            account (a “WapiDoc Account”) and provide certain
                            basic information about yourself. If you do provide
                            us with any information, you authorize WapiDoc to
                            use and disclose it as described in our{" "}
                            <Link href="../legal/privacy-policy">
                                Privacy Policy
                            </Link>
                            .
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You acknowledge that although some Content may be
                            provided by healthcare professionals, the provision
                            of such Content does not create a medical
                            professional/patient relationship, and does not
                            constitute an opinion, medical advice, or diagnosis
                            or treatment, but is provided to assist you in
                            choosing a doctor, dentist or other healthcare
                            specialist, professional, provider, organization, or
                            agents or affiliates thereof (collectively,
                            “Healthcare Providers”) or otherwise be generally
                            informative. “Content” means content, text, data,
                            graphics, images, photographs, video, audio,
                            information, suggestions, articles, scheduling
                            availability, guidance, and other materials
                            provided, made available or otherwise found through
                            the Services, including, without limitation, Content
                            provided in direct response to your questions or
                            postings.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WHILE WE MAKE REASONABLE EFFORTS TO PROVIDE YOU WITH
                            ACCURATE CONTENT, WE MAKE NO GUARANTEES,
                            REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESS OR
                            IMPLIED, WITH RESPECT TO ANY CONTENT (INCLUDING BUT
                            NOT LIMITED TO DESCRIPTIONS OF PROFESSIONAL
                            QUALIFICATIONS, EXPERTISE, QUALITY OF WORK, PRICE OR
                            COST INFORMATION, INSURANCE COVERAGE OR BENEFIT
                            INFORMATION). IN NO EVENT SHALL WE BE LIABLE TO YOU
                            OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN
                            BY YOU IN RELIANCE ON ANY SUCH CONTENT. FURTHERMORE,
                            WE DO NOT IN ANY WAY ENDORSE, REFER OR RECOMMEND ANY
                            INDIVIDUAL OR ENTITY LISTED IN CONTENT AND/OR
                            ACCESSIBLE THROUGH THE SERVICES.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            2. WE DO NOT PROVIDE MEDICAL ADVICE
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WapiDoc is not a healthcare provider. The Content
                            that you obtain or receive from WapiDoc, its
                            employees, contractors, partners, sponsors,
                            advertisers, licensors or otherwise through the
                            Services, is for informational, scheduling and
                            payment purposes only. All medically related
                            information, including, without limitation,
                            information shared via WapiDoc Answers, WapiDoc
                            Knowledge Base, WapiDoc Blog, WapiDoc social
                            channels, WapiDoc emails and text messages, and
                            WapiDoc advertising is for informational and
                            communicative purposes only.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WHILE WE HOPE THE CONTENT IS USEFUL IN YOUR
                            HEALTHCARE JOURNEY, IT IS NOT INTENDED AS A
                            SUBSTITUTE FOR, NOR DOES IT REPLACE, PROFESSIONAL
                            MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. DO NOT USE
                            THE SERVICES FOR EMERGENCY MEDICAL NEEDS. IF YOU
                            EXPERIENCE A MEDICAL OR MENTAL HEALTH EMERGENCY, ARE
                            THINKING ABOUT SUICIDE OR TAKING ACTIONS THAT MAY
                            CAUSE HARM TO YOU OR TO OTHERS, IMMEDIATELY CALL A
                            HEALTHCARE PROFESSIONAL AND/OR 911. YOUR USE OF THE
                            SERVICES IS SOLELY AT YOUR OWN RISK. NOTHING STATED,
                            POSTED, OR AVAILABLE THROUGH ANY SERVICES IS
                            INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE
                            PRACTICE OF MEDICINE, DENTISTRY, NURSING, PHARMACY,
                            OR OTHER PROFESSIONAL HEALTHCARE ADVICE, OR THE
                            PROVISION OF MEDICAL CARE.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WapiDoc is not a referral service and does not
                            refer, recommend or endorse any particular
                            Healthcare Provider, test, procedure, opinion, or
                            other information that may appear through the
                            Services. If you rely on any Content, you do so
                            solely at your own risk. We encourage you to
                            independently confirm any Content relevant to you
                            with other sources, including the Healthcare
                            Provider’s office, medical associations relevant to
                            the applicable specialty, your state medical boards,
                            and the appropriate licensing or certification
                            authorities to verify listed credentials and
                            education.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            3. NO DOCTOR PATIENT RELATIONSHIP
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            DOCTORS, DENTISTS, NURSES, AND OTHER MEDICAL
                            PROFESSIONALS USE THE SERVICES TO SHARE CONTENT WITH
                            YOU, BUT YOUR USE OF THIS CONTENT IS NOT A
                            SUBSTITUTE FOR HEALTHCARE. NO LICENSED MEDICAL
                            PROFESSIONAL/PATIENT RELATIONSHIP IS CREATED WHEN
                            YOU USE THE SERVICES OR CONTENT. THIS IS TRUE
                            WHETHER SUCH CONTENT IS PROVIDED BY OR THROUGH THE
                            USE OF THE SERVICES OR THROUGH ANY OTHER
                            COMMUNICATIONS FROM WAPIDOC INCLUDING, WITHOUT
                            LIMITATION, THE “FIND A DOCTOR” FEATURE, WAPIDOC
                            ANSWERS, WAPIDOC KNOWLEDGE BASE, WAPIDOC BLOG,
                            WAPIDOC SOCIAL CHANNELS, WAPIDOC EMAILS OR TEXT
                            MESSAGE LINKS TO OTHER SITES, OR ANY ASSISTANCE WE
                            MAY PROVIDE TO HELP YOU FIND AN APPROPRIATE
                            HEALTHCARE PROVIDER IN ANY FIELD.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WapiDoc encourages Healthcare Providers to use the
                            Services responsibly, but we have no control over,
                            and cannot guarantee the availability of, any
                            Healthcare Provider at any particular time. We will
                            not be liable for cancelled or otherwise unfulfilled
                            appointments, or any injury or loss resulting
                            therefrom, or for any other injury or loss resulting
                            or arising from, or related to, the use of the
                            Services whatsoever.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            4. AUTHORIZATION AND ACKNOWLEDGEMENT; IMPORTANT
                            INFORMATION ABOUT HEALTHCARE PROVIDER RELATIONSHIPS
                            AND HEALTHCARE PROVIDER LISTS
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            In connection with using the Services to schedule
                            appointments with Healthcare Providers, you
                            understand that: <br />
                            <br />
                            YOU ARE RESPONSIBLE FOR CHOOSING YOUR OWN HEALTHCARE
                            PROVIDER, INCLUDING WITHOUT LIMITATION, DETERMINING
                            WHETHER THE APPLICABLE HEALTHCARE PROVIDER IS
                            SUITABLE FOR YOUR HEALTHCARE NEEDS BASED ON
                            SPECIALTY, EXPERIENCE, QUALIFICATION, LICENSES AND
                            OTHER IMPORTANT FACTS AND CIRCUMSTANCES THAT COULD
                            IMPACT YOUR CARE. <br />
                            <br />
                            WapiDoc or its designee takes certain limited steps
                            to (a) verify that Healthcare Providers
                            participating in the Services hold certain active
                            licenses, certifications or registrations required
                            by law to practice the specialties of the services
                            offered by them through the Services, and (b) verify
                            that Healthcare Providers are not listed in the U.S.
                            Department of Health and Human Services Office of
                            the Inspector General Exclusion database. WapiDoc
                            may also exclude Healthcare Providers from our
                            Services who, in WapiDoc’s discretion, have engaged
                            in inappropriate or unprofessional conduct
                            (including, but not limited to, conduct in violation
                            of our Community Standards). <br />
                            <br />
                            While all qualified providers are eligible to use
                            our Services, WapiDoc’s lists of Healthcare
                            Providers are not exhaustive. Some Healthcare
                            Providers listed through the Services enter into
                            contracts with us, and may pay us fees in order to
                            be marketed through or to use the Services. If you
                            book an appointment with a Healthcare Provider
                            through the Services, WapiDoc may receive a
                            commission. <br />
                            <br />
                            To help you find Healthcare Providers who may be
                            suitable for your needs, and enable the maximum
                            choice and diversity of Healthcare Providers
                            participating in the Services, we will provide you
                            with lists and/or profiles of Healthcare Providers.
                            These results are based on information that you
                            provide to us, such as insurance information,
                            geographical location, and healthcare specialty
                            and/or services they provide. They may also be based
                            on other criteria (including, for example,
                            Healthcare Provider availability, past selections by
                            and/or ratings of Healthcare Providers by you or by
                            other WapiDoc users, and past experience of WapiDoc
                            users with Healthcare Providers). Please note that
                            there may be other Healthcare Providers in your area
                            who are not available through WapiDoc. <br />
                            <br />
                            WapiDoc (a) does not employ, refer, recommend, or
                            endorse any Healthcare Providers, (b) does not make
                            any representations or warranties with respect to
                            these Healthcare Providers or the quality of the
                            services they may provide, (c) is not responsible
                            for ensuring that information (including
                            credentials) a Healthcare Provider provides about
                            himself or herself is accurate or up-to-date, and
                            (d) does not receive any additional fees from
                            Healthcare Providers for featuring them (i.e.,
                            higher or better placement on lists) through the
                            Services (subject to Sponsored Results as described
                            below). Note, however, to the extent that you use
                            the Services as provided by your employer, WapiDoc
                            may provide lists and/or profile previews based also
                            on criteria determined by your employer and your
                            employer’s agents or advisors. In addition, to the
                            extent that WapiDoc serves as a platform for, or
                            provides technical support to, the provider
                            directory associated with your health coverage,
                            Healthcare Providers will appear in the directory
                            based on criteria determined by your health plan or
                            plan sponsor, as applicable. <br />
                            <br />
                            We may show you advertisements or sponsored results
                            (“Sponsored Results”) through the Services,
                            including above or interspersed within the search
                            results. WapiDoc receives additional fees from
                            Healthcare Providers for including them in Sponsored
                            Results, and such Sponsored Results are not, and
                            should not be considered, an endorsement, referral
                            or recommendation by WapiDoc of the Healthcare
                            Provider. Sponsored Results are conspicuously
                            labeled as “Sponsored Results” or “Paid Ads” in the
                            Services.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            5. THE SERVICES AND CONTENT ARE INFORMATIONAL AND
                            EDUCATIONAL RESOURCES
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            The Services are an informational and educational
                            resource for consumers and Healthcare Providers. We
                            may, but have no obligation to, publish Content
                            through the Services that is reviewed by our
                            editorial personnel. No party (including WapiDoc)
                            involved in the preparation or publication of such
                            works guarantees that the Content is timely,
                            accurate or complete, and they will not be
                            responsible or liable for any errors or omissions
                            in, or for the results obtained from the use of,
                            such Content. The Services are an informational and
                            educational resource for consumers and Healthcare
                            Providers. We may, but have no obligation to,
                            publish Content through the Services that is
                            reviewed by our editorial personnel. No party
                            (including WapiDoc) involved in the preparation or
                            publication of such works guarantees that the
                            Content is timely, accurate or complete, and they
                            will not be responsible or liable for any errors or
                            omissions in, or for the results obtained from the
                            use of, such Content.
                            <br />
                            <br />
                            Healthcare Provider Content:
                            <br />
                            <br />
                            Content related to Healthcare Providers and their
                            practices (“Healthcare Provider Content”) is
                            intended for general reference purposes only.
                            Healthcare Provider Content may be provided by the
                            Healthcare Provider and/or office staff, and
                            collected from multiple other data sources that may
                            not be confirmed by the Healthcare Provider. While
                            we make efforts to confirm Healthcare Provider
                            Content and keep it updated, it can change
                            frequently and may become out of date, incomplete or
                            inaccurate at any time. WapiDoc does not provide any
                            advice or certification regarding the qualifications
                            of any particular Healthcare Provider.
                            <br />
                            <br />
                            Procedures/Products/Services:
                            <br />
                            <br />
                            Procedures, products, services and devices discussed
                            and/or marketed through the Services are not
                            applicable to all individuals, patients or all
                            clinical situations. Any procedures, products,
                            services or devices represented through the Services
                            by advertisers, sponsors, and other participants of
                            the Services, either paid or unpaid, are presented
                            for your awareness and do not necessarily imply, and
                            we make no claims as to, safety or appropriateness
                            for any particular individual or prediction of
                            effectiveness, outcome or success.
                            <br />
                            <br />
                            Insurance Content:
                            <br />
                            <br />
                            Insurance and insurance related Content (including,
                            without limitation, insurance coverage and benefit
                            Content) (“Insurance Content”) is intended for
                            general reference purposes and for your convenience
                            only. Insurance Content may be based on Personal
                            Information (as defined below) you provide to us as
                            well as Healthcare Provider Content (such as CPT
                            codes and other encounter data). Insurance Content
                            may also be provided either directly by the
                            insurance provider you identify or via a third-party
                            clearinghouse. Insurance Content can change
                            frequently and may become out of date, incomplete or
                            inaccurate. You agree that you will (a) provide
                            accurate and complete insurance-related Personal
                            Information, and (b) verify and maintain accurate
                            insurance-related Personal Information (including,
                            without limitation, verifying such Personal
                            Information obtained by automated means from an
                            insurance card you provide). Although WapiDoc
                            strives to provide you with accurate Insurance
                            Content, we are not responsible for any inaccurate,
                            incomplete or outdated Insurance Content, and you
                            are responsible for complying with subparts (a) and
                            (b) hereof.
                            <br />
                            <br />
                            “Personal Information” means, without limitation,
                            your name, address, social security number and
                            contact information, insurance information, medical
                            history and current medical needs, billing
                            information, and other personally identifiable
                            information.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            6. MEDICAL INFORMATION
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You may elect to enter certain medical information
                            into the Services, including by requesting an
                            appointment or filling out a medical history form
                            (“Medical History Form”) on behalf of yourself or a
                            third party from whom you have authorization to
                            provide such information. You can request (and in
                            doing so, authorize) WapiDoc to provide this
                            information to your chosen Healthcare Provider. You
                            acknowledge and agree that such information will be
                            reviewed and approved by you or someone authorized
                            by you at the time of your appointment to ensure its
                            accuracy. You also acknowledge that WapiDoc may use
                            the data or information you provide in a Medical
                            History Form in accordance with our{" "}
                            <Link href="../legal/privacy-policy">
                                Privacy Policy
                            </Link>
                            .
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            7. YOUR RESPONSIBILITIES
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            7.1 Your WapiDoc Account Credentials
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            When you create a WapiDoc Account, you will provide
                            an email address and create a password
                            (collectively, “Credentials”). You should keep your
                            Credentials private and not share your Credentials
                            with anyone else. You must immediately notify us if
                            your password has been stolen or compromised by
                            sending an email to service@wapidoc.com. You promise
                            to provide us with accurate, complete, and updated
                            registration information about yourself. You may
                            also be able to connect to the Services maintained
                            by a third party institution, such as Facebook,
                            Inc., Google LLC (“Google”) or Apple Inc. (“Apple”).
                            If you connect to the Services through a third party
                            service, you give us permission to access and use
                            your information from such third party service as
                            permitted by such third party service, and to store
                            your log-in credentials for such third party
                            service. You are ultimately in control of how much
                            information is accessible to us and may exercise
                            such control by adjusting your privacy settings on
                            such third party service. WapiDoc has no control
                            over, and assumes no responsibility for, the
                            services, content, accuracy, privacy policies, or
                            practices of or opinions expressed by any such third
                            party.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            7.2 Your Responsibilities Generally
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            While it’s free to create a WapiDoc account and
                            download our app, you are still responsible for your
                            healthcare expenses. Any charges for any medical or
                            related services rendered by Healthcare Providers
                            will apply and will be entirely your responsibility.
                            You are responsible for ensuring that all
                            information that you provide to WapiDoc is accurate
                            and up-to-date, including your insurance
                            information. Some Services may not be available
                            through WapiDoc or your Healthcare Provider
                            depending upon a number of factors, including your
                            insurance participation. Ultimately, you must
                            resolve any dispute between you or any Healthcare
                            Provider arising from any transaction hereunder
                            directly with the Healthcare Provider.
                            <br />
                            <br />
                            You are responsible for all use of the Services and
                            for all use of your Credentials, including use by
                            others to whom you have given your Credentials. You
                            may only use the Services for lawful, non-commercial
                            purposes. If your use of the Services is prohibited
                            by applicable laws, then you aren’t authorized to
                            use the Services. You may not use the Services in
                            any manner that could damage, disable, overburden,
                            or impair our servers or networks, or interfere with
                            any other party’s use and enjoyment of the Services.
                            You may not attempt to gain unauthorized access to
                            any of the Services, user accounts, or computer
                            systems or networks, through hacking, password
                            mining or any other means. You may not accumulate or
                            index, directly or indirectly, any Content or
                            portion of the Services (including, without
                            limitation, Healthcare Provider Content, appointment
                            availability, price information, and Insurance
                            Content) for any purpose whatsoever.
                            <br />
                            <br />
                            You are also responsible for reviewing and complying
                            with the terms set forth in our Acceptable Use
                            Policy and our Community Standards.
                            <br />
                            <br />
                            In addition to our rights in these Terms of Use, we
                            may take any legal action and implement any
                            technological measures to prevent violations of the
                            restrictions hereunder and to enforce these Terms of
                            Use, our Acceptable Use Policy, and our Community
                            Standards.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            7.3 Responsibilities of Healthcare Providers and
                            Others in the Healthcare or Medical Industries
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If you are a Healthcare Provider or other person or
                            entity in the healthcare or medical industries,
                            regardless of whether you maintain a WapiDoc Account
                            or whether you schedule or intend to schedule
                            appointments through the Services, you acknowledge
                            and agree that:
                            <br />
                            <br />
                            (a) You will not use the Services to view, access or
                            otherwise use, directly or indirectly, price,
                            availability, or other Content for any purpose other
                            than your own personal use as a patient or
                            prospective patient;
                            <br />
                            <br />
                            (b) You will not use the Services to establish,
                            attempt to establish, or enforce, directly or
                            indirectly, any agreement or coordination of the
                            prices charged for any product or service; the
                            kinds, frequencies or amounts of any product or
                            service offered; or the customer or customer
                            categories for any product or service, or otherwise
                            engage or attempt to engage in price fixing, output
                            restriction, or customer or market allocation; and
                            <br />
                            <br />
                            (c) You will not use the Services, directly or
                            indirectly, to engage in any anti-competitive,
                            deceptive or unfair practices (including but not
                            limited to booking fraudulent healthcare
                            appointments), or otherwise violate applicable
                            antitrust, competition or consumer protection laws,
                            or regulations.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            7.4 Disputes Between Users
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If there is a dispute between participants on this
                            site or Services, or between users and any third
                            party (including but not limited to Healthcare
                            Providers), you agree that WapiDoc is under no
                            obligation to become involved. In the event that you
                            have a dispute with one or more other users or
                            Healthcare Providers, you release WapiDoc, its
                            directors, officers, employees, agents, and
                            successors from claims, demands, and damages of
                            every kind or nature, known or unknown, suspected or
                            unsuspected, disclosed or undisclosed, arising out
                            of or in any way related to such disputes and/or our
                            Services.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            8. Telemedicine Services
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You may be able to utilize certain telemedicine
                            services through the Services, including but not
                            limited to via WapiDoc’s video service (“WapiDoc
                            Video Service”). WapiDoc is not a healthcare
                            provider, insurance provider, or a prescription
                            fulfillment warehouse. In providing the WapiDoc
                            Video Service, WapiDoc’s role is limited to making
                            certain telehealth related information available to
                            you and/or facilitate your access to telemedicine
                            services provided by Healthcare Providers. WapiDoc
                            is not responsible for such Healthcare Providers’
                            acts or omissions, or for any content of your
                            communications with them. WapiDoc is independent of
                            the Healthcare Providers, and does not employ or
                            otherwise exercise any control over the services
                            provided by Healthcare Providers, regardless of
                            whether they utilize the WapiDoc Video Service.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            9. THIRD PARTY LINKS AND SERVICES
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            9.1 Links to Other Websites
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            While using the Services, you may encounter links to
                            other websites. These links are provided for your
                            convenience only and we do not endorse these sites
                            or the products and services they provide. You
                            acknowledge and agree that we are not responsible or
                            liable for the content or accuracy of these other
                            websites. Although we attempt to link to trustworthy
                            websites, it is possible that they will contain
                            materials that are objectionable, unlawful, or
                            inaccurate and we will not be responsible or liable
                            for the legality or decency of material contained in
                            or accessed through such other websites. By using
                            the Services, you release and hold us harmless from
                            any and all liability arising from your use of any
                            third-party website or service. Your interactions
                            with organizations and/or individuals found on or
                            through the Services, including payment and delivery
                            of goods or services, and any other terms,
                            conditions, warranties or representations associated
                            with such dealing, are solely between you and such
                            organizations and/or individuals. You should make
                            whatever investigation you feel necessary or
                            appropriate before proceeding with any online or
                            offline transaction with any of these third parties.
                            You agree that WapiDoc will not be responsible or
                            liable for any loss or damage of any sort incurred
                            as the result of any such dealings.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            9.2 Third Party Software
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We may incorporate third party software as part of
                            certain of the Services, including without
                            limitation open source third party software. Your
                            use of such third party software is subject to any
                            and all applicable additional terms and conditions
                            governing such use provided by the third party
                            software provider. Where applicable, additional
                            notices relating to the third party software may be
                            provided by us, which for example may contain
                            attribution and disclaimer notices applicable to the
                            third party software.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            9.3 Application Stores
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You acknowledge and agree that the availability of
                            our mobile application is dependent on the third
                            party stores from which you download the application
                            (e.g., the Apple App Store, Google Play or other
                            store or distribution platform (each, an
                            “Application Provider”)). Each Application Provider
                            may have its own terms and conditions to which you
                            must agree before downloading mobile applications
                            from such store. You agree to comply with, and your
                            license to use our application is conditioned upon
                            your compliance with, such Application Provider
                            terms and conditions. To the extent such other terms
                            and conditions from such Application Provider are
                            less restrictive than, or otherwise conflict with,
                            the terms and conditions of these Terms of Use, the
                            more restrictive or conflicting terms and conditions
                            in these Terms of Use apply.
                            <br />
                            <br />
                            If you are accessing the Services via an application
                            (an “Application”) through an Application Provider,
                            you acknowledge and agree that: (i) these Terms of
                            Use are between you and us, and not with the
                            Application Provider, and that we are responsible
                            for the Service, not the Application Provider; (ii)
                            the Application Provider has no obligation to
                            furnish maintenance and support services or handle
                            any warranty claims; (iii) the Application Provider
                            is not responsible for addressing any claims you or
                            any third party have relating to the Application;
                            (iv) the Application Provider is a third party
                            beneficiary of these Terms of Use as related to your
                            use of the Application, and the Application Provider
                            will have the right (and will be deemed to have
                            accepted the right) to enforce these Terms of Use as
                            related to your use of the Application against you;
                            (v) in the event of any third-party claim that the
                            Application or your possession and use of the
                            Application infringes that third party’s
                            intellectual property rights, WapiDoc, and not
                            Application Provider, will be responsible for the
                            investigation, defense, settlement, and discharge of
                            any such infringement claim; and (vi) you are not
                            located in a country subject to a U.S. government
                            embargo, or that has been designated by the U.S.
                            government as a “terrorist supporting” country, and
                            that you are not listed on any U.S. government list
                            of prohibited or restricted parties. The foregoing
                            terms apply to your use of all the Services,
                            including the Application.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            10. PAYMENT AND TRANSACTION PROCESSING
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            10.1 Payment and Transaction Processing Generally
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You may be permitted to use the Services to pay for
                            Designated Provider Services (as defined in Section
                            10.2), other products or services provided by the
                            applicable Healthcare Provider, and/or other payment
                            obligations, including, for example, your insurance
                            copayments. In connection with such payments, we
                            process your payment card information in accordance
                            with our Privacy Policy. WapiDoc and/or its payment
                            processing partner may collect from the applicable
                            Healthcare Provider a transaction processing fee for
                            our billing, collection and payment services (the
                            “Transaction Processing Services”) performed in
                            connection with such payment. Please note that any
                            payment terms presented to you in the process of
                            using or signing up for a paid service or the
                            Transaction Processing Services are deemed part of
                            these Terms.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            10.2 Designated Provider Services
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Certain Healthcare Providers may provide through the
                            Services price information for their healthcare and
                            related products and services (“Designated Provider
                            Services”), and permit you to use the Services to
                            pay for such Designated Provider Services.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Prior to scheduling an appointment for Designated
                            Provider Services, please carefully review (a) the
                            price information, and (b) all of the products and
                            services that are included and excluded in each
                            Designated Provider Service. Healthcare Providers
                            are required to provide the applicable Designated
                            Provider Service at or below the price indicated at
                            the time of scheduling (“Designated Price”), unless
                            you separately agree otherwise. Services in addition
                            to or different from Designated Provider Services
                            may be available, offered or rendered by Healthcare
                            Providers. You and your Healthcare Providers are
                            responsible for agreeing to pricing for any
                            additional or different services. WAPIDOC IS NOT
                            RESPONSIBLE FOR YOUR OR ANY HEALTHCARE PROVIDER’S
                            FAILURE TO AGREE TO PRICING FOR ANY ADDITIONAL OR
                            DIFFERENT SERVICES.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            10.3 Payment of Your Financial Obligations Under
                            Your Insurance Coverage and Benefits
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Certain Healthcare Providers may permit you to use
                            the Services to pay for all or a portion of your
                            financial obligations under your insurance coverage
                            and benefits (the “Insurance Payment Services”).
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            While we endeavor to provide accurate Insurance
                            Content (for example, your copayment obligation), we
                            make no guarantees and disclaim all warranties that
                            the Insurance Content is correct. You acknowledge
                            that Insurance Content is provided by the insurance
                            provider or via a third-party clearinghouse. Please
                            also keep in mind that (a) Insurance Content is
                            constantly being updated (for example, to reflect
                            amounts you may have paid earlier towards your
                            deductible), (b) you are likely to receive an
                            explanation of benefits (or similar document) from
                            your insurance institution after you receive your
                            Healthcare Provider’s services, and (c) you may
                            ultimately owe your Healthcare Provider more than
                            the amount you paid through the Insurance Payment
                            Services.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            10.4 Transaction Processing
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            The transaction processing fee will not exceed the
                            fair market value for the Transaction Processing
                            Services provided by WapiDoc and its payment
                            processing partner. Healthcare Providers may
                            separately charge you for additional or different
                            products or services.
                            <br />
                            <br />
                            You acknowledge and agree that:
                            <br />
                            <br />
                            (a) you are responsible for and you will pay the
                            Designated Price for the Designated Provider
                            Services charged through WapiDoc by the applicable
                            Healthcare Provider, as well as other amounts the
                            applicable Healthcare Provider may charge through
                            WapiDoc for any additional or different services
                            rendered during or related to the applicable
                            appointment;
                            <br />
                            <br />
                            (b) you remain responsible for paying all amounts
                            required by law and/or contract (e.g., health plan
                            agreement), including all cost-sharing obligations
                            (such as, but not limited to, copayments,
                            deductibles and other coinsurance obligations);
                            <br />
                            <br />
                            (c) if you utilize our Transaction Processing
                            Services, WapiDoc may process your payment, and may
                            do so in collaboration with our payment processing
                            partner;
                            <br />
                            <br />
                            (d) WapiDoc is not responsible for any charges
                            incurred for any products or services provided by
                            Healthcare Provider, including any Designated
                            Provider Service;
                            <br />
                            <br />
                            (e) WapiDoc is not responsible for any charges
                            submitted for processing by Healthcare Providers;
                            <br />
                            <br />
                            (f) in the event you dispute any fees chargeable or
                            charged through WapiDoc by a Healthcare Provider,
                            you will resolve such dispute directly with the
                            applicable Healthcare Provider;
                            <br />
                            <br />
                            (g) if you utilize our Transaction Processing
                            Services, you may be required to accept the terms of
                            use and privacy policy of our payment processing
                            partner with respect to Transaction Processing
                            Services; and
                            <br />
                            <br />
                            (h) you will promptly review all charges processed
                            through the Transaction Processing Services, and
                            immediately notify WapiDoc to the extent you have
                            any questions, concerns or disputes; in no event may
                            you raise any questions, concerns or disputes after
                            twelve (12) months from the date of the applicable
                            transaction; and
                            <br />
                            <br />
                            We are not responsible for any error by, or other
                            acts or omissions of, the payment processor. You
                            further acknowledge and agree that neither WapiDoc
                            nor our payment processing partner will be
                            responsible if either we or our payment processing
                            partner are unable to complete a transaction for any
                            reason, including but not limited to:
                            <br />
                            <br />
                            (A) if you have not provided us with accurate,
                            current and complete payment information;
                            <br />
                            <br />
                            (B) if you do not have sufficient available funds or
                            available credit to complete the transaction;
                            <br />
                            <br />
                            (C) if you do not have an active payment card, or if
                            we are unable to confirm your payment card
                            information or your identity;
                            <br />
                            <br />
                            (D) if your account with us, your account with our
                            payment processing partner, your access to the
                            Services, or your access to our payment processing
                            partner’s services has been terminated or suspended
                            for any reason;
                            <br />
                            <br />
                            (E) if we or our payment processing partner have
                            reason to believe that the requested transaction is
                            unauthorized; or
                            <br />
                            <br />
                            (F) if we terminate or suspend the services we
                            provide to the applicable Healthcare Provider.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            11. REVIEWS AND OTHER CONTENT YOU POST OR SUBMIT
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You will have the opportunity to submit feedback
                            regarding your experiences with Healthcare Providers
                            you find through the Services, to submit inquiries
                            concerning possible medical needs, and to
                            participate in the other interactive or community
                            features of the Services (collectively, “Posted
                            Information”). It is important that you act
                            responsibly when providing Posted Information. Your
                            Posted Information must comply with our Acceptable
                            Use Policy, and your Healthcare Provider reviews
                            must comply with our posted guidelines, as well as
                            any internal policies we may develop and implement
                            from time to time. Please note that while WapiDoc
                            may moderate Posted Information (including but not
                            limited to Healthcare Provider reviews) for
                            compliance with the requirements in this , WapiDoc
                            does not endorse or confirm the accuracy of any
                            statements made in such Posted Information; Posted
                            Information reflects solely the views or opinions of
                            the author, and not of WapiDoc.
                            <br />
                            <br />
                            Please note that all of the following licenses are
                            subject to our Privacy Policy to the extent they
                            relate to Posted Information that is also your
                            personally-identifiable information.
                            <br />
                            <br />
                            By posting Posted Information through the Services,
                            you agree to and hereby grant, and you represent and
                            warrant that you have the right to grant, to WapiDoc
                            and its affiliates, agents, and contractors an
                            irrevocable, perpetual, royalty-free, fully
                            sublicensable, fully paid up, worldwide license to
                            use, copy, publicly perform, digitally perform,
                            publicly display, and distribute such Posted
                            Information and to adapt, edit, translate, prepare
                            derivative works of, incorporate into other works,
                            and otherwise fully exploit such Posted Information.
                            You represent and warrant that you have all rights
                            to grant such licenses to us without infringement or
                            violation of any third party rights, including
                            without limitation any privacy rights, publicity
                            rights, copyrights, trademarks, contract rights, or
                            any other intellectual property or proprietary
                            rights. You may not post Posted Information which is
                            false or fraudulent, or which otherwise does not
                            accurately represent your opinions and experiences.
                            <br />
                            <br />
                            You understand and agree that WapiDoc, in performing
                            the required technical steps to provide the Services
                            to our users (including you), may need to make
                            changes to your Posted Information to conform and
                            adapt that Posted Information to the technical
                            requirements of connection networks, devices,
                            services, or media, and the foregoing licenses
                            include the rights to do so.
                            <br />
                            <br />
                            Posted Information is the sole responsibility of the
                            person from whom such Posted Information originated
                            and does not reflect the opinion of WapiDoc. WapiDoc
                            does not assume liability for Posted Information or
                            for any claims, liabilities, or losses resulting
                            from any Posted Information.
                            <br />
                            <br />
                            We also welcome and encourage you to provide
                            feedback, comments and suggestions for improvements
                            to the Services (“Feedback“). You may submit
                            Feedback by emailing us, reaching out to our social
                            networking accounts, or by other means of
                            communication. Any Feedback you submit to us will be
                            considered non-confidential and non-proprietary to
                            you. By submitting Feedback to us, you grant us a
                            non-exclusive, worldwide, royalty-free, irrevocable,
                            sub-licensable, perpetual license to use and publish
                            such Feedback for any purpose, without compensation
                            to you.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            All of the Content is owned by us or our licensors
                            and is protected by copyright, trademark, patent,
                            and trade secret laws, other proprietary rights, and
                            international treaties. You acknowledge that the
                            Services and any underlying technology or software
                            used in connection with the Services contain
                            WapiDoc’s proprietary information. We give you
                            permission to use the Content for personal,
                            non-commercial purposes only and do not transfer any
                            intellectual property rights to you by virtue of
                            permitting your use of the Services. You may print,
                            download, and store information from the Services
                            for your own convenience, but you may not copy,
                            distribute, republish (except as expressly permitted
                            herein), sell, or exploit any of the Content, or
                            exploit the Services in whole or in part, for any
                            commercial gain or purpose whatsoever. Except as
                            expressly provided herein, neither WapiDoc nor its
                            suppliers grant you any express or implied rights,
                            and all rights in the Services not expressly granted
                            by WapiDoc to you are retained by WapiDoc.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            All of the Content is owned by us or our licensors
                            and is protected by copyright, trademark, patent,
                            and trade secret laws, other proprietary rights, and
                            international treaties. You acknowledge that the
                            Services and any underlying technology or software
                            used in connection with the Services contain
                            WapiDoc’s proprietary information. We give you
                            permission to use the Content for personal,
                            non-commercial purposes only and do not transfer any
                            intellectual property rights to you by virtue of
                            permitting your use of the Services. You may print,
                            download, and store information from the Services
                            for your own convenience, but you may not copy,
                            distribute, republish (except as expressly permitted
                            herein), sell, or exploit any of the Content, or
                            exploit the Services in whole or in part, for any
                            commercial gain or purpose whatsoever. Except as
                            expressly provided herein, neither WapiDoc nor its
                            suppliers grant you any express or implied rights,
                            and all rights in the Services not expressly granted
                            by WapiDoc to you are retained by WapiDoc.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            All of the Content is owned by us or our licensors
                            and is protected by copyright, trademark, patent,
                            and trade secret laws, other proprietary rights, and
                            international treaties. You acknowledge that the
                            Services and any underlying technology or software
                            used in connection with the Services contain
                            WapiDoc’s proprietary information. We give you
                            permission to use the Content for personal,
                            non-commercial purposes only and do not transfer any
                            intellectual property rights to you by virtue of
                            permitting your use of the Services. You may print,
                            download, and store information from the Services
                            for your own convenience, but you may not copy,
                            distribute, republish (except as expressly permitted
                            herein), sell, or exploit any of the Content, or
                            exploit the Services in whole or in part, for any
                            commercial gain or purpose whatsoever. Except as
                            expressly provided herein, neither WapiDoc nor its
                            suppliers grant you any express or implied rights,
                            and all rights in the Services not expressly granted
                            by WapiDoc to you are retained by WapiDoc.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            12. YOUR USE OF CONTENT
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            All of the Content is owned by us or our licensors
                            and is protected by copyright, trademark, patent,
                            and trade secret laws, other proprietary rights, and
                            international treaties. You acknowledge that the
                            Services and any underlying technology or software
                            used in connection with the Services contain
                            WapiDoc’s proprietary information. We give you
                            permission to use the Content for personal,
                            non-commercial purposes only and do not transfer any
                            intellectual property rights to you by virtue of
                            permitting your use of the Services. You may print,
                            download, and store information from the Services
                            for your own convenience, but you may not copy,
                            distribute, republish (except as expressly permitted
                            herein), sell, or exploit any of the Content, or
                            exploit the Services in whole or in part, for any
                            commercial gain or purpose whatsoever. Except as
                            expressly provided herein, neither WapiDoc nor its
                            suppliers grant you any express or implied rights,
                            and all rights in the Services not expressly granted
                            by WapiDoc to you are retained by WapiDoc.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            13. DISCLAIMER
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We created WapiDoc to improve patients’ healthcare
                            experience, and we want your experience with WapiDoc
                            to be exceptional. While we work hard to make that
                            happen, you acknowledge that we have no control
                            over, and no duty to take any action regarding: (a)
                            which users gain access to the Services, (b) what
                            Content you access, (c) what effect the Content may
                            have on you, (d) how you may interpret or use the
                            Content, or (e) what actions you may take as a
                            result of having been exposed to the Content. You
                            release us from all liability for your use or
                            inability to use any Content. We and our licensors,
                            suppliers, partners, parent, subsidiaries or
                            affiliated entities, and each of their respective
                            officers, directors, members, employees,
                            consultants, contract employees, representatives,
                            and agents, and each of their respective successors
                            and assigns (WapiDoc and all such parties together,
                            the “WapiDoc Parties”) make no representations or
                            warranties regarding suggestions or recommendations
                            of services or products offered or purchased through
                            the Services, and the WapiDoc Parties will not be
                            responsible or liable for the accuracy, copyright
                            compliance, legality, or decency of material
                            contained in or accessed through the Services. We
                            have no special relationship with or fiduciary duty
                            to you. WE (AND OUR LICENSORS AND SUPPLIERS) PROVIDE
                            THE SERVICES “AS IS” AND “AS AVAILABLE.” WE MAKE NO
                            EXPRESS OR IMPLIED WARRANTIES OR GUARANTEES ABOUT
                            THE CONTENT OR SERVICES. TO THE MAXIMUM EXTENT
                            PERMITTED BY LAW, WE HEREBY DISCLAIM ALL SUCH
                            WARRANTIES, INCLUDING ALL STATUTORY WARRANTIES, WITH
                            RESPECT TO THE SERVICES, INCLUDING, WITHOUT
                            LIMITATION, ANY WARRANTIES THAT THE SERVICES ARE
                            MERCHANTABLE, OF SATISFACTORY QUALITY, ACCURATE, FIT
                            FOR A PARTICULAR PURPOSE OR NEED, OR NON-INFRINGING.
                            WE DO NOT GUARANTEE THAT THE RESULTS THAT MAY BE
                            OBTAINED FROM THE USE OF THE SERVICES WILL BE
                            EFFECTIVE, RELIABLE OR ACCURATE OR WILL MEET YOUR
                            REQUIREMENTS. WE DO NOT GUARANTEE THAT YOU WILL BE
                            ABLE TO USE THE SERVICES (EITHER DIRECTLY OR THROUGH
                            THIRD-PARTY NETWORKS) AT TIMES OR LOCATIONS OF YOUR
                            CHOOSING. WE ARE NOT RESPONSIBLE FOR THE ACCURACY,
                            RELIABILITY, TIMELINESS OR COMPLETENESS OF
                            INFORMATION PROVIDED BY USERS OF THE SERVICES OR ANY
                            OTHER DATA OR INFORMATION PROVIDED OR RECEIVED
                            THROUGH THE SERVICES. EXCEPT AS EXPRESSLY SET FORTH
                            HEREIN, WAPIDOC MAKES NO WARRANTIES ABOUT THE
                            INFORMATION SYSTEMS, SOFTWARE AND FUNCTIONS MADE
                            ACCESSIBLE THROUGH THE SERVICES OR ANY OTHER
                            SECURITY ASSOCIATED WITH THE TRANSMISSION OF
                            SENSITIVE INFORMATION. WAPIDOC DOES NOT WARRANT THAT
                            THE SERVICES WILL OPERATE UNINTERRUPTED, ERROR-FREE,
                            BUG-FREE OR FREE FROM DEFECTS, THAT LOSS OF DATA
                            WILL NOT OCCUR, OR THAT THE SERVICES OR SOFTWARE ARE
                            FREE OF COMPUTER VIRUSES, CONTAMINANTS OR OTHER
                            HARMFUL ITEMS.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            14. GENERAL LIMITATION OF LIABILITY
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH
                            US IS THE CANCELLATION OF YOUR WAPIDOC ACCOUNT. IN
                            NO EVENT SHALL OUR CUMULATIVE LIABILITY TO YOU FOR
                            ANY AND ALL CLAIMS RELATING TO OR ARISING OUT OF
                            YOUR USE OF THE SERVICES, REGARDLESS OF THE FORM OF
                            ACTION, EXCEED THE GREATER OF: (a) THE TOTAL AMOUNT
                            OF FEES, IF ANY, THAT YOU PAID TO CREATE OR MAINTAIN
                            A WAPIDOC ACCOUNT FOR USE OF THE SERVICES, OR (b)
                            $100; EXCEPT THAT, FOR ANY AND ALL CLAIMS RELATING
                            TO OR ARISING OUT OF YOUR USE OF THE TRANSACTION
                            PROCESSING SERVICES, IN NO EVENT SHALL OUR
                            CUMULATIVE LIABILITY THEREOF REGARDLESS OF THE FORM
                            OF ACTION EXCEED THE TOTAL AMOUNT OF TRANSACTION
                            PROCESSING FEES, IF ANY, REMITTED TO AND RETAINED BY
                            WAPIDOC FOR PROVIDING TRANSACTION PROCESSING
                            SERVICES FOR APPOINTMENTS MADE BY YOU IN THE SIX (6)
                            MONTHS PRIOR TO THE EVENT GIVING RISE TO THE
                            APPLICABLE CLAIM.
                            <br />
                            <br />
                            TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, IN
                            NO EVENT AND UNDER NO LEGAL THEORY (INCLUDING,
                            WITHOUT LIMITATION, TORT, CONTRACT, STRICT
                            LIABILITY, OR OTHERWISE) SHALL ANY OF THE WAPIDOC
                            PARTIES BE LIABLE TO YOU (OR TO ANY THIRD PARTY
                            CLAIMING UNDER OR THROUGH YOU) FOR ANY INDIRECT,
                            SPECIAL, INCIDENTAL, PUNITIVE, CONSEQUENTIAL OR
                            EXEMPLARY DAMAGES ARISING FROM YOUR USE OF, OR
                            INABILITY TO USE, THE SERVICES. THESE EXCLUSIONS
                            APPLY TO ANY CLAIMS FOR LOST PROFITS, LOST DATA,
                            LOSS OF GOODWILL, BUSINESS INTERRUPTION, WORK
                            STOPPAGE, ACCURACY OF RESULTS, COMPUTER FAILURE OR
                            MALFUNCTION, ANY OTHER COMMERCIAL DAMAGES OR LOSSES,
                            ANY SUBSTITUTE GOODS, SERVICES, OR TECHNOLOGY, OR
                            MEDICAL MALPRACTICE OR NEGLIGENCE OF HEALTHCARE
                            PROVIDERS UTILIZED THROUGH USE OF THE SERVICES, OR
                            ANY MATTER BEYOND OUR REASONABLE CONTROL, EVEN IF WE
                            KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
                            DAMAGES. BECAUSE SOME STATES OR JURISDICTIONS DO NOT
                            ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY
                            FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH
                            STATES OR JURISDICTIONS, OUR LIABILITY SHALL BE
                            LIMITED IN ACCORDANCE HEREIN TO THE MAXIMUM EXTENT
                            PERMITTED BY LAW.
                            <br />
                            <br />
                            IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE YOUR
                            RIGHTS WITH RESPECT TO CALIFORNIA CIVIL CODE SECTION
                            1542, WHICH SAYS “A GENERAL RELEASE DOES NOT EXTEND
                            TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
                            SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
                            EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM HER
                            MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT
                            WITH THE DEBTOR.”
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            15. TERMINATION
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If you’re not using the Services in accordance with
                            this Agreement, we reserve the right, if we
                            determine it is best, to terminate, suspend and/or
                            deactivate your WapiDoc Account immediately, without
                            notice, if there has been a violation of this
                            Agreement, our Acceptable Use Policy, our Community
                            Standards, or other policies and terms posted
                            through the Services by you or by someone using your
                            Credentials without authorization. We may also
                            terminate, suspend or deactivate your WapiDoc
                            Account for any other reason, including inactivity
                            for an extended period. WapiDoc shall not be liable
                            to you or any third party for any termination,
                            suspension or deactivation of your access to the
                            Services. Further, you agree not to attempt to use
                            the Services after any such termination, suspension
                            or deactivation (except where deactivation is due
                            solely to inactivity, and you are permitted to
                            create another WapiDoc Account). Account termination
                            may result in destruction of any content associated
                            with your WapiDoc Account. Sections 1, 2, 3, 4, 6,
                            8, 9, 10, 11, 12, 13, 14, 15, 16, 17, and any other
                            provisions that, by their nature, should survive
                            termination of these Terms of Use shall survive any
                            termination or expiration of these Terms of Use.
                            <br />
                            <br />
                            WapiDoc reserves the right to investigate and, at
                            our discretion, take appropriate legal action
                            against anyone who violates these Terms of Use,
                            including without limitation, removing any offending
                            communication from the Services, terminating the
                            WapiDoc Account of such violators, and or blocking
                            your use of the Services.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            16. INDEMNIFICATION
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Upon a request by us, you agree to defend,
                            indemnify, and hold harmless the WapiDoc Parties
                            from all liabilities, claims, damages (actual and
                            consequential), demands, and expenses, including
                            reasonable attorney’s fees, that arise from or are
                            related to (a) your use of the Services; or (b) the
                            violation of this Agreement (including without
                            limitation these Terms of Use, the Acceptable Use
                            Policy, and Community Standards); or (c) the
                            violation of any intellectual property or other
                            right of any person or entity; or (d) by any person
                            using your Credentials without authorization. The
                            foregoing indemnification obligation does not apply
                            to liabilities, claims and expenses arising as a
                            result of our own gross negligence or intentional
                            misconduct.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            17. ARBITRATION AGREEMENT
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We hope that we can work out any disagreements you
                            might have with WapiDoc. But if there is a dispute
                            that needs to be further resolved, that process will
                            take place according to this section. Please read
                            the following ARBITRATION AGREEMENT carefully,
                            because it requires you to arbitrate certain
                            disputes and claims with WapiDoc and limits the
                            manner in which you can seek relief from WapiDoc.
                            Both you and WapiDoc acknowledge and agree that for
                            the purpose of any dispute arising out of relating
                            to the subject matter of these Terms of Use,
                            WapiDoc’s officers, directors, employees, and
                            independent contractors (“Personnel”) are
                            third-party beneficiaries of these Terms of Use, and
                            that upon your acceptance of these Terms of Use,
                            Personnel will have the right (and will be deemed to
                            have accepted the right) to enforce these Terms of
                            Use against you as a third-party beneficiary hereof.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            17.1 Arbitration Rules; Applicability of Arbitration
                            Agreement
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            The parties shall use their best efforts to settle
                            any dispute, claim, question, or disagreement
                            arising out of or relating to the subject matter of
                            these Terms directly through good-faith
                            negotiations, which shall be a precondition to
                            either party initiating arbitration. If such
                            negotiations do not resolve the dispute, it shall be
                            finally settled by binding arbitration in New York
                            County, New York. The arbitration will proceed in
                            the English language, in accordance with the JAMS
                            Streamlined Arbitration Rules and Procedures (the
                            “Rules”) then in effect, by one commercial
                            arbitrator with substantial experience in resolving
                            intellectual property and commercial contract
                            disputes. The arbitrator shall be selected from the
                            appropriate list of JAMS arbitrators in accordance
                            with such Rules. Judgment upon the award rendered by
                            such arbitrator may be entered in any court of
                            competent jurisdiction.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            17.2 Cost of Arbitration
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            The Rules will govern payment of all arbitration
                            fees. WapiDoc will pay all arbitration fees for
                            claims less than seventy-five thousand ($75,000)
                            dollars. WapiDoc will not seek its attorneys’ fees
                            and costs in arbitration unless the arbitrator
                            determines that your claim is frivolous.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            17.3 Small Claims Court; Infringement
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Either you or WapiDoc may assert claims, if they
                            qualify, in small claims court in New York County,
                            New York or any United States county where you live
                            or work. Furthermore, notwithstanding the foregoing
                            obligation to arbitrate disputes, each party shall
                            have the right to pursue injunctive or other
                            equitable relief at any time, from any court of
                            competent jurisdiction, to prevent the actual or
                            threatened infringement, misappropriation or
                            violation of a party's copyrights, trademarks, trade
                            secrets, patents or other intellectual property
                            rights.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            17.4 Waiver of Jury Trial
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            YOU AND WAPIDOC WAIVE ANY CONSTITUTIONAL AND
                            STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN
                            FRONT OF A JUDGE OR JURY. You and WapiDoc are
                            instead choosing to have claims and disputes
                            resolved by arbitration. Arbitration procedures are
                            typically more limited, more efficient, and less
                            costly than rules applicable in court and are
                            subject to very limited review by a court. In any
                            litigation between you and WapiDoc over whether to
                            vacate or enforce an arbitration award, YOU AND
                            WAPIDOC WAIVE ALL RIGHTS TO A JURY TRIAL, and elect
                            instead to have the dispute be resolved by a judge.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            17.5 Waiver of Class or Consolidated Actions
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS
                            ARBITRATION AGREEMENT MUST BE ARBITRATED OR
                            LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS
                            BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER
                            CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR
                            CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR
                            USER. If however, this waiver of class or
                            consolidated actions is deemed invalid or
                            unenforceable, neither you nor WapiDoc is entitled
                            to arbitration; instead all claims and disputes will
                            be resolved in a court as set forth in (g) below.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            17.6 Opt-out
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You have the right to opt out of the provisions of
                            this Section by sending written notice of your
                            decision to opt out to the following address:
                            WapiDoc, Attn: Legal, 568 Broadway 9th Floor, New
                            York, NY 10012, postmarked within thirty (30) days
                            of first accepting these Terms. You must include (i)
                            your name and residence address, (ii) the email
                            address and/or telephone number associated with your
                            account, and (iii) a clear statement that you want
                            to opt out of these Terms’ arbitration agreement.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            17.7 Exclusive Venue
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If you send the opt-out notice in Section 17.6,
                            and/or in any circumstances where the foregoing
                            arbitration agreement permits either you or WapiDoc
                            to litigate any dispute arising out of or relating
                            to the subject matter of these Terms in court, then
                            the foregoing arbitration agreement will not apply
                            to either party, and both you and WapiDoc agree that
                            any judicial proceeding (other than small claims
                            actions) will be brought in the state or federal
                            courts located in, respectively, New York County,
                            New York, or the federal district in which that
                            county falls.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            17.8 Severability
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            If the prohibition against class actions and other
                            claims brought on behalf of third parties contained
                            above is found to be unenforceable, then all of the
                            preceding language in this Arbitration Agreement
                            section will be null and void. This arbitration
                            agreement will survive the termination of your
                            relationship with WapiDoc.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            18. MISCELLANEOUS
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            18.1 Electronic Contracting; Copyright Dispute
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Your affirmative act of using the Services and/or
                            creating a WapiDoc Account constitutes your
                            electronic signature to this Agreement, which
                            includes our Privacy Policy, Acceptable Use Policy
                            and Community Standards. This Agreement and any
                            other documents to be delivered in connection with
                            the Services may be electronically signed, and any
                            electronic signatures appearing on this Agreement or
                            such other documents are the same as handwritten
                            signatures for the purposes of validity,
                            enforceability, and admissibility.
                            <br />
                            <br />
                            Please visit our Acceptable Use Policy to review our
                            copyright dispute policy.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            18.2 SMS/Text Communications
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            By voluntarily providing your cell phone number to
                            WapiDoc, you agree that WapiDoc may contact you by
                            telephone, SMS, or MMS messages that we think may be
                            of interest to you at that phone number, which may
                            include the use of automated dialing technology to
                            text you at the cell phone number you provided, and
                            you hereby consent to receiving such communications
                            for transactional, operational, or informational
                            purposes. Message and data rates from your mobile
                            telephone service provider may apply and are subject
                            to the terms and conditions imposed by your
                            provider. You can opt out of receiving text messages
                            at any time by adjusting your notification settings
                            in your account. Note that opting out of receiving
                            all texts may impact your use of the Services.
                            <br />
                            <br />
                            If you change or deactivate the phone number you
                            provided to WapiDoc, you have an affirmative
                            obligation to update your account information and
                            the phone number(s) attached to your account to
                            prevent us from inadvertently communicating with
                            anyone who acquires any phone number(s) previously
                            attributed to you, and any new phone number(s) you
                            attach to your account may receive WapiDoc’s
                            standard SMS messages unless you also unsubscribe
                            via the above procedures.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            18.3 Limitation of Claims
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            No action arising under or in connection with this
                            Agreement, regardless of the form, may be brought by
                            you more than one (1) year after the cause of action
                            arose; actions brought thereafter are forever
                            barred.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            18.4 Severability
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            In the event any one or more of the provisions of
                            this Agreement shall for any reason be held to be
                            invalid, illegal or unenforceable, the remaining
                            provisions of this Agreement shall be unimpaired.
                            Further, the invalid, illegal or unenforceable
                            provision shall be replaced by a provision that
                            comes closest to the intention of the parties that
                            underlie the invalid, illegal or unenforceable
                            provision, except to the extent no such provision is
                            valid, legal and enforceable, in which case such
                            invalid, illegal or unenforceable provision shall be
                            limited or eliminated to the minimum extent
                            necessary so that the other provisions of this
                            Agreement remain in full force and effect and
                            enforceable.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            18.5 Governing Law; Language
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            This Agreement shall be deemed to have been entered
                            into and shall be construed and enforced in
                            accordance with the Federal Arbitration Act,
                            applicable federal law, and the laws of the State of
                            New York as applied to contracts made and to be
                            performed entirely within New York, without giving
                            effect to the state’s conflicts of law statute. This
                            Agreement and all documents referenced herein were
                            drafted in the English language and any translations
                            thereof shall not be binding on either party to the
                            extent they conflict with the English versions.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            18.6 Entire Agreement; Waiver
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            This Agreement and any supplemental terms, policies,
                            rules and guidelines posted through the Services,
                            each of which are incorporated herein by reference,
                            including the Privacy Policy, the Acceptable Use
                            Policy and the Community Standards, constitute the
                            entire agreement between you and us and supersede
                            all previous written or oral agreements. The failure
                            of either party to exercise in any respect any right
                            provided for herein shall not be deemed a waiver of
                            any further rights hereunder.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            18.7 Headings
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            The headings of the sections of this Agreement are
                            for convenience only, do not form a part hereof, and
                            in no way limit, define, describe, modify, interpret
                            or construe the meaning, scope or intent of this
                            Agreement or any terms or conditions therein.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            18.8 Assignment
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            We may assign this Agreement at any time, including,
                            without limitation, to any parent, subsidiary, or
                            any affiliated institution, or as part of the sale
                            to, merger with, or other transfer of our business
                            or any assets to another entity. You may not assign,
                            transfer or sublicense this Agreement to anyone else
                            and any attempt to do so in violation of this
                            section shall be null and void.
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            18.9 Eligibility
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            You must be 18 years of age or over, or the legal
                            age to form a binding contract in your jurisdiction
                            if that age is greater than 18 years of age, to
                            create a WapiDoc Account or use the Services on your
                            own. Those under the age of 13 may not use the
                            Services. If you are between the ages of 13 and 18
                            or the applicable legal age in your jurisdiction,
                            you can use the Services only under the supervision
                            of your parent or guardian who has agreed to these
                            Terms of Use. By using the Services on behalf of a
                            minor child, you represent and warrant that you are
                            the parent or legal guardian of such child, and that
                            all references in these Terms of Use to “you” shall
                            refer to such child or such other individual on
                            whose behalf you have authorization to enter into
                            these Terms of Use and you in your capacity as the
                            parent or legal guardian of such child or as the
                            authorized party of such individual.
                            <br />
                            <br />
                            If you do not qualify under these Terms of Use, you
                            may not use the Services. Use of the Services is
                            void where prohibited by applicable law, and the
                            right to access the Services is revoked in such
                            jurisdictions. By using the Services, you represent
                            and warrant that you have the right, authority, and
                            capacity to enter into these Terms of Use. The
                            Services are administered in the U.S. and intended
                            for U.S. users only; any use outside of the U.S. or
                            use related to activities outside of the U.S. is
                            prohibited and at the user’s own risk. Users are
                            responsible for compliance with any local, state or
                            federal laws applicable to their use of the
                            Services.
                        </Typography>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default TermsandConditionsPage;
