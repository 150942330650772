import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export const Gender = ({ data, updateData }) => {
    return (
        <FormControl variant="outlined" align="left" fullWidth>
            <InputLabel>Gender</InputLabel>
            <Select
                label="choose gender"
                name="gender"
                labelId="choose gender"
                value={data.gender}
                onChange={(event) => {
                    updateData({ ...data, gender: event.target.value });
                }}
            >
                <MenuItem value="">
                    <em>Choose Gender</em>
                </MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
            </Select>
        </FormControl>
    );
};
