import React, { useState } from "react";
import { Tabs, Tab, Grid } from "@mui/material";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import AssistantOutlinedIcon from "@mui/icons-material/AssistantOutlined";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ServiceList } from "../../components/institution/serviceList";
import { OfferList } from "../../components/institution/offerList";
import { InsuranceList } from "../../components/institution/insuranceList";
import { ReviewList } from "../../components/institution/reviewList";
import { GalleryList } from "../../components/institution/galleryList";
import { ContactList } from "../../components/institution/contactList";
import { HoursList } from "../../components/institution/hoursList";

export const DataCard = ({
    data: {
        type,
        insurance,
        gallery,
        contact,
        links,
        hours,
        services,
        offers,
        reviews,
    },
    page,
}) => {
    const [tab, setTab] = useState("Services");
    return (
        <div className="card">
            <Grid
                item
                xs={12}
                style={{
                    paddingBottom: "0%",
                    color: "#324C74",
                    borderBottom: `1px solid #dfdfdf`,
                }}
            >
                <Tabs
                    value={tab}
                    onChange={(event, value) => {
                        setTab(value);
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className="single-tab"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#cf053e",
                        },
                    }}
                >
                    <Tab
                        icon={
                            <LiveHelpOutlinedIcon
                                style={{
                                    color: "#000000",
                                }}
                            />
                        }
                        iconPosition="start"
                        label="Services"
                        value="Services"
                    />
                    <Tab
                        icon={
                            <SupervisedUserCircleOutlinedIcon
                                style={{
                                    color: "#000000",
                                }}
                            />
                        }
                        iconPosition="start"
                        label="Insurance"
                        value="Insurance"
                    />
                    <Tab
                        icon={
                            <AssistantOutlinedIcon
                                style={{
                                    color: "#000000",
                                }}
                            />
                        }
                        iconPosition="start"
                        label="Reviews"
                        value="Reviews"
                    />
                    <Tab
                        icon={
                            <PhotoLibraryIcon
                                style={{
                                    color: "#000000",
                                }}
                            />
                        }
                        iconPosition="start"
                        label="Gallery"
                        value="Gallery"
                    />
                    <Tab
                        icon={
                            <ContactsOutlinedIcon
                                style={{
                                    color: "#000000",
                                }}
                            />
                        }
                        iconPosition="start"
                        label="Contacts"
                        value="Contacts"
                    />
                    <Tab
                        icon={
                            <AccessTimeIcon
                                style={{
                                    color: "#000000",
                                }}
                            />
                        }
                        iconPosition="start"
                        label="Working Hours"
                        value="Working Hours"
                    />
                    {type === "hospital" && (
                        <Tab
                            icon={
                                <AccessTimeIcon
                                    style={{
                                        color: "#000000",
                                    }}
                                />
                            }
                            iconPosition="start"
                            label="Visiting Hours"
                            value="Visiting Hours"
                        />
                    )}
                </Tabs>
            </Grid>
            <Grid item xs={12} style={{ padding: "2%" }}>
                {tab === "Services" && page === "institution" && (
                    <ServiceList services={services} />
                )}
                {tab === "Services" && page === "service" && (
                    <OfferList offers={offers} />
                )}
                {tab === "Insurance" && (
                    <InsuranceList insurances={insurance} />
                )}
                {tab === "Reviews" && <ReviewList reviews={reviews} />}
                {tab === "Gallery" && <GalleryList photos={gallery.photos} />}
                {tab === "Contacts" && (
                    <ContactList contact={contact} links={links} />
                )}
                {tab === "Working Hours" && (
                    <HoursList hours={hours.workingHours} type={"working"} />
                )}
                {tab === "Visiting Hours" && (
                    <HoursList hours={hours.visitingHours} type={"visiting"} />
                )}
            </Grid>
        </div>
    );
};
