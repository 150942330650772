import React from "react";
import { TextField } from "@mui/material";

export const SearchBar = ({ params, setKeyword, title, url }) => {
    let timer;

    return (
        <TextField
            id="keyword"
            name="keyword"
            style={{ width: "40%" }}
            margin="normal"
            variant="outlined"
            placeholder={`Search ${title}`}
            defaultValue={params.get("keyword")}
            fullWidth
            inputProps={{ maxLength: 30, style: { textAlign: "center" } }}
            onKeyUp={(event) => {
                if (event.target.value !== "" || event.target.value !== " ") {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                        setKeyword(event.target.value);
                        url.searchParams.set("keyword", event.target.value);
                        window.history.pushState(null, "", url.toString());
                    }, 500);
                }
            }}
        />
    );
};
