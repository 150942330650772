import React from "react";
import {
    Grid,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    Rating,
    FormControl,
} from "@mui/material";

const RatingForm = ({ data, updateData }) => {
    return (
        <Grid item xs={12} style={{ padding: "5%" }}>
            <Typography variant="subtitle1" align="left">
                Rating
            </Typography>
            <FormControl component="fieldset">
                <RadioGroup
                    aria-label="rating"
                    value={data.rating}
                    name="rating"
                >
                    {[0, 1, 2, 3, 4].reverse().map((item) => {
                        return (
                            <FormControlLabel
                                key={item}
                                value={4}
                                control={
                                    <Radio
                                        checked={
                                            data.rating === item ? true : false
                                        }
                                        size="small"
                                    />
                                }
                                label={
                                    <div
                                        style={{
                                            display: "flex",
                                            paddingTop: "5%",
                                        }}
                                    >
                                        <Rating
                                            name="simple-controlled"
                                            precision={1.0}
                                            size="small"
                                            value={item}
                                            readOnly
                                        />
                                        &nbsp;&nbsp;
                                        <Typography
                                            variant="body2"
                                            align="left"
                                        >
                                            &&nbsp;Up
                                        </Typography>
                                    </div>
                                }
                                onChange={() => {
                                    updateData({ ...data, rating: item });
                                }}
                            />
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </Grid>
    );
};

export default RatingForm;
