import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export const Owns = ({ data, updateData }) => {
    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel>Choose Number of Branches</InputLabel>
            <Select
                label="choose number of branches"
                name="owns"
                labelId="owns"
                value={data.owns}
                onChange={(event) => {
                    updateData({ ...data, owns: event.target.value });
                }}
            >
                <MenuItem value="single">
                    I represent a single institution
                </MenuItem>
                <MenuItem value="multiple">
                    I represent multiple institutions
                </MenuItem>
            </Select>
        </FormControl>
    );
};
