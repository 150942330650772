import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";
import { Grid, Typography, Link } from "@material-ui/core";

class TermsandConditionsPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Careers | Field Sales Representative</title>
                    <meta
                        name="description"
                        content="We are looking
                            to recruit a Field Sales/Marketing Teams who will
                            grasp the consumer needs and align them with winning
                            marketing strategies."
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid
                    container
                    style={{ paddingTop: "5%", paddingBottom: "5%" }}
                    className={"add-space"}
                >
                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ margin: "0% auto", padding: "5%" }}
                        className={"add-space"}
                    >
                        <Typography variant="h4" component="h1" gutterBottom>
                            Field Sales/Marketing Teams
                        </Typography>
                        <Typography variant="body1" paragraph gutterBottom>
                            We are a tech company that connects the physical and
                            digital world by providing easy and efficient access
                            to health care services and products with the push
                            of a button for everyone, everywhere. We are looking
                            to recruit a Field Sales/Marketing Teams who will
                            grasp the consumer needs and align them with winning
                            marketing strategies.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Responsibilities
                        </Typography>
                        <Typography variant="body1" paragraph gutterBottom>
                            <ul>
                                <li>
                                    Develop and cultivate new leads within the
                                    assigned territory, brief clients about
                                    products, follow up on them, the location,
                                    benefits, prices and payment methods and
                                    plans.
                                </li>
                                <li>
                                    Maintain records of all sales leads and/or
                                    customer accounts.
                                </li>
                                <li>
                                    Represent the brand during all customer and
                                    prospect interactions.
                                </li>
                                <li>
                                    Educate customers on how products or
                                    services can benefit them financially and
                                    professionally.
                                </li>
                                <li>
                                    Keep in touch with clients to build a good
                                    relationship and strengthen the image of the
                                    brand.
                                </li>
                                <li>
                                    Come up with personal initiatives to promote
                                    the sale of products.
                                </li>
                                <li>
                                    Give daily reports on leads collected, sales
                                    made, achievements and challenges to the
                                    supervisor.
                                </li>
                                <li>
                                    Perform any other duties as may be
                                    prescribed from time to time by your
                                    supervisor.
                                </li>
                                <li>
                                    Prepare a monthly report detailing the
                                    month’s activities, leads collected, sales,
                                    challenges faced and recommendations and
                                    send to the Supervisor.
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Skills
                        </Typography>
                        <Typography variant="body1" paragraph gutterBottom>
                            <ul>
                                <li>
                                    Should possess strong sales skills, customer
                                    relations and commercial awareness.
                                </li>
                                <li>
                                    Must have passion for technology and desire
                                    to learn.
                                </li>
                                <li>
                                    Must have a strong research proficiency.
                                </li>
                                <li>Strong organizational skills.</li>
                                <li>
                                    Ability to express yourself in English and
                                    Swahili.
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Qualifications
                        </Typography>
                        <Typography variant="body1" paragraph gutterBottom>
                            <ul>
                                <li>
                                    Certificate, Diploma or Degree in any
                                    related field from a recognized institution.
                                </li>
                                <li>
                                    Must have a background in Marketing / Sales
                                </li>
                                <li>
                                    Experience in using common data management
                                    software such as MS Excel.
                                </li>
                                <li>Must be over 18 years old</li>
                                <li>Must be ready to begin immediately.</li>
                            </ul>
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            How to apply
                        </Typography>
                        <Typography variant="body1" paragraph gutterBottom>
                            Interested candidates should send their CV, Cover
                            Letter, full photo and ID copy to{" "}
                            <Link
                                href={"mailto:hr@wapidoc.com"}
                                target={"_blank"}
                            >
                                hr@wapidoc.com{" "}
                            </Link>
                            with the subject <b>Field Sales Rep</b>
                            <br /> <br />
                            Only shortlisted candidates will be contacted.
                        </Typography>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default TermsandConditionsPage;
