import React, { Component } from "react";
import Drawer from "../../components/drawer";
import MapWrapper from "../../components/maps";
import { withSnackbar } from "notistack";
import { postRequest } from "../../middleware/graphQl";
import cogs from "../../middleware/cogs";
import locations from "./locations.json";
import insuranceList from "./insurance.json";

import {
    Typography,
    Button,
    CircularProgress,
    Grid,
    List,
    InputAdornment,
    ListItemIcon,
    ListItemText,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Divider,
} from "@material-ui/core";

import ListItemButton from "@mui/material/ListItemButton";
import Alert from "@material-ui/lab/Alert";

import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import QueryBuilderOutlinedIcon from "@material-ui/icons/QueryBuilderOutlined";
import LanguageIcon from "@material-ui/icons/Language";

import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import ContactsOutlinedIcon from "@material-ui/icons/ContactsOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CancelIcon from "@material-ui/icons/Cancel";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";

class institutionEditPage extends Component {
    state = {
        current: "basic",
        insurance: [],
        constituencies: [],
        _id: "",
        type: "",
        amount: "",
        name: "",
        description: "",
        county: "",
        constituency: "",
        ward: "",
        gprs: "",
        maps: false,
        primary_phone: "",
        secondary_phone: "",
        primary_email: "",
        secondary_email: "",
        address: "",
        postal_code: "",
        linkedin: "",
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: "",
        website: "",
        working_hours: "",
        visiting_hours: "",
        button_loading: false,
        loading: true,
        error: false,
        error_message: "",
        errors: {
            name: false,
            description: false,
            constituency: false,
            ward: false,
            primary_phone: false,
            secondary_phone: false,
            primary_email: false,
            secondary_email: false,
            address: false,
            postal_code: false,
        },
    };

    componentDidMount = () => {
        this.getInstitution(this.props.match.params.id);
    };

    getInstitution = async (id) => {
        const response = await postRequest("singleInstitution", "manager", {
            id: id,
        });

        if (response.errors) {
            if (response.errors[0].message.split(" ")[0] === "Cast") {
                window.location.replace(
                    `${process.env.REACT_APP_HOST}/manager/institutions`
                );
            } else {
                this.props.enqueueSnackbar(response.errors[0].message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            }
        } else {
            this.setState({
                _id: response.data.singleInstitution._id,
                gprs: response.data.singleInstitution.gprs,
                loading: false,
                insurance: response.data.singleInstitution.insurance,
                type: response.data.singleInstitution.type,
                name: response.data.singleInstitution.name,
                description: response.data.singleInstitution.description,
                county: response.data.singleInstitution.county,
                constituency: response.data.singleInstitution.constituency,
                ward: response.data.singleInstitution.ward,
                primary_phone: response.data.singleInstitution.primary_phone,
                secondary_phone:
                    response.data.singleInstitution.secondary_phone,
                primary_email: response.data.singleInstitution.primary_email,
                secondary_email:
                    response.data.singleInstitution.secondary_email,
                address: response.data.singleInstitution.address.split("-")[0],
                postal_code:
                    response.data.singleInstitution.address.split("-")[1],
                linkedin: response.data.singleInstitution.linkedin,
                facebook: response.data.singleInstitution.facebook,
                twitter: response.data.singleInstitution.twitter,
                instagram: response.data.singleInstitution.instagram,
                youtube: response.data.singleInstitution.youtube,
                website: response.data.singleInstitution.website,
                subscription: response.data.singleInstitution.subscription,
                photos: response.data.singleInstitution.photos,
                primary_photo: response.data.singleInstitution.primary_photo,
                working_hours: JSON.parse(
                    response.data.singleInstitution.working_hours
                ),
                visiting_hours: JSON.parse(
                    response.data.singleInstitution.visiting_hours
                ),
            });

            this.setState({
                weekday_opening: this.state.working_hours.weekday.split("-")[0],
                weekday_closing: this.state.working_hours.weekday.split("-")[1],
                saturday_opening:
                    this.state.working_hours.saturday.split("-")[0],
                saturday_closing:
                    this.state.working_hours.weekday.split("-")[1],
                sunday_opening: this.state.working_hours.sunday.split("-")[0],
                sunday_closing: this.state.working_hours.sunday.split("-")[1],
                holiday_opening: this.state.working_hours.holiday.split("-")[0],
                holiday_closing: this.state.working_hours.holiday.split("-")[1],
            });

            if (this.state.type === "hospital") {
                this.setState({
                    morning_opening:
                        this.state.visiting_hours.morning.split("-")[0],
                    morning_closing:
                        this.state.visiting_hours.morning.split("-")[1],
                    afternoon_opening:
                        this.state.visiting_hours.afternoon.split("-")[0],
                    afternoon_closing:
                        this.state.visiting_hours.afternoon.split("-")[1],
                    evening_opening:
                        this.state.visiting_hours.evening.split("-")[0],
                    evening_closing:
                        this.state.visiting_hours.evening.split("-")[1],
                });
            }
        }
    };

    handleChange = (event) => {
        if (event.target.name === "county") {
            this.setState({ constituency: "", ward: "" });
        } else if (event.target.name === "constituency") {
            this.setState({ ward: "" });
        }

        if (
            event.target.name === "primary_email" ||
            event.target.name === "secondary_email"
        ) {
            this.setState({
                [event.target.name]: event.target.value.replace(/\s/g, ""),
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
    };

    addInsurance = (insurance) => {
        let insurances = this.state.insurance;
        if (insurances.includes(insurance)) {
            const index = this.state.insurance.indexOf(insurance);
            if (index > -1) {
                insurances.splice(index, 1);
            }
        } else {
            insurances.push(insurance);
        }
        this.setState({ insurance: insurances });
    };

    setHours = (day, value) => {
        this.setState({ [day + "_opening"]: value, [day + "_closing"]: value });
    };

    updateInstitution = async () => {
        let errors = this.state.errors;
        let error = false;
        let error_message = "";
        this.setState({ button_loading: true });

        // name
        if (this.state.name === "") {
            errors["name"] = true;
            error = true;
        } else {
            errors["name"] = false;
        }

        // description
        if (this.state.description === "") {
            errors["description"] = true;
            error = true;
        } else {
            errors["description"] = false;
        }

        // constituency
        if (this.state.constituency === "") {
            errors["constituency"] = true;
            error = true;
        } else {
            errors["constituency"] = false;
        }

        // ward
        if (this.state.ward === "") {
            errors["ward"] = true;
            error = true;
        } else {
            errors["ward"] = false;
        }

        // primary phone
        if (this.state.primary_phone === "") {
            errors["primary_phone"] = true;
            error = true;
        } else if (
            !this.state.primary_phone.match(
                // eslint-disable-next-line
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
            )
        ) {
            errors["primary_phone"] = true;
            error = true;
            error_message = "Not a valid phone number";
        } else {
            errors["primary_phone"] = false;
        }

        // phone number
        if (
            !this.state.secondary_phone.match(
                // eslint-disable-next-line
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
            ) &&
            this.state.secondary_phone !== ""
        ) {
            errors["secondary_phone"] = true;
            error = true;
            error_message = "Not a valid phone number";
        } else if (
            this.state.primary_phone === this.state.secondary_phone &&
            this.state.secondary_phone !== ""
        ) {
            errors["secondary_phone"] = true;
            error = true;
            error_message = "Phone numbers can't be similar";
        } else {
            errors["secondary_phone"] = false;
        }

        // primary email
        if (this.state.primary_email === "") {
            errors["primary_email"] = true;
            error = true;
        } else if (
            !this.state.primary_email.match(
                // eslint-disable-next-line
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            )
        ) {
            errors["primary_email"] = true;
            error = true;
            error_message = "Not a valid email address";
        } else {
            errors["primary_email"] = false;
        }

        // secondary email
        if (
            !this.state.secondary_email.match(
                // eslint-disable-next-line
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            ) &&
            this.state.secondary_email !== ""
        ) {
            errors["secondary_email"] = true;
            error = true;
            error_message = "Not a valid email address";
        } else if (
            this.state.primary_email === this.state.secondary_email &&
            this.state.secondary_email !== ""
        ) {
            errors["secondary_email"] = true;
            error = true;
            error_message = "Emails can't be similar";
        } else {
            errors["secondary_email"] = false;
        }

        // address
        if (this.state.address === "") {
            errors["address"] = true;
            error = true;
        } else {
            errors["address"] = false;
        }

        // postal code
        if (this.state.postal_code === "") {
            errors["postal_code"] = true;
            error = true;
        } else {
            errors["postal_code"] = false;
        }

        // linkedin
        if (
            this.state.linkedin !== "" &&
            !this.state.linkedin.match(
                // eslint-disable-next-line
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
        ) {
            errors["linkedin"] = true;
            error_message = "Not a valid url";
            error = true;
        } else {
            errors["linkedin"] = false;
        }

        // facebook
        if (
            this.state.facebook !== "" &&
            !this.state.facebook.match(
                // eslint-disable-next-line
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
        ) {
            errors["facebook"] = true;
            error_message = "Not a valid url";
            error = true;
        } else {
            errors["facebook"] = false;
        }

        // instagram
        if (
            this.state.instagram !== "" &&
            !this.state.instagram.match(
                // eslint-disable-next-line
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
        ) {
            errors["instagram"] = true;
            error_message = "Not a valid url";
            error = true;
        } else {
            errors["instagram"] = false;
        }

        // twitter
        if (
            this.state.twitter !== "" &&
            !this.state.twitter.match(
                // eslint-disable-next-line
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
        ) {
            errors["twitter"] = true;
            error_message = "Not a valid url";
            error = true;
        } else {
            errors["twitter"] = false;
        }

        // website
        if (
            this.state.website !== "" &&
            !this.state.website.match(
                // eslint-disable-next-line
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            )
        ) {
            errors["website"] = true;
            error_message = "Not a valid url";
            error = true;
        } else {
            errors["website"] = false;
        }

        // weekday opening
        if (this.state.weekday_opening === "") {
            errors["weekday_opening"] = true;
            error = true;
        } else {
            errors["weekday_opening"] = false;
        }

        // weekday opening
        if (this.state.weekday_closing === "") {
            errors["weekday_closing"] = true;
            error = true;
        } else {
            errors["weekday_closing"] = false;
        }

        // saturday opening
        if (this.state.saturday_opening === "") {
            errors["saturday_opening"] = true;
            error = true;
        } else {
            errors["saturday_opening"] = false;
        }

        // saturday closing
        if (this.state.saturday_closing === "") {
            errors["saturday_closing"] = true;
            error = true;
        } else {
            errors["saturday_closing"] = false;
        }

        // sunday opening
        if (this.state.sunday_opening === "") {
            errors["sunday_opening"] = true;
            error = true;
        } else {
            errors["sunday_opening"] = false;
        }

        // sunday closing
        if (this.state.sunday_closing === "") {
            errors["sunday_closing"] = true;
            error = true;
        } else {
            errors["sunday_closing"] = false;
        }

        // holiday opening
        if (this.state.holiday_opening === "") {
            errors["holiday_opening"] = true;
            error = true;
        } else {
            errors["holiday_opening"] = false;
        }

        // holiday closing
        if (this.state.holiday_closing === "") {
            errors["holiday_closing"] = true;
            error = true;
        } else {
            errors["holiday_closing"] = false;
        }

        if (this.state.type === "hospital") {
            // morning opening
            if (this.state.morning_opening === "") {
                errors["morning_opening"] = true;
                error = true;
            } else {
                errors["morning_opening"] = false;
            }

            // morning closing
            if (this.state.morning_closing === "") {
                errors["morning_closing"] = true;
                error = true;
            } else {
                errors["morning_closing"] = false;
            }

            // afternoon opening
            if (this.state.afternoon_opening === "") {
                errors["afternoon_opening"] = true;
                error = true;
            } else {
                errors["afternoon_opening"] = false;
            }

            // afternoon closing
            if (this.state.afternoon_closing === "") {
                errors["afternoon_closing"] = true;
                error = true;
            } else {
                errors["afternoon_closing"] = false;
            }

            // evening opening
            if (this.state.evening_opening === "") {
                errors["evening_opening"] = true;
                error = true;
            } else {
                errors["evening_opening"] = false;
            }

            // evening closing
            if (this.state.evening_closing === "") {
                errors["evening_closing"] = true;
                error = true;
            } else {
                errors["evening_closing"] = false;
            }
        }

        if (!error) {
            let working_hours = JSON.stringify({
                weekday:
                    this.state.weekday_opening +
                    "-" +
                    this.state.weekday_closing,
                saturday:
                    this.state.saturday_opening +
                    "-" +
                    this.state.saturday_closing,
                sunday:
                    this.state.sunday_opening + "-" + this.state.sunday_closing,
                holiday:
                    this.state.holiday_opening +
                    "-" +
                    this.state.holiday_closing,
            });
            let gprs = document.getElementById("coordinates").value;
            let visiting_hours = JSON.stringify(this.state.visiting_hours);

            if (this.state.type === "hospital") {
                visiting_hours = JSON.stringify({
                    morning:
                        this.state.morning_opening +
                        "-" +
                        this.state.morning_closing,
                    afternoon:
                        this.state.afternoon_opening +
                        "-" +
                        this.state.afternoon_closing,
                    evening:
                        this.state.evening_opening +
                        "-" +
                        this.state.evening_closing,
                });
            }

            this.setState(
                {
                    gprs: [
                        Number(gprs.split(",")[0]),
                        Number(gprs.split(",")[1]),
                    ],
                    working_hours: working_hours,
                    visiting_hours: visiting_hours,
                },
                () => {
                    postRequest("updateInstitution", "manager", this.state)
                        .then((response) => {
                            if (response.errors) {
                                this.props.enqueueSnackbar(
                                    response.errors[0].message,
                                    {
                                        variant: "error",
                                        anchorOrigin: {
                                            vertical: "top",
                                            horizontal: "center",
                                        },
                                    }
                                );
                                this.setState({ button_loading: false });
                            } else {
                                this.props.enqueueSnackbar(
                                    "Institution update successfull",
                                    {
                                        variant: "success",
                                    }
                                );
                                this.setState({ button_loading: false });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            );
        } else {
            this.setState({
                button_loading: false,
                errors: errors,
                error: error,
                error_message: error_message,
            });
        }
    };

    render() {
        let countiesList = [];

        for (let i = 0; i < locations.length; i++) {
            countiesList.push(Object.keys(locations[i])[0]);
        }

        let counties = countiesList.map((county) => {
            return (
                <MenuItem key={county} value={county}>
                    {cogs.capitalize(county)}
                </MenuItem>
            );
        });

        let county = this.state.county;
        let countyPos = "";
        for (let i = 0; i < locations.length; i++) {
            if (Object.keys(locations[i])[0] === this.state.county) {
                countyPos = i;
                break;
            }
        }

        let constituencies = [];
        let constituenciesList = [];
        if (this.state.county !== "") {
            constituencies = locations[countyPos][county];
            for (let i = 0; i < constituencies.length; i++) {
                constituenciesList.push(Object.keys(constituencies[i])[0]);
            }

            constituencies = constituenciesList.map((constituency) => {
                return (
                    <MenuItem key={constituency} value={constituency}>
                        {cogs.capitalize(constituency)}
                    </MenuItem>
                );
            });
        }

        let communities = [];
        if (this.state.constituency !== "") {
            const pos = constituenciesList.indexOf(this.state.constituency);
            communities =
                locations[countyPos][county][pos][this.state.constituency];

            communities = communities.map((ward) => {
                return (
                    <MenuItem key={ward} value={ward}>
                        {cogs.capitalize(ward)}
                    </MenuItem>
                );
            });
        }

        const insurances = insuranceList.map((insurance) => {
            return (
                <Button
                    key={insurance}
                    style={{ margin: ".8%" }}
                    onClick={() => this.addInsurance(insurance)}
                    color="primary"
                    disableElevation
                    variant={
                        this.state.insurance.includes(insurance)
                            ? "contained"
                            : "outlined"
                    }
                    endIcon={
                        this.state.insurance.includes(insurance) ? (
                            <CancelIcon style={{ fontSize: "17px" }} />
                        ) : (
                            false
                        )
                    }
                >
                    {insurance}
                </Button>
            );
        });

        return (
            <Drawer
                content={
                    <div>
                        {!this.state.loading ? (
                            <Grid
                                container
                                style={{
                                    borderRadius: "5px",
                                    width: "90%",
                                    backgroundColor: "#ffffff",
                                    margin: "3% auto",
                                    boxShadow:
                                        "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                                }}
                            >
                                <Grid
                                    item
                                    xs={3}
                                    className={"mini-side-menu"}
                                    style={{ padding: "2% 0%" }}
                                >
                                    <List
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <ListItemButton
                                            onClick={() =>
                                                this.setState({
                                                    current: "basic",
                                                })
                                            }
                                            className={
                                                this.state.current === "basic"
                                                    ? "item border"
                                                    : "item"
                                            }
                                        >
                                            <ListItemIcon>
                                                <LiveHelpOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Basic Info" />
                                        </ListItemButton>
                                        <ListItemButton
                                            onClick={() =>
                                                this.setState({
                                                    current: "contacts",
                                                })
                                            }
                                            className={
                                                this.state.current ===
                                                "contacts"
                                                    ? "item border"
                                                    : "item"
                                            }
                                        >
                                            <ListItemIcon>
                                                <ContactsOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Contacts" />
                                        </ListItemButton>
                                        <ListItemButton
                                            onClick={() =>
                                                this.setState({
                                                    current: "insurance",
                                                })
                                            }
                                            className={
                                                this.state.current ===
                                                "insurance"
                                                    ? "item border"
                                                    : "item"
                                            }
                                        >
                                            <ListItemIcon>
                                                <SupervisedUserCircleOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Insurance" />
                                        </ListItemButton>
                                        <ListItemButton
                                            onClick={() =>
                                                this.setState({
                                                    current: "socials",
                                                })
                                            }
                                            className={
                                                this.state.current === "socials"
                                                    ? "item border"
                                                    : "item"
                                            }
                                        >
                                            <ListItemIcon>
                                                <FacebookIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Socials" />
                                        </ListItemButton>
                                        <ListItemButton
                                            onClick={() =>
                                                this.setState({
                                                    current: "working_hours",
                                                })
                                            }
                                            className={
                                                this.state.current ===
                                                "working_hours"
                                                    ? "item border"
                                                    : "item"
                                            }
                                        >
                                            <ListItemIcon>
                                                <QueryBuilderOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Working Hours" />
                                        </ListItemButton>
                                        {this.state.type === "hospital" && (
                                            <ListItemButton
                                                onClick={() =>
                                                    this.setState({
                                                        current:
                                                            "visiting_hours",
                                                    })
                                                }
                                                className={
                                                    this.state.current ===
                                                    "visiting_hours"
                                                        ? "item border"
                                                        : "item"
                                                }
                                            >
                                                <ListItemIcon>
                                                    <QueryBuilderOutlinedIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Visiting Hours" />
                                            </ListItemButton>
                                        )}
                                    </List>
                                </Grid>
                                <Grid
                                    item
                                    xs={9}
                                    style={{
                                        padding: "2%",
                                    }}
                                >
                                    {this.state.current === "basic" && (
                                        <div id="basic">
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    color: "#939393",
                                                    padding: "1% 0%",
                                                }}
                                            >
                                                Name, Description and Location
                                            </Typography>
                                            {this.state.error && (
                                                <Alert severity="error">
                                                    {this.state
                                                        .error_message === ""
                                                        ? "Kindly fill in the highlighted fields."
                                                        : this.state
                                                              .error_message}
                                                </Alert>
                                            )}
                                            <TextField
                                                error={this.state.errors.name}
                                                id="name"
                                                name="name"
                                                label="Institution Name"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="Example XYZ"
                                                value={this.state.name}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <StoreOutlinedIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                error={
                                                    this.state.errors
                                                        .description
                                                }
                                                id="description"
                                                name="description"
                                                label="Description / Tagline / Slogan"
                                                variant="outlined"
                                                placeholder="Enter your description here"
                                                value={this.state.description}
                                                multiline
                                                rows={4}
                                                fullWidth
                                                onChange={this.handleChange}
                                                inputProps={{ maxLength: 240 }}
                                            />
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                error={this.state.errors.county}
                                            >
                                                <InputLabel>County</InputLabel>
                                                <Select
                                                    error={
                                                        this.state.errors.county
                                                    }
                                                    label="county"
                                                    name="county"
                                                    labelId="county"
                                                    value={this.state.county}
                                                    onChange={this.handleChange}
                                                >
                                                    <MenuItem value="">
                                                        {" "}
                                                        <em>Chooose County</em>
                                                    </MenuItem>
                                                    {counties}
                                                </Select>
                                            </FormControl>
                                            {this.state.county !== "" && (
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    error={
                                                        this.state.errors
                                                            .constituency
                                                    }
                                                >
                                                    <InputLabel>
                                                        Choose Constituency
                                                    </InputLabel>
                                                    <Select
                                                        label="choose constituency"
                                                        name="constituency"
                                                        labelId="constituency"
                                                        value={
                                                            this.state
                                                                .constituency
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    >
                                                        <MenuItem value="">
                                                            <em>
                                                                Choose
                                                                Constituency
                                                            </em>
                                                        </MenuItem>
                                                        {constituencies}
                                                    </Select>
                                                </FormControl>
                                            )}
                                            {this.state.constituency !== "" && (
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                    error={
                                                        this.state.errors.ward
                                                    }
                                                >
                                                    <InputLabel>
                                                        Choose ward
                                                    </InputLabel>
                                                    <Select
                                                        label="choose ward"
                                                        name="ward"
                                                        labelId="ward"
                                                        value={this.state.ward}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    >
                                                        <MenuItem value="">
                                                            {" "}
                                                            <em>
                                                                Chooose ward
                                                            </em>
                                                        </MenuItem>
                                                        {communities}
                                                    </Select>
                                                </FormControl>
                                            )}

                                            <TextField
                                                disabled
                                                id="coordinates"
                                                name="coordinates"
                                                label="GPRS Coordinates"
                                                margin="normal"
                                                variant="outlined"
                                                defaultValue={this.state.gprs}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LocationOnIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Typography
                                                variant="body1"
                                                color="primary"
                                                align="right"
                                                style={{
                                                    margin: "2% 0%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        map: !this.state.map,
                                                    });
                                                }}
                                            >
                                                <b>
                                                    {this.state.map
                                                        ? "Hide"
                                                        : "Show"}{" "}
                                                    map
                                                </b>
                                            </Typography>
                                            {this.state.map && (
                                                <div>
                                                    <Alert severity="info">
                                                        Drag and drop the red
                                                        marker to your
                                                        institution's location.
                                                    </Alert>
                                                    <br />
                                                    <MapWrapper />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {this.state.current === "contacts" && (
                                        <div id="contacts">
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    color: "#939393",
                                                    padding: "1% 0%",
                                                }}
                                            >
                                                Contact Information
                                            </Typography>
                                            {this.state.error && (
                                                <Alert severity="error">
                                                    {this.state
                                                        .error_message === ""
                                                        ? "Kindly fill in the highlighted fields."
                                                        : this.state
                                                              .error_message}
                                                </Alert>
                                            )}
                                            <TextField
                                                error={
                                                    this.state.errors
                                                        .primary_phone
                                                }
                                                id="primary_phone"
                                                name="primary_phone"
                                                label="Primary Phone"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="0700123456"
                                                value={this.state.primary_phone}
                                                fullWidth
                                                inputProps={{ maxLength: 10 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <PhoneOutlinedIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                error={
                                                    this.state.errors
                                                        .secondary_phone
                                                }
                                                id="secondary_phone"
                                                name="secondary_phone"
                                                label="Secondary Phone (Optional)"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="0700123456"
                                                value={
                                                    this.state.secondary_phone
                                                }
                                                fullWidth
                                                inputProps={{ maxLength: 10 }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <PhoneOutlinedIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                error={
                                                    this.state.errors
                                                        .primary_email
                                                }
                                                id="primary_email"
                                                name="primary_email"
                                                label="Primary Email"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="info@example.com"
                                                value={this.state.primary_email}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <AlternateEmailOutlinedIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                error={
                                                    this.state.errors
                                                        .secondary_email
                                                }
                                                id="secondary_email"
                                                name="secondary_email"
                                                label="Secondary Email (Optional)"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="helpdesk@example.com"
                                                value={
                                                    this.state.secondary_email
                                                }
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <AlternateEmailOutlinedIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .address
                                                        }
                                                        id="address"
                                                        name="address"
                                                        label="Address"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="747"
                                                        type="number"
                                                        value={
                                                            this.state.address
                                                        }
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <span
                                                                        style={{
                                                                            color: "#222222",
                                                                        }}
                                                                    >
                                                                        P.O BOX
                                                                    </span>
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <MailOutlinedIcon
                                                                        fontSize="small"
                                                                        style={{
                                                                            color: "#939393",
                                                                        }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .postal_code
                                                        }
                                                        id="postal_code"
                                                        name="postal_code"
                                                        label="Postal Code"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="00100"
                                                        type="number"
                                                        value={
                                                            this.state
                                                                .postal_code
                                                        }
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <MailOutlinedIcon
                                                                        fontSize="small"
                                                                        style={{
                                                                            color: "#939393",
                                                                        }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                    {this.state.current === "insurance" && (
                                        <div>
                                            <Typography variant="h4">
                                                {this.state.owns ===
                                                    "multiple" &&
                                                !this.state.aspect
                                                    ? "Setup HQ Institution"
                                                    : "Setup Institution"}
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    color: "#939393",
                                                    padding: "1% 0%",
                                                }}
                                            >
                                                Insurance Providers
                                            </Typography>
                                            <div style={{ padding: "2% 0%" }}>
                                                {insurances}
                                            </div>
                                        </div>
                                    )}

                                    {this.state.current === "socials" && (
                                        <div id="socials">
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    color: "#939393",
                                                    padding: "1% 0%",
                                                }}
                                            >
                                                Social Media
                                            </Typography>
                                            {this.state.error && (
                                                <Alert severity="error">
                                                    {this.state
                                                        .error_message === ""
                                                        ? "Kindly fill in the highlighted fields."
                                                        : this.state
                                                              .error_message}
                                                </Alert>
                                            )}
                                            <TextField
                                                error={
                                                    this.state.errors.linkedin
                                                }
                                                id="linkedin"
                                                name="linkedin"
                                                label="Linkedin (Optional)"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="https://www.linkedin.com/example"
                                                value={this.state.linkedin}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LinkedInIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                error={
                                                    this.state.errors.facebook
                                                }
                                                id="facebook"
                                                name="facebook"
                                                label="Facebook (Optional)"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="https://www.facebook.com/example"
                                                value={this.state.facebook}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <FacebookIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                error={
                                                    this.state.errors.twitter
                                                }
                                                id="twitter"
                                                name="twitter"
                                                label="Twitter (Optional)"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="https://www.twitter.com/example"
                                                value={this.state.twitter}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <TwitterIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                error={
                                                    this.state.errors.instagram
                                                }
                                                id="instagram"
                                                name="instagram"
                                                label="Instagram (Optional)"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="https://www.instagram.com/example"
                                                value={this.state.instagram}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <InstagramIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                error={
                                                    this.state.errors.youtube
                                                }
                                                id="youtube"
                                                name="youtube"
                                                label="Youtube (Optional)"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="https://www.youtube.com/example"
                                                value={this.state.youtube}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <YouTubeIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                            <TextField
                                                error={
                                                    this.state.errors.website
                                                }
                                                id="website"
                                                name="website"
                                                label="Website (Optional)"
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="http://www.example.com"
                                                value={this.state.website}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LanguageIcon
                                                                fontSize="small"
                                                                style={{
                                                                    color: "#939393",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    )}

                                    {this.state.current === "working_hours" && (
                                        <div id="hours">
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    color: "#939393",
                                                    padding: "1% 0%",
                                                }}
                                            >
                                                Working Hours
                                            </Typography>
                                            {this.state.error && (
                                                <Alert severity="error">
                                                    {this.state
                                                        .error_message === ""
                                                        ? "Kindly fill in the highlighted fields."
                                                        : this.state
                                                              .error_message}
                                                </Alert>
                                            )}
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    color: "#939393",
                                                    margin: "2% 0%",
                                                }}
                                            >
                                                Weekdays
                                            </Typography>
                                            <Divider
                                                style={{
                                                    margin: "2% 0%",
                                                }}
                                            />
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={2}
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "2%",
                                                    }}
                                                >
                                                    <Typography variant="subtitle1">
                                                        Mon - Fri
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .weekday_opening
                                                        }
                                                        className="third-i"
                                                        id="weekday_opening"
                                                        name="weekday_opening"
                                                        label="Opening"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="08:00"
                                                        value={
                                                            this.state
                                                                .weekday_opening
                                                        }
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    AM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .weekday_closing
                                                        }
                                                        className="third-i"
                                                        id="weekday_closing"
                                                        name="weekday_closing"
                                                        label="Closing"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="05:00"
                                                        value={
                                                            this.state
                                                                .weekday_closing
                                                        }
                                                        fullWidth
                                                        // style={{ float: "right" }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    PM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        style={{
                                                            paddingTop: "3px",
                                                        }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "weekday",
                                                                        "00:00"
                                                                    )
                                                                }
                                                            >
                                                                24&nbsp;HOURS
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "weekday",
                                                                        "__:__"
                                                                    )
                                                                }
                                                            >
                                                                Closed
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    color: "#939393",
                                                    margin: "2% 0%",
                                                }}
                                            >
                                                Weekends
                                            </Typography>
                                            <Divider
                                                style={{
                                                    margin: "2% 0%",
                                                }}
                                            />
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={2}
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "2%",
                                                    }}
                                                >
                                                    <Typography variant="subtitle1">
                                                        Saturday
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .saturday_opening
                                                        }
                                                        className="third-i"
                                                        id="saturday_opening"
                                                        name="saturday_opening"
                                                        label="Opening"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="08:00"
                                                        value={
                                                            this.state
                                                                .saturday_opening
                                                        }
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    AM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .saturday_closing
                                                        }
                                                        className="third-i"
                                                        id="saturday_closing"
                                                        name="saturday_closing"
                                                        label="Closing"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="05:00"
                                                        value={
                                                            this.state
                                                                .saturday_closing
                                                        }
                                                        fullWidth
                                                        style={{
                                                            float: "right",
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    PM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        style={{
                                                            paddingTop: "3px",
                                                        }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "saturday",
                                                                        "00:00"
                                                                    )
                                                                }
                                                            >
                                                                24&nbsp;HOURS
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "saturday",
                                                                        "__:__"
                                                                    )
                                                                }
                                                            >
                                                                Closed
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={2}
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "2%",
                                                    }}
                                                >
                                                    <Typography variant="subtitle1">
                                                        Sunday
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .sunday_opening
                                                        }
                                                        className="third-i"
                                                        id="sunday_opening"
                                                        name="sunday_opening"
                                                        label="Opening"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="08:00"
                                                        value={
                                                            this.state
                                                                .sunday_opening
                                                        }
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    AM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .sunday_closing
                                                        }
                                                        className="third-i"
                                                        id="sunday_closing"
                                                        name="sunday_closing"
                                                        label="Closing"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="12:00"
                                                        value={
                                                            this.state
                                                                .sunday_closing
                                                        }
                                                        fullWidth
                                                        style={{
                                                            float: "right",
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    PM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        style={{
                                                            paddingTop: "3px",
                                                        }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "sunday",
                                                                        "00:00"
                                                                    )
                                                                }
                                                            >
                                                                24&nbsp;HOURS
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "sunday",
                                                                        "__:__"
                                                                    )
                                                                }
                                                            >
                                                                Closed
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        color: "#939393",
                                                        margin: "2% 0%",
                                                    }}
                                                >
                                                    Public Holidays
                                                </Typography>
                                                <Divider
                                                    style={{
                                                        width: "100%",
                                                        margin: "2% 0%",
                                                    }}
                                                />
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    style={{
                                                        paddingBottom: "5%",
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={2}
                                                        style={{
                                                            textAlign: "center",
                                                            paddingTop: "2%",
                                                        }}
                                                    >
                                                        <Typography variant="subtitle1">
                                                            Holidays
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} md={3}>
                                                        <TextField
                                                            error={
                                                                this.state
                                                                    .errors
                                                                    .holiday_opening
                                                            }
                                                            className="third-i"
                                                            id="holiday_opening"
                                                            name="holiday_opening"
                                                            label="Opening"
                                                            margin="normal"
                                                            variant="outlined"
                                                            placeholder="08:00"
                                                            value={
                                                                this.state
                                                                    .holiday_opening
                                                            }
                                                            fullWidth
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        AM
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} md={3}>
                                                        <TextField
                                                            error={
                                                                this.state
                                                                    .errors
                                                                    .holiday_closing
                                                            }
                                                            className="third-i"
                                                            id="holiday_closing"
                                                            name="holiday_closing"
                                                            label="Closing"
                                                            margin="normal"
                                                            variant="outlined"
                                                            placeholder="12:00"
                                                            value={
                                                                this.state
                                                                    .holiday_closing
                                                            }
                                                            fullWidth
                                                            style={{
                                                                float: "right",
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        PM
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                            style={{
                                                                paddingTop:
                                                                    "3px",
                                                            }}
                                                        >
                                                            <Grid item xs={6}>
                                                                <Button
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    style={{
                                                                        padding:
                                                                            "16% 5%",
                                                                    }}
                                                                    onClick={() =>
                                                                        this.setHours(
                                                                            "holiday",
                                                                            "00:00"
                                                                        )
                                                                    }
                                                                >
                                                                    24&nbsp;HOURS
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Button
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    style={{
                                                                        padding:
                                                                            "16% 5%",
                                                                    }}
                                                                    onClick={() =>
                                                                        this.setHours(
                                                                            "holiday",
                                                                            "__:__"
                                                                        )
                                                                    }
                                                                >
                                                                    Closed
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                    {this.state.current ===
                                        "visiting_hours" && (
                                        <div id="hours">
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    color: "#939393",
                                                    padding: "1% 0%",
                                                }}
                                            >
                                                Visiting Hours
                                            </Typography>
                                            {this.state.error && (
                                                <Alert severity="error">
                                                    {this.state
                                                        .error_message === ""
                                                        ? "Kindly fill in the highlighted fields."
                                                        : this.state
                                                              .error_message}
                                                </Alert>
                                            )}
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={2}
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "2%",
                                                    }}
                                                >
                                                    <Typography variant="subtitle1">
                                                        Morning Hours
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .morning_opening
                                                        }
                                                        className="third-i"
                                                        id="morning_opening"
                                                        name="morning_opening"
                                                        label="Opening"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="08:00"
                                                        value={
                                                            this.state
                                                                .morning_opening
                                                        }
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    AM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .morning_closing
                                                        }
                                                        className="third-i"
                                                        id="morning_closing"
                                                        name="morning_closing"
                                                        label="Closing"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="12:00"
                                                        value={
                                                            this.state
                                                                .morning_closing
                                                        }
                                                        fullWidth
                                                        style={{
                                                            float: "right",
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    AM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        style={{
                                                            paddingTop: "3px",
                                                        }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "morning",
                                                                        "00:00"
                                                                    )
                                                                }
                                                            >
                                                                24&nbsp;HOURS
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "morning",
                                                                        "__:__"
                                                                    )
                                                                }
                                                            >
                                                                Closed
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={2}
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "2%",
                                                    }}
                                                >
                                                    <Typography variant="subtitle1">
                                                        Afternoon Hours
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .afternoon_opening
                                                        }
                                                        className="third-i"
                                                        id="afternoon_opening"
                                                        name="afternoon_opening"
                                                        label="Opening"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="08:00"
                                                        value={
                                                            this.state
                                                                .afternoon_opening
                                                        }
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    AM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .afternoon_closing
                                                        }
                                                        className="third-i"
                                                        id="afternoon_closing"
                                                        name="afternoon_closing"
                                                        label="Closing"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="12:00"
                                                        value={
                                                            this.state
                                                                .afternoon_closing
                                                        }
                                                        fullWidth
                                                        style={{
                                                            float: "right",
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    PM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        style={{
                                                            paddingTop: "3px",
                                                        }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "afternoon",
                                                                        "00:00"
                                                                    )
                                                                }
                                                            >
                                                                24&nbsp;HOURS
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "afternoon",
                                                                        "__:__"
                                                                    )
                                                                }
                                                            >
                                                                Closed
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={2}
                                                    style={{
                                                        textAlign: "center",
                                                        paddingTop: "2%",
                                                    }}
                                                >
                                                    <Typography variant="subtitle1">
                                                        Evening Hours
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .evening_opening
                                                        }
                                                        className="third-i"
                                                        id="evening_opening"
                                                        name="evening_opening"
                                                        label="Opening"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="08:00"
                                                        value={
                                                            this.state
                                                                .evening_opening
                                                        }
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    PM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <TextField
                                                        error={
                                                            this.state.errors
                                                                .evening_closing
                                                        }
                                                        className="third-i"
                                                        id="evening_closing"
                                                        name="evening_closing"
                                                        label="Closing"
                                                        margin="normal"
                                                        variant="outlined"
                                                        placeholder="12:00"
                                                        value={
                                                            this.state
                                                                .evening_closing
                                                        }
                                                        fullWidth
                                                        style={{
                                                            float: "right",
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    PM
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        style={{
                                                            paddingTop: "3px",
                                                        }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "evening",
                                                                        "00:00"
                                                                    )
                                                                }
                                                            >
                                                                24&nbsp;HOURS
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                fullWidth
                                                                style={{
                                                                    padding:
                                                                        "16% 5%",
                                                                }}
                                                                onClick={() =>
                                                                    this.setHours(
                                                                        "evening",
                                                                        "__:__"
                                                                    )
                                                                }
                                                            >
                                                                Closed
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                    <Button
                                        className="submit-button"
                                        variant="contained"
                                        fullWidth
                                        onClick={() => this.updateInstitution()}
                                    >
                                        {!this.state.button_loading ? (
                                            "UPDATE INSTITUTION"
                                        ) : (
                                            <CircularProgress
                                                size={24}
                                                style={{ color: "#ffffff" }}
                                            />
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    height: "85vh",
                                }}
                            >
                                <CircularProgress
                                    size={45}
                                    style={{
                                        marginTop: "15%",
                                        borderRadius: "100%",
                                        padding: ".1%",
                                        boxShadow:
                                            "0rem 0rem 1rem rgba(0, 0, 0, 0.15)",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(institutionEditPage);
