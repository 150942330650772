/**
 * Function that capitalizes a string
 * @param {Uncapitalized string} string
 * @returns Capitalized string
 */
export const capitalize = (string) => {
    return string.replace(/\b\w/g, (l) => l.toUpperCase());
};

/**
 * Function that capitalizes the first letter of a string
 * @param {Uncapitalized first letter in string} string
 * @returns Capitalized string
 */
export const capitalizeFL = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * A function that formats a phone number to include
 * the country code
 * @param {0707837313} phoneNumber
 * @returns 254707837313
 */
export const formatPhone = (phoneNumber) => {
    if (phoneNumber.length < 12) {
        if (phoneNumber.charAt(0) === "0") {
            phoneNumber = phoneNumber.replace("0", "254");
        } else {
            phoneNumber = "254" + phoneNumber;
        }
    }

    return phoneNumber;
};

