import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { Helmet } from "react-helmet";
import {
    Box,
    Grid,
    Link,
    Typography,
    Button,
    CircularProgress,
    Alert,
    Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FirstName } from "../../components/forms/user/firstName";
import { LastName } from "../../components/forms/user/lastName";
import { Email } from "../../components/forms/user/email";
import { PhoneNumber } from "../../components/forms/user/phoneNumber";
import { Institution } from "../../components/forms/manager/institution";
import { Designation } from "../../components/forms/manager/designation";
import { Industry } from "../../components/forms/manager/industry";
import { Owns } from "../../components/forms/manager/owns";
import { Password } from "../../components/forms/user/password";
import {
    validateEmail,
    validatePhone,
    validatePassword,
} from "../../helpers/validation";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { gqlQueryManager } from "../../helpers/gqlManager";

export default function ManagerSignUp() {
    const [btnLoad, setBtnLoad] = useState(false);
    const [step, setStep] = useState("personal");
    const [err, setError] = useState("");
    const [data, updateData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        institution: "",
        designation: "administrator",
        industry: "care",
        owns: "single",
        password: "",
    });

    let disabled =
        data.email === "" ||
        data.PhoneNumber === "" ||
        data.password === "" ||
        data.firstName === "" ||
        data.lastName === "";

    let disabled2 = data.institution === "";

    const validatePersonal = () => {
        try {
            validateEmail(data.email);
            validatePhone(data.phoneNumber);
            validatePassword(data.password);
            setError("");
            setStep("institution");
        } catch (err) {
            setError(err.message);
        }
    };

    const submitData = () => {
        setBtnLoad(true);
        setError("");
        execute({
            variables: {
                data: data,
            },
            context: {
                clientName: "manager",
            },
        })
            .then(
                ({
                    data: {
                        createManager: { response },
                    },
                }) => {
                    if (response.substring(0, 6) === "250 OK") {
                        setStep("success");
                    } else {
                        throw new Error(
                            "Email wasn't sent. Kindly check your email and try again"
                        );
                    }
                }
            )
            .catch((error) => {
                setError(error.message.replace("Error: ", ""));
                setBtnLoad(false);
            });
    };

    const [execute] = useMutation(
        gql`
            ${gqlQueryManager("createManager", gql)}
        `
    );

    document.addEventListener("keyup", (event) => {
        if (!disabled) {
            if (event.keyCode === 13) {
                if (step === "personal" && !disabled) {
                    validatePersonal();
                } else if (step === "institution" && !disabled) {
                    submitData();
                }
            }
        }
    });

    return (
        <div>
            <Helmet>
                <title>SignUp</title>
                <meta
                    name="description"
                    content="Welcome to wapidoc. Create an account to join our wapidoc community."
                />
            </Helmet>
            <Grid container>
                <Grid
                    className={"wide-screen-section"}
                    item
                    xs={12}
                    md={6}
                    style={{ backgroundColor: "#ffebf0", height: "100vh" }}
                >
                    <Link href="/">
                        <img
                            style={{
                                width: "140px",
                                height: "auto",
                                padding: "1% 2%",
                            }}
                            src={"../WapiDocLogo.png"}
                            alt="WapiDocLogo"
                        />
                    </Link>
                    <img
                        style={{
                            width: "auto",
                            height: "80vh",
                            margin: "0 auto",
                            display: "block",
                        }}
                        src={"../../images/signupWapiDoc.svg"}
                        alt="Sign Up to WapiDOc"
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                        padding: "3%",
                        backgroundColor: "#ffffff",
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    {step !== "success" ? (
                        <div>
                            <Typography variant="h5" gutterBottom>
                                Sign up as an institution manager
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                paragraph
                                style={{ color: "#939393" }}
                            >
                                Already have an account?{" "}
                                <Link href="/manager/signin">Signin</Link>
                            </Typography>

                            <Box mt={6} mb={3}>
                                {err !== "" && (
                                    <Alert
                                        severity="error"
                                        style={{ marginBottom: "1.5%" }}
                                    >
                                        {err}
                                    </Alert>
                                )}

                                {step === "personal" && (
                                    <div>
                                        <Typography
                                            variant="subtitle1"
                                            gutterBottom
                                        >
                                            Personal Information
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <FirstName
                                                    data={data}
                                                    updateData={updateData}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <LastName
                                                    data={data}
                                                    updateData={updateData}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Email
                                            data={data}
                                            updateData={updateData}
                                        />
                                        <PhoneNumber
                                            data={data}
                                            updateData={updateData}
                                        />
                                        <Password
                                            data={data}
                                            updateData={updateData}
                                        />
                                        <Button
                                            id="submitPersonal"
                                            className="send-data-button"
                                            variant="contained"
                                            disableElevation
                                            fullWidth
                                            disabled={disabled}
                                            style={{
                                                backgroundColor: `${
                                                    !disabled
                                                        ? "#ce043d"
                                                        : "#E0E0E0"
                                                }`,
                                            }}
                                            onClick={() => {
                                                validatePersonal();
                                            }}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                )}

                                {step === "institution" && (
                                    <div>
                                        <Typography
                                            variant="subtitle1"
                                            gutterBottom
                                            onClick={() => {
                                                setStep("personal");
                                                setError("");
                                            }}
                                        >
                                            <ArrowBackIcon
                                                style={{
                                                    cursor: "pointer",
                                                    margin: "0% 2% -1.2% 0%",
                                                }}
                                            />
                                            Institution Information
                                        </Typography>
                                        <Institution
                                            data={data}
                                            updateData={updateData}
                                        />
                                        <Industry
                                            data={data}
                                            updateData={updateData}
                                        />
                                        <Owns
                                            data={data}
                                            updateData={updateData}
                                        />
                                        <Designation
                                            data={data}
                                            updateData={updateData}
                                        />
                                        <Button
                                            id="submitData"
                                            className="send-data-button"
                                            variant="contained"
                                            disableElevation
                                            fullWidth
                                            disabled={disabled2}
                                            style={{
                                                backgroundColor: `${
                                                    !disabled2
                                                        ? "#ce043d"
                                                        : "#E0E0E0"
                                                }`,
                                            }}
                                            onClick={() => {
                                                submitData();
                                            }}
                                        >
                                            {!btnLoad ? (
                                                "Continue"
                                            ) : (
                                                <CircularProgress
                                                    size={24}
                                                    style={{ color: "#ffffff" }}
                                                />
                                            )}
                                        </Button>
                                    </div>
                                )}
                            </Box>
                            <Typography variant="subtitle2">
                                By continuing to use WapiDoc, you agree to our{" "}
                                <a href="/legal/terms-and-conditions">
                                    Terms and Conditions
                                </a>{" "}
                                , <a href="/legal/data-policy">Data Policy</a>{" "}
                                and{" "}
                                <a href="/legal/cookie-policy">Cookie Policy</a>
                                .
                            </Typography>
                        </div>
                    ) : (
                        <div>
                            <Typography variant="h5" gutterBottom>
                                Account Created Successfully
                            </Typography>
                            <Typography
                                variant="h6"
                                style={{
                                    color: "#5DC726",
                                }}
                            >
                                <CheckCircleIcon
                                    style={{ marginBottom: "-1.2%" }}
                                />{" "}
                                An email is on the way
                            </Typography>
                            <Divider style={{ margin: "2% 0%" }} />
                            <Typography variant="body2" paragraph>
                                A verification link has just been sent to your
                                email account. Kindly click on the link to
                                confirm your email address.
                            </Typography>
                        </div>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
