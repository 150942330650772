import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

export const Loading = () => {
    return (
        <Grid container>
            <Grid
                item
                xs={12}
                style={{
                    textAlign: "center",
                    height: "85vh",
                }}
            >
                <CircularProgress
                    size={40}
                    style={{
                        marginTop: "20%",
                        color: "#ce043d",
                    }}
                />
            </Grid>
        </Grid>
    );
};
