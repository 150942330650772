import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export const Industry = ({ data, updateData }) => {
    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel>Choose Health Industry</InputLabel>
            <Select
                label="choose health industry"
                name="industry"
                labelId="industry"
                value={data.industry}
                onChange={(event) => {
                    updateData({ ...data, industry: event.target.value });
                }}
            >
                <MenuItem value="care">Health Care </MenuItem>
                <MenuItem value="insurance">Health Insurance </MenuItem>
                <MenuItem value="products">Health Products</MenuItem>
                <MenuItem value="training">Health Training</MenuItem>
            </Select>
        </FormControl>
    );
};
