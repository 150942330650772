import React from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
} from "@mui/material";
import { capitalize } from "../../../../helpers/string";

const parseHours = (time) => {
    return time === "00:00-00:00"
        ? "24/7"
        : time === "__:__-__:__"
        ? "CLOSED"
        : time.split("-")[0] + "AM - " + time.split("-")[1] + "PM";
};

export const InstitutionConfirm = ({ setReady, data, updateData }) => {
    return (
        <div>
            <Dialog
                open={data.status === "confirm-details"}
                onClose={false}
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    CONFIRM INSTITUTION DETAILS
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography
                            variant="subtitle1"
                            style={{ color: "#ce043d" }}
                        >
                            CATEGORY
                        </Typography>
                        <Typography variant="body1">
                            <b>Category: </b>
                            {capitalize(data.type.replace("-", " "))}
                        </Typography>

                        <Divider variant="left" style={{ margin: "2% 0%" }} />

                        <Typography
                            variant="subtitle1"
                            style={{ color: "#ce043d" }}
                        >
                            INFO
                        </Typography>
                        <Typography variant="body1">
                            <b>Name: </b> {capitalize(data.name)}
                        </Typography>
                        <Typography variant="body1">
                            <b>Description:</b> {data.description}
                        </Typography>

                        <Divider variant="left" style={{ margin: "2% 0%" }} />

                        <Typography
                            variant="subtitle1"
                            style={{ color: "#ce043d" }}
                        >
                            LOCATION
                        </Typography>
                        <Typography variant="body1">
                            <b>County: </b> {capitalize(data.location.county)}
                        </Typography>
                        <Typography variant="body1">
                            <b>Constituency</b>:
                            {capitalize(data.location.constituency)}
                        </Typography>
                        <Typography variant="body1">
                            <b>Ward: </b> {capitalize(data.location.ward)}
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            <b>Street: </b> {capitalize(data.location.county)}
                        </Typography>
                        <Typography variant="body1">
                            <b>Building: </b>
                            {capitalize(data.location.streetAddress.building)}
                        </Typography>
                        <Typography variant="body1">
                            <b>Floor</b>:{" "}
                            {capitalize(data.location.streetAddress.floor)}
                        </Typography>
                        <Typography variant="body1">
                            <b>Unit</b>:{" "}
                            {capitalize(data.location.streetAddress.unit)}
                        </Typography>

                        <Divider variant="left" style={{ margin: "2% 0%" }} />

                        <Typography
                            variant="subtitle1"
                            style={{ color: "#ce043d" }}
                        >
                            CONTACTS
                        </Typography>
                        <Typography variant="body1">
                            <b>Primary Phone Number: </b>
                            {data.contact.phoneNumber.primary}
                        </Typography>
                        <Typography variant="body1">
                            <b>Secondary Phone Number: </b>
                            {data.contact.phoneNumber.secondary}
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            <b>Primary Email: </b>
                            {data.contact.email.primary}
                        </Typography>
                        <Typography variant="body1">
                            <b>Secondary Email: </b>
                            {data.contact.email.secondary}
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            <b>Address: </b>
                            {data.contact.address}
                        </Typography>

                        <Divider variant="left" style={{ margin: "2% 0%" }} />

                        <Typography
                            variant="subtitle1"
                            style={{ color: "#ce043d" }}
                        >
                            INSURANCE
                        </Typography>
                        <Typography variant="body1">
                            <ul>
                                {data.insurance.map((item) => {
                                    return <li key={item}>{item}</li>;
                                })}
                            </ul>
                        </Typography>

                        <Divider variant="left" style={{ margin: "2% 0%" }} />

                        <Typography
                            variant="subtitle1"
                            style={{ color: "#ce043d" }}
                        >
                            SOCIAL MEDIA
                        </Typography>
                        <Typography variant="body1">
                            <b>Facebook:</b> {data.links.facebook}
                        </Typography>
                        <Typography variant="body1">
                            <b>Twitter: </b>
                            {data.links.twitter}
                        </Typography>
                        <Typography variant="body1">
                            <b>Instagram:</b> {data.links.instagram}
                        </Typography>
                        <Typography variant="body1">
                            <b>LinkedIn:</b> {data.links.linkedin}
                        </Typography>
                        <Typography variant="body1">
                            <b>WhatsApp:</b> {data.links.whatsapp}
                        </Typography>
                        <Typography variant="body1">
                            <b>Youtube:</b> {data.links.youtube}
                        </Typography>

                        <Divider variant="left" style={{ margin: "2% 0%" }} />

                        <Typography
                            variant="subtitle1"
                            style={{ color: "#ce043d" }}
                        >
                            WORKING HOURS
                        </Typography>
                        <Typography variant="body1">
                            <b>Weekdays:</b>{" "}
                            {parseHours(data.hours.workingHours.weekday)}
                        </Typography>
                        <Typography variant="body1">
                            <b>Saturday</b>{" "}
                            {parseHours(data.hours.workingHours.saturday)}
                        </Typography>
                        <Typography variant="body1">
                            <b>Sunday:</b>{" "}
                            {parseHours(data.hours.workingHours.sunday)}
                        </Typography>
                        <Typography variant="body1">
                            <b>Holidays:</b>{" "}
                            {parseHours(data.hours.workingHours.holiday)}
                        </Typography>

                        {data.type === "hospital" && (
                            <div>
                                <Divider
                                    variant="left"
                                    style={{ margin: "2% 0%" }}
                                />

                                <Typography
                                    variant="subtitle1"
                                    style={{ color: "#ce043d" }}
                                >
                                    VISITING HOURS
                                </Typography>
                                <Typography variant="body1">
                                    <b>Morning: </b>{" "}
                                    {parseHours(
                                        data.hours.visitingHours.morning
                                    )}
                                </Typography>
                                <Typography variant="body1">
                                    <b>Afternoon: </b>
                                    {parseHours(
                                        data.hours.visitingHours.afternoon
                                    )}
                                </Typography>
                                <Typography variant="body1">
                                    <b>Evening: </b>{" "}
                                    {parseHours(
                                        data.hours.visitingHours.evening
                                    )}
                                </Typography>
                            </div>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        id="submitData"
                        variant="contained"
                        disableElevation
                        style={{
                            backgroundColor: "#444444",
                            borderRadius: "10px",
                            color: "#ffffff",
                            padding: "1.5% 3%",
                        }}
                        onClick={() => {
                            updateData({
                                ...data,
                                status:
                                    data.type === "hospital"
                                        ? "visiting-hours"
                                        : "working-hours",
                            });
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="submitData"
                        variant="contained"
                        disableElevation
                        style={{
                            padding: "1.5% 3%",
                            borderRadius: "10px",
                            color: "#ffffff",
                            marginLeft: "3%",
                            backgroundColor: "#008000",
                        }}
                        onClick={() => {
                            updateData({
                                ...data,
                                status: "active",
                            });
                            setReady(true);
                        }}
                    >
                        CONFIRM AND SUBMIT DATA
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
