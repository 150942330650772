import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { withSnackbar } from "notistack";
import { IKContext, IKUpload } from "imagekitio-react";
import Drawer from "../../components/drawer";
import { postRequest } from "../../middleware/graphQl";
import cogs from "../../middleware/cogs";
import locations from "./locations.json";

import {
    TextField,
    Grid,
    InputAdornment,
    Button,
    CircularProgress,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Badge,
    Avatar,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";

class ProfilePage extends Component {
    state = {
        services: [],
        loading: false,
        imageLoading: false,
        buttonLoading: false,
        error: false,
        errors: {
            first_name: false,
            last_name: false,
            email: false,
            phone: false,
            dob: false,
            gender: false,
            county: false,
            constituency: false,
            ward: false,
        },
        error_message: "",
        manager: JSON.parse(localStorage.getItem("manager")) || {
            county: "",
            constituency: "",
        },
    };

    componentDidMount = () => {
        cogs.managerAuthorization(
            jwt_decode,
            postRequest,
            window.location.href
        );
    };

    handleChange = (event) => {
        let manager = this.state.manager;

        if (event.target.name === "county") {
            manager["constituency"] = "";
            manager["ward"] = "";
        } else if (event.target.name === "constituency") {
            manager["ward"] = "";
        }

        manager[event.target.name] = event.target.value;
        this.setState({ manager });
    };

    onUploadSuccess = async (event) => {
        axios.get(
            `${process.env.REACT_APP_HOST_API}/imagekit/delete/${this.state.manager.photo}`
        );

        let manager = this.state.manager;

        manager["photo"] = event.name;

        const response = await postRequest("updateManager", "manager", manager);

        if (response.errors) {
            this.setState({ imageLoading: false });
            this.props.enqueueSnackbar(response.errors[0].message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
        } else {
            const manager = await postRequest("singleManager", "manager", {
                id: response.data.updateManager._id,
            });

            localStorage.setItem(
                "manager",
                JSON.stringify(manager.data.singleManager)
            );
            this.setState({
                imageLoading: false,
                manager: manager.data.singleManager,
            });

            this.props.enqueueSnackbar("Image Upload Successful", {
                variant: "success",
            });
        }
    };

    onUploadFail = () => {
        this.setState({ loading: false });
        this.props.enqueueSnackbar("Image Upload Failed", {
            variant: "error",
            anchorOrigin: {
                vertical: "top",
                horizontal: "center",
            },
        });
    };

    updateManager = async () => {
        let errors = this.state.errors;
        let error = false;

        // first_name
        if (this.state.manager.first_name === "") {
            errors["first_name"] = true;
            error = true;
        } else {
            errors["first_name"] = false;
        }

        // last_name
        if (this.state.manager.last_name === "") {
            errors["last_name"] = true;
            error = true;
        } else {
            errors["last_name"] = false;
        }

        // age
        if (this.state.manager.dob === "") {
            errors["dob"] = true;
            error = true;
        } else {
            errors["dob"] = false;
        }

        // gender
        if (this.state.manager.gender === "") {
            errors["gender"] = true;
            error = true;
        } else {
            errors["gender"] = false;
        }

        // county
        if (this.state.manager.county === "") {
            errors["county"] = true;
            error = true;
        } else {
            errors["county"] = false;
        }

        // constituency
        if (this.state.manager.constituency === "") {
            errors["constituency"] = true;
            error = true;
        } else {
            errors["constituency"] = false;
        }

        // ward
        if (this.state.manager.ward === "") {
            errors["ward"] = true;
            error = true;
        } else {
            errors["ward"] = false;
        }

        this.setState({ errors: errors, error: error });

        if (!error) {
            this.setState({ buttonLoading: true });

            let manager = this.state.manager;

            const response = await postRequest(
                "updateManager",
                "manager",
                manager
            );

            if (response.errors) {
                this.setState({ loading: false });
                this.props.enqueueSnackbar(response.errors[0].message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            } else {
                const manager = await postRequest("singleManager", "manager", {
                    id: response.data.updateManager._id,
                });

                localStorage.setItem(
                    "manager",
                    JSON.stringify(manager.data.singleManager)
                );

                this.setState({
                    buttonLoading: false,
                    manager: manager.data.singleManager,
                });

                this.props.enqueueSnackbar("Update Successful", {
                    variant: "success",
                });
            }
        }
    };

    render() {
        let countiesList = [];

        for (let i = 0; i < locations.length; i++) {
            countiesList.push(Object.keys(locations[i])[0]);
        }

        let counties = countiesList.map((county) => {
            return (
                <MenuItem key={county} value={county}>
                    {cogs.capitalize(county)}
                </MenuItem>
            );
        });

        let county = this.state.manager.county;
        let countyPos = "";
        for (let i = 0; i < locations.length; i++) {
            if (Object.keys(locations[i])[0] === this.state.manager.county) {
                countyPos = i;
                break;
            }
        }

        let constituencies = [];
        let constituenciesList = [];
        if (this.state.manager.county !== "") {
            constituencies = locations[countyPos][county];
            for (let i = 0; i < constituencies.length; i++) {
                constituenciesList.push(Object.keys(constituencies[i])[0]);
            }

            constituencies = constituenciesList.map((constituency) => {
                return (
                    <MenuItem key={constituency} value={constituency}>
                        {cogs.capitalize(constituency)}
                    </MenuItem>
                );
            });
        }

        let communities = [];
        if (this.state.manager.constituency !== "") {
            const pos = constituenciesList.indexOf(
                this.state.manager.constituency
            );
            communities =
                locations[countyPos][county][pos][
                    this.state.manager.constituency
                ];

            communities = communities.map((ward) => {
                return (
                    <MenuItem key={ward} value={ward}>
                        {cogs.capitalize(ward)}
                    </MenuItem>
                );
            });
        }

        return (
            <Drawer
                content={
                    <div>
                        {!this.state.loading ? (
                            <div
                                style={{
                                    paddingTop: "2%",
                                }}
                            >
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        md={8}
                                        style={{
                                            margin: "2% auto",
                                            backgroundColor: "#ffffff",
                                            padding: "2%",
                                            borderRadius: "5px",
                                            boxShadow:
                                                "0 0.1rem 1rem rgba(0, 0, 0, 0.15)",
                                        }}
                                    >
                                        <Typography variant="h5" align="center">
                                            Update Profile
                                        </Typography>
                                        <IKContext
                                            publicKey={
                                                process.env
                                                    .REACT_APP_IMAGEKIT_PUBLIC_ENDPOINT
                                            }
                                            urlEndpoint={
                                                process.env
                                                    .REACT_APP_IMAGEKIT_URL_ENDPOINT
                                            }
                                            authenticationEndpoint={
                                                process.env
                                                    .REACT_APP_IMAGEKIT_AUTH_ENDPOINT
                                            }
                                        >
                                            <IKUpload
                                                id="imageuploadbutton"
                                                fileName={`${this.state.manager.first_name}_${this.state.manager.last_name}.jpg`}
                                                folder="/managers/profile_pictures/"
                                                onChange={() =>
                                                    this.setState({
                                                        imageLoading: true,
                                                    })
                                                }
                                                onError={() =>
                                                    this.onUploadFail()
                                                }
                                                onSuccess={(event) =>
                                                    this.onUploadSuccess(event)
                                                }
                                                style={{ display: "none" }}
                                            />
                                        </IKContext>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                padding: "1% 0%",
                                            }}
                                        >
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                                badgeContent={
                                                    this.state.imageLoading ? (
                                                        <CircularProgress
                                                            size={24}
                                                            style={{
                                                                borderRadius:
                                                                    "100%",
                                                                color: "#324C74",
                                                            }}
                                                        />
                                                    ) : (
                                                        <EditIcon
                                                            style={{
                                                                fontSize:
                                                                    "28px",
                                                                backgroundColor:
                                                                    "#324C74",
                                                                borderRadius:
                                                                    "100%",
                                                                padding: "20%",
                                                                cursor: "pointer",
                                                                color: "#ffffff",
                                                            }}
                                                            onClick={() => {
                                                                document
                                                                    .getElementById(
                                                                        "imageuploadbutton"
                                                                    )
                                                                    .click();
                                                            }}
                                                        />
                                                    )
                                                }
                                            >
                                                <Avatar
                                                    alt="profile pic"
                                                    src={`https://ik.imagekit.io/9vm1rbs0vzz/managers/profile_pictures/${this.state.manager.photo}?ik-sdk-version=javascript-1.4.3&updatedAt=1642422126448`}
                                                    style={{
                                                        width: "150px",
                                                        height: "150px",
                                                        margin: "0% auto",
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </Badge>
                                        </div>
                                        <br />
                                        {this.state.error && (
                                            <Alert severity="error">
                                                {this.state.error_message === ""
                                                    ? "Kindly fill in the highlighted fields."
                                                    : this.state.error_message}
                                            </Alert>
                                        )}
                                        <br />
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    error={
                                                        this.state.errors
                                                            .first_name
                                                    }
                                                    id="first_name"
                                                    name="first_name"
                                                    label="First Name"
                                                    margin="normal"
                                                    variant="outlined"
                                                    placeholder="John"
                                                    defaultValue={
                                                        this.state.manager
                                                            .first_name
                                                    }
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <PersonOutlineOutlinedIcon
                                                                    fontSize="small"
                                                                    style={{
                                                                        color: "#939393",
                                                                    }}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    error={
                                                        this.state.errors
                                                            .last_name
                                                    }
                                                    id="last_name"
                                                    name="last_name"
                                                    label="Last Name"
                                                    margin="normal"
                                                    variant="outlined"
                                                    placeholder="Doe"
                                                    defaultValue={
                                                        this.state.manager
                                                            .last_name
                                                    }
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <PersonOutlineOutlinedIcon
                                                                    fontSize="small"
                                                                    style={{
                                                                        color: "#939393",
                                                                    }}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    error={
                                                        this.state.errors.dob
                                                    }
                                                    variant="outlined"
                                                    id="dob"
                                                    name="dob"
                                                    label="Date of Birth"
                                                    type="date"
                                                    defaultValue={
                                                        this.state.manager.dob
                                                    }
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    align="left"
                                                    fullWidth
                                                >
                                                    <InputLabel>
                                                        Gender
                                                    </InputLabel>
                                                    <Select
                                                        error={
                                                            this.state.errors
                                                                .gender
                                                        }
                                                        label="choose gender"
                                                        name="gender"
                                                        labelId="choose gender"
                                                        value={
                                                            this.state.manager
                                                                .gender
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    >
                                                        <MenuItem value="">
                                                            <em>
                                                                Choose Gender
                                                            </em>
                                                        </MenuItem>
                                                        <MenuItem value="male">
                                                            Male
                                                        </MenuItem>
                                                        <MenuItem value="female">
                                                            Female
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <InputLabel>County</InputLabel>
                                            <Select
                                                error={this.state.errors.county}
                                                label="county"
                                                name="county"
                                                labelId="county"
                                                value={
                                                    this.state.manager.county
                                                }
                                                onChange={this.handleChange}
                                            >
                                                <MenuItem value="">
                                                    <em>Choose County</em>
                                                </MenuItem>
                                                {counties}
                                            </Select>
                                        </FormControl>
                                        {this.state.manager.county !== "" && (
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                error={
                                                    this.state.errors
                                                        .constituency
                                                }
                                            >
                                                <InputLabel>
                                                    Choose Constituency
                                                </InputLabel>
                                                <Select
                                                    label="choose constituency"
                                                    name="constituency"
                                                    labelId="constituency"
                                                    value={
                                                        this.state.manager
                                                            .constituency
                                                    }
                                                    onChange={this.handleChange}
                                                >
                                                    <MenuItem value="">
                                                        <em>
                                                            Choose Constituency
                                                        </em>
                                                    </MenuItem>
                                                    {constituencies}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {this.state.manager.constituency !==
                                            "" && (
                                            <FormControl
                                                variant="outlined"
                                                fullWidth
                                                error={this.state.errors.ward}
                                            >
                                                <InputLabel>
                                                    Choose ward
                                                </InputLabel>
                                                <Select
                                                    label="choose ward"
                                                    name="ward"
                                                    labelId="ward"
                                                    value={
                                                        this.state.manager.ward
                                                    }
                                                    onChange={this.handleChange}
                                                >
                                                    <MenuItem value="">
                                                        {" "}
                                                        <em>Chooose ward</em>
                                                    </MenuItem>
                                                    {communities}
                                                </Select>
                                            </FormControl>
                                        )}

                                        <Button
                                            className="submit-button"
                                            variant="contained"
                                            fullWidth
                                            onClick={() => this.updateManager()}
                                            style={{ padding: "1.2%" }}
                                        >
                                            {!this.state.buttonLoading ? (
                                                "Update Info"
                                            ) : (
                                                <CircularProgress
                                                    size={24}
                                                    style={{ color: "#ffffff" }}
                                                />
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    height: "85vh",
                                }}
                            >
                                <CircularProgress
                                    size={45}
                                    style={{
                                        marginTop: "15%",
                                        borderRadius: "100%",
                                        padding: ".2%",
                                        boxShadow:
                                            "0rem 0rem 1rem rgba(0, 0, 0, 0.15)",
                                        backgroundColor: "#ffffff",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(ProfilePage);
