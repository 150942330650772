import React, { Component } from "react";
import Drawer from "../../components/drawer";
import { withSnackbar } from "notistack";
import { postRequest } from "../../middleware/graphQl";
import cogs from "../../middleware/cogs";

import {
    Typography,
    Button,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Link,
    Stepper,
    Step,
    StepLabel,
    StepContent,
} from "@material-ui/core";

import Rating from "@material-ui/lab/Rating";

import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import ContactsOutlinedIcon from "@material-ui/icons/ContactsOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import AssistantOutlinedIcon from "@material-ui/icons/AssistantOutlined";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import VerifiedIcon from "@mui/icons-material/Verified";
import DirectionsIcon from "@material-ui/icons/Directions";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import PublicIcon from "@material-ui/icons/Public";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
// import CheckIcon from "@material-ui/icons/Check";

class institutionPage extends Component {
    state = {
        current: "services",
        favourite_loading: false,
        add_rating: false,
        update_rating: false,
        hover_rating: 0,
        rating: 0,
        service: { _id: "" },
        comment: "",
        rating_id: "",
        avgRating: 0,
        avgRatingComment: "NOT RATED",
        client: JSON.parse(localStorage.getItem("client")),
        institution: {},
        ratings: [],
        services: [],
        insurance: [],
        loading: true,
        error: false,
        error_message: "",
        errors: {
            comment: false,
            service: false,
        },
    };

    componentDidMount = () => {
        this.getInstitution(this.props.match.params.id);
        this.getServices(this.props.match.params.id);
    };

    getInstitution = async (id) => {
        const response = await postRequest("singleInstitution", "manager", {
            id: id,
        });

        if (response.errors) {
            if (response.errors[0].message.split(" ")[0] === "Cast") {
                window.location.replace(
                    `${process.env.REACT_APP_HOST}/manager/institutions`
                );
            } else {
                this.props.enqueueSnackbar(response.errors[0].message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            }
        } else {
            this.getAverageRating(response.data.singleInstitution.ratings);

            this.setState({
                institution: response.data.singleInstitution,
                loading: false,
                insurance: response.data.singleInstitution.insurance,
                ratings: response.data.singleInstitution.ratings,
                offer: "",
                price: "",
            });
        }
    };

    getServices = async (id) => {
        const response = await postRequest("getServices", "manager", {
            id: id,
        });

        if (response.errors) {
            this.setState({ loading: false });
            this.props.enqueueSnackbar(response.errors[0].message, {
                variant: "error",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
            });
        } else {
            this.setState({ services: response.data.services });
        }
    };

    handleChange = (event) => {
        if (event.target.name === "service") {
            this.setState({ service: { _id: event.target.value } });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    };

    getRatingComment = (rating) => {
        let rating_comment = "RATING";

        if (rating > 0 && rating <= 1) {
            rating_comment = "VERY POOR";
        } else if (rating > 1 && rating <= 2) {
            rating_comment = "POOR";
        } else if (rating > 2 && rating <= 3) {
            rating_comment = "AVERAGE";
        } else if (rating > 3 && rating <= 4) {
            rating_comment = "GOOD";
        } else if (rating > 4) {
            rating_comment = "EXCELLENT";
        }

        return rating_comment;
    };

    getAverageRating(ratings, avg = true) {
        let totalRatings = 0;
        let count = 0;
        let avgRating = 0;
        let avgRatingComment = "Not Rated";

        if (ratings.length > 0) {
            for (const i in ratings) {
                totalRatings += ratings[i].rating;
                count += 1;
            }

            avgRating = Math.round((totalRatings / count) * 10) / 10;
            avgRatingComment = this.getRatingComment(avgRating);
        }

        if (avg) {
            this.setState({
                avgRating: avgRating,
                avgRatingComment: avgRatingComment,
            });
        }

        return {
            avgRating: avgRating,
            avgRatingComment: avgRatingComment,
        };
    }

    render() {
        let services = this.state.services.map((service) => {
            return (
                <Grid
                    item
                    xs={3}
                    style={{ padding: "1% 1%" }}
                    key={service._id}
                >
                    <Link href={`../service/${service._id}`}>
                        <div align="center" className="category">
                            <Typography variant="h6">
                                {cogs.capitalize(service.care[0].name)}
                            </Typography>
                            <Typography variant="subtitle1">
                                {this.getAverageRating(service.ratings, false)
                                    .avgRating === 0
                                    ? "-"
                                    : this.getAverageRating(
                                          service.ratings,
                                          false
                                      ).avgRating}
                            </Typography>
                            <Rating
                                name="simple-controlled"
                                precision={0.1}
                                value={
                                    this.getAverageRating(
                                        service.ratings,
                                        false
                                    ).avgRating
                                }
                                readOnly
                                size="small"
                                style={{
                                    marginTop: ".3%",
                                    marginLeft: ".5%",
                                }}
                            />
                            <Typography variant="body1">
                                {
                                    this.getAverageRating(
                                        service.ratings,
                                        false
                                    ).avgRatingComment
                                }
                            </Typography>
                        </div>
                    </Link>
                </Grid>
            );
        });

        let ratings = this.state.ratings.map((rating) => {
            return (
                <Grid item xs={12} key={rating._id}>
                    <div
                        style={{
                            backgroundColor: "#ffffff",
                            padding: "2%",
                            margin: "1% 0%",
                            borderRadius: "10px",
                            boxShadow: "0 0.1rem 1rem rgba(0, 0, 0, 0.1)",
                        }}
                    >
                        <Typography variant="subtitle1" align="left">
                            {cogs.capitalize(rating.client[0].first_name)}{" "}
                            {cogs.capitalize(rating.client[0].last_name)}
                        </Typography>
                        <Typography variant="subtitle2" align="left">
                            {/* {cogs.capitalize(rating.service_name)} Services */}
                        </Typography>
                        <div style={{ display: "flex" }}>
                            <Typography variant="body1" align="left">
                                {rating.rating}{" "}
                            </Typography>
                            <Rating
                                name="simple-controlled"
                                precision={0.1}
                                value={rating.rating}
                                readOnly
                                size="small"
                                style={{
                                    marginTop: ".3%",
                                    marginLeft: ".5%",
                                }}
                            />
                        </div>
                        <Typography variant="body1" align="left">
                            {rating.comment}
                        </Typography>
                    </div>
                </Grid>
            );
        });

        let insurances = this.state.insurance.map((insurance) => {
            return (
                <Typography
                    variant="body1"
                    key={insurance}
                    style={{ margin: "1% 0%" }}
                >
                    <SupervisedUserCircleIcon
                        size={"small"}
                        style={{ color: "#13479A", marginBottom: "-.6%" }}
                    />{" "}
                    {insurance}
                </Typography>
            );
        });

        return (
            <Drawer
                content={
                    <div>
                        {!this.state.loading ? (
                            <Grid container style={{ color: "ffffff" }}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        background:
                                            "url('https://ik.imagekit.io/9vm1rbs0vzz/managers/institutions/" +
                                            this.state.institution
                                                .primary_photo +
                                            "?ik-sdk-version=javascript-1.4.3&updatedAt=1642468057296')",
                                        backgroundPosition: "center center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        height: "30vh",
                                        borderRadius: "5px",
                                        margin: "2%",
                                    }}
                                >
                                    {/* <Share
                                        link={`https://wapidoc.com/institution/${this.state.institution._id}`}
                                    /> */}
                                </Grid>
                                <Grid container style={{ margin: "-5% auto" }}>
                                    <Grid item xs={12}>
                                        <div className="card">
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <Typography
                                                        variant="h4"
                                                        align="left"
                                                    >
                                                        {cogs.capitalize(
                                                            this.state
                                                                .institution
                                                                .name
                                                        )}
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        style={{
                                                            color: "#666666",
                                                        }}
                                                        align="left"
                                                    >
                                                        {cogs.capitalize(
                                                            this.state
                                                                .institution
                                                                .ward
                                                        )}
                                                        {", "}
                                                        {cogs.capitalize(
                                                            this.state
                                                                .institution
                                                                .county
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2} align="right">
                                                    {/* <ShareIcon /> */}
                                                    <VerifiedIcon
                                                        style={{
                                                            marginLeft: "15%",
                                                            color: "green",
                                                        }}
                                                    />
                                                </Grid>
                                                <Divider
                                                    style={{
                                                        margin: "1% 0%",
                                                        width: "100%",
                                                    }}
                                                />
                                                <Grid item xs={10}>
                                                    <Typography
                                                        variant="body1"
                                                        paragraph
                                                        align="left"
                                                    >
                                                        {
                                                            this.state
                                                                .institution
                                                                .description
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                padding:
                                                                    "1% 0%",
                                                            }}
                                                        >
                                                            {this.state
                                                                .avgRating === 0
                                                                ? "-"
                                                                : this.state
                                                                      .avgRating}
                                                        </Typography>

                                                        <Typography variant="body1">
                                                            {
                                                                this.state
                                                                    .avgRatingComment
                                                            }
                                                        </Typography>
                                                        <Rating
                                                            name="simple-controlled"
                                                            precision={0.1}
                                                            value={
                                                                this.state
                                                                    .avgRating
                                                            }
                                                            readOnly
                                                            size="small"
                                                        />
                                                        <Typography variant="body1">
                                                            {
                                                                this.state
                                                                    .ratings
                                                                    .length
                                                            }{" "}
                                                            {this.state.ratings
                                                                .length === 1
                                                                ? "Review"
                                                                : "Reviews"}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Divider
                                                    style={{
                                                        margin: "1% 0%",
                                                        width: "100%",
                                                    }}
                                                />
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{ marginTop: "1%" }}
                                                >
                                                    <Button
                                                        aria-label="call-now"
                                                        href={`tel:${this.state.institution.primary_phone}`}
                                                        variant="text"
                                                        style={{
                                                            color: "#13479a",
                                                            marginRight: "3%",
                                                        }}
                                                        startIcon={<CallIcon />}
                                                        onClick={() => [
                                                            this.addAnalytics(
                                                                "click",
                                                                "call now"
                                                            ),
                                                            this.setState({
                                                                current:
                                                                    "contacts",
                                                            }),
                                                        ]}
                                                    >
                                                        Call Now
                                                    </Button>
                                                    <Button
                                                        aria-label="send email"
                                                        href={`mailto:${this.state.institution.primary_email}`}
                                                        target="_blank"
                                                        variant="text"
                                                        style={{
                                                            color: "#13479a",
                                                            marginRight: "3%",
                                                        }}
                                                        startIcon={<MailIcon />}
                                                    >
                                                        Send Email
                                                    </Button>
                                                    {this.state.institution
                                                        .website !== "" && (
                                                        <Button
                                                            aria-label="visit website"
                                                            href={
                                                                this.state
                                                                    .institution
                                                                    .website
                                                            }
                                                            target="_blank"
                                                            variant="text"
                                                            style={{
                                                                color: "#13479a",
                                                                marginRight:
                                                                    "3%",
                                                            }}
                                                            startIcon={
                                                                <PublicIcon />
                                                            }
                                                        >
                                                            Visit Website
                                                        </Button>
                                                    )}
                                                    <Button
                                                        aria-label="leave a review"
                                                        variant="text"
                                                        disabled
                                                        style={{
                                                            marginRight: "3%",
                                                        }}
                                                        startIcon={
                                                            <AssistantOutlinedIcon />
                                                        }
                                                    >
                                                        Leave a review
                                                    </Button>
                                                    <Button
                                                        aria-label="get-directions"
                                                        href={`https://maps.google.com/maps?q=-1.219744,+36.888998&hl=es&z=14&amp;output=embed`}
                                                        target="_blank"
                                                        variant="text"
                                                        style={{
                                                            color: "#13479a",
                                                            marginRight: "3%",
                                                        }}
                                                        startIcon={
                                                            <DirectionsIcon />
                                                        }
                                                    >
                                                        Get&nbsp;Directions
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div
                                            className="card"
                                            style={{ padding: "0px" }}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    paddingBottom: "0%",
                                                    color: "#13479A",
                                                    borderBottom:
                                                        "1px solid #13479A",
                                                }}
                                            >
                                                <List
                                                    style={{
                                                        display: "flex",
                                                        paddingTop: "0%",
                                                        paddingBottom: "0px",
                                                    }}
                                                >
                                                    <ListItem
                                                        className={
                                                            this.state
                                                                .current ===
                                                            "services"
                                                                ? "list-item"
                                                                : "nan"
                                                        }
                                                        button
                                                        key="services"
                                                        onClick={() =>
                                                            this.setState({
                                                                current:
                                                                    "services",
                                                            })
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <LiveHelpOutlinedIcon
                                                                style={{
                                                                    color: "#13479A",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Services"
                                                            style={{
                                                                color: "#13479A",
                                                            }}
                                                        />
                                                    </ListItem>
                                                    <ListItem
                                                        className={
                                                            this.state
                                                                .current ===
                                                            "insurance"
                                                                ? "list-item"
                                                                : "nan"
                                                        }
                                                        button
                                                        key="insurance"
                                                        onClick={() =>
                                                            this.setState({
                                                                current:
                                                                    "insurance",
                                                            })
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <SupervisedUserCircleOutlinedIcon
                                                                style={{
                                                                    color: "#13479A",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Insurance"
                                                            style={{
                                                                color: "#13479A",
                                                            }}
                                                        />
                                                    </ListItem>
                                                    <ListItem
                                                        className={
                                                            this.state
                                                                .current ===
                                                            "reviews"
                                                                ? "list-item"
                                                                : "nan"
                                                        }
                                                        button
                                                        key="reviews"
                                                        onClick={() =>
                                                            this.setState({
                                                                current:
                                                                    "reviews",
                                                            })
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <AssistantOutlinedIcon
                                                                style={{
                                                                    color: "#13479A",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Reviews"
                                                            style={{
                                                                color: "#13479A",
                                                            }}
                                                        />
                                                    </ListItem>

                                                    <ListItem
                                                        className={
                                                            this.state
                                                                .current ===
                                                            "contacts"
                                                                ? "list-item"
                                                                : "nan"
                                                        }
                                                        button
                                                        key="contacts"
                                                        onClick={() =>
                                                            this.setState({
                                                                current:
                                                                    "contacts",
                                                            })
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <ContactsOutlinedIcon
                                                                style={{
                                                                    color: "#13479A",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Contacts"
                                                            style={{
                                                                color: "#13479A",
                                                            }}
                                                        />
                                                    </ListItem>
                                                    <ListItem
                                                        className={
                                                            this.state
                                                                .current ===
                                                            "status"
                                                                ? "list-item"
                                                                : "nan"
                                                        }
                                                        button
                                                        key="status"
                                                        onClick={() =>
                                                            this.setState({
                                                                current:
                                                                    "status",
                                                            })
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <PhotoLibraryIcon
                                                                style={{
                                                                    color: "#13479A",
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary="Status"
                                                            style={{
                                                                color: "#13479A",
                                                            }}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{ padding: "2%" }}
                                            >
                                                {this.state.current ===
                                                    "services" && (
                                                    <div>
                                                        {this.state.services
                                                            .length > 0 ? (
                                                            <Grid container>
                                                                {services}
                                                            </Grid>
                                                        ) : (
                                                            <div>
                                                                <br />
                                                                <Typography
                                                                    variant="h6"
                                                                    align="center"
                                                                >
                                                                    No services
                                                                    yet
                                                                </Typography>
                                                                <img
                                                                    style={{
                                                                        width: "25%",
                                                                        height: "auto",
                                                                        display:
                                                                            "flex",
                                                                        margin: "0 auto",
                                                                    }}
                                                                    src={
                                                                        "../../images/blankSubService.png"
                                                                    }
                                                                    alt="No Data"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}

                                                {this.state.current ===
                                                    "insurance" && (
                                                    <div
                                                        style={{
                                                            padding: ".5%",
                                                        }}
                                                    >
                                                        {insurances}
                                                    </div>
                                                )}
                                                {this.state.current ===
                                                    "reviews" && (
                                                    <div
                                                        style={{
                                                            padding: ".5%",
                                                        }}
                                                    >
                                                        {this.state.ratings
                                                            .length > 0 ? (
                                                            <Grid container>
                                                                {ratings}
                                                            </Grid>
                                                        ) : (
                                                            <div>
                                                                <br />
                                                                <Typography
                                                                    variant="h6"
                                                                    align="center"
                                                                >
                                                                    No reviews
                                                                    yet
                                                                </Typography>
                                                                <img
                                                                    style={{
                                                                        width: "35%",
                                                                        height: "auto",
                                                                        display:
                                                                            "flex",
                                                                        margin: "0 auto",
                                                                    }}
                                                                    src={
                                                                        "../../images/blankSubService.png"
                                                                    }
                                                                    alt="No Data"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {this.state.current ===
                                                    "contacts" && (
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            align="left"
                                                        >
                                                            <CallIcon
                                                                size={"small"}
                                                                style={{
                                                                    color: "#13479A",
                                                                    marginBottom:
                                                                        "-.5%",
                                                                    marginRight:
                                                                        "1%",
                                                                }}
                                                            />
                                                            {"   "}
                                                            {
                                                                this.state
                                                                    .institution
                                                                    .primary_phone
                                                            }
                                                            {this.state
                                                                .institution
                                                                .secondary_phone !==
                                                                "" &&
                                                                " / " +
                                                                    this.state
                                                                        .institution
                                                                        .secondary_phone}
                                                        </Typography>
                                                        <br />
                                                        <Typography
                                                            variant="body1"
                                                            align="left"
                                                        >
                                                            <MailIcon
                                                                size={"small"}
                                                                style={{
                                                                    color: "#13479A",
                                                                    marginBottom:
                                                                        "-.8%",
                                                                    marginRight:
                                                                        "1%",
                                                                }}
                                                            />
                                                            {"   "}
                                                            {
                                                                this.state
                                                                    .institution
                                                                    .primary_email
                                                            }
                                                            {this.state
                                                                .institution
                                                                .secondary_email !==
                                                                "" &&
                                                                " / " +
                                                                    this.state
                                                                        .institution
                                                                        .secondary_email}
                                                        </Typography>
                                                        <br />
                                                        <Typography
                                                            variant="body1"
                                                            align="left"
                                                        >
                                                            <ContactMailIcon
                                                                size={"small"}
                                                                style={{
                                                                    color: "#13479A",
                                                                    marginBottom:
                                                                        "-.8%",
                                                                    marginRight:
                                                                        "1%",
                                                                }}
                                                            />
                                                            P.O BOX,{"   "}
                                                            {
                                                                this.state
                                                                    .institution
                                                                    .address
                                                            }
                                                            , Nairobi, Kenya
                                                        </Typography>
                                                        <br />
                                                        {this.state.institution
                                                            .website !== "" && (
                                                            <Typography
                                                                variant="body1"
                                                                align="left"
                                                            >
                                                                <PublicIcon
                                                                    size={
                                                                        "small"
                                                                    }
                                                                    style={{
                                                                        color: "#13479A",
                                                                        marginBottom:
                                                                            "-.8%",
                                                                        marginRight:
                                                                            "1%",
                                                                    }}
                                                                />
                                                                {"   "}
                                                                {
                                                                    this.state
                                                                        .institution
                                                                        .website
                                                                }
                                                            </Typography>
                                                        )}
                                                        <br />
                                                        {this.state.institution
                                                            .linkedin !==
                                                            "" && (
                                                            <Link
                                                                href={
                                                                    this.state
                                                                        .institution
                                                                        .linkedin
                                                                }
                                                                target="_blank"
                                                                underline="hover"
                                                            >
                                                                <LinkedInIcon
                                                                    style={{
                                                                        color: "#13479A",
                                                                        marginRight:
                                                                            "3%",
                                                                    }}
                                                                />
                                                            </Link>
                                                        )}
                                                        {this.state.institution
                                                            .facebook !==
                                                            "" && (
                                                            <Link
                                                                href={
                                                                    this.state
                                                                        .institution
                                                                        .facebook
                                                                }
                                                                target="_blank"
                                                                underline="hover"
                                                            >
                                                                <FacebookIcon
                                                                    style={{
                                                                        color: "#13479A",
                                                                        marginRight:
                                                                            "3%",
                                                                    }}
                                                                />
                                                            </Link>
                                                        )}

                                                        {this.state.institution
                                                            .twitter !== "" && (
                                                            <Link
                                                                href={
                                                                    this.state
                                                                        .institution
                                                                        .twitter
                                                                }
                                                                target="_blank"
                                                                underline="hover"
                                                            >
                                                                <TwitterIcon
                                                                    style={{
                                                                        color: "#13479A",
                                                                        marginRight:
                                                                            "3%",
                                                                    }}
                                                                />
                                                            </Link>
                                                        )}
                                                        {this.state.institution
                                                            .instagram !==
                                                            "" && (
                                                            <Link
                                                                href={
                                                                    this.state
                                                                        .institution
                                                                        .instagram
                                                                }
                                                                target="_blank"
                                                                underline="hover"
                                                            >
                                                                <InstagramIcon
                                                                    style={{
                                                                        color: "#13479A",
                                                                        marginRight:
                                                                            "3%",
                                                                    }}
                                                                />
                                                            </Link>
                                                        )}
                                                    </div>
                                                )}
                                                {this.state.current ===
                                                    "status" && (
                                                    <Stepper
                                                        orientation="vertical"
                                                        color="secondary"
                                                    >
                                                        <Step
                                                            key={
                                                                "Institution Setup"
                                                            }
                                                        >
                                                            <StepLabel
                                                                StepIconComponent={
                                                                    HourglassEmptyOutlinedIcon
                                                                }
                                                            >
                                                                {
                                                                    "Institution Setup"
                                                                }
                                                            </StepLabel>
                                                            <StepContent>
                                                                <Typography>
                                                                    <b>
                                                                        {cogs.capitalize(
                                                                            this
                                                                                .state
                                                                                .institution
                                                                                .name
                                                                        )}
                                                                    </b>{" "}
                                                                    <b>
                                                                        (
                                                                        {cogs.capitalize(
                                                                            this
                                                                                .state
                                                                                .institution
                                                                                .ward
                                                                        )}
                                                                        {", "}
                                                                        {cogs.capitalize(
                                                                            this
                                                                                .state
                                                                                .institution
                                                                                .constituency
                                                                        )}
                                                                        )
                                                                    </b>{" "}
                                                                    was created
                                                                    successfully.
                                                                </Typography>
                                                            </StepContent>
                                                        </Step>
                                                        <Step
                                                            key={
                                                                "Institution Application Fee"
                                                            }
                                                        >
                                                            <StepLabel
                                                                StepIconComponent={
                                                                    HourglassEmptyOutlinedIcon
                                                                }
                                                            >
                                                                {
                                                                    "Institution Application Fee"
                                                                }
                                                            </StepLabel>
                                                            <StepContent>
                                                                <Typography>
                                                                    Creation of
                                                                    institution{" "}
                                                                    <b>
                                                                        {cogs.capitalize(
                                                                            this
                                                                                .state
                                                                                .institution
                                                                                .name
                                                                        )}
                                                                    </b>{" "}
                                                                    successfully.
                                                                </Typography>
                                                            </StepContent>
                                                        </Step>
                                                        <Step
                                                            key={
                                                                "Institution Visit"
                                                            }
                                                        >
                                                            <StepLabel
                                                                StepIconComponent={
                                                                    HourglassEmptyOutlinedIcon
                                                                }
                                                            >
                                                                {
                                                                    "Institution Visit"
                                                                }
                                                            </StepLabel>
                                                            <StepContent>
                                                                <Typography>
                                                                    Creation of
                                                                    institution{" "}
                                                                    <b>
                                                                        {cogs.capitalize(
                                                                            this
                                                                                .state
                                                                                .institution
                                                                                .name
                                                                        )}
                                                                    </b>{" "}
                                                                    successfully.
                                                                </Typography>
                                                            </StepContent>
                                                        </Step>
                                                        <Step
                                                            key={
                                                                "Institution Application Status"
                                                            }
                                                        >
                                                            <StepLabel
                                                                StepIconComponent={
                                                                    HourglassEmptyOutlinedIcon
                                                                }
                                                            >
                                                                {
                                                                    "Institution Application Status"
                                                                }
                                                            </StepLabel>
                                                            <StepContent>
                                                                <Typography>
                                                                    Creation of
                                                                    institution{" "}
                                                                    <b>
                                                                        {cogs.capitalize(
                                                                            this
                                                                                .state
                                                                                .institution
                                                                                .name
                                                                        )}
                                                                    </b>{" "}
                                                                    successfully.
                                                                </Typography>
                                                            </StepContent>
                                                        </Step>
                                                        <Step
                                                            key={
                                                                "Registration/Retention Fee"
                                                            }
                                                        >
                                                            <StepLabel
                                                                StepIconComponent={
                                                                    HourglassEmptyOutlinedIcon
                                                                }
                                                            >
                                                                {
                                                                    "Registration/Retention Fee"
                                                                }
                                                            </StepLabel>
                                                            <StepContent>
                                                                <Typography>
                                                                    Creation of
                                                                    institution{" "}
                                                                    <b>
                                                                        {cogs.capitalize(
                                                                            this
                                                                                .state
                                                                                .institution
                                                                                .name
                                                                        )}
                                                                    </b>{" "}
                                                                    successfully.
                                                                </Typography>
                                                            </StepContent>
                                                        </Step>
                                                    </Stepper>
                                                )}
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    height: "85vh",
                                }}
                            >
                                <CircularProgress
                                    size={45}
                                    style={{
                                        marginTop: "15%",
                                        borderRadius: "100%",
                                        padding: ".1%",
                                        boxShadow:
                                            "0rem 0rem 1rem rgba(0, 0, 0, 0.15)",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(institutionPage);
