/**
 * Function that returns the right gql query based on the input
 * @param {*} string
 * @returns GQL Query
 */
export const gqlQueryManager = (key, gql) => {
    let query = [];

    switch (key) {
        case "createManager":
            query = `
                mutation CreateManager($data: ManagerInput) {
                    createManager(data: $data) {
                        response
                    }
                }
            `;
            break;
        case "updateManager":
            query = `
                mutation UpdateManager($id: String!, $data: UpdateManager) {
                    updateManager(_id: $id, data: $data) {
                        _id
                        industry
                    }
                }
            `;
            break;
        case "updateInstitution":
            query = `
                mutation UpdateInstitution($id: String!, $data: UpdateInstitution) {
                    updateInstitution(_id: $id, data: $data) {
                        _id
                        status
                    }
                }
            `;
            break;
        case "singleInstitution":
            query = `
                query SingleInstitution($id: String) {
                    singleInstitution(_id: $id) {
                        name
                        type
                        description
                        contact {
                            address
                            email {
                                primary
                                secondary
                            }
                            phoneNumber {
                                primary
                                secondary
                            }
                        }
                        location {
                            constituency
                            county
                            ward
                            streetAddress {
                                unit
                                street
                                floor
                                building
                            }
                            gps{
                                type
                                coordinates
                            }
                        }
                        insurance
                        links {
                            facebook
                            instagram
                            linkedin
                            twitter
                            website
                            youtube
                        }
                        hours {
                            visitingHours {
                                afternoon
                                evening
                                morning
                            }
                            workingHours {
                                holiday
                                weekday
                                sunday
                                saturday
                            }
                        }
                        gallery {
                            primaryPhoto
                            photos
                        }
                        status
                    }
                }
            `;
            break;
        default:
            query = "facilities";
            break;
    }

    return query;
};
