import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Grid, Link, Typography, Button, Box, Divider } from "@mui/material";
import { PhoneNumber } from "../components/forms/signin/phoneNumber";
import { Email } from "../components/forms/signin/email";

export default function SignIn() {
    const [method, setMethod] = useState(true);
    const [option, setOption] = useState(true);

    return (
        <div>
            <Helmet>
                <title>SignIn</title>
                <meta
                    name="description"
                    content="Welcome back. Sign in and continue enjoying our services."
                />
            </Helmet>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                        background:
                            "url('https://ik.imagekit.io/9vm1rbs0vzz/public/WapiDoc_SignUp_LntVgRwab.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642468057296')",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        height: "100vh",
                    }}
                >
                    <Link to="/">
                        <img
                            style={{
                                width: "140px",
                                height: "auto",
                                padding: "1% 2%",
                            }}
                            src={"../WapiDocLogo.png"}
                            alt="WapiDocLogo"
                        />
                    </Link>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                        padding: "3%",
                        backgroundColor: "#ffffff",
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Welcome Back
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        paragraph
                        style={{ color: "#939393" }}
                    >
                        Don't have an account?{" "}
                        <Link href="/signup">Create an account</Link>
                    </Typography>
                    <br />
                    <Box my={1}>
                        {method ? (
                            <PhoneNumber setOption={setOption} />
                        ) : (
                            <Email />
                        )}
                        {option && (
                            <div>
                                <Divider style={{ margin: "3% 0%" }}>
                                    OR
                                </Divider>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    style={{
                                        border: "1px solid #000000",
                                        color: "#000000",
                                        margin: "2% 0%",
                                        padding: "1.5% 0%",
                                        borderRadius: "10px",
                                    }}
                                    onClick={() => setMethod(!method)}
                                >
                                    Sign up with{" "}
                                    {!method ? "Phone Number" : "Email "}
                                </Button>
                            </div>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}
