import React, { useState } from "react";
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    Button,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Collapse,
    Alert,
} from "@mui/material";
import { Location } from "../../user/locations";
import { capitalize } from "../../../../helpers/string";
import { InstitutionMap } from "./maps";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const street = ["street", "building", "floor", "unit"];

export const InstitutionLocation = ({ setReady, data, updateData }) => {
    const [location, updateLocation] = useState(data.location);
    const [showStreetAddress, setStreetAddress] = useState(false);
    const [showMap, setMap] = useState(false);

    let disabled =
        location.county === "" ||
        location.constituency === "" ||
        location.ward === "" ||
        location.streetAddress.street === "" ||
        location.streetAddress.building === "" ||
        location.streetAddress.floor === "" ||
        location.streetAddress.unit === "";
    
    navigator.geolocation.getCurrentPosition((position) => {
         updateData({
             ...data,
             location: {
                 ...data.location,
                 gps: {
                     ...data.location.gps,
                     coordinates: [
                         position.coords.latitude,
                         position.coords.longitude,
                     ],
                 },
             },
         });
     });
    
    return (
        <Grid container>
            <Grid item xs={12} md={8} style={{ margin: "0px auto" }}>
                <Typography
                    variant="body2"
                    textAlign={"center"}
                    style={{
                        color: "#888",
                        fontSize: "24px",
                        padding: "3% 0%",
                    }}
                >
                    Location, street address, maps
                </Typography>
                <Location data={location} updateData={updateLocation} />

                <ListItemButton
                    onClick={() => setStreetAddress(!showStreetAddress)}
                    style={{
                        padding: "1% 2%",
                        margin: "2% 0%",
                        width: "100%",
                        borderRadius: "10px",
                    }}
                >
                    <ListItemText>
                        <Typography variant="subtitle1" align="left">
                            Street Address
                        </Typography>
                    </ListItemText>
                    <ListItemIcon>
                        {showStreetAddress ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                </ListItemButton>
                <Collapse in={showStreetAddress} timeout="auto" unmountOnExit>
                    {street.map((item) => {
                        return (
                            <TextField
                                key={item}
                                id={item}
                                name={item}
                                label={capitalize(item)}
                                margin="normal"
                                variant="outlined"
                                placeholder={`Enter ${capitalize(item)}`}
                                value={capitalize(location.streetAddress[item])}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <LocationOnIcon
                                                fontSize="small"
                                                style={{
                                                    color: "#939393",
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(event) => {
                                    updateLocation({
                                        ...location,
                                        streetAddress: {
                                            ...location.streetAddress,
                                            [item]: event.target.value,
                                        },
                                    });
                                }}
                            />
                        );
                    })}
                </Collapse>

                <ListItemButton
                    onClick={() => setMap(!showMap)}
                    style={{
                        padding: "1% 2%",
                        margin: "2% 0%",
                        width: "100%",
                        borderRadius: "10px",
                    }}
                >
                    <ListItemText>
                        <Typography variant="subtitle1" align="left">
                            Map - GPS Coordintes
                        </Typography>
                    </ListItemText>
                    <ListItemIcon>
                        {showMap ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                </ListItemButton>
                <Collapse in={showMap} timeout="auto" unmountOnExit>
                    <Alert severity="info">
                        Drag and drop the red marker to your institution's
                        location.
                    </Alert>
                    <InstitutionMap
                        setReady={setReady}
                        data={data}
                        updateData={updateData}
                    />
                </Collapse>

                <Grid container spacing={3} my>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            style={{
                                backgroundColor: "#444444",
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    status: "category",
                                });
                            }}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="submitData"
                            variant="contained"
                            disableElevation
                            fullWidth
                            disabled={disabled}
                            style={{
                                padding: "3%",
                                borderRadius: "10px",
                                color: "#ffffff",
                                marginLeft: "0%",
                                backgroundColor: `${
                                    !disabled ? "#ce043d" : "#E0E0E0"
                                }`,
                            }}
                            onClick={() => {
                                updateData({
                                    ...data,
                                    location: location,
                                    status: "contacts",
                                });
                                setReady(true);
                            }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
