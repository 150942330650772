import React, { Component } from "react";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { postRequest } from "../../middleware/graphQl";
import cogs from "../../middleware/cogs";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Pdf from "react-to-pdf";

import {
    MenuItem,
    Select,
    Divider,
    FormControl,
    Grid,
    Typography,
    TextField,
    Button,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Chip,
    List,
    ListItem,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import PaymentIcon from "@material-ui/icons/Payment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PrintIcon from "@mui/icons-material/Print";

class checkoutPage extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    state = {
        method: "mpesa-express",
        payments: true,
        invoice: "",
        manager: JSON.parse(localStorage.getItem("manager")),
        institution: "",
        amount: "",
        tax: "",
        loading: true,
        stk_push_button: "not-loading",
        stk_push_response: null,
    };

    componentDidMount = () => {
        cogs.managerAuthorization(jwt_decode, postRequest);
        this.getInvoice(this.props.match.params.id);
    };

    getInvoice = async (id) => {
        const response = await postRequest("singleInvoice", "manager", {
            id: id,
        });

        if (response.errors) {
            this.setState({ loading: false });
            if (response.errors[0].message.split(" ")[0] === "Cast") {
                window.location.replace(
                    `${process.env.REACT_APP_HOST}/manager/invoices`
                );
            } else {
                this.props.enqueueSnackbar(response.errors[0].message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            }
        } else {
            this.setState({
                loading: false,
                invoice: response.data.singleInvoice,
                institution: response.data.singleInvoice.institution[0],
            });
        }
    };

    handleChange = (event) => {
        if (event.target.name === "phone") {
            let manager = this.state.manager;
            manager["phone"] = event.target.value;
            this.setState({ manager: manager });
        } else {
            this.setState({ method: event.target.value });
        }
    };

    makePayment = async () => {
        try {
            this.setState({
                stk_push_button: "loading",
                stk_push_result: null,
            });
            const response = await axios.post(
                `${process.env.REACT_APP_HOST_API}/query/mpesa/express`,
                {
                    amount: "1",
                    phone: this.state.manager.phone,
                    invoice: this.state.invoice.number,
                }
            );

            if (response.errors) {
                this.props.enqueueSnackbar(response.errors[0].message, {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            } else {
                if (response.data.ResponseCode === "0") {
                    this.setState({
                        stk_push_button: "loading",
                        stk_push_response: response.data,
                    });
                    this.expressCheck(response.data.CheckoutRequestID);
                } else {
                    this.setState({
                        stk_push_button: "try-again",
                        stk_push_response: response.data,
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    expressCheck = async (CheckoutRequestID) => {
        const response = await axios.post(
            `${process.env.REACT_APP_HOST_API}/query/mpesa/express/query`,
            {
                CheckoutRequestID: CheckoutRequestID,
            }
        );

        if (response.data.body.ResultCode === "0") {
            this.setState({
                stk_push_button: "success",
                stk_push_response: response.data.body,
            });
            window.location.replace(
                `${process.env.REACT_APP_HOST}/manager/dashboard`
            );
        } else if (
            response.data.body.ResultCode === "1037" ||
            response.data.body.ResultCode === "1032"
        ) {
            this.setState({
                stk_push_button: "try-again",
                stk_push_response: response.data.body,
            });
        } else {
            this.expressCheck(CheckoutRequestID);
        }
    };

    printDocument = () => {
        // document.getElementById("paymentOptions").style.display = "none";

        const input = document.getElementById("divToPrint");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/jpeg");
            const pdf = new jsPDF();
            pdf.addImage(imgData, "JPEG", 0, 0);
            // pdf.output('dataurlnewwindow');
            pdf.save("download.pdf");
        });
    };

    render() {
        const pdfOptions = {
            format: [365, 400],
        };

        return (
            <div
                style={{ backgroundColor: "#efefef", padding: "5%" }}
                ref={this.myRef}
            >
                <div>
                    {!this.state.loading && (
                        <Grid
                            container
                            id="divToPrint"
                            style={{
                                backgroundColor: "#ffffff",
                                margin: "0 auto",
                                padding: "5%",
                                width: "70%",
                            }}
                        >
                            <Grid item xs={6}>
                                <img
                                    src={"../../SuanillLogo.png"}
                                    alt="suanill"
                                    style={{ width: "250px" }}
                                />
                                <br />
                                <br />
                                {/* <List style={{ display: "flex" }}>
                                    <ListItem
                                        style={{
                                            width: "auto",
                                        }}
                                    ></ListItem>

                                    <ListItem
                                        style={{ width: "auto" }}
                                    ></ListItem>
                                </List> */}
                                {/* <Typography variant="h5">
                                    INVOICE NUMBER <br />
                                    {this.state.invoice.number}
                                </Typography> */}
                            </Grid>
                            {this.state.payments && (
                                <Grid item xs={6} id="paymentOptions">
                                    <Typography variant="h5">
                                        {cogs.capitalize(
                                            this.state.invoice.description
                                        )}
                                        {this.state.invoice.status ? (
                                            <Chip
                                                icon={
                                                    <PaymentIcon
                                                        fontSize={"small"}
                                                    />
                                                }
                                                label="Paid"
                                                color="secondary"
                                                style={{
                                                    backgroundColor: "green",
                                                    padding: "0% 1%",
                                                }}
                                            />
                                        ) : (
                                            <Chip
                                                icon={
                                                    <PaymentIcon
                                                        fontSize={"small"}
                                                    />
                                                }
                                                label="Unpaid"
                                                color="secondary"
                                                style={{
                                                    backgroundColor: "#F73F37",
                                                    padding: "0% 1%",
                                                }}
                                            />
                                        )}
                                    </Typography>

                                    <div>
                                        <br />
                                        <Typography
                                            variant="body2"
                                            align="left"
                                        >
                                            Choose Payment Method
                                        </Typography>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <Select
                                                name="payment-method"
                                                labelId="payment-method"
                                                value={this.state.method}
                                                onChange={this.handleChange}
                                            >
                                                {/* <MenuItem value="mpesa-paybill">
                                            Mpesa Paybill
                                        </MenuItem> */}
                                                <MenuItem value="mpesa-express">
                                                    Mpesa Express
                                                </MenuItem>
                                                <MenuItem value="bank-deposit">
                                                    Direct Bank Deposit
                                                </MenuItem>
                                                <MenuItem value="bank-transfer">
                                                    Direct Bank Transfer (RTGS /
                                                    Pesa Link)
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        {this.state.method ===
                                            "mpesa-paybill" && (
                                            <div>
                                                <br />
                                                <Typography variant="body2">
                                                    1. Go to M-Pesa menu
                                                    <br />
                                                    2. Click on Lipa na M-Pesa
                                                    <br />
                                                    3. Click on PayBill
                                                    <br />
                                                    4. Enter Business No
                                                    <b> 4062057</b>
                                                    <br />
                                                    5. Enter Account No
                                                    <b>
                                                        {" "}
                                                        {
                                                            this.state.invoice
                                                                .number
                                                        }
                                                    </b>
                                                    <br />
                                                    6. Enter amount
                                                    <b>
                                                        {" "}
                                                        KSH{" "}
                                                        {this.state.invoice.taxed_amount
                                                            .toString()
                                                            .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                            )}
                                                    </b>
                                                    <br />
                                                    7. Enter your M-Pesa pin
                                                    <br />
                                                    8. Wait for the M-Pesa
                                                    message
                                                    <br />
                                                    9. Enter the M-Pesa code
                                                    below
                                                    <br />
                                                    10. Click Validate Payment.
                                                </Typography>
                                                <TextField
                                                    name="mpesa-code"
                                                    label="Mpesa Code"
                                                    variant="outlined"
                                                    placeholder="PHFXXXXXXX"
                                                    fullWidth
                                                />
                                                <Button
                                                    className="submit-button"
                                                    variant="contained"
                                                    color="secondary"
                                                    fullWidth
                                                >
                                                    Validate Payment
                                                </Button>
                                            </div>
                                        )}
                                        {this.state.method ===
                                            "mpesa-express" && (
                                            <div>
                                                <Typography
                                                    variant="body2"
                                                    align="left"
                                                >
                                                    Preferred Phone Number
                                                </Typography>
                                                <TextField
                                                    name="phone"
                                                    variant="outlined"
                                                    defaultValue={
                                                        this.state.manager.phone
                                                    }
                                                    placeholder={
                                                        this.state.manager.phone
                                                    }
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                />

                                                <Typography
                                                    variant="body2"
                                                    style={{ margin: "2% 0%" }}
                                                >
                                                    A payment request will been
                                                    sent directly to your mobile
                                                    device.
                                                </Typography>
                                                <Typography variant="body2">
                                                    Phone Number:{" "}
                                                    <span
                                                        style={{
                                                            fontWeight: "800",
                                                        }}
                                                    >
                                                        {cogs.filterPhone(
                                                            this.state.manager
                                                                .phone
                                                        )}
                                                    </span>
                                                </Typography>
                                                <Typography variant="body2">
                                                    Amount:{" "}
                                                    <span
                                                        style={{
                                                            fontWeight: "800",
                                                        }}
                                                    >
                                                        KES{" "}
                                                        {this.state.invoice.taxed_amount
                                                            .toString()
                                                            .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                            )}
                                                    </span>
                                                </Typography>
                                                <Typography variant="body2">
                                                    Account Number:
                                                    <span
                                                        style={{
                                                            fontWeight: "800",
                                                        }}
                                                    >
                                                        {" "}
                                                        {
                                                            this.state.invoice
                                                                .number
                                                        }
                                                    </span>
                                                </Typography>
                                                <Typography variant="body2">
                                                    Kindly enter your M-Pesa pin
                                                    to complete your
                                                    transaction.
                                                </Typography>

                                                {this.state
                                                    .stk_push_response && (
                                                    <div>
                                                        {this.state
                                                            .stk_push_response
                                                            .ResultCode !==
                                                        undefined ? (
                                                            <div>
                                                                {this.state
                                                                    .stk_push_response
                                                                    .ResultCode ==
                                                                0 ? (
                                                                    <div>
                                                                        <Alert severity="success">
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .stk_push_response
                                                                                    .ResultDesc
                                                                            }
                                                                        </Alert>
                                                                        <br />
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <Alert severity="warning">
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .stk_push_response
                                                                                    .ResultDesc
                                                                            }
                                                                        </Alert>
                                                                        <br />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                {this.state
                                                                    .stk_push_response
                                                                    .ResponseCode ==
                                                                "0" ? (
                                                                    <div>
                                                                        <Alert severity="success">
                                                                            Payment
                                                                            request
                                                                            sent
                                                                            to
                                                                            your
                                                                            phone
                                                                        </Alert>
                                                                        <br />
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <Alert severity="warning">
                                                                            {
                                                                                this
                                                                                    .state
                                                                                    .stk_push_response
                                                                                    .errorMessage
                                                                            }
                                                                        </Alert>
                                                                        <br />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}

                                                {this.state.stk_push_button ===
                                                    "not-loading" && (
                                                    <Button
                                                        className="submit-button"
                                                        variant="contained"
                                                        color="secondary"
                                                        fullWidth
                                                        onClick={() =>
                                                            this.makePayment()
                                                        }
                                                    >
                                                        SEND PAYMENT REQUEST
                                                    </Button>
                                                )}

                                                {this.state.stk_push_button ===
                                                    "loading" && (
                                                    <Button
                                                        variant="contained"
                                                        disabled
                                                        fullWidth
                                                        style={{
                                                            color: "#ffffff",
                                                        }}
                                                    >
                                                        PROCESSING&nbsp;
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                color: "#ffffff",
                                                            }}
                                                        />
                                                    </Button>
                                                )}

                                                {this.state.stk_push_button ===
                                                    "try-again" && (
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        fullWidth
                                                        onClick={() =>
                                                            this.makePayment()
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                "#D74545",
                                                        }}
                                                    >
                                                        TRY AGAIN
                                                    </Button>
                                                )}

                                                {this.state.stk_push_button ===
                                                    "success" && (
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        fullWidth
                                                        style={{
                                                            backgroundColor:
                                                                "#4D9A51",
                                                        }}
                                                    >
                                                        SUCCESS&nbsp;
                                                        <CircularProgress
                                                            size={24}
                                                            style={{
                                                                color: "#ffffff",
                                                            }}
                                                        />
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                        {this.state.method ===
                                            "bank-deposit" && (
                                            <div>
                                                <br />
                                                <Typography variant="body2">
                                                    Bank Name:{" "}
                                                    <b>CO-OPERATIVE BANK</b>
                                                    <br />
                                                    Account Name:{" "}
                                                    <b>SUANILL LTD</b>
                                                    <br />
                                                    Account Number:{" "}
                                                    <b>01192846345600</b>
                                                    <br />
                                                    Branch:{" "}
                                                    <b>KIMATHI STREET</b>
                                                    <br />
                                                </Typography>
                                                <Divider
                                                    style={{
                                                        width: "100%",
                                                        margin: "3% 0%",
                                                    }}
                                                />
                                                <Typography variant="body2">
                                                    <b>
                                                        For this mode of payment
                                                        Cash or Banker's Cheque
                                                        is accepted. Personal
                                                        cheques are not
                                                        accepted.
                                                    </b>
                                                    <br />
                                                    <Divider
                                                        style={{
                                                            margin: "3% 0%",
                                                        }}
                                                    />
                                                    <b>
                                                        Scan your bank deposit
                                                        slip and email to:
                                                        payments@wapidoc.com
                                                        with the subject <br />
                                                        "REF:{" "}
                                                        {
                                                            this.state.invoice
                                                                .number
                                                        }
                                                        "
                                                    </b>
                                                    <br />
                                                </Typography>
                                            </div>
                                        )}
                                        {this.state.method ===
                                            "bank-transfer" && (
                                            <div>
                                                <br />
                                                <Typography variant="body2">
                                                    Bank Name:{" "}
                                                    <b>CO-OPERATIVE BANK</b>
                                                    <br />
                                                    Account Name:{" "}
                                                    <b>SUANILL LTD</b>
                                                    <br />
                                                    Account Number:{" "}
                                                    <b>01192846345600</b>
                                                    <br />
                                                    Branch:{" "}
                                                    <b>KIMATHI STREET</b>
                                                    <br />
                                                    Swift Code: <b>KCOOKENA</b>
                                                    <br />
                                                    <Divider
                                                        style={{
                                                            margin: "2% 0%",
                                                        }}
                                                    />
                                                    <b>
                                                        Kindly share your
                                                        transfer notification
                                                        via email to:
                                                        payments@wapidoc.com
                                                        with the subject <br />
                                                        "REF:{" "}
                                                        {
                                                            this.state.invoice
                                                                .number
                                                        }
                                                        "
                                                    </b>
                                                    <br />
                                                </Typography>
                                                <br />
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                            )}
                            <Grid container style={{ margin: "2% 0%" }}>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        align="left"
                                        gutterBottom
                                    >
                                        INVOICED TO
                                    </Typography>
                                    <Typography variant="body2">
                                        {cogs.capitalize(
                                            this.state.manager.first_name +
                                                " " +
                                                this.state.manager.last_name
                                        )}
                                        <br />
                                        {cogs.capitalize(
                                            this.state.institution.name
                                        )}
                                        <br />+
                                        {this.state.institution.primary_phone}
                                        <br />
                                        {this.state.institution.primary_email}
                                        <br />
                                        P.O Box {this.state.institution.address}
                                        <br />
                                        {cogs.capitalize(
                                            this.state.institution.county
                                        )}
                                        {", "}
                                        Kenya
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} align={"right"}>
                                    <Typography variant="body2">
                                        <b>PAY TO</b>
                                        <br />
                                        Suanill LTD
                                        <br />
                                        P. O Box 28196 G.P.O <br />
                                        Nairobi, Kenya
                                        <br />
                                        +254113885426
                                        <br />
                                        info@suanill.com
                                        <br />
                                        Abc Place, Waiyaki Way
                                        <br />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        INVOICE
                                    </Typography>
                                    <Typography variant="body2">
                                        <b>Invoice No:</b>
                                        {"  "}
                                        {this.state.invoice.number}
                                        <br />
                                        <b>Date:</b> {this.state.invoice.date}
                                        <br />
                                        <b>Expires:</b>{" "}
                                        {this.state.invoice.expires}
                                        <br />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        align="right"
                                        gutterBottom
                                    >
                                        Payment Method
                                    </Typography>
                                    <Typography variant="body2" align="right">
                                        {cogs.capitalize(
                                            this.state.method.replace("-", " ")
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider
                                style={{
                                    width: "100%",
                                    color: "#ffffff",
                                    margin: "2% 0%",
                                }}
                            />
                            <TableContainer
                                style={{
                                    border: "1px solid #dfdfdf",
                                    borderRadius: "5px",
                                    marginTop: "2%",
                                }}
                            >
                                <Table
                                    size="small"
                                    aria-label="customized table"
                                >
                                    <TableHead>
                                        <TableRow
                                            style={{
                                                backgroundColor: "#dfdfdf",
                                            }}
                                        >
                                            <TableCell align="left">
                                                <Typography
                                                    variant="body2"
                                                    align="left"
                                                >
                                                    <b>INVOICED ITEMS</b>
                                                    <br />
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">
                                                <Typography
                                                    variant="body2"
                                                    align="left"
                                                >
                                                    <b>Description</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography
                                                    variant="body2"
                                                    align="left"
                                                >
                                                    <b>Category</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography
                                                    variant="body2"
                                                    align="left"
                                                >
                                                    <b>Duration</b>
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="right">
                                                <Typography
                                                    variant="body2"
                                                    align="right"
                                                >
                                                    <b>Amount</b>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="left">
                                                {cogs.capitalize(
                                                    this.state.invoice
                                                        .description
                                                )}
                                            </TableCell>
                                            <TableCell align="left">
                                                {cogs.capitalize(
                                                    this.state.invoice.category
                                                )}
                                            </TableCell>
                                            <TableCell align="left">
                                                {cogs.capitalize(
                                                    this.state.invoice
                                                        .subscription
                                                )}
                                            </TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="right">
                                                KES{" "}
                                                {this.state.invoice.amount
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">
                                                <Typography
                                                    variant="body2"
                                                    align="right"
                                                >
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            align="right"
                                                        >
                                                            Sub Total
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            KES{" "}
                                                            {this.state.invoice.amount
                                                                .toString()
                                                                .replace(
                                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                                    ","
                                                                )}
                                                        </Grid>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">
                                                <Typography
                                                    variant="body2"
                                                    align="right"
                                                >
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            16.00% VAT
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            KES{" "}
                                                            {this.state.invoice.tax
                                                                .toString()
                                                                .replace(
                                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                                    ","
                                                                )}
                                                        </Grid>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">
                                                <Typography
                                                    variant="subtitle1"
                                                    align="right"
                                                >
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            Total
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            KES{" "}
                                                            {this.state.invoice.taxed_amount
                                                                .toString()
                                                                .replace(
                                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                                    ","
                                                                )}
                                                        </Grid>
                                                    </Grid>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid item xs={12} style={{ padding: "2% 0%" }}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">
                                            <Link to="/manager/dashboard">
                                                <Button color="primary">
                                                    Proceed to Dashboard
                                                </Button>
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} align="right">
                                        {/* <Pdf
                                            targetRef={this.myRef}
                                            filename={
                                                this.state.invoice.number +
                                                ".pdf"
                                            }
                                            options={pdfOptions}
                                        >
                                            {({ toPdf }) => (
                                                <Button
                                                    onClick={toPdf}
                                                    color="primary"
                                                    endIcon={
                                                        <FileDownloadIcon />
                                                    }
                                                >
                                                    Download
                                                </Button>
                                            )}
                                        </Pdf>

                                        <Button
                                            onClick={() =>
                                                this.setState(
                                                    { payments: false },
                                                    () => {
                                                        window.print();
                                                    }
                                                )
                                            }
                                            color="primary"
                                            endIcon={<PrintIcon />}
                                        >
                                            Print
                                        </Button> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {this.state.loading && (
                        <div
                            style={{
                                textAlign: "center",
                                height: "100vh",
                            }}
                        >
                            <CircularProgress
                                style={{
                                    backgroundColor: "#fff",
                                    marginTop: "20%",
                                    borderRadius: "100%",
                                    padding: ".3%",
                                    boxShadow:
                                        "0rem 0rem 1rem rgba(0, 0, 0, 0.15)",
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default checkoutPage;
