import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import { Grid, Typography, Link } from "@material-ui/core";

class TermsandConditionsPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Cookie Policy</title>
                    <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid
                    container
                    style={{ paddingTop: "5%", paddingBottom: "5%" }}
                    className={"add-space"}
                >
                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ margin: "0% auto", padding: "5%" }}
                        className={"add-space"}
                    >
                        <Typography variant="h4" component="h1" gutterBottom>
                            Cookie Policy
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Effective Date: October 12, 2021
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            WapiDoc provides this Cookie Policy to users to
                            explain how cookies and similar technologies
                            (collectively, “Cookie(s)”) are used when You visit
                            Our Site. Our “Site” includes www.wapidoc.com,
                            emails We send, Our mobile apps, any other
                            applications owned and operated by WapiDoc,
                            third-party websites with which We have an
                            advertising arrangement, and any other services that
                            display this Cookie Policy. This policy explains
                            what these technologies are and how they may be
                            used, as well as options available to You to control
                            their use.
                            <br />
                            <br />
                            WapiDoc reserves the right, at its sole discretion,
                            to change this Cookie Policy at any time. If We
                            decide to change the Cookie Policy, We will then
                            post the changes in appropriate places on the Site
                            so that You are aware of the changed Cookie Policy.
                            Your continued use of the Site after the Effective
                            Date for any such changes to the Cookie Policy will
                            be deemed conclusively to constitute Your acceptance
                            of and agreement to the changed Cookie Policy.
                            Consequently, You should frequently review this
                            Privacy Policy.
                            <br />
                            <br />
                            To the extent any personal information is collected
                            through Cookies, Our Privacy Policy applies and
                            complements this Cookie Policy.
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            1. What are Cookies?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            A “cookie” is a text file that a website sends to a
                            visitor’s browser and is stored on Your computer,
                            mobile phone, tablet, or other connected device
                            (collectively, “Computer”). Cookies can store
                            information about Your visit to and use of Our Site
                            or other websites, can make that information
                            available to Us or third parties, and can help Us
                            offer You functionalities on Our Sites.
                            <br />
                            <br />
                            We may use other similar technologies, including web
                            beacons (also called “pixels,” “tags,” or “clear
                            GIFs”) and JavaScript, to fulfill similar functions
                            as cookies. A web beacon is often a one-pixel,
                            transparent image (although it can be a larger or
                            visible image as well), located on a website or in
                            an email or other type of message and retrieved from
                            a website on the Internet. Similarly, our mobile
                            application may integrate “software development
                            kits” (SDKs) provided by Ourselves or third parties.
                            Both web beacons and SDKs are used for similar
                            purposes as cookies, and are treated as “Cookies”
                            for purposes of this Cookie Policy.
                            <br />
                            <br />
                            When We place and access Cookies on Your Computer,
                            these Cookies are known as “first-party” Cookies.
                            Cookies may also be placed and accessed by
                            third-party advertisers or Our third-party service
                            providers; these are known as “third-party” Cookies.
                            When third-party Cookies are used, third parties may
                            receive information about Your visit to Our Site and
                            use it to present content or advertisements to You
                            on Our Sites, to recognize You and customize Your
                            experience at third-party sites, or for any other
                            purpose described in this Cookie Policy.
                            Additionally, Cookies can either be “persistent”
                            (i.e., they remain on Your Computer until You delete
                            them) or temporary (i.e., they last only until You
                            close Your browser).
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            2. Kinds of Cookies We Use?
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Our Site may use the following types of Cookies:
                            <br />
                            <br />
                            <b>· Strictly Necessary Cookies:</b> These are
                            Cookies we use to provide the Site and its services
                            and features, as well as to prevent fraudulent
                            activity, improve security, and for system
                            administration. Without these necessary Cookies, We
                            may not be able to provide the Site or certain
                            services or features to You.
                            <br />
                            <br />
                            <b>· Functionality and Preference Cookies:</b> We
                            may use Cookies to collect information about Your
                            choices and preferences, such as Your user settings,
                            and customize the Site accordingly. For example,
                            WapiDoc uses Cookies to recognize registered users
                            and help manage user access privileges to this Site.
                            <br />
                            <br />
                            <b>· Performance Cookies:</b> These Cookies collect
                            information about Your use of the Site and enable Us
                            to track Site usage and traffic patterns, assess the
                            performance of the Site, and improve the way it
                            works. For example, they may show Us frequently
                            visited pages, help Us record any difficulties You
                            have with the Site, show Us whether Our advertising
                            is effective, and help Us estimate the Site’s
                            audience size.
                            <br />
                            <br />
                            <b>· Targeting Cookies:</b> We and third-party
                            advertisers may use Cookies to show advertisements
                            for goods or services targeted to your interests
                            based on information submitted by users to our Site,
                            or inferred by the actions of users of Our Site.
                            These advertisements may display on our Site, on
                            other websites, or in social media networks.
                            Targeting Cookies can also help Us to personalize a
                            user’s viewing experience on this Site or on other
                            third-party sites with which WapiDoc has an
                            advertising arrangement, and otherwise help Us
                            provide You with content on the Sites that is
                            tailored to You and Your interests.
                            <br />
                            <br />
                            <b>· Social Media Cookies:</b> We may use Cookies to
                            enable You to log into Our Site or share content
                            from the Sites using Your social media account.
                            Cookies may also help Us place advertisements in
                            social media networks
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            3. Options for Managing Cookies
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Although most web browsers are initially set up to
                            accept Cookies, if You prefer, You may decline the
                            placement of a Cookie on Your hard drive by using
                            the appropriate feature(s) of Your web browser
                            software (if available) to delete the Cookie. Use
                            your browser’s “Help” function for more information.
                            You can also learn more about Cookies at
                            <Link href="http://allaboutcookies.org/">
                                http://allaboutcookies.org/
                            </Link>
                            . Please understand that certain areas within this
                            Site may not function properly if the web browser
                            will not accept Cookies.
                            <br />
                            <br />
                            Blocking cookies in your web browser may not prevent
                            cross-site tracking of your online activity using
                            other online tracking technologies. In addition to
                            managing your cookie settings for your browser, you
                            can opt-out of tracking directly with the businesses
                            that operate the tracking technologies.
                        </Typography>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default TermsandConditionsPage;
