import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import { Grid, Typography, Link, Divider } from "@material-ui/core";

class RequirementsPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Requirements</title>
                    <meta
                        name="description"
                        content="A Quick Guide on WapiDoc's Application
                                    Requirements"
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container className={"welcome-section add-space"}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                className={"add-space"}
                                style={{ padding: "16% 3% 0% 3%" }}
                            >
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    style={{
                                        fontSize: "20px",
                                        color: "#ffffff",
                                    }}
                                >
                                    Simplified steps to get you started
                                </Typography>
                                <Typography
                                    component="h1"
                                    variant="h3"
                                    align="left"
                                    style={{
                                        color: "#ffffff",
                                    }}
                                >
                                    A Quick Guide on WapiDoc's Application
                                    Requirements
                                </Typography>
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "3%" }}>
                        <Typography
                            variant="h3"
                            align="center"
                            style={{ marginBottom: "2%" }}
                        >
                            WapiDoc is available to
                        </Typography>
                        <Grid container>
                            <Grid
                                item
                                xs={6}
                                md={4}
                                align="center"
                                style={{ padding: "3%" }}
                            >
                                <img
                                    src={"../images/multiple.png"}
                                    alt="How it Works Step 1"
                                    width="40%"
                                />
                                <Typography
                                    variant="h6"
                                    align="center"
                                    style={{ margin: "3% 0%" }}
                                >
                                    Institutions
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={4}
                                align="center"
                                style={{ padding: "3%" }}
                            >
                                <img
                                    src={"../images/hospital.png"}
                                    alt="How it Works Step 2"
                                    width="40%"
                                />
                                <Typography
                                    variant="h6"
                                    align="center"
                                    style={{ margin: "3% 0%" }}
                                >
                                    Hospitals
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={4}
                                align="center"
                                style={{ padding: "3%" }}
                            >
                                <img
                                    src={"../images/medical-center.png"}
                                    alt="How it Works Step 3"
                                    width="40%"
                                />
                                <Typography
                                    variant="h6"
                                    align="center"
                                    style={{ margin: "3% 0%" }}
                                >
                                    Medical Centers
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={4}
                                align="center"
                                style={{ padding: "3%" }}
                            >
                                <img
                                    src={"../images/consultant.png"}
                                    alt="How it Works Step 3"
                                    width="40%"
                                />
                                <Typography
                                    variant="h6"
                                    align="center"
                                    style={{ margin: "3% 0%" }}
                                >
                                    Consultants
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={4}
                                align="center"
                                style={{ padding: "3%" }}
                            >
                                <img
                                    src={"../images/clinic.png"}
                                    alt="How it Works Step 3"
                                    width="40%"
                                />
                                <Typography
                                    variant="h6"
                                    align="center"
                                    style={{ margin: "3% 0%" }}
                                >
                                    Clinics
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={4}
                                align="center"
                                style={{ padding: "3%" }}
                            >
                                <img
                                    src={"../images/mobile.png"}
                                    alt="How it Works Step 3"
                                    width="40%"
                                />
                                <Typography
                                    variant="h6"
                                    align="center"
                                    style={{ margin: "3% 0%" }}
                                >
                                    Mobile/Online Doctors
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{ padding: "5%", background: "#f7f7f7" }}
                    >
                        <Grid container className={"requirements-list"}>
                            <Typography
                                variant="h3"
                                align="center"
                                style={{ margin: "0% 10% 5% 5%" }}
                            >
                                What you need for your institution to be
                                registered on WapiDoc.
                            </Typography>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                style={{
                                    padding: "3%",
                                }}
                            >
                                <Typography variant="h2">1</Typography>
                                <Divider style={{ margin: "3% 0%" }} />
                                <Typography
                                    variant="h4"
                                    style={{ color: "#032659" }}
                                >
                                    A valid licence approved by the relevant
                                    authority.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ padding: "3%" }}>
                                <Typography variant="h2">2</Typography>
                                <Divider style={{ margin: "3% 0%" }} />
                                <Typography
                                    variant="h4"
                                    style={{ color: "#032659" }}
                                >
                                    G.P.R.S Coordinates
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ padding: "3%" }}>
                                <Typography variant="h2">3</Typography>
                                <Divider style={{ margin: "3% 0%" }} />
                                <Typography
                                    variant="h4"
                                    style={{ color: "#032659" }}
                                >
                                    Institution Pictures
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ padding: "3%" }}>
                                <Typography variant="h2">4</Typography>
                                <Divider
                                    style={{
                                        margin: "3% 0%",
                                        color: "#032659",
                                    }}
                                />
                                <Typography
                                    variant="h4"
                                    style={{ color: "#032659" }}
                                >
                                    An application and registration fee based on
                                    your institution category.
                                </Typography>
                                <br />
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    paragraph
                                >
                                    Learn more about{" "}
                                    <Link href="/managers/pricing">
                                        pricing.
                                    </Link>
                                </Typography>
                            </Grid>

                            {/* <Grid item xs={6}>
                                <Typography
                                    variant="h3"
                                    align="left"
                                    style={{ margin: "0% 0% 3% 0%" }}
                                >
                                    If you have a medical business licence, you
                                    are a few steps away from being registered
                                    on WapiDoc.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    paragraph
                                    align="left"
                                >
                                    Have a look at WapiDoc's{" "}
                                    <Link href="/managers/application-requirements">
                                        application requirements
                                    </Link>
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default RequirementsPage;
