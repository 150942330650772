import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Helmet } from "react-helmet";
import jwtDecode from "jwt-decode";
import {
    Grid,
    Typography,
    TextField,
    InputAdornment,
    Box,
} from "@mui/material";
import { Menu } from "../components/menu/menu";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { clientAuthorization, GetUserData } from "../helpers/user";
import { Loading } from "../components/loading";

export default function ReferralPage() {
    clientAuthorization(jwtDecode, window.location);

    const [copied, setCopied] = useState({ app: false, manager: false });

    let { loading, data } = GetUserData(jwtDecode, useQuery, gql);

    const copyToClipboard = (ref) => {
        var copyText = document.getElementById(ref);

        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);

        setCopied({ ...copied, [ref]: true });
    };

    return (
        <div>
            <Helmet>
                <title>Referral Program</title>
                <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
            </Helmet>
            <Menu />
            {data && (
                <Grid
                    container
                    className={"add-space"}
                    style={{ padding: "2%" }}
                >
                    <Grid
                        className={"add-space"}
                        item
                        xs={12}
                        md={6}
                        style={{
                            margin: "10% auto",
                            backgroundColor: "#ffffff",
                            padding: "2%",
                            borderRadius: "5px",
                            boxShadow: "0 0.1rem 1rem rgba(0, 0, 0, 0.15)",
                        }}
                    >
                        <Box textAlign="center">
                            <img
                                src="https://ik.imagekit.io/9vm1rbs0vzz/public/ShareLink_5CbyBs6m9wS.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1645063905486"
                                alt="Share Link"
                                style={{
                                    width: "200px",
                                    margin: "0% auto",
                                }}
                            />
                            <br />
                            <br />

                            <Typography variant="h6">
                                Grow our community by sharing these links
                            </Typography>
                        </Box>

                        <br />
                        <Typography
                            variant="subtitle1"
                            textAlign={"left"}
                            style={{ padding: "0% 1%" }}
                        >
                            Share with friends
                        </Typography>
                        <TextField
                            disabled
                            id="app"
                            name="app"
                            multiline
                            maxRows={4}
                            variant="outlined"
                            value={`https://play.google.com/store/apps/details?id=com.wapidoc.app&referrer=utm_source=webapp&utm_medium=${data.singleClient.referralCode}`}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {copied.app ? (
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                                style={{
                                                    color: "green",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                copied &nbsp;
                                                <CheckCircleIcon
                                                    style={{
                                                        marginBottom: "-10%",
                                                        color: "#5DC726",
                                                    }}
                                                />
                                            </Typography>
                                        ) : (
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                                style={{
                                                    color: "#939393",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    copyToClipboard("app");
                                                }}
                                            >
                                                copy &nbsp; &nbsp;
                                                <ContentCopyIcon
                                                    style={{
                                                        marginBottom: "-10%",
                                                    }}
                                                />
                                            </Typography>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <br />
                        <Typography
                            variant="subtitle1"
                            textAlign={"left"}
                            style={{ padding: "0% 1%" }}
                        >
                            Share with health care providers
                        </Typography>
                        <TextField
                            disabled
                            id="manager"
                            name="manager"
                            margin="normal"
                            variant="outlined"
                            value={`https://wapidoc.com/manager/signup/${data.singleClient.referralCode}`}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {copied.manager ? (
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                                style={{
                                                    color: "green",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                copied &nbsp;
                                                <CheckCircleIcon
                                                    style={{
                                                        marginBottom: "-10%",
                                                        color: "#5DC726",
                                                    }}
                                                />
                                            </Typography>
                                        ) : (
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                                style={{
                                                    color: "#939393",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    copyToClipboard("manager");
                                                }}
                                            >
                                                copy &nbsp; &nbsp;
                                                <ContentCopyIcon
                                                    style={{
                                                        marginBottom: "-10%",
                                                    }}
                                                />
                                            </Typography>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            )}
            {loading && <Loading />}
        </div>
    );
}
