import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Menu } from "../components/menu/menu";
import Footer from "../components/footer";

import { Grid, Typography, Button } from "@material-ui/core";

class RequirementsPage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Careers</title>
                    <meta
                        name="description"
                        content="We’re looking for creative minds to help us continue building
the nation's best health care directory."
                    />
                </Helmet>
                <Menu background={"transparent"} />
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container className="careers">
                            <Grid item xs={12} style={{ padding: "8% 5%" }}>
                                <Typography
                                    variant="h2"
                                    align="center"
                                    style={{ color: "#CF053E" }}
                                >
                                    Join our Team
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="center"
                                    style={{
                                        fontSize: "20px",
                                    }}
                                >
                                    We’re looking for creative minds to help us
                                    continue building <br />
                                    the nation's best health care directory.
                                </Typography>
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "5% 2%" }}>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <div className={"career-div"}>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ paddingBottom: "2%" }}
                                    >
                                        Field Sales/Marketing Representative
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        We are looking to recruit a Field
                                        Sales/Marketing Representative who will
                                        grasp the consumer needs and align them
                                        with winning marketing strategies.
                                    </Typography>
                                    <Button
                                        className="submit-button"
                                        disableElevation
                                        variant="contained"
                                        href={`/careers/field-sales-marketing-teams`}
                                        style={{ padding: "1.5% 4%" }}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        );
    }
}

export default RequirementsPage;
