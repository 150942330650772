import React from "react";
import {
    Grid,
    Typography,
    Avatar,
    Rating,
    Divider
} from "@mui/material";
import { capitalize } from "../../helpers/string";

export const ReviewList = ({ reviews }) => {
    return reviews.length > 0 ? (
        reviews.map(({ _id, client, rating, comment, service }) => {
            return (
                <Grid container key={_id}>
                    <Grid item xs={1}>
                        <Avatar
                            alt={`${client.firstName}`}
                            src={`https://ik.imagekit.io/9vm1rbs0vzz/clients/profile_pictures/${client.profilePhoto}?ik-sdk-version=javascript-1.4.3&updatedAt=1642490516065`}
                            style={{ width: "80%", height: "auto" }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography variant="subtitle1" align="left">
                            {capitalize(client.firstName)}{" "}
                            {capitalize(client.lastName)}
                        </Typography>
                        <div style={{ display: "flex" }}>
                            <Typography variant="body1" align="left">
                                {rating}
                            </Typography>
                            <Rating
                                name="simple-controlled"
                                precision={0.1}
                                value={rating}
                                readOnly
                                size="small"
                                style={{
                                    marginTop: ".3%",
                                    marginLeft: ".5%",
                                }}
                            />
                        </div>
                        <Typography variant="subtitle2" align="left">
                            {capitalize(service.name)} Services
                        </Typography>
                        <Typography variant="body1" align="left">
                            {comment}
                        </Typography>
                    </Grid>
                    <Divider
                        style={{
                            margin: "3% 0%",
                            width: "100%",
                            color: "#dfdfdf",
                        }}
                    />
                </Grid>
            );
        })
    ) : (
        <img
            style={{
                width: "35%",
                height: "auto",
                display: "flex",
                margin: "5% auto",
            }}
            src={
                "https://ik.imagekit.io/9vm1rbs0vzz/public/Empty_sK1ZlJORw.svg"
            }
            alt="No Data"
        />
    );
};
